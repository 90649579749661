import { DateTime } from "luxon"
import { analyses, samples } from "state_management"
import { useDispatch, useSelector } from "react-redux"
import { useClosePopup } from "components/Popup/useClosePopup"
import { useState } from "react"

import { chooseOptimalColor } from "utils/scripts/schedule"

import { FiCheck } from "react-icons/fi"
import { BsCalendar2 } from "react-icons/bs"
import { AiOutlineExclamation } from "react-icons/ai"
import { HiOutlineArrowNarrowDown } from "react-icons/hi"
import { FaCircle } from "react-icons/fa"
import { FiCircle, FiAlertTriangle } from "react-icons/fi"


export default function SampleRowElement({
  group,
  maxWidth,
  activeSample,
  setActiveSample,
  samplePriority,
  setSamplePriority,
  filteredSampleGroups,
  setActiveSampleAnalysis,
}) {
  
  const dispatch = useDispatch()
  const [groupPriority, setGroupPriority] = useState(group.maxPriority)
  const shouldBeDoneSamples = useSelector((state) => state.sampleView.shouldBeDoneSamples)
  
  // Hook for a RefObject that checks if popup should close
  let domNode = useClosePopup(() => {
    setActiveSample(null)
  })

  const prioNumberMeaning = {1: "(High)", 2:"(Medium)", 3:"(Low)"}

  return (
    <div className="samplegroup-row" key={group.sampleName}>
      <h3
        style={{ width: maxWidth }}
        title={group.sampleName}
        onClick={() => {
          setActiveSample(!activeSample ? group.sampleName : null)
          setSamplePriority(filteredSampleGroups.find((obj) => obj.sampleName === group.sampleName).maxPriority)
        }}
      >
        <span className="sampleDeadline">
          {DateTime.fromISO(group.minDeadline, { zone: 'utc' }).toFormat("dd/MM")}
        </span>
        <span className="sampleName">{group.sampleName}</span>
        <span className="sampleIcons">
          {groupPriority === 1 && <AiOutlineExclamation title="The sample has highest priority" />}
          {groupPriority === 3 && <HiOutlineArrowNarrowDown title="The sample has lowest priority" />}
          {group.isDone && <FiCheck title="The sample is done" />}
        </span>
        {shouldBeDoneSamples.includes(group.sampleName) && (<FiAlertTriangle className="alert-triangle-sample" title="Sample in progress on done analysis" />)}
        {activeSample === group.sampleName && (
          <div className="sampleview-dropdown" ref={domNode}>
            <div className="sampleview-dropdown-content">
              <header className="sampleview-dropdown-header">Priority</header>
              {[1, 2, 3].map((priority) => {
                return (
                  <div
                    key={priority}
                    className="sampleview-dropdown-item"
                    onClick={() => {
                      if (samplePriority !== priority) {
                        setGroupPriority(priority)
                        dispatch(samples.changePriority(priority, activeSample)).then (() => {
                          dispatch(analyses.fetchUnplanned())
                        })
                      }
                    }}
                  >
                    {priority + " " + prioNumberMeaning[priority]}{" "}
                    {groupPriority === priority ? (
                      <FaCircle style={{ width: "0.75rem", height: "0.75rem" }} />
                    ) : (
                      <FiCircle
                        style={{
                          width: "0.75rem",
                          height: "0.75rem",
                          color: "white",
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </h3>
      <div className="samplerow-analyses-grouped">
        {group.analyses.map((analysis, idx) => (
          <div
            className="samplerow-analysis"
            key={idx}
            style={{ backgroundColor: analysis.color, color: chooseOptimalColor(analysis.color), justifyContent: analysis.shouldBeDone ? 'space-between' : ''}}
            title={analysis.name + "\nObservation Id: " + analysis.observationId}
            onClick={(event) => {
              let foundAnalysis = filteredSampleGroups
                .find((sample) => sample.sampleName === group.sampleName)
                .analyses.find((sampleAnalysis) => sampleAnalysis.name === analysis.name && sampleAnalysis.observationId === analysis.observationId)
              
              if (foundAnalysis.analysesPlanned) {
                foundAnalysis.tasks.sort((a, b) => DateTime.fromISO(a.end) - DateTime.fromISO(b.end))
              }
              
              const buttonRect = event.target.getBoundingClientRect();
              foundAnalysis.position = ({
                top: buttonRect.top + (1.5*buttonRect.height),
                left: buttonRect.left + (buttonRect.width / 2),
                height: buttonRect.height,
              })
              setActiveSampleAnalysis({ ...foundAnalysis, sampleName: group.sampleName, productName: group.productName })
            }}
          >
            {analysis.tasksStopped ? (
              <FiCheck title="The analysis is done" size="1.5em" />
            ) : analysis.analysesPlanned ? (
              <BsCalendar2 title="The analysis is planned" size="1.2em" />
            ) : (
              ""
            )}
            <span style={{pointerEvents: 'none'}}>{analysis.name}</span>
            {analysis.shouldBeDone && (<FiAlertTriangle className="alert-triangle-analysis" title="Sample in progress on done analysis" />)}
          </div>
        ))}
      </div>
    </div>
  )
}
