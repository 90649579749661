import React, { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { DayPicker } from "react-day-picker"
import { samples, teamStatus, visual, waiting } from "state_management"
import { format } from 'date-fns'
import 'react-day-picker/style.css'
import { useTimer } from "utils/hooks/useTimer"

export default function Calendar({ dateFrom, dateTo, lastDisabledDay, IAPconfig, onClearTimer }) {
  const dispatch = useDispatch()
  const dateFromJsDate = dateFrom ? dateFrom.toJSDate() : null
  const dateToJsDate = dateTo ? dateTo.toJSDate() : null
  const IAPconfigRef = useRef(IAPconfig)
  const modifiers = {
    mondays:  (date) => date.getDay() === 1,
    sundays:  (date) => date.getDay() === 0,
  }

  useEffect(() => {
    IAPconfigRef.current = IAPconfig
  }, [IAPconfig])

  const runSampleAllocation = () => {
    const currentIAPconfig = IAPconfigRef.current
    if (currentIAPconfig.timePeriod.from && currentIAPconfig.timePeriod.to) {
      dispatch(teamStatus.setIAPconfiguration(currentIAPconfig.timePeriod.from, currentIAPconfig.timePeriod.to)).then(() => {
        dispatch(waiting.changeSampleAllocating(true))
        dispatch(samples.runSampleAllocation()).finally(() => dispatch(waiting.changeSampleAllocating(false)))
      })
    }
  }

  const [startTimer, clearTimer] = useTimer(runSampleAllocation, 4000)

  useEffect(() => {
    onClearTimer.current = clearTimer
  }, [clearTimer, onClearTimer])

  const handleDayClick = (range) => {
    if (!range) {
      return null;
    }
    dispatch(
      visual.setIAPconfiguration({
        ...IAPconfig,
        timePeriod: {
          to: range.to ? format(range.to, 'yyyy-MM-dd') : null,
          from: range.from ? format(range.from, 'yyyy-MM-dd') : null,
        },
      })
    )

    // Reset the timer on each day click
    clearTimer()
    startTimer()
  }

  return (
    <>
      <p className="LeftMenu-reset-date-btn">
        {!dateFrom && !dateTo && "Please select the first day."}
        {dateFrom && !dateTo && "Please select the last day."}
        {dateFrom && dateTo && `${dateFrom.toLocaleString()} to ${dateTo.toLocaleString()}. `}
        {dateFrom && dateTo && (
          <span
            onClick={() => {
              dispatch(visual.setIAPconfiguration({ ...IAPconfig, timePeriod: { from: null, to: null } }))
              clearTimer()
            }}
          >
            Reset
          </span>
        )}
      </p>
      <DayPicker
        defaultMonth={dateFromJsDate || lastDisabledDay.toJSDate()}
        className="Selectable"
        mode="range"
        modifiers={modifiers}
        weekStartsOn={1}
        selected={dateFromJsDate ? { from: dateFromJsDate, to: dateToJsDate } : undefined}
        modifiersClassNames={{
          selected: 'selected',
          start: 'start',
          end: 'end',
          mondays: 'mondays',
          sundays: 'sundays',
        }}
        onSelect={handleDayClick}
        firstDayOfWeek={1}
        disabled={[{ before: lastDisabledDay.toJSDate() }]}
      />
    </>
  )
}

