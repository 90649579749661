import React, {useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"

import { RightMenu, LeftMenu, ScheduleButtons } from "components"
import ScheduleScreenContent from "./ScheduleScreenContent"

import "./index.scss"
import { constraintChecks } from "state_management"

function UpdateActions(){
  const dispatch = useDispatch()
  const actions = useSelector((state) => state.projectActions.plannedProjectActions)
  const tasks = useSelector((state) => state.analysisTasks)
  useEffect(() => {constraintChecks.allConstraintChecks(dispatch)}, [actions, tasks]) // eslint-disable-line react-hooks/exhaustive-deps
  return (<div></div>)
}

export default function ScheduleScreen() {
  const isMember = useSelector((state) => state.auth.isMember)
  const allMembers = useSelector((state) => state.members)

  const rightMenuVisible = useSelector((state) => state.visual.rightMenuVisible)
  const leftMenuVisible = useSelector((state) => state.visual.leftMenuVisible)
  const showRightMenu = useSelector((state) => state.teamStatus.showProjects || state.teamStatus.showAnalyses)

  const members = Object.values(allMembers).filter((member) => member.showOnPlan)
  return (
    <div className="ScheduleScreen">
      {!isMember && <LeftMenu open={leftMenuVisible} />}
      {showRightMenu && <RightMenu open={rightMenuVisible} />}
      <ScheduleButtons type="team-plan" moveLeft={leftMenuVisible} moveRight={rightMenuVisible && showRightMenu} />
      <ScheduleScreenContent
        members={members}
        moveLeft={leftMenuVisible}
        moveRight={rightMenuVisible && showRightMenu}
      />
      <UpdateActions />
    </div>
  )
}
