// State for hovering on the schedule
// TODO: Remove this, and use state in the relevant screens instead!
// By storing it in the redux state, when going between screens, we sometimes
// get elements that are constantly hovered. Just a minor visual annoyance.

export const initialState = {
  hour: null,
  memberId: null,
  whiteTaskId: null,
  analysisId: null,
  projectId: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "scheduleHover/setMemberHour":
      return { ...state, hour: action.hour, memberId: action.memberId }
    case "scheduleHover/setWhiteTaskId":
      return { ...state, whiteTaskId: action.id }
    case "scheduleHover/setAnalysisId":
      return { ...state, analysisId: action.id }
    case "scheduleHover/setProjectId":
      return { ...state, projectId: action.id }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export function setMemberHour(hour, memberId) {
  return { type: "scheduleHover/setMemberHour", hour, memberId }
}

export function setWhiteTaskId(id) {
  return { type: "scheduleHover/setWhiteTaskId", id }
}

export function setAnalysisId(id) {
  return { type: "scheduleHover/setAnalysisId", id }
}

export function setProjectId(id) {
  return { type: "scheduleHover/setProjectId", id }
}
