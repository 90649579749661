import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import { Loading, Popup, ConfirmDialog } from "components"
import SampleRowElement from "./SampleRowElement"

import "./index.scss"

import { Button } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import { Text, Container } from "components/Form"
import { FiInfo, FiAlertTriangle } from "react-icons/fi"
import { sampleView, samples } from "state_management"
import useLocalStorage from "utils/hooks/useLocalStorage"

import isOnMobile from "utils/scripts/isOnMobile"
import { dynamicSortMultiple } from "utils/scripts/sort"

export default function SampleView() {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.sampleView.isLoading)
  const needsRefresh = useSelector((state) => state.sampleView.needsRefresh)
  const sampleGroups = useSelector((state) => state.sampleView.sampleGroups)
  const sampleGroupsSortedByProduct = useSelector((state) => state.sampleView.sampleGroupsSortedByProduct)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const members = useSelector((state) => state.members)
  const shouldBeDoneSamples = useSelector((state) => state.sampleView.shouldBeDoneSamples)
  const [filterString, setFilterString] = useState("")
  const [failConfirm, setFailConfirm] = useState(false)

  const [activeSample, setActiveSample] = useState("")
  const [samplePriority, setSamplePriority] = useState(null)
  const [activeSampleAnalysis, setActiveSampleAnalysis] = useState({})
  const [sortByProduct, setSortByProduct] = useLocalStorage("sampleView-sortByProduct", false)
  const [includeDone, setIncludeDone] = useLocalStorage("sampleView-includeDone", false)
  const sampleViewRef = useRef(null);
  const shouldShowUnplannedPopup = Object.values(activeSampleAnalysis).length !== 0 && !activeSampleAnalysis?.analysesPlanned;

  useEffect(() => {
    if (needsRefresh) {
      dispatch(sampleView.fetch())
    }
  }, [needsRefresh, dispatch])

  useEffect(() => {
    let timer;
    if (shouldShowUnplannedPopup) {
      // Set the timeout to hide the popup
      timer = setTimeout(() => {
        setActiveSampleAnalysis({});
      }, 4000);
  
      const handleScroll = () => {
        setActiveSampleAnalysis({});
        clearTimeout(timer);
      };
  
      // Ensure the element exists before adding the event listener
      const element = sampleViewRef.current;
      if (element) {
        element.addEventListener('scroll', handleScroll, { passive: true });
      }
  
      // Cleanup function
      return () => {
        clearTimeout(timer);
        if (element) {
          element.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [shouldShowUnplannedPopup]);

  const filteredSampleGroups = useMemo(() => {
    // Ensure the arrays are not null before proceeding
    if (!sampleGroups || !sampleGroupsSortedByProduct) {
      return [];
    }
    
    // Filter the sample groups based on the filterString and remove null values
    let sortedSampleGroups = (!sortByProduct ? sampleGroups : sampleGroupsSortedByProduct)
      .map((product) => {
        if (!sortByProduct) {
          return filterString ? (product.sampleName.toUpperCase().includes(filterString.toUpperCase()) ? product : null) : product;
        } else {
          const [key, value] = Object.entries(product)[0];
          const filteredSamples = key.toUpperCase().includes(filterString.toUpperCase())
            ? value
            : value.filter((sample) => sample.sampleName.toUpperCase().includes(filterString.toUpperCase()));
          const isAllDone = filteredSamples.every((sample) => sample.isDone);
          if (!includeDone && isAllDone) {
            return null;
          }
          return filteredSamples.length > 0
            ? { [key]: filteredSamples.filter((sample) => includeDone || !sample.isDone) }
            : null;
        }
      })
      .filter((product) => product !== null);

    // Sort the filtered sample groups by minDeadline and sampleName
    if (!sortByProduct) {
      // Sort the whole array by sampleName if not sorting by product
      sortedSampleGroups = sortedSampleGroups.sort(dynamicSortMultiple('minDeadline', 'sampleName'));
    } else {
      // When sorting by product, sort each product group's samples
      sortedSampleGroups = sortedSampleGroups.map((group) => {
        const [productName, samples] = Object.entries(group)[0];
        
        // Sort the samples within this group
        const sortedSamples = samples.sort(dynamicSortMultiple('minDeadline', 'sampleName'));
  
        // Return the sorted group with the product name key
        return { [productName]: sortedSamples };
      });
    }
  
    return sortedSampleGroups;
  }, [sampleGroups, sampleGroupsSortedByProduct, sortByProduct, filterString, includeDone]);

  if (isLoading || needsRefresh) {
    return <Loading title="Loading samples" />
  }

  const initialValue = 0
  // Compute width of blue-box. Need to take width of deadline and icons into account: '70px' and '85px'
  const maxWidth =
    "min(calc(" +
    sampleGroups.reduce((acc, curr) => Math.max(curr.sampleName.length, acc), initialValue) +
    "rem + 70px + 85px), 33%)"
  if (sampleGroups.length !== 0) {
    return (
      <>
        <div className="SampleView" ref={sampleViewRef}>
          <div className="sampleView-overhead-container">
            <div className="sampleview-filter-box" style={{ width: maxWidth }}>
              <InputGroup>
                <Text
                  placeholder={`Filter by Sample${sortByProduct ? "/Product Name" : " Name"}`}
                  value={filterString}
                  onChange={setFilterString}
                />
              </InputGroup>
            </div>
            <div className="mb-3 sampleview-checkbox">
              <Form.Check
                checked={sortByProduct}
                type={"checkbox"}
                id={`sort-by-product`}
                label={`Sort by product`}
                onChange={(e) => setSortByProduct(!sortByProduct)}
              />
              <Form.Check
                checked={includeDone}
                type={"checkbox"}
                id={`include-done-samples`}
                label={`Include done samples`}
                onChange={(e) => setIncludeDone(!includeDone)}
              />
            </div>
            {shouldBeDoneSamples.length > 0 && (<FiAlertTriangle className="alert-triangle" title="Sample in progress on done analysis" />)}
          </div>
          {filteredSampleGroups.length !== 0 && !sortByProduct ? (
            filteredSampleGroups.map((sample) => {
              if (!includeDone && sample.isDone) {
                return null // return null if the condition is true
              } else {
                return (
                  <SampleRowElement
                    key={sample.sampleName}
                    group={sample}
                    maxWidth={maxWidth}
                    activeSample={activeSample}
                    samplePriority={samplePriority}
                    filteredSampleGroups={filteredSampleGroups}
                    setActiveSampleAnalysis={setActiveSampleAnalysis}
                    setActiveSample={setActiveSample}
                    setSamplePriority={setSamplePriority}
                  />
                )
              }
            })
          ) : filteredSampleGroups.length !== 0 && sortByProduct ? (
            filteredSampleGroups.map((product) => {
              const currentProductSamples = product[Object.keys(product)[0]]
              return (
                <React.Fragment key={Object.keys(product)[0]}>
                  <h3>{Object.keys(product)[0] === "null" ? "Samples without product" : Object.keys(product)[0]}</h3>
                  {currentProductSamples.map((sample) => (
                    <SampleRowElement
                      key={sample.sampleName}
                      group={sample}
                      maxWidth={maxWidth}
                      activeSample={activeSample}
                      samplePriority={samplePriority}
                      filteredSampleGroups={sampleGroups}
                      setActiveSampleAnalysis={setActiveSampleAnalysis}
                      setActiveSample={setActiveSample}
                      setSamplePriority={setSamplePriority}
                    />
                  ))}
                </React.Fragment>
              )
            })
          ) : (
            <h3>No search results</h3>
          )}

          {Object.values(activeSampleAnalysis).length !== 0 && (
            <>
              {activeSampleAnalysis?.analysesPlanned ? (
                <Popup
                  onCancel={() => {
                    if (!failConfirm) {
                      setActiveSampleAnalysis({})
                    }
                  }}
                  className="sampleview-task-table-popup"
                  center
                >
                  <div className="sampleview-task-table-popup-content">
                    <h4 className="sampleview-task-title">{activeSampleAnalysis.name}</h4>
                    <div className="sampleview-task-main">
                      <Table bordered hover className="SampleTable">
                        <thead>
                          <tr>
                            <th>Tasks</th>
                            <th>Collaborator</th>
                            <th>Start/End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeSampleAnalysis.tasks.map((task, idx) => (
                            <tr key={idx}>
                              <td>{task.name}</td>
                              <td>{task.collaborators.length !== 0 ? members[task.collaborators[0]].initials : ""}</td>
                              <td>
                                <ul>
                                  <li>{DateTime.fromISO(task.start).setZone(memberTimezone).toFormat("ff")}</li>
                                  <li>{DateTime.fromISO(task.end).setZone(memberTimezone).toFormat("ff")}</li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div>
                      <h5 className="sampleview-task-title">{`Observation Id: ${activeSampleAnalysis.observationId}`}</h5>
                      <div className="sampletable-buttons">
                        <Button 
                          className="sampletable-unplan-button"
                          variant="danger"
                          onClick={() => {
                            dispatch(samples.unplanSamples(activeSampleAnalysis.analysisId, [activeSampleAnalysis.sampleId]))
                            setActiveSampleAnalysis({})
                          }}
                          >
                            {isOnMobile ? "Unplan" : "Unplan Sample"}
                        </Button>
                        <Button onClick={() => setFailConfirm(true)}>{isOnMobile ? "Fail" : "Fail Sample"}</Button>
                      </div>
                    </div>
                  </div>
                </Popup>
              ) : (
                <>
                  {shouldShowUnplannedPopup && (
                    <Popup
                      singleMessage={
                        <>
                          <p>
                            <b>{activeSampleAnalysis.name}</b>
                          </p>
                          <p>Observation Id: {activeSampleAnalysis.observationId}</p>
                          <p>This analysis is not planned</p>
                        </>
                      }
                      onCancel={() => setActiveSampleAnalysis({})}
                      position={activeSampleAnalysis.position}
                    />
                  )}
                </>
              )}
            </>
          )}
          {failConfirm && (
            <ConfirmDialog
              onCancel={() => {
                setFailConfirm(false)
              }}
              onConfirm={() => {
                dispatch(
                  samples.failSamples(
                    activeSampleAnalysis.analysisId,
                    [activeSampleAnalysis.sampleId],
                    true,
                    activeSampleAnalysis.sampleName,
                    activeSampleAnalysis.productName
                  )
                )
                setActiveSampleAnalysis({})
                setFailConfirm(false)
              }}
            >
              <Container>
                <div className="FailSamples-info-text">
                  <FiInfo title="Confirming this will fail selected samples. A duplicate will be placed in the unplanned analysis menu" />
                </div>
                <h4>Are you sure you want to fail the selected samples?</h4>
              </Container>
            </ConfirmDialog>
          )}
        </div>
      </>
    )
  } else {
    return (
      <div className="SampleView">
        <h3>No samples available</h3>
      </div>
    )
  }
}
