import React from "react"
import FormControl from "react-bootstrap/FormControl"

export default function Number({ disabled, required, style, value, min, max, onChange = null }) {
  return (
    <FormControl
      disabled={disabled}
      required={required}
      type="number"
      min={min}
      max={max}
      style={style}
      // parseInt is used to remove leading zeroes in the input
      value={onChange ? (value !== null ? parseInt(value)+"" : "") : null}
      onWheel={(e) => e.target.blur() }

      // defaultValue={onChange ? undefined : value || ""}     // Removed because we can't have both value and defaultValue
      onChange={
        onChange
          ? (event) => 
            onChange(
              event.target.value !== "" ? (event.target.value < (min || 0) ? (min || 0) : window.Number(event.target.value)) : null
              // event.target.value < (min || 0) ? 0 : event.target.value !== "" ? window.Number(event.target.value) : null
            )
          : null
      }
    />
  )
}
