import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "../ManageScreen/index.scss"
import Button from "react-bootstrap/Button"
import { ConfirmDialog } from "components"

import { auth, teamStatus, message } from "state_management"

export default function SuiteScreen() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const companyName = useSelector((state) => state.auth.companyName)
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)

  return (
    <div className="ManageScreen-main-container">
      {showLogoutConfirm && (
        <ConfirmDialog
          onCancel={() => setShowLogoutConfirm(false)}
          onConfirm={() => {
            setShowLogoutConfirm(false)
            dispatch(teamStatus.discardPlanningModeChanges())
              .then(() => {
                dispatch(auth.logout())
                navigate("/login")
              })
              // Overwrite default error message
              // TODO: Find a better way to do this!
              .catch((e) => {
                if (e.name === "IAPinUseError") {
                  dispatch(message.warning("Just a moment - the IAP for your team is running"))
                } else {
                  dispatch(message.error("Failed discarding changes. You were not logged out!"))
                }
              })
          }}
        >
          <h4>You are currently in simulation mode. Logging out will discard changes!</h4>
        </ConfirmDialog>
      )}
      <div className="ManageScreen-item ManageScreen-Logo">
        <p>Plannertech Suite</p>
      </div>
      <div className="ManageScreen-item ManageScreen-TeamPlanButton">
        <Button title="Plannertech Team Plan" onClick={() => navigate(`/`)}>
          Plannertech
        </Button>
      </div>
      <div className="ManageScreen-item ManageScreen-MemberPlanButton">
        <Button title={companyName + " QC Librarian"} onClick={() => navigate(`/librarian`)}>
          QC Librarian
        </Button>
      </div>
      <div className="ManageScreen-item Managescreen-Logout">
        <Button
          title="Logout"
          variant="secondary"
          onClick={() => {
            if (isInPlanningMode && isCurrentPlanner) {
              setShowLogoutConfirm(true)
            } else {
              dispatch(auth.logout()).then(() => navigate("/login"))
            }
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}
