import { request } from "utils/request"
import { asyncAction } from "./common"
import {
  members,
  whiteTasks,
  analyses,
  analysisDefinitions,
  assets,
  projects,
  projectTemplates,
  competenceGroups,
  teamStatus,
  samples,
  constraintChecks,
  teamInfo,
  analysisGroups,
  projectGroups,
  sampleView,
  chatBot,
  standardActions,
} from "."
import { DateTime } from "luxon"
const initialState = {
  hasFetchedPlannedAnalyses: false,
  hasFetchedUnplannedAnalyses: false,
  hasFetchedPlannedProjectActions: false,
  hasFetchedUnplannedProjectActions: false,
  hasFetchedAnalysisDefinitions: false,
  hasFetchedAssets: false,
  hasFetchedMembers: false,
  hasFetchedWhiteTasks: false,
  hasFetchedAnalysisTasks: false,
  hasFetchedProjects: false,
  hasFetchedProjectTemplates: false,
  hasFetchedCompetenceGroups: false,
  hasFetchedTeamStatus: false,
  hasFetchedActionConstraintChecks: false,
  hasFetchedTaskConstraintChecks: false,
  hasFetchedStandardActions: false,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "loading/fetchAll/BEGIN":
      return initialState
    /*
    case "loading/onScheduleChange/BEGIN":
      return {
        ...state,
        hasFetchedWhiteTasks: false,
        hasFetchedPlannedProjectActions: false,
        hasFetchedAnalysisTasks: false,
        hasFetchedActionConstraintChecks: false,
        hasFetchedTaskConstraintChecks: false,
      }*/
    case "analyses/fetchPlanned":
      return { ...state, hasFetchedPlannedAnalyses: true }
    case "analyses/fetchUnplanned":
      return { ...state, hasFetchedUnplannedAnalyses: true }
    case "projectActions/fetchPlanned":
      return { ...state, hasFetchedPlannedProjectActions: true }
    case "projectActions/fetchUnplanned":
      return { ...state, hasFetchedUnplannedProjectActions: true }
    case "analysisDefinitions/fetch":
      return { ...state, hasFetchedAnalysisDefinitions: true }
    case "assets/fetch":
      return { ...state, hasFetchedAssets: true }
    case "members/fetch":
      return { ...state, hasFetchedMembers: true }
    case "whiteTasks/fetch":
      return { ...state, hasFetchedWhiteTasks: true }
    case "analysisTasks/fetch":
      return { ...state, hasFetchedAnalysisTasks: true }
    case "projects/fetch":
      return { ...state, hasFetchedProjects: true }
    case "projectTemplates/fetch":
      return { ...state, hasFetchedProjectTemplates: true }
    case "competenceGroups/fetch":
      return { ...state, hasFetchedCompetenceGroups: true }
    case "teamStatus/fetchCurrent":
      return { ...state, hasFetchedTeamStatus: true }
    case "constraintChecks/fetchAllAnalysisTask":
      return { ...state, hasFetchedTaskConstraintChecks: true }
    case "constraintChecks/fetchAllProjectAction":
      return { ...state, hasFetchedActionConstraintChecks: true }
    case "standardActions/fetch":
      return { ...state, hasFetchedStandardActions: true }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}
function isAnalysisReady(state, isAnalysis) {
  return (
    !isAnalysis ||
    (state.hasFetchedPlannedAnalyses &&
      state.hasFetchedUnplannedAnalyses &&
      state.hasFetchedAnalysisDefinitions &&
      state.hasFetchedTaskConstraintChecks)
  )
}

function isProjectReady(state, isProjects) {
  return !isProjects || (state.hasFetchedProjects && state.hasFetchedProjectTemplates)
}

export function isReady(state) {
  const stateTmp = window.store.getState()
  let isAnalysis = stateTmp.teamStatus.showAnalyses
  let isProjects = stateTmp.teamStatus.showProjects
  return (
    isAnalysisReady(state, isAnalysis) &&
    isProjectReady(state, isProjects) &&
    state.hasFetchedAssets &&
    state.hasFetchedMembers &&
    state.hasFetchedWhiteTasks &&
    state.hasFetchedCompetenceGroups &&
    state.hasFetchedTeamStatus
  )
}

// currentLocation is used to determine whether we are in a view that doesn't need to be a part of the initial fetch
// Currently only sample-view and asset-view are not fetched on initial load
export const fetchAll = asyncAction("loading/fetchAll", (dispatch, currentLocation = null) =>  {
  console.log("FETCHING EVERYTHING")
  const state = window.store.getState()
  let isAnalysis = state.teamStatus.showAnalyses
  let isProjects = state.teamStatus.showProjects
  return Promise.all([
    request("GET", "/has-fetched"),
    dispatch(members.fetch()),
    dispatch(whiteTasks.fetch()),
    dispatch(standardActions.fetchStandardActions()),
    //isAnalysis ? dispatch(analysisTasks.fetch(true)) : "",
    dispatch(teamStatus.fetchCurrent()).then(() => 
      isAnalysis ? dispatch(analyses.fetchUnplanned()) : "",
      isAnalysis ? dispatch(analyses.fetchPlanned()) : "",
      isAnalysis ? dispatch(analyses.fetchUnassignedSamples()) : "",
      isAnalysis ? dispatch(analysisDefinitions.fetch()) : "",
      isAnalysis ? dispatch(analysisGroups.fetch()) : "",
    ),
    dispatch(assets.fetch()),
    isProjects ? dispatch(projects.fetch()) : "",
    isProjects ? dispatch(projectTemplates.fetch()) : "",
    isProjects ? dispatch(projectGroups.fetch()) : "",
    dispatch(competenceGroups.fetch()),
    dispatch(samples.clear()),
    dispatch(teamStatus.fetchLastSampleAllocation()),
    dispatch(teamInfo.fetch()),
    dispatch(chatBot.refreshChat()),
    currentLocation === "/sample-view" ? dispatch(sampleView.fetch()) : "",
    currentLocation === "/asset-view" ? dispatch(assets.fetchGraphInfo()) : "",
      
  ]).then((res) => {
    constraintChecks.allConstraintChecks(dispatch)
    return Promise.all([
      currentLocation !== "/sample-view" ? dispatch(sampleView.fetch()) : "",
      currentLocation !== "/asset-view" ? dispatch(assets.fetchGraphInfo()) : "",
    ])
  })
})

export const onScheduleChange = asyncAction("loading/onScheduleChange", (dispatch) => {
  const state = window.store.getState()
  const isAnalysis = state.teamStatus.showAnalyses

  // If a user moves 1 month backwards/forward we should fetch projects/planned/whiteTasks actions again
  const lastProjectFetchAfter = state.waiting.projectFetchAfter
  const lastProjectFetchBefore = state.waiting.projectFetchBefore
  const lastWhiteTaskFetchAfter = state.waiting.whiteTaskFetchAfter
  const lastWhiteTaskFetchBefore = state.waiting.whiteTaskFetchBefore
  const mondayDate = state.visual.scheduleMondayDate

  // The plus 2 weeks is because lastFetchBefore might be the tuesday after mondayDate
  // And the schedule can be as large as 2 weeks
  if (lastProjectFetchAfter && lastProjectFetchAfter >= DateTime.fromISO(mondayDate)) {
    dispatch(projects.fetch("past"))
  } else if (lastProjectFetchBefore && lastProjectFetchBefore <= DateTime.fromISO(mondayDate).plus({ weeks: 2 })) {
    dispatch(projects.fetch("future"))
  }
  if (lastWhiteTaskFetchAfter && lastWhiteTaskFetchAfter >= DateTime.fromISO(mondayDate)) {
    dispatch(whiteTasks.fetch("past"))
  } else if (lastWhiteTaskFetchBefore && lastWhiteTaskFetchBefore <= DateTime.fromISO(mondayDate).plus({ weeks: 2 })) {
    dispatch(whiteTasks.fetch("future"))
  }

  return Promise.all([
    isAnalysis ? dispatch(analyses.fetchPlanned()) : "",
    //isAnalysis ? dispatch(constraintChecks.fetchAllAnalysisTask()) : "",
    //isProjects ? dispatch(constraintChecks.fetchAllProjectAction()) : "",
  ])
})
