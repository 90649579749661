import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  isLoading: false,
  analyses: null,
  projects: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "diagnostics/fetchAnalyses/BEGIN":
    case "diagnostics/fetchProjects/BEGIN":
      return { ...state, isLoading: true }
    case "diagnostics/fetchAnalyses":
      return { ...state, isLoading: false, analyses: action.res }
    case "diagnostics/fetchProjects":
      return { ...state, isLoading: false, projects: action.res }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchAnalyses = asyncAction("diagnostics/fetchAnalyses", (dispatch) => {
  return request("GET", "/diagnostics").then((res) => res.diagnostics)
})

export const fetchProjects = asyncAction("diagnostics/fetchProjects", (dispatch) => {
  return request("GET", "/diagnostics/project").then((res) => res.diagnostics)
})
