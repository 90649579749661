// A few helper functions for managing state
import { DateTime } from "luxon"
// Object immutability helpers

// Create a new map / object from a list / array, with ids as keys
export function createMap(items) {
  const rtn = {}
  if(items){
    items.forEach((item) => {
      rtn[item.id] = item
    })
  } else {
    return rtn
  }
  return rtn
}

// Add new item to a map with ids as keys
export function addToMap(map, newItem) {
  return { ...map, [newItem.id]: newItem }
}

// Replace existing item in a map with ids as keys
export function replaceInMap(map, newItem) {
  return { ...map, [newItem.id]: newItem }
}

// Remove an item from a map with `id`s as keys
export function removeFromMap(map, removedItem) {
  const rtn = { ...map }
  delete rtn[removedItem.id]
  return rtn
}

// List/array immutability helpers

// Add new item to a list, based on id
export function addToList(items, newItem) {
  items = items || [];
  return [...items, newItem]
}

// Replace existing item in a list, based on id
export function replaceInList(items, newItem) {
  return items.map((item) => (item.id === newItem.id ? newItem : item))
}

// Replace item from list, based on id
export function removeFromList(items, removedItem) {
  return items.filter((item) => item.id !== removedItem.id)
}

// Dispatching helper
export function asyncAction(type, func) {
  return (...args) => {
    return (dispatch) => {
      dispatch({ type: `${type}/BEGIN`, args })
      return func(dispatch, ...args)
        .then((res) => {
          try {
            dispatch({ type, args, res })
          } catch (err) {
            console.error("Error while dispatching", err)
            throw err
          }
          // Only returned here to make `auth.login` work!
          return res
        })
        .catch((err) => {
          dispatch({ type: `${type}/ERROR`, err })
          throw err
        })
    }
  }
}

export function getRange(mondayDate, withWeekend, withWeek) {
  let days
  if (withWeekend) {
    if (withWeek) {
      days = 28
    } else {
      days = 21
    }
  } else {
    if (withWeek) {
      days = 28
    } else {
      days = 21
    }
  }
  const after = DateTime.fromISO(mondayDate).minus({ day: 7 })
  const before = after.plus({ days })
  return [after.toUTC().toISO(), before.toUTC().toISO()]
}
export function visibleRange(mondayDate, withWeekend, withWeek) {
  let days
  if (withWeekend) {
    if (withWeek) {
      days = 14
    } else {
      days = 7
    }
  } else {
    if (withWeek) {
      days = 12
    } else {
      days = 5
    }
  }
  const after = DateTime.fromISO(mondayDate)
  const before = after.plus({ days })
  return [after, before]
}
