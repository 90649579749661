import React, { useState } from "react"
import { Container, Text, Title } from "components/Form"
import { ConfigContainer } from "components"
import { isPasswordValid } from "utils/scripts/security"
import { useDispatch } from "react-redux"
import { customerAdministration, message } from "state_management"

export default function AdminInputConfig({ admin, customerId, onClose }) {
  const dispatch = useDispatch()
  const [activeAdminUsername, setActiveAdminUsername] = useState(admin ? admin.name : "")
  const [activeAdminEmail, setActiveAdminEmail] = useState(admin ? admin.email : "")
  const [activeAdminPassword, setActiveAdminPassword] = useState("")

  return (
    <ConfigContainer
      title={`${admin ? "Edit" : "Add"} Admin`}
      cancelHandler={onClose}
      submitHandler={() => {
        // Check password validity when creating new admin or when changing password
        if (!admin || activeAdminPassword) {
          const passwordResult = isPasswordValid(activeAdminPassword)
          if (!passwordResult.valid) {
            const passwordMessage = "Missing password requirements: " + passwordResult.missingRequirements.join(", ")
            return dispatch(message.warning(passwordMessage))
          }
        }
        const res = {
          name: activeAdminUsername,
          email: activeAdminEmail,
        }
        if (activeAdminPassword) {
          res.password = activeAdminPassword
        }

        if (admin) {
          dispatch(customerAdministration.editAdmin(admin.id, res)).then(onClose())
        } else {
          dispatch(customerAdministration.addAdmin(customerId, res)).then(onClose())
        }
      }}
    >
      <Container>
        <Title>Username</Title>
        <Text required value={activeAdminUsername} onChange={(e) => setActiveAdminUsername(e)} />
      </Container>
      <Container>
        <Title>e-mail</Title>
        <Text required type="email" value={activeAdminEmail} onChange={(e) => setActiveAdminEmail(e)} />
      </Container>
      <Container>
        <Title>Password</Title>
        <Text
          required={!admin}
          type="password"
          value={activeAdminPassword}
          onChange={(e) => setActiveAdminPassword(e)}
        />
      </Container>
    </ConfigContainer>
  )
}
