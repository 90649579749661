import React from "react"
import InputGroup from "react-bootstrap/InputGroup"

export default function Title({width, children }) {
  return (
    // <InputGroup.Prepend>
      <InputGroup.Text style={{width:width}}
      >{children}</InputGroup.Text>
    // </InputGroup.Prepend>
  )
}
