import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import Column from "./Column"
import { DragDropContext } from "react-beautiful-dnd"
import { ScheduleButtons } from "components"
import { projectActions, message, scrumView, projects as projectState } from "state_management"
import { ActionConfig, UnplannedToPlannedConfig, CreateColumnConfig } from "components/Config"
import { Popup, ConfirmDialog } from "components"
import { Loading } from "components"
import { Text } from "components/Form"
import Button from "react-bootstrap/Button"
import { createMap } from "state_management"

// Takes two lists a and b. Returns a list of elements in a but no in b
function remainingTasks(a, b) {
  return a.filter((taskA) => {
    const overlap = b.filter((taskB) => {
      return taskB === taskA
    })
    return !!!overlap.length
  })
}

// The scrum view screen.
// Place actions in scrum view when entering it.
// We therefore only have to handle manipulations to actions
// that can happen from the scrum view
export default function ScrumViewScreen() {
  const dispatch = useDispatch()
  const projects = useSelector((state) => state.projects) // Scrum view state. This is in redux for cleaner interface and further improvements
  const planned = useSelector((state) => state.projectActions.plannedProjectActions)
  const unplanned = useSelector((state) => state.projectActions.unplannedProjectActions)
  const backlog = useSelector((state) => state.projectActions.backlogProjectActions)
  const didFetch = useSelector((state) => state.scrumView.didFetch)
  const tasksTmp = useSelector((state) => state.scrumView.tasks)
  const columnsTmp = useSelector((state) => state.scrumView.columns)
  const columnOrder = useSelector((state) => state.scrumView.columnOrder)
  const columnsIds = useSelector((state) => state.scrumView.columnsIds)
  const [filterString, setFilterString] = useState("")
  const [isSyncing, setIsSyncing] = useState(false)
  const [showSortByTimePopup, setShowSortByTimePopup] = useState(false)
  const [activeAction, setActiveAction] = useState(null)
  const [unplannedToPlannedConfig, setUnplannedToPlannedConfig] = useState({
    task: null,
    columnFunc: undefined,
  })
  const [showCreateColumn, setShowCreateColumn] = useState(false)
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({ bookedAssets: [], freeAssets: [] })

  const sortByTime = useCallback((backlog, unplanned, planned, projetcS) => {
    setIsSyncing(true)
    const sortByDeadline = (a, b) => {
      return sortActions(a, b, "deadline")
    }
    const sortByStart = (a, b) => {
      return sortActions(a, b, "start")
    }
    // Sorting function that sort based on
    // Action with deadline
    // Action without deadline but with project deadline
    // Action without deadline and project deadline
    const sortActions = (a, b, x) => {
      return (a, b) => {
        const aTmp = a[x]
          ? a[x]
          : projects[a.projectId]
          ? projects[a.projectId][x]
            ? projects[a.projectId][x]
            : null
          : null
        const bTmp = b[x]
          ? b[x]
          : projects[b.projectId]
          ? projects[b.projectId][x]
            ? projects[b.projectId][x]
            : null
          : null

        if (!aTmp && !bTmp) return 0
        if (!aTmp) return -1
        if (!bTmp) return 1
        if (aTmp < bTmp) return -1
        if (aTmp > bTmp) return 1
        return 0
      }
    }
    let projects = projetcS
    let backlogActions = createMap(Object.values(Object.values(backlog).filter((x) => x.stage === "BACKLOG")))
    let unplannedActions = createMap(Object.values(Object.values(unplanned).filter((x) => x.stage === "TODO")))
    let plannedActions = createMap(Object.values(Object.values(planned).filter((x) => x.stage === "PLANNED")))
    let finishedActions = createMap(Object.values(Object.values(planned).filter((x) => x.stage === "FINISHED")))
    let tasks = { ...backlogActions, ...unplannedActions, ...plannedActions, ...finishedActions }
    let columns = {
      BACKLOG: {
        id: "BACKLOG",
        title: "Backlog",
        taskIds: [
          ...Object.values({ ...backlogActions })
            .filter((task) => task.scrumColumnId === null)
            .sort(sortByDeadline(projects))
            .map((x) => x.id),
        ],
      },
      TODO: {
        id: "TODO",
        title: "To Do",
        taskIds: [
          ...Object.values({ ...unplannedActions })
            .filter((task) => task.scrumColumnId === null)
            .sort(sortByDeadline(projects))
            .map((x) => x.id),
        ],
      },
      PLANNED: {
        id: "PLANNED",
        title: "Planned",
        taskIds: Object.values(plannedActions)
          .filter((task) => task.scrumColumnId === null)
          .sort(sortByStart())
          .map((x) => x.id),
      },

      FINISHED: {
        id: "FINISHED",
        title: "Finished",
        taskIds: Object.values(finishedActions)
          .filter((task) => task.scrumColumnId === null)
          .sort(sortByStart())
          .map((x) => x.id),
      },
    }
    const beArray = {}
    const orderArray = {}
    const parentCols = ["BACKLOG", "TODO", "PLANNED", "FINISHED"]
    let parent = ""
    parentCols.forEach((col) => {
      orderArray[col] = {}
    })

    columnsIds.forEach((col) => {
      columns[col.name.toUpperCase()] = {
        id: col.stage,
        title: col.name,
        taskIds: Object.values(tasks)
          .filter((task) => task.scrumColumnId === col.id)
          .sort(sortByStart())
          .map((x) => x.id),
      }
    })

    columnOrder.forEach((col) => {
      if (parentCols.includes(col)) parent = col
      columns[col].taskIds.forEach((id, index) => {
        beArray[id] = index
        orderArray[parent][id] = index
      })
    })

    dispatch(scrumView.sortByTime(columns, tasks, projects))
    dispatch(projectActions.reOrderColumns(beArray, orderArray, ["BACKLOG", "TODO", "PLANNED", "FINISHED"]))
    setIsSyncing(false)
  }, [dispatch, setIsSyncing, columnOrder, columnsIds]);

  useEffect(() => {
    // Assuming you have access to backlog, planned, and projects variables here
    sortByTime(backlog, unplanned, planned, projects);
  }, [unplanned, planned, backlog, projects, sortByTime]);

  useEffect(() => {
    dispatch(scrumView.getColumns()).then(() => dispatch(scrumView.fillOut(unplanned, planned, backlog, projects)))
    return () => dispatch(scrumView.leavingPage())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!didFetch) {
    return <Loading />
  }
  // Filter tasks by string
  const tasks = filterString
    ? createMap(
        Object.values(tasksTmp).filter((task) => {
          const nameString = (
            task.name +
            " " +
            (projects[task.projectId] && !projects[task.projectId].singleActionProject
              ? projects[task.projectId].name
              : "")
          ).toUpperCase()
          return filterString ? nameString.match(filterString.toUpperCase()) : true
        })
      )
    : tasksTmp
  const columns = { ...columnsTmp }
  for (var key in columnsTmp) {
    columns[key] = {
      ...columnsTmp[key],
      taskIds: [...columnsTmp[key].taskIds.filter((id) => tasks[id])],
    }
  }
  // Remove all popup states
  const cancelAll = () => {
    setUnplannedToPlannedConfig({ ...unplannedToPlannedConfig, task: null })
    setActiveAction(null)
  }

  const createColumn = (parentCol, columnName) => {
    if (!columnOrder.includes(columnName.toUpperCase())) {
      dispatch(
        scrumView.createColumn(
          { name: columnName, stage: parentCol },
          { parentCol: parentCol, title: columnName, orderId: columnName.toUpperCase() }
        )
      )
      setShowCreateColumn(false)
    } else {
      dispatch(message.warning("Please choose an unique name"))
    }
  }

  // When something is dropped
  const onDragEnd = (result) => {
    setIsSyncing(true)
    const { destination, source, draggableId } = result
    // Outside of any column. Do nothing
    if (!destination) {
      setIsSyncing(false)
      return
    }
    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]

    // same column same spot. Do nothing
    if (start === finish && destination.index === source.index) {
      setIsSyncing(false)
      return
    }
    // same column different spot. Update state in SCRUM View
    if (start === finish) {
      const newTaskIds = [...start.taskIds]
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      // We have go through this procces because of the filter
      // This pattern comes up again through the whole scrum view
      const remainingTaskIds = remainingTasks([...columnsTmp[source.droppableId].taskIds], newTaskIds)
      const newColumn = {
        ...start,
        taskIds: [...newTaskIds, ...remainingTaskIds],
      }
      const orderArray = {}
      const beArray = {}
      orderArray[newColumn.id] = {}
      newColumn.taskIds.forEach((id, index) => {
        orderArray[newColumn.id][id] = index
        beArray[id] = index
      })
      dispatch(projectActions.reOrderColumns(beArray, orderArray, [newColumn.id]))
      dispatch(scrumView.updateColumn(source.droppableId, newColumn))
      setIsSyncing(false)
      return
    }

    // Function that updates columns
    const updateScrumViewValues = async (func) => {
      setIsSyncing(true)
      const orderArray = {}
      const beArray = {}
      const startTaskIds = [...start.taskIds] // Update starting column with proper tasks
      const startRemainingTaskIds = remainingTasks([...columnsTmp[source.droppableId].taskIds], startTaskIds)
      startTaskIds.splice(source.index, 1) // Remove the moved item
      const newStart = {
        ...start,
        taskIds: [...startTaskIds, ...startRemainingTaskIds],
      }
      orderArray[newStart.dropId] = {}
      newStart.taskIds.forEach((id, index) => {
        orderArray[newStart.dropId][id] = index
        beArray[id] = index
      })

      // Update finishing colum with proper tasks insert the moved item
      const finishTaskIds = [...finish.taskIds]
      finishTaskIds.splice(destination.index, 0, draggableId)
      const finishRemainingTaskIds = remainingTasks([...columnsTmp[destination.droppableId].taskIds], finishTaskIds)
      const newFinish = {
        ...finish,
        taskIds: [...finishTaskIds, ...finishRemainingTaskIds],
      }
      orderArray[finish.dropId] = {}
      newFinish.taskIds.forEach((id, index) => {
        orderArray[finish.dropId][id] = index
        beArray[id] = index
      })

      dispatch(scrumView.updateColumns(start.dropId, finish.dropId, newStart, newFinish))
      await dispatch(projectActions.reOrderColumns(beArray, orderArray, [start.dropId, finish.dropId])).then( () => {func && dispatch(func())})
      setIsSyncing(false)
    }

    let parentCol = ""
    let colId = null
    const parentCols = ["BACKLOG", "TODO", "PLANNED", "FINISHED"]
    for (let i = 0; i < columnOrder.length; i++) {
      if (parentCols.includes(columnOrder[i])) {
        parentCol = columnOrder[i]
      }
      if (columnOrder[i] === destination.droppableId) {
        if (parentCol === destination.droppableId) parentCol = ""
        break
      }
    }
    columnsIds.forEach((col) => {
      if (col.name.toUpperCase() === destination.droppableId) {
        colId = col.id
      }
    })

    // The user gets the planned popup
    if ((start.id === "TODO" || start.id === "BACKLOG") && finish.id === "PLANNED") {
      setUnplannedToPlannedConfig({
        task: tasks[draggableId],
        columnFunc: updateScrumViewValues,
        oldIndex: source.index,
        newIndex: destination.index,
        scrumColumnId: parentCol ? colId : null,
      })
      setIsSyncing(false)
      return
    }

    // Update state throughout PlannerTech
    const tmpStart = tasks[draggableId].start
    if (finish.id === "TODO" || finish.id === "BACKLOG") {
      updateScrumViewValues(() => 
        projectActions.editPlanned(draggableId, {
        stage: parentCol ? parentCol : destination.droppableId,
        oldIndex: source.index,
        newIndex: destination.index,
        scrumColumnId: parentCol ? colId : null,
      }))
    } else if (tmpStart) {
      updateScrumViewValues(() => 
      projectActions.editPlanned(draggableId, {
        stage: parentCol ? parentCol : destination.droppableId,
        start: tmpStart,
        oldIndex: source.index,
        newIndex: destination.index,
        scrumColumnId: parentCol ? colId : null,
      }))
    } else {
      updateScrumViewValues(() => 
      projectActions.editPlanned(draggableId, {
        stage: parentCol ? parentCol : destination.droppableId,
        oldIndex: source.index,
        newIndex: destination.index,
        scrumColumnId: parentCol ? colId : null,
      }))
    }
  }

  const clickHandler = (task) => {
    if (task.stage === "PLANNED" || task.stage === "FINISHED") {
      dispatch(projectActions.getBookedFreeAssets(task.id, planned[task.id])).then(async (res) => {
        setBookedAndFreeAssets(res)
      })
    }
    setActiveAction(task)
  }

  return (
    <>
      {activeAction && (
        <Popup nonScrollable onCancel={() => setActiveAction(null)}>
          <ActionConfig
            title={activeAction.isSingleTask ? "Create Single Action" : "Edit Action"}
            action={activeAction}
            isSingleAction={
              activeAction.isSingleTask ||
              (projects[activeAction.projectId] && projects[activeAction.projectId].singleActionProject)
            }
            deleteHandler={
              projects[activeAction.projectId] && projects[activeAction.projectId].singleActionProject
                ? () => {
                    dispatch(projectState.remove(activeAction.projectId))                  
                      .then((res) => {
                        dispatch(scrumView.removeAction(res))
                      })
                    setActiveAction(null)
                  }
                : null
            }
            submitHandler={(res) => {
              const changeColumns = () => {
                // If the stage is changed, columns need to be updated
                if (res.stage && res.stage !== activeAction.stage) {
                  const startColumnTasks = [...columns[activeAction.stage].taskIds]
                  // Get index of the removed item
                  let idxAtStartColumn = 0
                  startColumnTasks.forEach((task, idx) => {
                    if (task === activeAction.id) idxAtStartColumn = idx
                  })

                  // Update starting column
                  const startRemainingTaskIds = remainingTasks(
                    [...columnsTmp[activeAction.stage].taskIds],
                    startColumnTasks
                  )
                  startColumnTasks.splice(idxAtStartColumn, 1)

                  // Update finishing columns
                  const endColumnTasks = columns[res.stage].taskIds
                  endColumnTasks.splice(0, 0, activeAction.id)
                  const endRemainingTaskIds = remainingTasks([...columnsTmp[res.stage].taskIds], endColumnTasks)

                  // Update the actual view
                  dispatch(
                    scrumView.updateColumns(
                      activeAction.stage,
                      res.stage,
                      { ...columns[activeAction.stage], taskIds: [...startColumnTasks, ...startRemainingTaskIds] },
                      { ...columns[res.stage], taskIds: [...endColumnTasks, ...endRemainingTaskIds] }
                    )
                  )
                }
              }
              // Display popup if going from unplanned to planned
              if ((activeAction.stage === "TODO" || activeAction.stage === "BACKLOG") && res.stage === "PLANNED") {
                setUnplannedToPlannedConfig({ task: { id: activeAction.id, ...res }, columnFunc: changeColumns })
                return
              }
              if (activeAction.isSingleTask) {
                dispatch(projectActions.createSingleAction({ ...res, tempId: "123456789" }))
              } else if (activeAction.stage === "TODO" || activeAction.stage === "BACKLOG") {
                dispatch(projectActions.editUnplanned(activeAction.id, res)).then(() => changeColumns())
              } else {
                dispatch(projectActions.editPlanned(activeAction.id, res)).then(() => changeColumns())
              }

              setActiveAction(null)
            }}
            cancelHandler={() => setActiveAction(null)}
            projectId={activeAction.projectId}
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {unplannedToPlannedConfig.task && (
        <Popup
          nonScrollable
          onCancel={() => {
            setActiveAction(null)
            setUnplannedToPlannedConfig({ task: null, columnFunc: undefined })
          }}
        >
          <UnplannedToPlannedConfig
            cancelHandler={() => {
              setUnplannedToPlannedConfig({ task: null, columnFunc: undefined })
            }}
            cancelAll={cancelAll}
            task={unplannedToPlannedConfig.task}
            submitHandler={() => {
              unplannedToPlannedConfig.columnFunc()
              setActiveAction(null)
            }}
            res={unplannedToPlannedConfig.task}
            index={{ oldIndex: unplannedToPlannedConfig.oldIndex, newIndex: unplannedToPlannedConfig.newIndex }}
            columnId={unplannedToPlannedConfig.scrumColumnId}
          />
        </Popup>
      )}
      {showSortByTimePopup && (
        <ConfirmDialog
          onCancel={() => setShowSortByTimePopup(false)}
          onConfirm={() => {
            sortByTime(backlog, unplanned, planned, projects)
            setShowSortByTimePopup(false)
          }}
        >
          <h4>Are you sure you want to sort by time</h4>
        </ConfirmDialog>
      )}
      {showCreateColumn && (
        <Popup onCancel={() => setShowCreateColumn(false)}>
          <CreateColumnConfig
            cancelHandler={() => {
              setShowCreateColumn(false)
            }}
            submitHandler={createColumn}
          ></CreateColumnConfig>
        </Popup>
      )}
      <ScheduleButtons type="scrum-view" />
      <div className="ScrumView-Screen">
        <div className="ScrumView-Header">
          <div className="ScrumView-HeaderLeft">
            <Text placeholder="Filter by Name" value={filterString} onChange={setFilterString} />
          </div>
          <div className="ScrumView-HeaderMiddle">
            <Button onClick={() => setShowCreateColumn(true)} title="Create a new Column" style={{marginRight: "1rem"}}>
              Create Stage
            </Button>
            <Button
              onClick={() => setShowSortByTimePopup(true)}
              title="Orders Backlog and To Do actions after their deadlines, and Planned and Finished after their starting time"
            >
              Sort By Time
            </Button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className={`ScrumView-Columns-Container ${isSyncing ? 'syncing' : ''}`}>
            {isSyncing && (
              <>
                <div className="overlay"></div>
                <div className="spinner">
                  <h1>Loading...</h1>
                </div>
              </>
            )}
            {columnOrder.map((col) => {
              const column = columns[col]
              column["dropId"] = col
              const filteredTasks = column.taskIds.filter((id) => tasks[id]).map((id) => tasks[id])
              return (
                <Column
                  key={column.dropId}
                  column={column}
                  tasks={filteredTasks}
                  projects={projects}
                  onClick={clickHandler}
                />
              )
            })}
          </div>
        </DragDropContext>
      </div>
    </>
  )
}
