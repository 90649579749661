import React from "react"
import { IoMdClose } from "react-icons/io"
import "./index.scss"
import { ErrorBoundary } from "components"
import { useClosePopup } from "./useClosePopup"

// `nonScrollable` should NOT be set on popups where the inner content handles scrolling itself
// 'useClosePopUp' is used for closing the popup when mouseclicking outside the component, or when pressing 'Escape' key
// Note: This cannot be auto-detected without in some cases generating two scrollbars, believe me, I've tried!
// TODO: Don't allow this, instead, allow setting a footer on the popup!
export default function Popup({
  nonScrollable = false,
  children,
  onCancel,
  className,
  title = null,
  center,
  singleMessage,
  position,
  doesClickOutsideClose = true,
}) {
  const contentClassName = `Popup-content ${className ? className : ""} ${title ? "PopupassetCount-with-title" : ""}`

  // Hook for a RefObject that checks if popup should close
  let domNode = useClosePopup(() => {
    // Use Ternary operator to check if doesClickOutsideClose is true, then run onCancel()
    doesClickOutsideClose && onCancel()
  })

  if (singleMessage) {
    return (
      <div
        ref={domNode}
        className="popup-singlemessage"
        style={position ? {
          position: "absolute",
          top: position.top,
          left: position.left,
        } : undefined}
      >
        <IoMdClose className="popup-singlemessage-close" onClick={() => onCancel()} title="Cancel" />
        <div className={`popup-singlemessage-message ${className ? className : ""}`}>{singleMessage}</div>
      </div>
    )
  } else {
    return (
      <div className="Popup" style={center ? { justifyContent: "center", alignItems: "center" } : null}>
        <div ref={domNode} className={contentClassName}>
          {title && <p className="Pheading">{title}</p>}
          <IoMdClose className="Popup-cancel" onClick={() => onCancel()} title="Cancel" />
          {nonScrollable ? (
            <ErrorBoundary>{children}</ErrorBoundary>
          ) : (
            <div className="Popup-scroll">
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
          )}
        </div>
      </div>
    )
  }
}
