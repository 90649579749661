// A generic error class for request errors
// The `message` parameter of instances of this is safe to display to the user!
export class RequestError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
  }
}

// Can mean all sorts of errors happening on lower layers than HTTP.
// But using the fetch-API, we don't get told what the actual error was!
export class ConnectionError extends RequestError {}

export class DoNotShowError extends RequestError {}

// When IAP is in use
export class IAPinUseError extends RequestError {}

// When a user tries to access a sample analysis that has been changed by another user
export class AnalysisUpdatedError extends RequestError {}

// Any of the 5xx HTTP errors
export class ServerError extends RequestError {}

export class UnauthorizedError extends RequestError {}

export class NotAllowedError extends RequestError {}

export class InvalidParametersError extends RequestError {}
