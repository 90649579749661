import React, { useRef, useEffect } from "react"

// A hidden input, with a custom validity message.
// message = null shows nothing and message = "abc" shows the given message.
// Note: Put the class `HiddenInput-container`` on the parent element of this!
export default function HiddenInput({ message = null }) {
  const ref = useRef(null)

  useEffect(() => {
    ref.current.setCustomValidity(message || "")
  }, [message])

  return <input ref={ref} required tabIndex="-1" className="HiddenInput" defaultValue={message ? "" : "done"} />
}
