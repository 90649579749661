import React, { useEffect, useRef } from "react"
import { useDrop } from "react-dnd"
import "./index.scss"
import { useDrag } from "react-dnd"

export default function ListItemDnD({ text, dragType, onDrop, onMove, id, children }) {
  const containerRef = useRef()
  const textRef = useRef();

  const [{ isOver }, drop] = useDrop({
    accept: dragType,
    collect: (mon) => ({
      isOver: !!mon.isOver(),
    }) /*
    hover(item, monitor) {
      if (!containerRef.current) {
        return
      }
      onMove()
       TODO: make members move up an down by this
      const dragIndex = item.id
      const hoverIndex = id

      if (dragIndex === hoverIndex) {
        return
      }
      const hoveredRect = containerRef.current.getBoundClientRect()
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2
      const mousePosition = monitor.getClientOffset()
      const hoverClientY = mousePosition.y - hoveredRect.top

      if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      item.index = hoverIndex
      
    },*/,
    drop: ({ type, dragId }) => {
      onDrop(dragId, id)
    },
  }, [dragType, id, onDrop])

  const [, drag, ] = useDrag(() => ({
    type: dragType,
    item: { type: dragType, dragId: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [dragType, id])

  drag(drop(containerRef))

  useEffect(() => {
    const node = textRef.current;
    if (node && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      node.innerText = text;

      while (node.scrollWidth > containerWidth && node.innerText.length > 3) {
        node.innerText = node.innerText.slice(0, -15) + "...";
      }
    }
  }, [text])

  return (
    <div className={`list-item-dnd ${isOver ? "isOver" : ""}`} ref={containerRef}>
      <p title={text} ref={textRef}>{text}</p>
      {children}
    </div>
  )
}
