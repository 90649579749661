import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"

import { FiInfo } from "react-icons/fi"

import { message, samples } from "state_management"
import { Container, Text } from "components/Form"
import { ConfirmDialog } from "components"

import "./index.scss"

export default function DeleteUnplannedSamples() {

  const dispatch = useDispatch()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [enteredKeyword, setEnteredKeyword] = useState("")

  const deletionKeyword = "DELETE"

  return (
    <>
    {deleteConfirm && (
      <ConfirmDialog
        onCancel={() => {
          setDeleteConfirm(false)
          setEnteredKeyword("")
        }}
        onConfirm={() => {
          if (deletionKeyword !== enteredKeyword) {
            dispatch(message.warning(`type ${deletionKeyword} before confirming`))
            setEnteredKeyword("")
            return
          }
          dispatch(samples.removeAllUnplanned())
          setEnteredKeyword("")
          setDeleteConfirm(false)
        }}
      >
        <Container>
          <div className="DeleteAllUnplannedSamples-info-text">
            <FiInfo title="Confirming this will delete all samples, which have no actions placed on the plan" />
          </div>
          <h4>To confirm deletion type {deletionKeyword} and press confirm</h4>
        </Container>
        <Container>
          <Text value={enteredKeyword} onChange={(e) => setEnteredKeyword(e)} />
        </Container>
      </ConfirmDialog>
    )}
      <div className="DeleteAllUnplannedSamples-container">
          <Button
          variant="danger"
          onClick={() => {
            setDeleteConfirm(true)
          }}
        >
          Delete Samples
        </Button>
      </div>
      </>
  )
}