import { useSelector } from "react-redux"
import {
  PriorityButtons,
  PriorityForecastButtons,
  IncludeOverdueButton,
  LockUnlockButtons,
} from "./TeamSpecificComponents/"

// Handles the conditional rendering to make the LeftMenu more readable.
export default function useTeamComponents() {
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  const IAPconfig = useSelector((state) => state.visual.IAPconfig)

  const isMixed = showProjects && showAnalyses
  const isProject = showProjects && !showAnalyses
  const iapMode = !showProjects ? "analysis" : !showAnalyses ? "project" : IAPconfig.IAPmode

  return {
    PriorityButtons: !showForecast ? <PriorityButtons /> : <PriorityForecastButtons />,
    IncludeOverdueButton: (isProject || (isMixed && iapMode === "project")) && <IncludeOverdueButton />,
    LockUnlockButtons: !showForecast && (showProjects || showAnalyses) && <LockUnlockButtons />
  }
}
