import { request, UnauthorizedError } from "utils/request"
import { asyncAction } from "./common"
import { MemberRoles } from "."

export const loggedOutState = {
  companyId: null,
  companyName: null,
  teamId: null,
  memberId: null,
  accessToken: null,
  role: null,
  isLoggedIn: false,
  isOnlyLibrarian: null,
  isMember: true, // Default true, just to be safe
  memberTimezone: null,
  globalMessage: "",
  showGlobalMessage: false,
  hasApiToken: null,
  language: null
}

export function reducer(state = loggedOutState, action) {
  // A bit out of the ordinary reducer-stuff:
  // Log the user out if we receive such a status from the BE.
  // This works regardless of whether the user is currently logged in or not!
  if (action.err instanceof UnauthorizedError) {
    return loggedOutState
  }
  const res = action.res
  switch (action.type) {
    case "loading/fetchAll/ERROR":
      // Log out if fetching everything failed
      // This is done to prevent infinite loops!
      return loggedOutState
    case "auth/login":
    case "auth/verifyMfaToken":
    case "auth/logout-other-device":
      return {
        companyId: res.company && res.company.id,
        companyName: res.company && res.company.name,
        teamId: !res.team ? res.team : res.team.id,
        memberId: res.id,
        accessToken: res.access_token,
        role: res.role,
        isOnlyLibrarian: res.isOnlyLibrarian,
        isLoggedIn: true,
        isMember: res.role === MemberRoles.MEMBER,
        memberTimezone: res.member_timezone,
        globalMessage: res.globalMessage,
        showGlobalMessage: res.globalMessage ? true : false,
        hasApiToken: res.hasApiToken !== undefined ? res.hasApiToken : null,
        language: res.language
      }
    case "auth/accessCompany":
      return { ...state, companyId: res }
    case "auth/changeTimezone":
      return { ...state, memberTimezone: action.timezone }
    case "auth/changeShowGlobalMessage":
      return { ...state, showGlobalMessage: false }
    case "auth/exitCompany":
      return { ...state, companyId: null }
    case "auth/logout":
      return loggedOutState
    case "auth/setGlobalMessage":
      return { ...state, globalMessage: action.args[0] }
    case "auth/setHasAPIToken":
      return { ...state, hasApiToken: action.hasApiToken }
    case "auth/setLanguage":	
      return { ...state, language: action.args[0] }
    default:
      return state
  }
}

export const login = asyncAction("auth/login", (dispatch, username, password) => {
  return request("POST", `/login`, { username, password }, true, true)
})

export const logout = asyncAction("auth/logout", (dispatch) => {
  const state = window.store.getState()
  window.localStorage.setItem("plannertechVersion", JSON.stringify(state.teamStatus.plannertechVersion))
  return request("POST", `/logout`).then(() => {
    return { type: "auth/logout" }
  })
})

export const logoutOtherDevice = asyncAction("auth/logout-other-device", (dispatch, username, password) => {
  return request("POST", `/logout-other-device`, { username, password }, true, true)
})

export const accessCompany = asyncAction("auth/accessCompany", (dispatch, id) => {
  return request("PATCH", `/customer-admin/give-company-id/${id}`).then(() => id)
})

export const exitTeam = asyncAction("auth/exitCompany", (dispatch) => {
  return request("PATCH", `/customer-admin/remove-company-id`)
})

export function changeTimezone(timezone) {
  return { type: "auth/changeTimezone", timezone }
}

export const getMfaToken = asyncAction("auth/getMfaToken", (dispatch, username, password) => {
  return request("POST", `/get-mfa-key`, { username, password })
})

export const verifyMfaToken = asyncAction("auth/verifyMfaToken", (dispatch, username, password, mfaKey) => {
  return request("POST", `/verify-mfa-key`, { username, password, mfaKey }, true, true)
})

export function changeShowGlobalMessage() {
  return { type: "auth/changeShowGlobalMessage" }
}

export const setGlobalMessage = asyncAction("auth/setGlobalMessage", (dispatch, message) => {
  return request("POST", `/customer-admin/set-global-message`, { message })
})

export function setHasAPIToken(hasApiToken) {
  return { type: "auth/setHasAPIToken", hasApiToken }
}

export const setLanguage = asyncAction("auth/setLanguage", (dispatch, language) => {
  return request("PATCH", `/librarian/speech-to-text/language`, { input: { language: language } })
})