import { createMap, addToMap, replaceInMap, asyncAction, removeFromMap } from "./common"
import { request } from "utils/request"

const initialState = {
  customers: {},
  isLoadingCustomers: false,
  isLoadingAdmins: false,
  admins: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "customers/addCustomer":
      return { ...state, customers: addToMap(state.customers, action.res) }
    case "customers/editCustomer":
      return { ...state, customers: replaceInMap(state.customers, action.res) }
    case "customers/fetchCustomers/BEGIN":
      return { ...state, isLoadingCustomers: true }
    case "customers/fetchCustomers":
      return { customers: createMap(action.res), isLoadingCustomers: false }
    case "customers/deleteCustomer":
      return { ...state, customers: removeFromMap(state.customers, action.res) }
    case "customers/fetchAdmins/BEGIN":
      return { ...state, isLoadingAdmins: true }
    case "customers/fetchAdmins":
      return { ...state, admins: createMap(action.res), isLoadingAdmins: false }
    case "customers/addAdmin":
      return { ...state, admins: addToMap(state.admins, action.res) }
    case "customers/editAdmin":
      return { ...state, admins: replaceInMap(state.admins, action.res) }
    case "customers/deleteAdmin":
      return { ...state, admins: removeFromMap(state.admins, action.res) }
    case "customers/setHasAPIToken":
      const { id, hasApiToken } = action.payload;
      return {
          ...state,
          customers: {
              ...state.customers,
              [id]: {
                  ...state.customers[id],
                  hasApiToken: hasApiToken
              }
          }
      };
    default:
      return state
  }
}

export const addCustomer = asyncAction("customers/addCustomer", (dispatch, input) => {
  return request("POST", `/customer-admin/companies`, { input })
})

export const editCustomer = asyncAction("customers/editCustomer", (dispatch, id, patch) => {
  return request("PATCH", `/customer-admin/company/${id}`, { patch })
})

export const deleteCustomer = asyncAction("customers/deleteCustomer", (dispatch, id) => {
  return request("DELETE", `/customer-admin/company/${id}`).then(() => ({ id }))
})

export const fetchCustomers = asyncAction("customers/fetchCustomers", (dispatch) => {
  return request("GET", `/customer-admin/companies`).then((res) => res.items)
})

export const fetchAdmins = asyncAction("customers/fetchAdmins", (dispatch, id) => {
  return request("GET", `/customer-admin/admins/${id}`).then((res) => res.items)
})

export const addAdmin = asyncAction("customers/addAdmin", (dispatch, id, input) => {
  return request("POST", `/customer-admin/admin/${id}`, { input }).then((res) => res.admin)
})

export const editAdmin = asyncAction("customers/editAdmin", (dispatch, id, patch) => {
  return request("PATCH", `/customer-admin/admin/${id}`, { patch }).then((res) => res.admin)
})

export const deleteAdmin = asyncAction("customers/deleteAdmin", (dispatch, id) => {
  return request("DELETE", `/customer-admin/admin/${id}`).then(() => ({ id }))
})

export function setHasAPIToken(id, hasApiToken) {
  return { type: "customers/setHasAPIToken", payload: { id: id, hasApiToken: hasApiToken } }
}