import { request } from "utils/request"
import { addToList, replaceInList, removeFromList, asyncAction } from "./common"

// Admin-only state!
const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "teamsOnLocation/fetch/BEGIN":
      return { ...state, [action.args[0]]: { ...(state[action.args[0]] || {}), isLoading: true } }
    case "teamsOnLocation/fetch":
      return { ...state, [action.res.locationId]: { isLoading: false, teams: action.res.teams } }
    case "team/delete-api-token":
      return {
        ...state,
        [action.args[1]]: {
          ...state[action.args[1]],
          teams: state[action.args[1]].teams.map((item) =>
            item.id === action.args[0] ? { ...item, hasApiToken: false } : item
          ),
        },
      }
    case "team/create-api-token":
      return {
        ...state,
        [action.args[1]]: {
          ...state[action.args[1]],
          teams: state[action.args[1]].teams.map((item) =>
            item.id === action.args[0] ? { ...item, hasApiToken: true } : item
          ),
        },
      }
    case "teamsOnLocation/createForecastTeam":
      return {
        ...state,
        [action.res.locationId]: {
          ...state[action.res.locationId],
          teams: addToList(state[action.res.locationId].teams, action.res),
        },
      }
    case "teamsOnLocation/create":
      return {
        ...state,
        [action.res.locationId]: {
          ...state[action.res.locationId],
          teams: addToList(state[action.res.locationId].teams, action.res),
        },
      }
    case "teamsOnLocation/edit":
      return {
        ...state,
        [action.res.locationId]: {
          ...state[action.res.locationId],
          teams: replaceInList(state[action.res.locationId].teams, action.res),
        },
      }
    case "teamsOnLocation/remove":
      return {
        ...state,
        [action.res.locationId]: {
          ...state[action.res.locationId],
          teams: removeFromList(state[action.res.locationId].teams, action.res),
        },
      }
    case "auth/logout":
      return initialState
    case "members/createAsAdmin":
      const teamId = action.args[0].teamId
      const locationId = Object.values(state).map((location) => {
        const team = location.teams.find(team => team.id === teamId)
        return team ? team.locationId : null
      }).find(team => team !== null)
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          teams: state[locationId].teams.map((oldTeam) => (
            oldTeam.id === teamId ? {
              ...oldTeam,
              seatsUsed: oldTeam.seatsUsed + 1,
              seatsTotal: oldTeam.seatsTotal + 1,
            } : oldTeam
          ))
        }
      }
    default:
      return state
  }
}

export const fetch = asyncAction("teamsOnLocation/fetch", (dispatch, locationId) => {
  return request("GET", `/teams/${locationId}`).then((res) => ({ locationId, teams: res.items }))
})

export const create = asyncAction("teamsOnLocation/create", (dispatch, locationId, input) => {
  return request("POST", "/teams", { input: { ...input, locationId } }).then((res) => res.team)
})

export const createForecastTeam = asyncAction("teamsOnLocation/createForecastTeam", (dispatch, teamId) => {
  return request("POST", `/team/create-forecast-team/${teamId}`).then((res) => res.team)
})

export const edit = asyncAction("teamsOnLocation/edit", (dispatch, locationId, teamId, patch) => {
  return request("PATCH", `/team/${teamId}`, { patch: { ...patch, locationId } }).then((res) => res.team)
})

export const remove = asyncAction("teamsOnLocation/remove", (dispatch, locationId, teamId) => {
  return request("DELETE", `/team/${teamId}`).then(() => ({ locationId, id: teamId }))
})
