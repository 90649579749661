// This state handles waiting displays and different flags
// A lot of this logic is in teamStatus atm

import { DateTime } from "luxon"

const initialState = {
  scheduleMonday: false,
  iapLoading: "",
  fillInLoading: "",
  sampleAllocating: false,
  projectFetchAfter: false,
  projectFetchBefore: false,
  whiteTaskFetchAfter: false,
  whiteTaskFetchBefore: false,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "waiting/loadedSchedule":
      return { ...state, scheduleMonday: action.monday }
    case "waiting/iapLoading":
      return { ...state, iapLoading: action.payload }
    case "waiting/fillInLoading":
      return { ...state, fillInLoading: action.payload }
    case "waiting/changeSampleAllocating":
      return { ...state, sampleAllocating: action.payload }  
    case "projects/setFetchDate":
      return {
        ...state,
        projectFetchAfter: DateTime.fromISO(action.after),
        projectFetchBefore: DateTime.fromISO(action.before),
      }
    case "whiteTasks/setFetchDate":
      return {
        ...state,
        whiteTaskFetchAfter: DateTime.fromISO(action.after),
        whiteTaskFetchBefore: DateTime.fromISO(action.before),
      }
    default:
      return state
  }
}

export const changeIapLoading = (to) => {
  return { type: "waiting/iapLoading", payload: to }
}

export const changeFillInLoading = (to) => {
  return { type: "waiting/fillInLoading", payload: to }
}

export const changeSampleAllocating = (to) => {
  return { type: "waiting/changeSampleAllocating", payload: to }
}

// We want to load some things after the schedule is rendered. They use this monday
export const changeScheduleMonday = (monday) => {
  return { type: "waiting/loadedSchedule", monday }
}
