import { DateTime, Duration } from "luxon"
import { createMap } from "state_management"
import { initialNodeCoords } from "components/GraphDesignTool"

// Outputs the earliest start action in the provided array
function EarliestStartAction(actions) {
  return Object.values(actions)
    .map((action) => action.earliestStart)
    .sort((a, b) => {
      if (a && b) {
        return a > b ? -1 : 1
      } else {
        return a ? -1 : b ? 1 : 0
      }
    })[0]
}

//Checks whether the logic in the actions holds.
//It starts at the changed action, then traverses downward until it reaches a starting action and calculates
//The time used to complete these actions. Afterwards it traverses upwards from the changed action
export function ActionValidator(actions, connections, activeAction, deadline) {
  //Setup for traversing the actions
  const Actions = {}
  actions.forEach((item) => (Actions[item.id] = item))
  let workingAction = activeAction
  let fromWorkingAction = activeAction
  let timeForCompletion = Duration.fromMillis(0).plus({ seconds: activeAction.duration })
  let visitedActions = []

  //Traverses the actions downwards
  while (workingAction) {
    //Declared to get rid of warning
    let innerWorkingAction = workingAction
    let previousActionIdFinder = connections.filter((connection) => connection.nextId === innerWorkingAction.id)
    workingAction = previousActionIdFinder.length > 0 ? Actions[previousActionIdFinder[0].prevId] : null
    timeForCompletion = workingAction
      ? timeForCompletion
          .plus({ seconds: workingAction.duration })
          .plus({ seconds: previousActionIdFinder[0].timeBetween.min })
      : DateTime.fromISO(fromWorkingAction.earliestStart).plus(timeForCompletion)
    fromWorkingAction = workingAction
    if (fromWorkingAction !== activeAction) {
      visitedActions.push(fromWorkingAction)
    }
    //Makes sure the loop does not go infinite
    if (new Set(visitedActions).size !== visitedActions.length) {
      return "Actions contains a cycle"
    }
  }
  if (timeForCompletion > DateTime.fromISO(activeAction.deadline)) {
    return activeAction.name + " deadline not possible"
  }

  //Traverses the actions upwards
  workingAction = activeAction
  fromWorkingAction = activeAction
  while (workingAction) {
    if (timeForCompletion < DateTime.fromISO(workingAction.earliestStart)) {
      timeForCompletion = DateTime.fromISO(workingAction.earliestStart).plus({ seconds: workingAction.duration })
    }
    //Declared to get rid of warning
    let innerWorkingAction = workingAction
    let nextActionIdFinder = connections.filter((connection) => connection.prevId === innerWorkingAction.id)
    workingAction = nextActionIdFinder.length > 0 ? Actions[nextActionIdFinder[0].nextId] : null
    timeForCompletion = workingAction
      ? timeForCompletion
          .plus({ seconds: workingAction.duration })
          .plus({ seconds: nextActionIdFinder[0].timeBetween.min })
      : timeForCompletion

    if (timeForCompletion > DateTime.fromISO(fromWorkingAction.deadline)) {
      return fromWorkingAction.name + " deadline not possible"
    }
    fromWorkingAction = workingAction
  }
  if (deadline && timeForCompletion > DateTime.fromISO(deadline)) {
    return "Not enough time to complete all actions before project deadline"
  }
  return null
}

function compareDates(a, b) {
  return a.hasSame(b, "minute", "hours", "month", "year")
}

// Helper function to detect whether the configuration is filled out correctly.
// This is not done using standard `required` attributes on input fields, since
// the function is called using `onClick` and not with standard form submission!
export function ProjectValidator(res, templates, hasId, isTemplate, isDraft=false) {
  if (!res.name) {
    return "Please fill in project name"
  }
  // if (isTemplate && !hasId && Object.values(templates).find((t) => t.name === res.name)) {
  //   return "Please use a unique template name"
  // }
  if (!res.color) {
    return "Please choose a project color"
  }

  if(!isDraft && !res.earliestStart) {
    return "Please select a project start date"
  }

  for (let i = 0; i < res.actions.length; i++) {
    if (!isDraft && !res.actions[i].name) {
      return "Please fill in action names"
    }
    if (!isDraft && res.actions[i].allowedGroupIds.length === 0) {
      return "Please add at least one competence group to each action"
    }
  }
  /*
  var earliestActionStart = EarliestStartAction(res.actions)
  if (earliestActionStart < res.earliestStart) {
    return "An action starts before the project"
  }*/
  return undefined
}

//Decides wheter or not a user has changed fields on the project page.
export function SomethingHasChanged(a, b) {
  //a is oldproject, b is newproject
  var msg = ""

  //When creating a new project definition
  if (!a && b) {
    msg = b.name ? msg.concat("- Project Name ") : msg
    msg = b.priority !== 2 ? msg.concat("- Priority ") : msg
    msg = b.earliestStart ? msg.concat("- Project Earliest Start ") : msg
    msg = b.description ? msg.concat("- Project Description ") : msg
    msg = b.color ? msg.concat("- Color ") : msg
    msg = b.deadline ? msg.concat("- Project Deadline ") : msg
    msg = b.actionConnections.length ? msg.concat("- Action Connections ") : msg
    msg = b.actions.length ? msg.concat("- Actions Count ") : msg
    msg = b.performerConstraints.length ? msg.concat("- Performer Constraints") : msg
    msg = b.earliestStartChecked ? msg.concat("- Earliest Start Checkbox") : msg
    return msg
  }

  // This might seem redundant but it is done due to action order issues
  // This is the same conversion of b-values from ProjectConfigurationPage
  const sortedActions = Object.values(createMap(a.actions.map((action) => ({ ...action }))))
  const sortedConnections = Object.values(createMap(a.actionConnections))

  // When projects are imported nodeCoords are {x: 0, y: 0}. This is used before the user moves them
  const nodeCoords = initialNodeCoords(createMap(sortedActions.map((action) => ({ ...action }))), sortedConnections)

  //Project properties check
  msg = a.name !== b.name ? msg.concat("- Project Name ") : msg
  msg = a.priority !== b.priority ? msg.concat("- Priority ") : msg
  msg =
    a.earliestStart || b.earliestStart
      ? compareDates(DateTime.fromISO(a.earliestStart), DateTime.fromISO(b.earliestStart))
        ? msg
        : msg.concat("- Project Earliest Start ")
      : msg
  msg = a.earliestStartChecked !== b.earliestStartChecked ? msg.concat("- Earliest Start Checkbox ") : msg
  msg = a.description !== b.description ? msg.concat("- Project Description ") : msg
  msg = a.color !== b.color ? msg.concat("- Color ") : msg
  msg =
    a.deadline || b.deadline
      ? compareDates(DateTime.fromISO(a.deadline), DateTime.fromISO(b.deadline))
        ? msg
        : msg.concat("- Project Deadline ")
      : msg

  var constra = false
  if (a.performerConstraints.length === b.performerConstraints.length) {
    for (let i = 0; i < a.performerConstraints.length; i++) {
      constra = a.performerConstraints[i].firstId !== b.performerConstraints[i].firstId ? true : constra
      constra =
        a.performerConstraints[i].performerConstraint !== b.performerConstraints[i].performerConstraint ? true : constra
      constra = a.performerConstraints[i].secondId !== b.performerConstraints[i].secondId ? true : constra
    }
  } else {
    msg = msg.concat("- Performer Constraints Count")
  }
  msg = constra ? msg.concat("- Performer Constraints") : msg

  var Connections = false
  if (sortedConnections.length === b.actionConnections.length) {
    for (let i = 0; i < sortedConnections.length; i++) {
      if (
        sortedConnections[i].nextId !== b.actionConnections[i].tempNextId ||
        sortedConnections[i].prevId !== b.actionConnections[i].tempPrevId ||
        sortedConnections[i].timeBetween !== b.actionConnections[i].timeBetween
      ) {
        Connections = true
      }
    }
  } else {
    Connections = true
  }
  msg = Connections ? msg.concat("- Action Connections") : msg

  //Action checks
  var AllowedGroups = false
  var AllowedAssets = false
  var Name = false
  var Deadline = false
  var Description = false
  var Duration = false
  var Earliest = false
  var OnSite = false
  var HasMoved = false

  if (sortedActions.length === b.actions.length) {
    for (let i = 0; i < sortedActions.length; i++) {
      if (!b.actions[i].allowedGroupIds) {
        b.actions[i].allowedGroupIds = []
      }
      if (sortedActions[i].allowedGroupIds.length === b.actions[i].allowedGroupIds.length) {
        for (let j = 0; j < sortedActions[i].allowedGroupIds.length; j++) {
          if (sortedActions[i].allowedGroupIds[j] !== b.actions[i].allowedGroupIds[j]) {
            AllowedGroups = true
          }
        }
      } else {
        AllowedGroups = true
      }
      if (sortedActions[i].allowedAssetIds.length === b.actions[i].allowedAssetIds.length) {
        for (let j = 0; j < sortedActions[i].allowedAssetIds.length; j++) {
          if (sortedActions[i].allowedAssetIds[j] !== b.actions[i].allowedAssetIds[j]) {
            AllowedAssets = true
          }
        }
      } else {
        AllowedAssets = true
      }

      if (sortedActions[i].name !== b.actions[i].name) {
        Name = true
      }
      if (
        (sortedActions[i].deadline || b.actions[i].deadline) &&
        !compareDates(DateTime.fromISO(sortedActions[i].deadline), DateTime.fromISO(b.actions[i].deadline))
      ) {
        Deadline = true
      }
      if (
        (sortedActions[i].earliestStart || b.actions[i].earliestStart) &&
        !compareDates(DateTime.fromISO(sortedActions[i].earliestStart), DateTime.fromISO(b.actions[i].earliestStart))
      ) {
        Earliest = true
      }

      if (sortedActions[i].description !== b.actions[i].description) {
        Description = true
      }
      if (sortedActions[i].duration !== b.actions[i].duration) {
        Duration = true
      }
      if (sortedActions[i].onSite !== b.actions[i].onSite) {
        OnSite = true
      }
      const sortedActionsCooX = sortedActions[i].coordinates["x"]
        ? sortedActions[i].coordinates["x"]
        : nodeCoords[sortedActions[i].id].x
      const sortedActionsCooY = sortedActions[i].coordinates["y"]
        ? sortedActions[i].coordinates["y"]
        : nodeCoords[sortedActions[i].id].y
      if (sortedActionsCooX !== b.actions[i].coordinates["x"] || sortedActionsCooY !== b.actions[i].coordinates["y"]) {
        HasMoved = true
      }
    }
  } else {
    msg = msg.concat("- Action Count ")
  }
  msg = AllowedGroups ? msg.concat("- Action Allowed Groups ") : msg
  msg = AllowedAssets ? msg.concat("- Action Allowed Assets ") : msg
  msg = Name ? msg.concat("- Action Names ") : msg
  msg = Deadline ? msg.concat("- Action Deadline ") : msg
  msg = Description ? msg.concat("- Action Descriptions ") : msg
  msg = Duration ? msg.concat("- Action Durations ") : msg
  msg = Earliest ? msg.concat("- Action Earliest Start ") : msg
  msg = OnSite ? msg.concat("- Action on site") : msg
  msg = HasMoved ? msg.concat("- Action has moved") : msg
  return msg
}

// Not used ATM
export function UpdateActions(oldStart, newStart, actions) {
  // Initial checks for empty starts
  var difference = null
  if (!newStart) {
    return actions
  }
  if (!oldStart) {
    const earliestStartAction = DateTime.fromISO(EarliestStartAction(actions))
    if (!earliestStartAction) {
      return actions
    }
    difference = DateTime.fromISO(newStart).diff(earliestStartAction)
  } else {
    difference = DateTime.fromISO(newStart).diff(oldStart)
  }
  // Update of actions
  difference &&
    Object.values(actions).forEach((action) => {
      action.earliestStart = action.earliestStart
        ? DateTime.fromISO(action.earliestStart).plus(difference).toISO()
        : null
      action.deadline = action.deadline ? DateTime.fromISO(action.deadline).plus(difference).toISO() : null
    })
  return actions
}
