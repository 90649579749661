import React, { useState} from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import InputGroup from "react-bootstrap/InputGroup"
import { Text } from "components/Form"

import "./index.scss"

import { FiPlus } from "react-icons/fi"

import { chooseOptimalColor } from "utils/scripts/schedule"

export default function AnalysisTab() {
  const navigate = useNavigate()
  const analysisDefinitions = useSelector((state) => state.analysisDefinitions)
  const [filterString, setFilterString] = useState("")
  const analysisGroups = useSelector((state) => state.analysisGroups.groups)
  const analysisGroupsSorted = Object.values(analysisGroups).sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
    return 0
  })

  if (!analysisDefinitions) {
    return <div />
  }

  const filteredAnalysisDefinitions = Object.values(analysisDefinitions).filter((analysis) => {
    return analysis.name.toUpperCase().includes(filterString.toUpperCase())
  })

  return (
    <div className="AnalysisTab-main-container">
      {Object.keys(analysisDefinitions).length > 0 && ( // Only display filter if the team has analyses
        <div className="AnalysisTab-filter">
          <InputGroup className="AssetView-filter-input-field">
            <Text placeholder="Filter by Name" value={filterString} onChange={setFilterString} />
          </InputGroup>
        </div>
      )}
      {analysisGroupsSorted.map((group) => (
        <React.Fragment key={group.id}>
          <div className="AnalysisGroupName-container">
            <h3>{group.name}</h3>
          </div>
          <div className="AnalysisTab-analyses-container">
            {filteredAnalysisDefinitions.map((analysisDefinition) => {
              return (
                analysisDefinition.analysisGroupIds.includes(group.id) && (
                  <div
                    key={analysisDefinition.id}
                    className={"AnalysisTab-analysis-container AnalysisTab-hovereffect" + (analysisDefinition.isDraft ? " AnalysisDraft-withBorder" : "")
                    }
                    style={{
                      backgroundColor: analysisDefinition.color,
                      color: chooseOptimalColor(analysisDefinition.color),
                    }}
                    onClick={() => navigate(`/setup/analyses/${analysisDefinition.id}`)}
                  >
                    <p>{analysisDefinition.name}</p>
                  </div>
                )
              )
            })}
          </div>
        </React.Fragment>
      ))}
      {analysisGroupsSorted.length !== 0 && <div className="AnalysisGroupName-container">
        <h3>No group</h3>
      </div>}
      <div className="AnalysisTab-analyses-container">
          {filteredAnalysisDefinitions.map((analysisDefinition) => {
            return analysisDefinition.analysisGroupIds.length === 0 && (
            <div
              key={analysisDefinition.id}
              className={"AnalysisTab-analysis-container AnalysisTab-hovereffect" + (analysisDefinition.isDraft ? " AnalysisDraft-withBorder" : "")}
              style={{
                backgroundColor: analysisDefinition.color,
                color: chooseOptimalColor(analysisDefinition.color),
              }}
              onClick={() => navigate(`/setup/analyses/${analysisDefinition.id}`)}
            >
              <p>{analysisDefinition.name}</p>
            </div>
          )
        })}
        <div className="AnalysisTab-analysis-container">
          <FiPlus title="Add analysis" onClick={() => navigate(`/setup/analyses/create`)} />
        </div>
      </div>
    </div>
  )
}
