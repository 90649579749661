import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useDrop } from "react-dnd"
import { FiCheck, FiPlay, FiPause, FiAlertTriangle } from "react-icons/fi"
import { FaMapMarkerAlt } from "react-icons/fa"
import { GiFactory } from "react-icons/gi"
import { FiPlusCircle } from "react-icons/fi"
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "./index.scss"

import { Popup } from "components"
import { ActionConfig } from "components/Config"
import Button from "react-bootstrap/Button"
import isOnMobile from "utils/scripts/isOnMobile"

import { DateTime } from "luxon"
import { chooseOptimalColor, optimalColor } from "utils/scripts/schedule"
import dragTypes from "utils/scripts/dragTypes"
import useCustomDrag from "utils/scripts/useCustomDrag"

import { projectActions, projects as projectState, standardActions, visual } from "state_management"
import { FilterActionsConfig, UnplannedToPlannedConfig } from "components/Config"
import { Text } from "components/Form"
import StandardActions from "./StandardActions"
import ProjectConfigurationPage from "components/Config/ProjectConfigurationPage"

// The To Do list in team-plan
export default function ProjectTasksMenu() {
  const projects = useSelector((state) => state.projects)
  const unplannedProjectActions = useSelector((state) => state.projectActions.unplannedProjectActions)
  const allStandardActionTemplates = useSelector((state) => state.standardActions.standardActions)
  const allStandardActions = allStandardActionTemplates.reduce((acc, template) => {
    return acc.concat(template.actions);
  }, []);
  const [activeActionId, setActiveActionId] = useState(null)
  const activeAction = useSelector(
    (state) => (activeActionId && state.projectActions.unplannedProjectActions[activeActionId]) || null
  )
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  const [createSingleAction, setCreateSingleAction] = useState(null)
  const [createStandardAction, setCreateStandardAction] = useState(null)
  const [activeStandardTaskId, setActiveStandardTaskId] = useState(null)
  const activeStandardAction = useSelector((state) => allStandardActions.find((action) => action.id === activeStandardTaskId) || null)
  const showStandardActions = useSelector((state) => state.visual.teamPlan.showStandardActions)
  const [showFilterConfig, setShowFilterConfig] = useState(false)
  const [unplannedToPlannedConfig, setUnplannedToPlannedConfig] = useState({ res: null, id: null })
  const filterSettings = useSelector((state) => state.visual.filterSettings)
  const [textFilter, setTextFilter] = useState("")
  const dispatch = useDispatch()
  const [editProject, setEditProject] = useState(false);

  const [{ isOver }, drop] = useDrop( () => ({
    accept: dragTypes.ACTION,
    collect: (mon) => ({
      isOver: !!mon.isOver(),
    }),
    drop: ({ item }) => {
      dispatch(projectActions.removePlanned(item.id))
    },
  }), [dragTypes.ACTION])

  const renderProjectActions = () => {
    const startDayThreshold = filterSettings
      ? DateTime.local().setZone(memberTimezone).plus({ weeks: filterSettings.earliestStartWeeks })
      : DateTime.local().setZone(memberTimezone)

    let sortableUnplannedProjectActions = []
    for (var key in unplannedProjectActions) {
      const action = unplannedProjectActions[key]
      const projectEarliestStart = projects[action.projectId].earliestStart
      const beforeThreshold = action.earliestStart
        ? DateTime.fromISO(action.earliestStart).setZone(memberTimezone) < startDayThreshold
        : projectEarliestStart
        ? DateTime.fromISO(projectEarliestStart).setZone(memberTimezone) < startDayThreshold
        : true
      if (!filterSettings.isEarliestStartFilter || beforeThreshold) {
        sortableUnplannedProjectActions.push(action)
      }
    }
    if (filterSettings) {
      if (filterSettings.isCompetenceGroupFilter && filterSettings.competenceGroupFilter.length) {
        sortableUnplannedProjectActions = sortableUnplannedProjectActions.filter((unplannedAction) =>
          filterSettings.competenceGroupFilter.some((r) => unplannedAction.allowedGroupIds.indexOf(r) >= 0)
        )
      }
      if (filterSettings.isDeadlineFilter) {
        if (filterSettings.deadlineStart) {
          sortableUnplannedProjectActions = sortableUnplannedProjectActions.filter((unplannedAction) =>
            unplannedAction.deadline
              ? DateTime.fromISO(unplannedAction.deadline).setZone(memberTimezone) >= DateTime.fromISO(filterSettings.deadlineStart).setZone(memberTimezone)
              : projects[unplannedAction.projectId]
              ? DateTime.fromISO(projects[unplannedAction.projectId].deadline).setZone(memberTimezone) >=
                DateTime.fromISO(filterSettings.deadlineStart).setZone(memberTimezone)
              : false
          )
        }
        if (filterSettings.deadlineEnd) {
          sortableUnplannedProjectActions = sortableUnplannedProjectActions.filter((unplannedAction) =>
            unplannedAction.deadline
              ? DateTime.fromISO(unplannedAction.deadline).setZone(memberTimezone).startOf("day") <=
                DateTime.fromISO(filterSettings.deadlineEnd).setZone(memberTimezone).startOf("day")
              : projects[unplannedAction.projectId]
              ? DateTime.fromISO(projects[unplannedAction.projectId].deadline).setZone(memberTimezone) <=
                DateTime.fromISO(filterSettings.deadlineEnd).setZone(memberTimezone)
              : false
          )
        }
      }
      if (textFilter) {
        function textRegex(unplannedAction, searchText) {
          searchText = searchText.toLowerCase()
          // Fancy regex expression to place a \ in front of all special characters
          searchText = searchText.replace(/[.$&+,:;=?@#|'<>-^*()%!]/g, '\\$&')
          const re = new RegExp(searchText, "i")
          const str =
            unplannedAction.name +
            " " +
            unplannedAction.description +
            " " +
            (projects[unplannedAction.projectId].singleActionProject ? "" : projects[unplannedAction.projectId].name)
          return str.match(re)
        }
        sortableUnplannedProjectActions = sortableUnplannedProjectActions.filter((unplannedAction) =>
          textRegex(unplannedAction, textFilter)
        )
      }
      if(filterSettings.scrumViewOrder){
        sortableUnplannedProjectActions = sortableUnplannedProjectActions.sort((a, b) => a.orderIndex - b.orderIndex)
        return Object.values([...sortableUnplannedProjectActions]).map((action) => {
          return <UnplannedProjectActionItem key={action.id} action={action} onClick={() => setActiveActionId(action.id)} />
        })
      }

    }
    const sortedUnplannedNoDeadline = sortableUnplannedProjectActions
      .filter((x) => !x.deadline && !projects[x.projectId].deadline)
      .sort((a, b) => {
        if (a.name !== b.name) return a.name.localeCompare(b.name)
        if (a.id > b.id) return 1
        if (b.id > a.id) return -1
        return 0
      })
    const sortedUnplannedWithDeadline = sortableUnplannedProjectActions
      .filter((x) => x.deadline || projects[x.projectId].deadline)
      .sort((a, b) => {
        const aDeadline = a.deadline
          ? a.deadline
          : projects[a.projectId]
          ? projects[a.projectId].deadline
            ? projects[a.projectId].deadline
            : null
          : null
        const bDeadline = b.deadline
          ? b.deadline
          : projects[b.projectId]
          ? projects[b.projectId].deadline
            ? projects[b.projectId].deadline
            : null
          : null
        if (aDeadline === bDeadline) {
          const projectA = projects[a.projectId]
          const projectB = projects[b.projectId]
          if (projectA.priority < projectB.priority) return -1
          if (projectA.priority > projectB.priority) return 1
          if (a.name !== b.name) return a.name.localeCompare(b.name)
          if (a.id > b.id) return 1
          if (b.id > a.id) return -1
          return 0
        }
        if (!aDeadline && !bDeadline) return 0
        if (!aDeadline) return -1
        if (!bDeadline) return 1
        if (aDeadline < bDeadline) return -1
        if (aDeadline > bDeadline) return 1
        return 0
      })
    return Object.values([...sortedUnplannedWithDeadline, ...sortedUnplannedNoDeadline]).map((action) => {
      return <UnplannedProjectActionItem key={action.id} action={action} onClick={() => setActiveActionId(action.id)} />
    })
  }

  return (
    <>
      {editProject && (
        <Popup
          className="Edit-Create-Project"
          onCancel={() => {
            setEditProject(false)
          }}
        >
          <ProjectConfigurationPage
            isCreateOrEditProject={true}
            setEditProject={() => {
              setEditProject()
            }}
          />
        </Popup>
      )}
      {activeActionId && unplannedProjectActions[activeActionId] && (
        <Popup nonScrollable onCancel={() => setActiveActionId(null)}>
          <ActionConfig
            title={"Edit Action"}
            action={unplannedProjectActions[activeActionId]}
            cancelHandler={() => setActiveActionId(null)}
            deleteHandler={
              projects[activeAction.projectId] && projects[activeAction.projectId].singleActionProject
                ? () => {
                    dispatch(projectState.remove(activeAction.projectId))
                    setActiveActionId(null)
                  }
                : null
            }
            isSingleAction={projects[activeAction.projectId] && projects[activeAction.projectId].singleActionProject}
            submitHandler={(res) => {
              if (res.stage === "PLANNED") {
                setUnplannedToPlannedConfig({ res, id: activeActionId })
                return
              }
              dispatch(projectActions.editUnplanned(activeActionId, res)).then(() => setActiveActionId(null))
            }}
            projectId={!isOnMobile ? activeAction.projectId : null}
          />
        </Popup>
      )}
      {createSingleAction && (
        <Popup nonScrollable onCancel={() => setCreateSingleAction(null)}>
          <ActionConfig
            title={"Create Action"}
            isSingleAction
            action={{}}
            cancelHandler={() => setCreateSingleAction(null)}
            submitHandler={(res) => {
              dispatch(projectActions.createSingleAction({ ...res, tempId: "123456789" })).then(() =>
                setCreateSingleAction(null)
              )
            }}
          />
        </Popup>
      )}
      {unplannedToPlannedConfig.res && (
        <Popup
          nonScrollable
          onCancel={() => {
            setActiveActionId(unplannedToPlannedConfig.id)
            setUnplannedToPlannedConfig({ res: null, id: null })
          }}
        >
          <UnplannedToPlannedConfig
            cancelHandler={() => {
              setActiveActionId(unplannedToPlannedConfig.id)
              setUnplannedToPlannedConfig({ res: null, id: null })
            }}
            cancelAll={() => {
              setActiveActionId(null)
              setUnplannedToPlannedConfig({ res: null, id: null })
            }}
            task={unplannedProjectActions[unplannedToPlannedConfig.id]}
            res={unplannedToPlannedConfig.res}
          />
        </Popup>
      )}

      {activeStandardTaskId && activeStandardAction && (
        <Popup
          nonScrollable
          onCancel={() => setActiveStandardTaskId(null)}
        >
          <ActionConfig
            title={"Edit Standard Action"}
            action={activeStandardAction}
            cancelHandler={() => setActiveStandardTaskId(null)}
            submitHandler={(res) => {
              dispatch(standardActions.editStandardAction(activeStandardTaskId, res)).then(() => {
                setActiveStandardTaskId(null)
              })
            }}
            disabled={true}
            deleteHandler={() => {
              dispatch(standardActions.deleteStandardAction(activeStandardTaskId)).then(() => {
                setActiveStandardTaskId(null)
              })
            }}
            projectId={!isOnMobile ? activeStandardAction.projectId : null}
            deleteTitle="Delete"
          />
        </Popup>
      )}

      {createStandardAction && (
        <Popup nonScrollable onCancel={() => setCreateStandardAction(null)}>
          <ActionConfig
            title={"Create Standard Action"}
            isSingleAction
            action={{}}
            cancelHandler={() => setCreateStandardAction(null)}
            submitHandler={(res) => {
              dispatch(standardActions.createStandardActions({...res, tempId: "123456789" })).then(() => 
                setCreateStandardAction(null)
              )
            }}
            disabled={true}
          />
        </Popup>
      )}

      <div
        className="ProjectTasksMenu-main-container"
        ref={drop}
        style={{
          filter: isOver ? "brightness(50%)" : "none",
        }}
      >
        <div className="standard-action-container">
          <div className="standard-action-dropdown" onClick={() => dispatch(visual.setShowStandardActions(!showStandardActions))}>
            <h5>Standard Actions</h5>
            {showStandardActions ? <IoIosArrowUp/> : <IoIosArrowDown/>}
          </div>
          <Button
            title="Create Standard Action"
            onClick={() => setCreateStandardAction(true)}
          >
            <FiPlusCircle/>
          </Button>
        </div>

        {showStandardActions && (
          <div>
            <StandardActions setActiveStandardTaskId={setActiveStandardTaskId}/>
          </div>
        )}

        <hr/>
        <div className="ProjectTasksMenu-tasks-container">
          {showFilterConfig && <FilterActionsConfig cancelHandler={() => setShowFilterConfig(false)}></FilterActionsConfig>}
          <div className="FilterButtons-Container">
            <h5 className="Pheading ActionList-Caption">Unplanned Projects</h5>
            <div className="todoList-Buttons">
              <Button
                className="Filter-button"
                title="Create new project"
                variant="primary"
                onClick={() => setEditProject(true)}
              >
                <p>Create New Project</p>
              </Button>

              {/* Create singe action button */}
              {/* <Button
                className="Filter-button"
                title="Create Single Action"
                variant="primary"
                onClick={() => setCreateSingleAction(true)}
              >
                <FiPlusCircle color="white" />
              </Button> */}
            </div>
            <div className="Todolist-Textfilter-Filterlist">
              <Text value={textFilter} onChange={setTextFilter} placeholder="Filter by Text"></Text>
              <Button className="Filter-button" onClick={() => setShowFilterConfig(true)}>
                <p>Filter</p>
              </Button>
            </div>
              <ul className="list-unstyled">
                {filterSettings ? filterSettings.isDeadlineFilter && <li>By Deadline</li> : ""}
                {filterSettings ? filterSettings.isCompetenceGroupFilter && <li>By Group</li> : ""}
                {textFilter ? <li>By Text</li> : ""}
                {filterSettings ? filterSettings.scrumViewOrder && <li>Scrum View Ordering</li> :""}
              </ul>
          </div>
          {unplannedProjectActions && renderProjectActions()}
        </div>
      </div>
    </>
  )
}

export function UnplannedProjectActionItem({ action, onClick }) {
  const projects = useSelector((state) => state.projects)
  const groups = useSelector((state) => state.competenceGroups)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const dispatch = useDispatch()
  const allStandardActions = useSelector((state) => state.standardActions.standardActions)

  
  const project = action.isStandardAction
  ? allStandardActions.find((standardAction) =>
  standardAction.actions.some((actionItem) => actionItem.id === action.id)
  )
  : projects[action.projectId];
  
  const { isDragging, dragRef } = useCustomDrag(action.isStandardAction ? dragTypes.STANDARD_ACTION : dragTypes.ACTION, action)
  
  // This is conditionally rendered, so when unplanning a standard action, it doesn't throw an error because the actions projectId is also removed from the database
  if (project) return (
    <div
      ref={dragRef}
      className={`ProjectTasksMenu-analysis-container` + (action.isStandardAction ? "-standard-action" : "")}
      onClick={onClick}
      style={{
        opacity: isDragging ? "0.4" : undefined,
        backgroundColor: project.color,
        cursor: "pointer",
        color: chooseOptimalColor(project.color),
        boxShadow:
          action.isStandardAction ?
          "0px 0px 0px 0px #000000"
          :
          action.deadline || project.deadline
            ? (action.deadline && DateTime.fromISO(action.deadline).setZone(memberTimezone).startOf("day") < DateTime.local().setZone(memberTimezone).startOf("day")) ||
              (!action.deadline &&
                project.deadline &&
                DateTime.fromISO(project.deadline).setZone(memberTimezone).startOf("day") < DateTime.local().setZone(memberTimezone).startOf("day"))
              ? "0px 0px 0px 6px red"
              : ""
            : "",
      }}
    >
      {!action.isStandardAction &&
        <div className="UnplannedProjectAction-Onsite">
          {!action.onSite && (
            <FaMapMarkerAlt
              title={"Can be performed anywhere"}
              onClick={(e) => {
                dispatch(projectActions.setLocationUnplanned(action.id, true))
                e.stopPropagation()
              }}
            />
          )}
          {action.onSite && (
            <GiFactory
              title={"Can only be performed on site"}
              color={optimalColor(project.color,"blue","orange")}
              onClick={(e) => {
                dispatch(projectActions.setLocationUnplanned(action.id, true))
                e.stopPropagation()
              }}
            />
          )}
        </div>
      }
      {action.state === "STARTED" ? (
        <FiPlay />
      ) : action.state === "PAUSED" ? (
        <FiPause />
      ) : action.state === "STOPPED" ? (
        <FiCheck />
      ) : null}
      <div className="ProjectTasksMenu-text">
        <div className="flex-container">
          {project.singleActionProject ? null : <h5 style={{margin:"0"}}>{project.name + ":"}&nbsp;</h5>}
          <h5>{action.name}</h5>
        </div>
        {!action.isStandardAction && (
          <>
            <p>
              Deadline:{" "}
              {action.deadline
                ? DateTime.fromISO(action.deadline).setZone(memberTimezone).toFormat("ff")
                : project.deadline
                ? DateTime.fromISO(project.deadline).setZone(memberTimezone).toFormat("ff")
                : ""}
            </p>
            <p>
              {"Competence Groups: " +
                action.allowedGroupIds
                  .filter((groupId) => groups[groupId])
                  .map((groupId) => groups[groupId].name)
                  .sort()
                  .join(", ")}
            </p>
          </>
        )}
      </div>
      {action.iapWarning && action.iapWarning.length > 0 && (
        <div title={action.iapWarning} className="hoverable-container">
          <div className="project-yellow-warning-container">
            <FiAlertTriangle />
          </div>
        </div>  
      )}
    </div>
  )
}
