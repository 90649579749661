import { useBlocker } from 'react-router-dom';

function useNavigationBlocker(shouldBlock) {
  let blocker = useBlocker(({ currentLocation, nextLocation }) =>
    shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  return blocker;
}

export default useNavigationBlocker;

