import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FiPlus, FiSettings, FiTrash2 } from "react-icons/fi"

import "./index.scss"

import { Popup, ListItemDnD, ConfirmDialog } from "components"
import { AssetConfig } from "components/Config"

import { assets as assetsState } from "state_management"

import dragTypes from "utils/scripts/dragTypes"

export default function AssetsTab() {
  const [addConfig, setAddConfig] = useState(false)
  const [activeEdit, setActiveEdit] = useState(null)
  const [activeDelete, setActiveDelete] = useState(null)

  const assets = Object.values(useSelector((state) => state.asset.assets)).sort()
  const dispatch = useDispatch()

  return (
    <div className="AssetsTab-main-container">
      {activeDelete && (
        <ConfirmDialog
          onCancel={() => setActiveDelete(null)}
          onConfirm={() =>
            dispatch(assetsState.remove(activeDelete.id)).then(
              () => setActiveDelete(null),
              () => setActiveDelete(null)
            )
          }
        >
          <h4>Are you sure you want to delete asset: {activeDelete.name}?</h4>
          <h5 style={{color: "red"}}>It will also be deleted for other teams on this location.</h5>
        </ConfirmDialog>
      )}
      {activeEdit && (
        <Popup nonScrollable onCancel={() => setActiveEdit(null)}>
          <AssetConfig asset={activeEdit} onClose={() => setActiveEdit(null)} />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <AssetConfig onClose={() => setAddConfig(false)} />
        </Popup>
      )}
      <div className="AssetsTab-list">
        {assets.map((asset) => (
          <ListItemDnD
          text={asset.name}
          key={asset.id}
          dragType={dragTypes.ASSET}
          id={asset.id}
          item={asset}
          onDrop={(dragId, dropId) => {
            const dropIdx = assets.findIndex((x) => x.id === dropId)
            const dragIdx = assets.findIndex((x) => x.id === dragId)
            if (dragIdx > dropIdx) {
              assets.splice(dropIdx, 0, assets[dragIdx])
              assets.splice(dragIdx + 1, 1)
            } else {
              assets.splice(dropIdx + 1, 0, assets[dragIdx])
              assets.splice(dragIdx, 1)
            }
            const ids = assets.map((x) => x.id)
            dispatch(assetsState.editOrder({ ids: ids }))
          }}>
            <FiTrash2 onClick={() => setActiveDelete(asset)} title="Delete Asset" />
            <FiSettings onClick={() => setActiveEdit(asset)} title="Edit Asset" />
          </ListItemDnD>
        ))}
        <div className="AssetsTab-add-asset">
          <FiPlus title="Add Asset" onClick={() => setAddConfig(true)} />
        </div>
      </div>
    </div>
  )
}
