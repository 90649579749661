import React, { useState } from "react"

import EditSheets from "../EditSheets"
import UploadDropzone from "../UploadDropzone"
import UploadButton from "../UploadButton"
import { Popup, IntegrationResultMessage } from "components"

export default function AutomaticIntegrationTab() {
    const [filesAutomatic, setFilesAutomatic] = useState([])
    const [resultMessage, setResultMessage] = useState(null)
    return (
        <div className="integration-tab">
            {resultMessage && (
                <Popup
                    onCancel={() => setResultMessage(null)}
                    title={resultMessage.aborted ? "Upload aborted!" : "Upload complete!"}
                >
                    <IntegrationResultMessage resultMessage={resultMessage} setResultMessage={setResultMessage} />
                </Popup>
            )}
            <p>Automatic Sheet Configuration</p>
            <div className="automatic-sheet-container">
                <EditSheets isAutomatic />
            </div>
            <p>File Upload</p>
            <UploadDropzone files={filesAutomatic} onSubmit={setFilesAutomatic} />
            <UploadButton
                route="/upload/raw-data"
                files={filesAutomatic}
                setFiles={setFilesAutomatic}
                setResultMessage={setResultMessage}
            />
        </div>
    )
}
