import React, { useMemo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { visibleRange, createMap } from "state_management/common"
import Table from "react-bootstrap/Table"

import "./index.scss"

import { DateTime } from "luxon"

/*
// If the start - end interval is in the IStart- Iend interval
// at any point return true else false
const isInInterval = (start, end, iStart, iEnd, member, iMember) => {
  if (member !== iMember) return false
  if (start >= iStart && end <= iEnd) return true
  if (start <= iStart && end >= iStart) return true
  if (iStart >= start && iEnd <= end) return true
  if (iStart <= start && iEnd >= start) return true
  return false
}

// Returns the updated interval given two intervals
const newInterval = (start, end, iStart, iEnd) => {
  const newStart = start < iStart ? start : iStart
  const newEnd = end > iEnd ? end : iEnd
  return [newStart, newEnd]
}

// Calculates the time spent by an item in the grey areas,
const timeSpentInGreyArea = (start, end, member, before) => {
  // This sorta works, BUT there is a problem with the time zones, when looking at a members work start and end, vs the timezone of the analysis, project, grey task etc.
  // Some contants
  const aDayInSeconds = 86400
  const workBegin = member.workHours.min
  const workEnd = member.workHours.max
  let greyTime = 0

  // Create array with each day that the item spans in current schedule
  let daysArr = []
  let currStart = start
  while (currStart <= end && currStart <= before) {
    daysArr.push(currStart)
    currStart = currStart
      .plus(Duration.fromObject({ days: 1 }))
      .minus(Duration.fromObject({ hours: currStart.hour, minutes: currStart.minute, seconds: currStart.second }))
  }

  // Pops the day that depends on item end time
  let lastDay = daysArr.pop()

  // These intervals are all lasting to midnight
  daysArr.forEach((day) => {
    const timeADay = Duration.fromObject({ hours: day.hour, minutes: day.minute, seconds: day.second }).as("seconds")
    if (timeADay < workBegin) {
      greyTime += workBegin - timeADay
    }
    const overlapAfterWork = timeADay - workEnd
    if (overlapAfterWork > 0) {
      greyTime += aDayInSeconds - workEnd - overlapAfterWork
    } else {
      greyTime += aDayInSeconds - workEnd
    }
  })

  // This is the last day that the item spans (might be the first aswell)
  lastDay = Duration.fromObject({ hours: lastDay.hour, minutes: lastDay.minute, seconds: lastDay.second }).as("seconds")
  const itemEnd = Duration.fromObject({ hours: end.hour, minutes: end.minute, seconds: end.second }).as("seconds")

  const timeSpentBeforeWork = workBegin - lastDay - Math.max(0, workBegin - itemEnd)
  const timeSpentAfterWork = itemEnd - workEnd - Math.max(0, lastDay - workEnd)
  if (timeSpentBeforeWork > 0) greyTime += timeSpentBeforeWork
  if (timeSpentAfterWork > 0) greyTime += timeSpentAfterWork
  return greyTime
}*/

// This is used to find A2T, P2T and W2T. It calculates those values,
// and builds a list of intervals over the items
const helperMemo = (mondayDate, withWeekend, withWeek, items, allTime, members) => {
  if (!allTime) return ["Loading", 0]
  const [after, before] = visibleRange(mondayDate, withWeekend, withWeek)
  const intervals = []
  const real = Object.values(items).reduce((real, item) => {
    // Some error handling for misscommunication in FE and BE
    if (members[item.memberIds[0]]) {
      if (!members[item.memberIds[0]].showOnPlan) return real
    } else {
      return real
    }

    let start = DateTime.fromISO(item.start)
    let end = DateTime.fromISO(item.end)

    // If the item is in our visible schedule
    if ((start < before && start > after) || (end < before && end > after)) {
      if (end > before) end = before
      if (start < after) start = after
      real += end.diff(start).as("seconds")
      // build the intervals list
      intervals.push({ start, end, member: item.memberIds[0] })
      return real
    }
    return real
  }, 0)
  return [((real / allTime) * 100).toFixed(2), intervals]
}

// Shows the metrics.
// Logic: If items are stacked or in grey areas they still count for everything
// in A2T, W2T and P2T. U2T are a little different: It does not include items in
// grey areas and if they are stacked it only counts for one item
export default function MetricsTable() {
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const members = useSelector((state) => state.members)

  const mondayDate = useSelector((state) => state.waiting.scheduleMonday)
  const withWeekend = useSelector((state) => state.visual.scheduleWithWeekend)
  const withWeek = useSelector((state) => state.visual.scheduleWithWeek)
  const analysisTasks = useSelector((state) => state.analysisTasks)
  const projectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const whiteTasks = useSelector((state) => state.whiteTasks)
  const [visualDays, setVisualDays] = useState([])

  useEffect(() => {
    let days = ["monday", "tuesday", "wednesday", "thursday", "friday"]
    if (withWeekend) days.push("saturday", "sunday")
    if (withWeek) days.push(...days)
    setVisualDays(days)
  }, [withWeek, withWeekend])

  // Calculates visible time
  const overAllTime = useMemo(() => {
    return Object.values(members).reduce((acc, member) => {
      return (
        acc +
        (member.showOnPlan && !member.archived
          ? member.workHours.max > member.workHours.min
            ? (member.workHours.max - member.workHours.min) * visualDays.filter((day) => member.workWeek[day]).length
            : (member.workHours.min - member.workHours.max) * visualDays.filter((day) => member.workWeek[day]).length
          : 0)
      )
    }, 0)
  }, [members, visualDays])

  // Analysis
  const [A2T] = useMemo(
    () => helperMemo(mondayDate, withWeekend, withWeek, analysisTasks, overAllTime, members),
    [analysisTasks, mondayDate, withWeek, withWeekend, overAllTime, members]
  )

  // Divide projectActions into operational and non-operational
  const [operationalActions, nonOperationalActions] = useMemo(() => {
    const ops = {};
    const nonOps = {};

    Object.keys(projectActions).forEach(key => {
      if (projectActions[key].isOperational) {
        ops[key] = projectActions[key];
      } else {
        nonOps[key] = projectActions[key];
      }
    });

    return [ops, nonOps];
  }, [projectActions]);

  // Projects
  const [P2T] = useMemo(
    () => helperMemo(mondayDate, withWeekend, withWeek, operationalActions, overAllTime, members),
    [operationalActions, mondayDate, withWeek, withWeekend, overAllTime, members]
  )

  // Grey tasks
  const [W2T] = useMemo(() => {
    const combinedTasks = {
      ...whiteTasks,
      ...nonOperationalActions,
    };

    return helperMemo(
      mondayDate,
      withWeekend,
      withWeek,
      createMap(
        Object.values(combinedTasks).filter((task) => {
          return (!task.greenTask && !task.isOperational) || task.isStandardAction;
        })
      ),
      overAllTime,
      members
    );
  }, [whiteTasks, nonOperationalActions, mondayDate, withWeek, withWeekend, overAllTime, members]);

  // Grey tasks operational
  const [OG2T] = useMemo(
    () =>
      helperMemo(
        mondayDate,
        withWeekend,
        withWeek,
        createMap(
          Object.values(whiteTasks).filter((task) => {
            return !task.greenTask && task.isOperational
          })
        ),
        overAllTime,
        members
      ),
    [whiteTasks, mondayDate, withWeek, withWeekend, overAllTime, members]
  )
  // Finds time filled up on the schedule and time spent in grey aread
  /*
  const overlapAndGrey = useMemo(() => {
    if (!intervalA || !intervalP || !intervalW) return

    // fill finalIntervals up with intervals from combinedIntervals
    // this is done by joining intervals that overlap
    // eslint-disable-next-line no-unused-vars
    const [after, before] = visibleRange(mondayDate, withWeekend, withWeek)
    let finalIntervals = []
    let combinedIntervals = [...intervalA, ...intervalP, ...intervalW]
    let gotoTopOfWhile = false
    while (combinedIntervals.length) {
      let workingInterval = combinedIntervals[0]
      for (let i = 1; i < combinedIntervals.length; i++) {
        const element = combinedIntervals[i]
        if (
          isInInterval(
            workingInterval.start,
            workingInterval.end,
            element.start,
            element.end,
            workingInterval.member,
            element.member
          )
        ) {
          // Join matching intervals and place it at the beginning of combinedIntervals
          const [newStart, newEnd] = newInterval(workingInterval.start, workingInterval.end, element.start, element.end)
          combinedIntervals[0] = { start: newStart, end: newEnd, member: element.member }
          combinedIntervals.splice(i, 1)
          gotoTopOfWhile = true
          break
        }
      }
      // reevaluate this updated interval
      if (gotoTopOfWhile) {
        gotoTopOfWhile = false
        continue
      }
      // No matches are found and the interval is accepted
      finalIntervals.push(workingInterval)
      combinedIntervals.splice(0, 1)
    }
    const greyTime = finalIntervals.reduce((acc, interval) => {
      return (acc += timeSpentInGreyArea(interval.start, interval.end, members[interval.member], before))
    }, 0)
    const allOccupiedTime = finalIntervals.reduce((acc, interval) => {
      return (acc += interval.end.diff(interval.start).as("seconds"))
    }, 0)
    return { allOccupiedTime, greyTime }
  }, [intervalA, intervalP, intervalW, mondayDate, withWeekend, withWeek, members])
  */
  return (
    <>
      <hr className="Left-Menu-line" style={{ marginTop: "2rem", marginBottom: "2rem" }} />
      <Table className="Metrics-table" hover>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {showAnalyses && (
            <tr title="Percentage of analysis-time relative to total time (A2T)">
              <td>A2T</td>
              <td>{A2T}</td>
            </tr>
          )}
          {showProjects && (
            <tr title="Percentage of project-time relative to total time (P2T)">
              <td>P2T</td>
              <td>{P2T}</td>
            </tr>
          )}
          <tr title="Percentage of grey task time relative to total time (G2T)">
            <td>G2T</td>
            <td>{W2T}</td>
          </tr>
          {parseFloat(OG2T) !== 0 && (
            <tr title="Percentage of operational grey task time relative to total time (OG2T)">
              <td>OG2T</td>
              <td>{OG2T}</td>
            </tr>
          )}
          {/*
        <tr title="Percentage of unplanned time relative to total time (U2T)">
          <td>U2T</td>
          <td>
            {overlapAndGrey
              ? (
                  ((overAllTime - (overlapAndGrey.allOccupiedTime - overlapAndGrey.greyTime)) / overAllTime) *
                  100
                ).toFixed(2)
              : "Loading..."}
          </td>
        </tr>
              */}
        </tbody>
      </Table>
    </>
  )
}
