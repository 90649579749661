import { useEffect, useRef, useCallback } from "react"

/**
 * Custom React Hook that handles user inactivity logout.
 *
 * This hook initializes a timer whenever user activity is detected. If the user becomes
 * inactive for a specified duration, a provided callback function is executed, which can
 * be used to handle logout or display warning messages, for instance. The timer resets
 * with each detected activity. The activity is detected through various event listeners
 * like mousemove, keydown, click, and visibility change.
 *
 * @param {Function} handleInactivity - The function to be called upon user inactivity.
 * @param {boolean} isLoggedIn - A boolean indicating the user's login status.
 * @param {number} timeoutDuration - The duration (in milliseconds) to wait before declaring inactivity.
 */
export default function useInactivityLogout(handleInactivity, isLoggedIn, timeoutDuration) {
  // Holds the reference to the logout timer in order to clear it later if needed.
  const logoutTimerRef = useRef()

  /**
   * Initializes or resets the inactivity timer.
   *
   * The timer is set with the timeout duration, and will execute the provided
   * `handleInactivity` function if the timer is allowed to run its course.
   * When the function is called again, it first clears the existing timer (if any),
   * ensuring that only one timer is running at a time.
   */
  const startInactivityTimer = useCallback(() => {
    clearTimeout(logoutTimerRef.current) // Clear any existing timers
    logoutTimerRef.current = setTimeout(handleInactivity, timeoutDuration) // Set a new timer
  }, [handleInactivity, timeoutDuration])

  /**
   * Sets up event listeners to detect user activity and manage the inactivity timer,
   * based on the `isLoggedIn` state. If the user is logged in, listeners for various
   * activities (mousemove, keydown, click, visibilitychange) are set up, each resetting
   * the inactivity timer upon being fired.
   *
   * Also, a cleanup function is provided to remove the listeners and clear the timer
   * when the component using the hook is unmounted or if the user logs out.
   */
  useEffect(() => {
    if (isLoggedIn) {
      startInactivityTimer() // Initialize the inactivity timer when the user is logged in

      // Define the list of events that will reset the inactivity timer
      const events = ["mousemove", "keydown", "click", "visibilitychange"]

      // Add event listeners that reset the inactivity timer upon any of the specified user activities
      events.forEach((event) => {
        window.addEventListener(event, startInactivityTimer)
      })

      // Cleanup function: Remove event listeners and clear the timer upon component unmount or logout
      return () => {
        clearTimeout(logoutTimerRef.current) // Clear the inactivity timer
        // Remove all previously set event listeners to clean up and avoid memory leaks
        events.forEach((event) => {
          window.removeEventListener(event, startInactivityTimer)
        })
      }
    }
  }, [isLoggedIn, timeoutDuration, startInactivityTimer])
}
