import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import "./index.scss"

import { ConfigContainer, Loading } from "components"
import { Title, Text, Container, DurationInput, DayPicker, Select, TaskStatePicker } from "components/Form"
import { message, samples } from "state_management"

import { DateTime, Duration } from "luxon"

import AssetSetup from "./AssetSetup.jsx"
import PeopleSetup from "./PeopleSetup.jsx"

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { FiPlus } from "react-icons/fi"
import { Button } from "react-bootstrap"

// Config used for analysis tasks that are on the plan, and analysis task "defintion" setup.
export default function AnalysisTaskConfig({
  task,
  title,
  isDefinition = false,
  isDraft = false,
  submitHandler,
  cancelHandler,
  deleteHandler,
  bookedAndFreeAssets,
  activeAssetId,
}) {
  const dispatch = useDispatch()
  const competenceGroups = useSelector((state) => state.competenceGroups)
  const assets = useSelector((state) => state.asset.assets)
  const analysis = useSelector((state) => state.analyses.plannedAnalyses[task.analysisId])
  const members = useSelector((state) => state.members)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  // Start with setuptime missing, and default of 0 for other fields
  const [name, setName] = useState(task.name || "")
  const [teamMember, setTeamMember] = useState(task.memberIds ? task.memberIds[0] : [])
  const [state, setState] = useState(task.state || "NOT_STARTED")
  const [description, setDescription] = useState(task.description ? task.description.trim() : "")
  const [startDate, setStartDate] = useState(task.start ? DateTime.fromISO(task.start).setZone(memberTimezone) : null)
  const [startTime, setStartTime] = useState(
    startDate ? Duration.fromObject({ hours: startDate.hour, minutes: startDate.minute }) : null
  )
  const [setuptime, setSetuptime] = useState(
    task.setuptime !== undefined ? Duration.fromObject({ seconds: task.setuptime }) : null
  )
  const [perSampleDuration, setPerSampleDuration] = useState(
    Duration.fromObject({ seconds: task.perSampleDuration || 0 })
  )
  const [allowedGroupIds, setAllowedGroupIds] = useState(
    task.allowedGroupIds ? task.allowedGroupIds.filter((groupId) => competenceGroups[groupId]) : []
  )
  const [showAdditionals, setShowAdditionals] = useState(false)
  const [assetRequirements, setAssetRequirements] = useState(
    task.assetRequirements ?
    task.assetRequirements.map(req => ({
      assetSetuptime: Duration.fromObject({ seconds: req.assetSetuptime || 0 }),
      assetPerSampleDuration: Duration.fromObject({ seconds: req.assetPerSampleDuration || 0 }),
      assetOffset: Duration.fromObject({ seconds: req.assetOffset || 0 }),
      assetId: req.assetId || null,
      allowedAssetIds: req.allowedAssetIds ? req.allowedAssetIds : []
    }))
    :
    []
  );
  const activeAssetRequirement = assetRequirements.find(req => req.assetId === activeAssetId);
  const [startTimeAssetView, setStartTimeAssetView] = useState(
    startDate && activeAssetRequirement?.assetOffset ? Duration.fromObject({ hours: startDate.hour, minutes: startDate.minute }).plus(activeAssetRequirement?.assetOffset) : null
  )
  const [assetIds, setAssetIds] = useState(assetRequirements.map(req => req.assetId).filter(id => id != null));
  const [primaryAsset, setPrimaryAsset] = useState(assetIds[0] || null);
  const sortedAllowedPrimaryAssetIds = assetRequirements[0] ? assetRequirements[0].allowedAssetIds
    .map(id => assets[id])
    .filter(asset => asset)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(asset => asset.id)
    :
    []


  const handleAssetRequirementChange = (index, field, value) => {
    const updatedRequirements = assetRequirements.map((requirement, reqIndex) => {
      if (index === reqIndex) {
        return { ...requirement, [field]: value };
      }
      return requirement;
    });
    setAssetRequirements(updatedRequirements);
  };

  const addAssetRequirement = () => {
    const newRequirement = {
      assetSetuptime: Duration.fromObject({ seconds: 0 }),
      assetPerSampleDuration: Duration.fromObject({ seconds: 0 }),
      assetOffset: Duration.fromObject({ seconds: 0 }),
      assetId: null,
      allowedAssetIds: []
    };
    setAssetRequirements([...assetRequirements, newRequirement]);
  };

  const removeAssetRequirement = (index) => {
    setAssetRequirements(currentRequirements =>
      currentRequirements.filter((_, reqIndex) => index !== reqIndex)
    );
  };

  useEffect(() => {
    const newAssetIds = assetRequirements.map(req => req.assetId).filter(id => id !== null);
    setAssetIds(newAssetIds);
  }, [assetRequirements]);

  useEffect(() => {
    if (assetIds.length > 0) {
      setPrimaryAsset(assetIds[0]);
    }
  }, [assetIds])

  let sortedCollection = [];
  // Check if bookedAndFreeAssets is not null or undefined
  if (bookedAndFreeAssets) {
    let combinedAssets = [];

    // Iterate over each index
    Object.values(bookedAndFreeAssets).forEach(index => {
      const freeAssets = index.freeAssets || [];
      const bookedAssets = index.bookedAssets || [];

      // Combine free and booked assets from the index, with appropriate status
      combinedAssets = combinedAssets.concat(
        freeAssets.map(assetId => ({
          ...assets[assetId],
          status: 'Free',
        })),
        bookedAssets.map(assetId => ({
          ...assets[assetId],
          status: 'Booked',
        }))
      );
    });

    // Sort the combined assets alphabetically by their name and append the status to the name
    sortedCollection = combinedAssets.sort((a, b) => a.name.localeCompare(b.name))
      .map(asset => ({
        ...asset,
        name: `${asset.name} (${asset.status})`
      }));
  }
  const allowedPrimaryAssetIds = sortedAllowedPrimaryAssetIds
  .map((id) => sortedCollection.find((asset) => asset.id === id))
  .filter(Boolean);

  const disabled = false

  const location = useLocation()
  const isAnalysisSetupView = location.pathname.includes("/analyses/")
  const isAssetView = location.pathname.includes("/asset-view")

  const sampleData = useSelector((state) => state.samples[task.analysisId] || null)

  if (!sampleData && isAssetView) {
    dispatch(samples.fetchForPlanned(task.analysisId))
  }

  useEffect(() => {
    // Update the start time view when the startDate or active asset's offset changes
    if (startDate && activeAssetRequirement?.assetOffset) {
      setStartTimeAssetView(Duration.fromObject({ hours: startDate.hour, minutes: startDate.minute }).plus(activeAssetRequirement.assetOffset));
    }
  }, [startDate, activeAssetRequirement]);

  const [endDate, setEndDate] = useState(DateTime.fromISO(task.end).setZone(memberTimezone));
  const [durationValue, setDurationValue] = useState(null);

  // Used to update the day and time so it doesn't get above 24 hours
  useEffect(() => {
    if (sampleData && !sampleData.isLoading) {
      let taskStartTime = DateTime.fromISO(task.start).setZone(memberTimezone);
      let taskEndTime = DateTime.fromISO(task.end).setZone(memberTimezone);

      // When isAssetView is true, adjust the taskStartTime by adding the assetOffset
      if (isAssetView && activeAssetRequirement) {
        const offsetInMinutes = activeAssetRequirement.assetOffset / 60000;
        taskStartTime = taskStartTime.plus({ minutes: offsetInMinutes });
        taskEndTime = taskStartTime;
      }

      let totalMinutes = 0;

      // Convert assetSetuptime and assetPerSampleDuration from milliseconds to minutes
      if (isAssetView && activeAssetRequirement?.assetSetuptime) {
        const setupTimeInMinutes = activeAssetRequirement.assetSetuptime / 60000;
        const perSampleDurationInMinutes = (activeAssetRequirement.assetPerSampleDuration / 60000) * sampleData.items.length;
        totalMinutes = setupTimeInMinutes + perSampleDurationInMinutes;
      } else {
        // Convert setuptime and perSampleDuration from milliseconds to minutes
        if (setuptime) {
          totalMinutes = setuptime / 60000;
        }
        if (perSampleDuration) {
          totalMinutes += (perSampleDuration / 60000) * sampleData.items.length;
        }
      }

      const hoursToAdd = Math.floor(totalMinutes / 60);
      const minutesToAdd = totalMinutes % 60;

      let newEndTime = isAssetView ? taskEndTime.plus({ hours: hoursToAdd, minutes: minutesToAdd }) : taskStartTime.plus({ hours: hoursToAdd, minutes: minutesToAdd });

      // Check if the day has changed after adding time
      if (newEndTime.day !== taskEndTime.day) {
        // If the day has changed, update the DayPicker component's value
        setEndDate(newEndTime);
      } else if (isAssetView) {
        // If isAssetView is true but the day hasn't changed, ensure endDate is updated based on the newEndTime
        setEndDate(newEndTime);
      }

      setDurationValue(Duration.fromObject({
        hours: newEndTime.hour,
        minutes: newEndTime.minute,
      }));

      if (isAssetView) {
        setStartTimeAssetView(Duration.fromObject({
          hours: taskStartTime.hour,
          minutes: taskStartTime.minute,
        }));
      }
    }
  }, [task.end, task.start, memberTimezone, isAssetView, activeAssetRequirement, sampleData, setuptime, perSampleDuration]);

  // Make description text-area more dynamic by requiring the same amount of row as the 'description' string
  const descriptionTextRows = description.slice().split(/\r\n|\r|\n/).length || 1

  if ((!sampleData || sampleData.isLoading) && isAssetView) {
    return <Loading />
  }

  return (
    <ConfigContainer
      disabled={disabled}
      title={title}
      submitHandler={() => {
        const res = {
          name,
          setuptime: setuptime ? setuptime.as("seconds") : null,
          perSampleDuration: perSampleDuration.as("seconds"),
          allowedGroupIds,
          description,
          assetRequirements: assetRequirements.map(req => ({
            ...req,
            assetSetuptime: req.assetSetuptime ? req.assetSetuptime.as('seconds') : 0,
            assetPerSampleDuration: req.assetPerSampleDuration ? req.assetPerSampleDuration.as('seconds') : 0,
            assetOffset: req.assetOffset ? req.assetOffset.as('seconds') : 0,
            assetId: req.assetId ? req.assetId : null,
            allowedAssetIds: req.allowedAssetIds || []
          })),
        }
        if (!isDefinition) {
          if (state !== "NOT_STARTED") {
            const curDate = DateTime.local().setZone(memberTimezone).startOf("day").toISODate()
            const endDate = DateTime.fromISO(task.end).setZone(memberTimezone).startOf("day").toISODate()
            if (endDate > curDate) {
              const stateMsg = state === "STOPPED" ? "finished" : state === "PAUSED" ? "paused" : "started"
              const msg = `A future Analysis task can't be ${stateMsg}`
              dispatch(message.warning(msg))
              return
            }
          }
          res.state = state
          res.start = startDate.set({ hour: startTime.hours, minute: startTime.minutes }).setZone(memberTimezone).toISO()
        }
        res.memberIds = Array.isArray(teamMember) ? teamMember : [teamMember]
        const isValid = res.assetRequirements.every((req, index) => {
          if (index > 0 && (!req.allowedAssetIds || req.allowedAssetIds.length === 0)) {
            dispatch(message.warning(`Secondary asset setup must have at least one allowed asset`));
            return false
          }
          return true
        })
        if (isValid) {
          submitHandler(res);
        }

        // Tasks in setup view have analysisDefinitionId while tasks in plan view have analysisId
        // The following dispatch is only relevant for planned analyses
      }}
      cancelHandler={cancelHandler}
      deleteTitle={!isDefinition ? "Unplan Analysis" : undefined}
      deleteHandler={deleteHandler}
      submitButtonName="Save"
      cancelButtonName="Cancel"
    >
      <div className="setup-container">
        <Container>
          <Title>Analysis</Title>
          <Text disabled value={analysis ? analysis.name : ""} />
        </Container>
        <Container>
          <Title>
            Task Name<div className="red-text">*</div>
          </Title>
          <Text disabled={disabled} required={!isDraft} value={name} onChange={setName} />
        </Container>
        {!isDefinition && (
          <>
            <Container>
              <Title>
                Team Member <div className="red-text">*</div>
              </Title>
              <Select
                name="Team Member"
                required
                closeMenuOnSelect={true}
                attr={"initials"}
                collection={Object.values(members).filter(
                  (member) => (!member.archived || task.memberIds[0] === member.id) && member.showOnPlan
                )}
                value={teamMember}
                onChange={setTeamMember}
                isMulti={false}
                menuPosition="fixed"
              />
            </Container>
            <Container>
              <Title>Primary Asset</Title>
              <Select
                name="Primary Asset"
                disabled={disabled}
                collection={allowedPrimaryAssetIds[0] ? allowedPrimaryAssetIds : []}
                value={primaryAsset}
                onChange={(newAssetIds) => {
                  handleAssetRequirementChange(0, 'assetId', newAssetIds[0])
                  setAssetIds(newAssetIds)
                }}
                menuPlacement="bottom"
                isMulti={false}
              />
            </Container>
            <div className="container-compartment">
              <>
                {isAssetView ? (<p style={{ marginBottom: "1rem" }}>Asset Time</p>) : (<p style={{ marginBottom: "1rem" }}>Member Time</p>)}
                <Container>
                  <Title>From date</Title>
                  <DayPicker
                    required
                    disabled={isAssetView ? true : disabled}
                    value={startDate}
                    selected={startDate}
                    defaultMonth={new Date(startDate)}
                    onChange={(event) => {
                      if (!event) {
                        setStartDate(undefined)
                        return
                      }
                      if (event.invalid) {
                        return
                      }
                      setStartDate(event)
                    }}
                  />
                  <Title>From time (HH:MM)</Title>
                  <DurationInput
                    disabled={isAssetView ? true : disabled}
                    value={isAssetView ? startTimeAssetView : startTime}
                    onChange={isAssetView ? setStartTimeAssetView : setStartTime}
                    required
                    isTime
                  />
                </Container>
                <Container>
                  <Title>To date</Title>
                  <DayPicker disabled value={endDate} />
                  <Title>To time (HH:MM)</Title>
                  <DurationInput
                    disabled
                    value={durationValue}
                  />
                </Container>
              </>
            </div>
          </>
        )}

        {!isDefinition && (
          <div className="action-links" onClick={() => setShowAdditionals(!showAdditionals)}>
            <div className="action-container">
              <span>Additional Information</span>
              {showAdditionals ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
        )}

        {(showAdditionals || isDefinition) && (
          <div className="additional-info">
            {!isDefinition && (
              <Container>
                <Title>State</Title>
                <TaskStatePicker required disabled={disabled} value={state} onChange={setState} />
              </Container>
            )}
            <Container>
              <Title>Description</Title>
              <Text disabled={disabled} large value={description} onChange={setDescription} rows={descriptionTextRows} />
            </Container>

            {isAnalysisSetupView ? (
              <>
                <PeopleSetup
                  setuptime={setuptime}
                  setSetuptime={setSetuptime}
                  isDraft={isDraft}
                  perSampleDuration={perSampleDuration}
                  setPerSampleDuration={setPerSampleDuration}
                  disabled={disabled}
                  competenceGroups={competenceGroups}
                  allowedGroupIds={allowedGroupIds}
                  setAllowedGroupIds={setAllowedGroupIds}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  memberTimezone={memberTimezone}
                  task={task}
                />
                {assetRequirements.map((requirement, index) => (
                  <AssetSetup
                    key={index}
                    index={index}
                    assetRequirement={requirement}
                    setAssetRequirement={(field, value) => handleAssetRequirementChange(index, field, value)}
                    removeAssetRequirement={() => removeAssetRequirement(index)}
                    assets={assets}
                    isDefinition={isDefinition}
                    disabled={disabled}
                    sortedCollection={sortedCollection}
                  />
                ))}
              </>
            ) : (
              <>
                {assetRequirements.map((requirement, index) => (
                  <AssetSetup
                    key={index}
                    index={index}
                    assetRequirement={requirement}
                    setAssetRequirement={(field, value) => handleAssetRequirementChange(index, field, value)}
                    removeAssetRequirement={() => removeAssetRequirement(index)}
                    assets={assets}
                    isDefinition={isDefinition}
                    disabled={disabled}
                    sortedCollection={sortedCollection}
                  />
                ))}
                <PeopleSetup
                  setuptime={setuptime}
                  setSetuptime={setSetuptime}
                  isDraft={isDraft}
                  perSampleDuration={perSampleDuration}
                  setPerSampleDuration={setPerSampleDuration}
                  disabled={disabled}
                  competenceGroups={competenceGroups}
                  allowedGroupIds={allowedGroupIds}
                  setAllowedGroupIds={setAllowedGroupIds}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  memberTimezone={memberTimezone}
                  task={task}
                />
              </>
            )}
            {isDefinition && (
              <div className="add-new-asset">
                <p>New Asset</p>
                <Button title="Add new Asset" onClick={addAssetRequirement}>
                  <FiPlus/>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </ConfigContainer>
  )
}
