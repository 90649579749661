import React, { useEffect, useState, useRef, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Duration, DateTime } from "luxon"
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap"

import "./index.scss"

import AssetViewElement from "./AssetViewElement"
import { ScheduleButtons, Loading, Popup } from "components"
import { SampleTableForPlannedAnalysis } from "components/SampleTable"
import { ActionConfig, AnalysisTaskConfig, WhiteTaskConfig } from "components/Config"
import InputGroup from "react-bootstrap/InputGroup"
import { Text } from "components/Form"

import { assets, analysisTasks, analyses, projects, projectActions, visual, whiteTasks } from "state_management"

import { getDates, isSameDate } from "utils/scripts/schedule"

import isOnMobile from "utils/scripts/isOnMobile"

// This file contains a lot of useMemo that's used for optimizing the hover effect rendering.
export default function AssetViewScreen() {
  const dispatch = useDispatch()
  const graphInfo = useSelector((state) => state.asset.graphInfo)
  const withWeek = useSelector((state) => state.visual.scheduleWithWeek)
  const mondayDate = useSelector((state) => state.visual.scheduleMondayDate)
  const currentDate = DateTime.fromISO(useSelector((state) => state.visual.activeDate))
  const showCurrentDate = useSelector((state) => state.visual.assetView.showCurrentDate)
  const showCompactAssetview = useSelector((state) => state.visual.assetView.showCompact)
  const showWeekendAssetView = useSelector((state) => state.visual.assetView.showWeekend)
  const allProjects = useSelector((state) => state.projects)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  const [activeAnalysisTaskId, setActiveAnalysisTaskId] = useState(null)
  const [activeAssetId, setActiveAssetId] = useState(null)
  const activeAnalysisTask = useSelector(
    (state) => (activeAnalysisTaskId && state.analysisTasks[activeAnalysisTaskId]) || null
  )
  const [activeSamplePopupAnalysisId, setActiveSamplePopupAnalysisId] = useState(null)
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({bookedAssets: [], freeAssets: []})
  const [activeActionId, setActiveActionId] = useState(null)
  const activeAction = useSelector(
    (state) => (activeActionId && state.projectActions.plannedProjectActions[activeActionId]) || null
  )
  const [activeWhiteTaskConfig, setActiveWhiteTaskConfig] = useState(false)
  const [curWhiteTaskInfo, setCurWhiteTaskInfo] = useState({})
  const [hoverInfo, setHoverInfo] = useState({})

  const [activeWhiteTaskId, setActiveWhiteTaskId] = useState(null)
  const activeWhiteTask = useSelector((state) => (activeWhiteTaskId && state.whiteTasks[activeWhiteTaskId]) || null)
  const [filterString, setFilterString] = useState("")

  const dayRef = useRef(null)
  useEffect(() => {
    // The condition for scrolling when weekends are shown
    const shouldScrollForWeekend = showWeekendAssetView && currentDate.weekday > 5

    if ((shouldScrollForWeekend || showCurrentDate) && dayRef.current) {
      dayRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })

      if (showCurrentDate) {
        dispatch(visual.showCurrentDate(false))
      }
    }
  }, [showWeekendAssetView, currentDate, showCurrentDate, dispatch])

  // 'activeWhiteTaskConfig' , 'activeWhiteTask', and 'activeAction' are dependencies in order to fetch/render newly created grey-tasks in asset view
  // TODO: Instead of fetching everything when creating/editing/removing a white task / analysis task / action, just change the redux state
  useEffect(() => {
    dispatch(assets.fetchGraphInfo())
  }, [mondayDate, dispatch, activeWhiteTaskConfig, activeWhiteTask, activeAction])

  const assetsToRender = useMemo(() => {
    if (graphInfo) {
      return Object.values(graphInfo)
    } else {
      return []
    }
  }, [graphInfo])

  const dates = useMemo(
    () => getDates(mondayDate, showWeekendAssetView, withWeek, memberTimezone),
    [mondayDate, showWeekendAssetView, withWeek, memberTimezone]
  )

  const filteredAssetsToRender = useMemo(() => {
    return filterString
      ? assetsToRender.filter((asset) => asset.name.toUpperCase().includes(filterString.toUpperCase()))
      : assetsToRender
  }, [assetsToRender, filterString])

  if (!graphInfo) {
    return <Loading />
  }

  const w = (withWeek && showCompactAssetview) ? 160 :
            (showCompactAssetview) ? 90 :
            50 * dates.length;
  const h = filteredAssetsToRender.length * 10

  const initialValue = 0
  // Max width of all assets for the team
  const maxAssetWidth = (Math.min(20, assetsToRender.reduce((acc, curr) => Math.max(curr.name.length, acc), initialValue)) * 1) + "rem"

  return (
    <div className="AssetViewScreen-main-container">
      <div className="AssetViewScreen-top-left-container" style={{ width: showCompactAssetview ? "12rem" : maxAssetWidth}}></div>
      <div className="AssetViewScreen-filter" style={{ width: showCompactAssetview ? "12rem" : maxAssetWidth}}>
        <InputGroup className="AssetView-filter-input-field">
          <Text placeholder="Filter by Name" value={filterString} onChange={setFilterString} />
        </InputGroup>
      </div>
      <div
        className="AssetViewScreen-days-container"
        style={{
          width: w + "%",
          position: h === 0 ? "absolute" : "sticky",
          left: showCompactAssetview ? "12rem" : maxAssetWidth,
        }}
      >
        <Days dates={dates} hoverInfo={hoverInfo} dayRef={dayRef} currentDate={currentDate} />
      </div>
      {
        // This ternary operator is a hacky solution to prevent the days-container from collapsing all the way to the left when the filter can't find any assets
        h !== 0 ? (
          <>
            <div className="AssetView" style={{ height: showCompactAssetview ? h / 10 * 2.5 + "rem" : h + "%", width: showCompactAssetview ? "12rem" : maxAssetWidth }}>
              {filteredAssetsToRender.map((asset) => (
                <div key={asset.id} title={asset.name}>
                  <p style={{fontSize: showCompactAssetview ? "90%" : "160%"}}>{asset.name}</p>
                </div>
              ))}
            </div>
            <div
              className="AssetViewScreen-asset-container"
              style={{ width: w + "%", height: showCompactAssetview ? h / 10 * 2.5 + "rem" : h + "%", left: showCompactAssetview ? "12rem" : maxAssetWidth }}
            >
              <AssetViewPlan
                assetsToRender={filteredAssetsToRender}
                setActiveAnalysisTaskId={setActiveAnalysisTaskId}
                setActiveActionId={setActiveActionId}
                setActiveWhiteTaskId={setActiveWhiteTaskId}
                dates={dates}
                setCurWhiteTaskInfo={setCurWhiteTaskInfo}
                setActiveWhiteTaskConfig={setActiveWhiteTaskConfig}
                setHoverInfo={setHoverInfo}
                w={w}
                setBookedAndFreeAssets={setBookedAndFreeAssets}
                showCompactAssetview={showCompactAssetview}
                setActiveAssetId={setActiveAssetId}
              />
            </div>
          </>
        ) : (
          <>
            <div className="AssetView" style={{ width: w + "%", height: 50 + "%", backgroundColor: "transparent", marginTop: "9rem", marginLeft: "2rem" }}>
              <h3>No assets found</h3>
            </div>
          </>
        )
      }

      <div style={{ top: 0, width: "100vw", left: 0, position: "fixed", zIndex: 3 }}>
        <ScheduleButtons type="asset-view" />
      </div>

      {activeAction && (
        <Popup nonScrollable onCancel={() => setActiveActionId(null)}>
          <ActionConfig
            title={<h4>Edit Action</h4>}
            action={activeAction}
            isSingleAction={
              activeAction &&
              allProjects[activeAction.projectId] &&
              allProjects[activeAction.projectId].singleActionProject
            }
            cancelHandler={() => setActiveActionId(null)}
            submitHandler={(res) =>
              dispatch(projectActions.editPlanned(activeActionId, res)).then(() => setActiveActionId(null))
            }
            deleteTitle="Unplan"
            deleteHandler={() => {
              setActiveActionId(null)
              dispatch(projectActions.removePlanned(activeActionId))
            }}
            shiftDeleteHandler={() => {
              const projectId = allProjects[allProjectActions[activeActionId].projectId].id
              dispatch(projects.unplan(projectId)).then(() => setActiveActionId(null))
            }}
            projectId={!isOnMobile ? activeAction.projectId : null}
            confirmDeletionText={"Are you sure you want to unplan all future actions in this project?"}
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {activeAnalysisTask && (
        <Popup
          nonScrollable
          onCancel={() => {
            setActiveAnalysisTaskId(null)
            setActiveSamplePopupAnalysisId(null)
          }}
        >
          <div className="taskTabs">
            <ToggleButtonGroup type="radio" name="asset-view-name" defaultValue={"TASK"}>
              <ToggleButton
                title="Edit Analysis-task"
                variant="primary"
                value="TASK"
                id="asset-task-view_id"
                className="task-tab-btn"
                onClick={() => setActiveSamplePopupAnalysisId(null)}
              >
                Edit Analysis-task
              </ToggleButton>
              <ToggleButton
                title="View Analysis Samples"
                variant="primary"
                value="SAMPLE"
                id="asset-sample-view_id"
                className="task-tab-btn"
                onClick={() => setActiveSamplePopupAnalysisId(activeAnalysisTask.analysisId)}
              >
                View Analysis Samples
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="taskAndSampleContainer">
            {!activeSamplePopupAnalysisId && (
              <AnalysisTaskConfig
                task={activeAnalysisTask}
                cancelHandler={() => setActiveAnalysisTaskId(null)}
                submitHandler={(res) => {
                  return dispatch(analysisTasks.edit(activeAnalysisTaskId, res))
                    .then(() => dispatch(assets.fetchGraphInfo()))
                    .then(() => {
                      setActiveAnalysisTaskId(null)
                      if (activeAnalysisTask.analysisId) {
                        dispatch(analyses.setMovable(activeAnalysisTask.analysisId));
                      }
                    })
                }}
                deleteHandler={() => {
                  dispatch(analyses.remove(activeAnalysisTask.analysisId))
                    .then(() => dispatch(assets.fetchGraphInfo()))
                    .then(() => setActiveAnalysisTaskId(null))
                }}
                bookedAndFreeAssets={bookedAndFreeAssets}
                activeAssetId={activeAssetId}
              />
            )}
            {activeSamplePopupAnalysisId && (
              <SampleTableForPlannedAnalysis
                analysisId={activeSamplePopupAnalysisId}
                cancelHandler={() => {
                  setActiveAnalysisTaskId(null)
                  setActiveSamplePopupAnalysisId(null)
                }}
              />
            )}
          </div>
        </Popup>
      )}

      {activeWhiteTaskConfig && (
        <Popup nonScrollable onCancel={() => setActiveWhiteTaskConfig(false)}>
          <WhiteTaskConfig
            task={{
              start: DateTime.fromISO(curWhiteTaskInfo.date.plus({ hours: curWhiteTaskInfo.time }))
                .setZone(memberTimezone)
                .toISO(),
              duration: Duration.fromObject({ hours: 1 }).as("seconds"),
              assetIds: [curWhiteTaskInfo.asset.id],
              memberIds: [],
            }}
            onClose={() => {
              setActiveWhiteTaskConfig(false)
              setCurWhiteTaskInfo({})
            }}
            isAssetView
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {activeWhiteTask && (
        <Popup nonScrollable onCancel={() => setActiveWhiteTaskId(null)}>
          <WhiteTaskConfig task={activeWhiteTask} onClose={() => setActiveWhiteTaskId(null)} isAssetView bookedAndFreeAssets={bookedAndFreeAssets}/>
        </Popup>
      )}
    </div>
  )
}

// 'const' component as shorthand instead of function to easier use React.memo()
const AssetViewPlan = React.memo(
  ({
    assetsToRender,
    setActiveAnalysisTaskId,
    setActiveActionId,
    setActiveWhiteTaskId,
    dates,
    setCurWhiteTaskInfo,
    setActiveWhiteTaskConfig,
    setHoverInfo,
    w,
    setBookedAndFreeAssets,
    showCompactAssetview,
    setActiveAssetId,
  }) => {
    const dispatch = useDispatch()

    return assetsToRender.map((asset) => (
      <div key={asset.id}>
        <AssetViewElement
          assetId={asset.id}
          setActiveAssetId={setActiveAssetId}
          setActiveAnalysisTaskId={setActiveAnalysisTaskId}
          setActiveActionId={setActiveActionId}
          setActiveWhiteTaskId={setActiveWhiteTaskId}
          setBookedAndFreeAssets={setBookedAndFreeAssets}
          isCompactView={showCompactAssetview}
        />
        <div className="AssetViewScreen-asset-element-container">
          {dates.map((date) => {
            let res = []
            for (let i = 0; i < 24; i++) {
              res.push(
                <div
                  key={i}
                  className="AssetViewScreen-asset-element-hour"
                  style={{
                    flexGrow: "1",
                    borderLeft: i > 0 && "1px solid transparent",
                    boxSizing: "border-box",
                    lineStyle: "hidden",
                  }}
                  onDoubleClick={() => {
                    setCurWhiteTaskInfo({
                      date: date,
                      time: i,
                      asset: asset,
                    })
                    dispatch(whiteTasks.getBookedFreeAssets(null, { start: date.toISO() })).then((res) => {
                      setBookedAndFreeAssets(res)
                    })
                    setActiveWhiteTaskConfig(true)
                  }}
                  onMouseEnter={() => {
                    setHoverInfo({
                      curHour: i,
                      curDate: date.toISO(),
                    })
                  }}
                  onMouseLeave={() => {
                    setHoverInfo({})
                  }}
                ></div>
              )
            }
            return (
              <div
                className="AssetViewScreen-asset-element-day"
                key={date.toISO()}
                style={{
                  display: "flex",
                  width: w + "%",
                  borderLeft: "2px solid transparent",
                  borderRight: date === dates[dates.length - 1] ? "2px solid transparent" : "",
                  boxSizing: "border-box",
                }}
              >
                {res}
              </div>
            )
          })}
        </div>
      </div>
    ))
  }
)

const Days = React.memo(({ dates, hoverInfo, dayRef, currentDate }) => {
  const showCompactAssetview = useSelector((state) => state.visual.assetView.showCompact)
  return dates.map((date, idx) => {
    return (
      <div
        key={date.toISO()}
        ref={isSameDate(currentDate, date) ? dayRef : null}
        style={{
          flexGrow: "1",
          boxSizing: "border-box",
          borderLeft: "2px solid black",
          borderRight: idx === dates.length - 1 ? "2px solid black" : "",
        }}
      >
        <div
          style={{
            height: "75%",
            position: "relative",
            minWidth: "110px",
          }}
        >
          <p
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              margin: "auto",
              width: "fit-content",
              height: "fit-content",
              fontSize: showCompactAssetview ? "120%" : "130%",
            }}
          >
            {date.toFormat("ccc d MMM")}
          </p>
        </div>
        <div
          style={{
            height: "25%",
            display: "flex",
          }}
        >
          {/* Only render 'Hours' when there is a change in current date */}
          {<Hours curHour={date.toISO() === hoverInfo.curDate ? hoverInfo.curHour : null} />}
        </div>
      </div>
    )
  })
})

const Hours = React.memo(({ curHour }) => {
  const showCompactAssetview = useSelector((state) => state.visual.assetView.showCompact)
  let res = []
  for (let i = 1; i < 25; i++) {
    res.push(
      <div
        key={i}
        className="AssetViewScreen-hour"
        style={{
          flexGrow: "1",
          borderLeft: i > 1 && "1px solid black",
          boxSizing: "border-box",
          backgroundColor: i - 1 === curHour ? "#0097ce" : undefined,
        }}
        title={
          Duration.fromObject({ hours: i - 1 }).toFormat("hh:mm") +
          " to " +
          Duration.fromObject({ hours: i }).toFormat("hh:mm")
        }
      >
        {!showCompactAssetview ? Duration.fromObject({ hours: i - 1 }).toFormat("hh") : ""}
      </div>
    )
  }
  return res
})
