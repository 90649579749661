import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { ConfigContainer } from "components"
import { Container, Title, Text, Tabular } from "components/Form"
import { integrationSheets } from "state_management"

// Configuration setup for integration sheets.
// TODO: Remove the reliance on the sheet's "__typename"
export default function IntegrationSheetConfig({ sheet, onSubmit, onClose }) {
  // This config works differently from most, it generates the Text inputs dynamically!
  const dispatch = useDispatch()
  const sheetFormat =
    sheet.__typename === "ActionUploadConfig"
      ? [
          { attr: "sheetName", title: "Sheet Name", required: true },
          { attr: "projectName", title: "Project Name", required: true },
          { attr: "projectDescription", title: "Project Description", required: true },
          { attr: "projectDeadline", title: "Project Deadline", required: true },
          { attr: "projectPriority", title: "Project Priority", required: false, default: "2" },
          { attr: "actionName", title: "Action Name", required: true },
          { attr: "actionDescription", title: "Action Description", required: true },
          { attr: "actionEarliestStart", title: "Action Earliest Start", required: true },
          { attr: "actionDeadline", title: "Action Deadline", required: true },
          { attr: "actionDuration", title: "Action Duration", required: true },
          { attr: "actionCompetenceGroup", title: "Action Competence Group", required: true },
          { attr: "teamName", title: "Team Name", required: false },
        ]
      : sheet.__typename === "SampleObservationUploadConfig"
      ? [
          { attr: "sheetName", title: "Sheet Name", required: true },
          { attr: "sampleName", title: "Sample Name", required: true },
          { attr: "analysisName", title: "Analysis Name", required: true },
          { attr: "arrival", title: "Latest Arrival", required: true },
          { attr: "deadline", title: "Deadline", required: true },
          { attr: "observationId", title: "Observation ID", required: false, default: "1" },
          { attr: "priority", title: "Priority", required: false, default: "2" },
          { attr: "status", title: "Status", required: false, default: "ARRIVED" },
          { attr: "exclusive", title: "Exclusive", required: false, default: "false" },
          { attr: "numberOfObservations", title: "Number of Observations", required: false, default: "1" },
          { attr: "productName", title: "Product Name", required: false},
          { attr: "teamName", title: "Team Name", required: false },
        ]
      : sheet.__typename === "LimsUploadConfig"
      ? [
          { attr: "sheetName", title: "Sheet Name", required: true },
          { attr: "receivedDate", title: "LIMS Received Date", required: true },
          { attr: "sampleBatchNumber", title: "LIMS Batch Number", required: true },
          { attr: "analysisName", title: "LIMS Analysis Name", required: true },
          { attr: "observationId", title: "LIMS unique ID", required: false, default: "1" },
          { attr: "productDescription", title: "LIMS Product Description", required: false },
          { attr: "status", title: "LIMS Status", required: false, default: "ARRIVED" },
          { attr: "materialNumber", title: "LIMS Material Number", required: false },
        ]
      : sheet.__typename === "SapUploadConfig"
      ? [
          { attr: "sheetName", title: "Sheet Name", required: true },
          { attr: "sapReleaseDate", title: "SAP Release Date", required: true },
          { attr: "sapBatchNumber", title: "SAP Batch Number", required: true },
          { attr: "sapMaterialNumber", title: "SAP Material Number", required: true },
          { attr: "sapMaterialDescription", title: "SAP Material Description", required: true },
          { attr: "sapSupplierNumber", title: "SAP Supplier Number", required: false },
          { attr: "sapSupplierName", title: "SAP Supplier Name", required: false },
        ]
      : [
          { attr: "sheetName", title: "Sheet Name", required: true },
          { attr: "templateName", title: "Template Name", required: true },
          { attr: "projectName", title: "Project Name", required: true },
          { attr: "projectDescription", title: "Project Description", required: false },
          { attr: "projectEarliestStart", title: "Project Earliest Start", required: true },
          { attr: "projectDeadline", title: "Project Deadline", required: false },
          { attr: "projectPriority", title: "Project Priority", required: false },
          { attr: "recurrenceAmount", title: "No. of Recurrences", required: false, dependsOn: "recurrenceInterval" },
          { attr: "recurrenceInterval", title: "Days Between Recurrences", required: false, dependsOn: "recurrenceAmount" },
          { attr: "teamName", title: "Team Name", required: false },
        ]

  const [data, setData] = useState(() => {
    const rtn = {}
    sheetFormat.forEach((format) => {
      rtn[format.attr] = sheet[format.attr] || ""
    })
    return rtn
  })

  return (
    <ConfigContainer
      title={`Edit Sheet ${sheet.sheetName}`}
      cancelHandler={onClose}
      submitHandler={() => {

        // When submitting, the input paramaters have their leading and trailing whitespaces trimmed.
        const dataTrimmed = data
        for(let key in dataTrimmed) {
          dataTrimmed[key] = dataTrimmed[key].trim()
        }
        setData(dataTrimmed)

        if (sheet.id) {
          if (sheet.__typename === "ActionUploadConfig") {
            dispatch(integrationSheets.editActionSheet(sheet.id, data)).then(onClose)
          } else if (sheet.__typename === "ProjectTemplateUploadConfig") {
            dispatch(integrationSheets.editProjectTemplateSheet(sheet.id, data)).then(onClose)
          } else if (sheet.__typename === "SampleObservationUploadConfig") {
            dispatch(integrationSheets.editSampleSheet(sheet.id, data)).then(onClose)
          } else if (sheet.__typename === "LimsUploadConfig"){
            dispatch(integrationSheets.editLimsAnalysisSheet(sheet.id, data)).then(onClose)
          } else {
            dispatch(integrationSheets.editSapAnalysisSheet(sheet.id, data)).then(onClose)
          }
        } else {
          if (sheet.__typename === "ActionUploadConfig") {
            dispatch(integrationSheets.createActionSheet(data)).then(onClose)
          } else if (sheet.__typename === "ProjectTemplateUploadConfig") {
            dispatch(integrationSheets.createProjectTemplateSheet(data)).then(onClose)
          } else if (sheet.__typename === "SampleObservationUploadConfig") {
            dispatch(integrationSheets.createSampleSheet(data)).then(onClose)
          } else if (sheet.__typename === "LimsUploadConfig"){
            dispatch(integrationSheets.createLimsAnalysisSheet(data)).then(onClose)
          } else {
            dispatch(integrationSheets.createSapAnalysisSheet(data)).then(onClose)
          }
        }
      }}
    >
      <Container>
        <Title>Type</Title>
        <Text
          disabled
          value={
            sheet.__typename === "ActionUploadConfig"
              ? "Actions & Projects"
              : sheet.__typename === "ProjectTemplateUploadConfig"
              ? "Project Template"
              : sheet.__typename === "LimsUploadConfig"
              ? "LIMS to Analysis"
              : sheet.__typename === "SapUploadConfig"
              ? "SAP to Project"
              : "Sample Observation"
          }
        />
      </Container>
      <Container key={sheetFormat[0].attr}>
        <Title value={"Shhet"}>Sheet Name</Title>
        <Text
          required={sheetFormat[0].required}
          value={data[sheetFormat[0].attr]}
          onChange={(value) => setData({ ...data, [sheetFormat[0].attr]: value })}
        />
      </Container>
      <Tabular>
        <thead>
          <tr>
            <td>
              <h6> {sheet.__typename === "LimsUploadConfig" ? "LIMS " : sheet.__typename === "SapUploadConfig" ? "SAP " : "Plannertech "} Variable</h6>
            </td>
            <td>
              <h6>Spreadsheet Column Name</h6>
            </td>
          </tr>
        </thead>
        <tbody>
          {sheetFormat.slice(1, sheetFormat.length).map((format) => (
            <tr key={format.attr}>
              <td>
                {format.title}
                {format.attr === "recurrenceInterval" ? (` (mandatory if recurrence is used)`) : (!format.required && ` (leave empty if not used${format.default ? `, default=${format.default}` : ""})`)}
              </td>
              <td>
                <Text
                  required={format.dependsOn ? (data[format.dependsOn].trim() !== "") : format.required}
                  value={data[format.attr]}
                  onChange={(value) => setData({ ...data, [format.attr]: value })}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Tabular>
    </ConfigContainer>
  )
}
