import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { SampleTable, Loading, Popup } from "components"
import { samples, message } from "state_management"
import Table from "react-bootstrap/Table"
import { Button } from "react-bootstrap"
import { Graph } from "./TopologicalSort"

export default function SampleTableForUnplannedAnalysis({ analysisId }) {
  const dispatch = useDispatch()
  const sampleData = useSelector((state) => state.samples[analysisId] || null)
  const competenceGroups = useSelector((state) => state.competenceGroups)
  const assets = useSelector((state) => state.asset.assets)
  const hasExclusiveSample = sampleData?.items ? sampleData.items.some(sample => sample.exclusive === true) : false

  if (!sampleData) {
    dispatch(samples.fetchForUnplanned(analysisId))
  }

  const sampleCount = useSelector((state) => state.analyses.unplannedAnalyses[analysisId].sampleCount)
  
  useEffect(() => {
    if(sampleData && sampleData.items && sampleCount !== sampleData.items.length) {
      dispatch(message.warning("Analysis updated, please refresh"))
    }
  })
  

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)

  // Find the competence group members for the analysis
  const analysisName = useSelector((state) => state.analyses.unplannedAnalyses[analysisId].name)
  const analysis = Object.values(useSelector((state) => state.analysisDefinitions)).find(
    (analysis) => analysis.name === analysisName
  )

  // Ugly fix, first checking for '!planned' because 'analysis' is undefined for planned analyses.
  const tasks = analysis ? analysis.taskDefinitions : []

  // For sorting: Connections only exist if there are more than one task
  const connections = analysis ? analysis.taskDefinitionConnections : []

  // Driver Code for the topological sorting
  var g = new Graph(tasks.length)
  const tasksSorted = g.getSortedArray(g, connections, tasks)

  if (!sampleData || sampleData.isLoading) {
    return <Loading />
  }

  return (
    <>
      {showAdditionalInfo && (
        <Popup onCancel={() => setShowAdditionalInfo(false)} className="sampletable-additional-info-popup">
          <Table bordered hover className="SampleTable">
            <thead>
              <tr>
                <th>Tasks</th>
                <th>Competence Groups</th>
                <th>Allowed Assets</th>
              </tr>
            </thead>
            <tbody>
              {tasksSorted.map((task) => (
                <tr key={task.id}>
                  <td>{task.name}</td>
                  <td>
                    <ul>
                      {task.allowedGroupIds
                        .map((ids) => competenceGroups[ids])
                        .map((group) => (
                          <li key={group.name}>{group.name}</li>
                        ))}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {task.assetRequirements
                        .flatMap((requirement) => requirement.allowedAssetIds)
                        .map((id) => assets[id])
                        .map((asset) => (
                          <li key={asset.name}>{asset.name}</li>
                        ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Popup>
      )}
      <div className="sample-additional-information-button">
        <Button variant="success" onClick={() => setShowAdditionalInfo(true)}>
          Additional information
        </Button>
      </div>
      <SampleTable popupSamples={sampleData.items} />
      {hasExclusiveSample && (
        <div className="explanation-text">
          * Indicates an exclusive sample
        </div>
      )}
    </>
  )
}
