// Returns a closure to use inside the builtin 'sort' function
export function dynamicSortMultiple() {
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0, result = 0, numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
        * as long as we have extra properties to compare
        */
      while(result === 0 && i < numberOfProperties) {
          result = dynamicSort(props[i])(obj1, obj2);
          i++;
      }
      return result;
  }
}

// The following two functions are used to sort the popupSamples array
function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a, b) {
      var valueA = a[property];
      var valueB = b[property];
      var isDate = false;

      // Check if value follows the ISO 8601 date format using regex
      var isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
      if (isoDateRegex.test(valueA) && isoDateRegex.test(valueB)) {
          isDate = true;
      }

      // If it's a date, extract just the date part (YYYY-MM-DD)
      if (isDate) {
          valueA = valueA.split('T')[0];
          valueB = valueB.split('T')[0];
      }

      var result = (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
      return result * sortOrder;
  }
}
