import { loading } from "."
import { DateTime } from "luxon"
import { convertOffset } from "utils/scripts/schedule"

export const initialTemplateState = {
  scheduleWithWeekend: false,
  scheduleWithWeek: false,
  scheduleMondayDate: null,
  activeRightMenuTab: "Analysis",
  leftMenuVisible: false,
  rightMenuVisible: false,
  activeDate: null,
  IAPconfig: {
    IAPmode: "analysis",
    timePeriod: {
      to: null,
      from: null,
    },
  },
  filterSettings: {
    competenceGroupFilter: [],
    deadlineStart: null,
    deadlineEnd: null,
    textFilter: "",
    earliestStartWeeks: 2,
    isCompetenceGroupFilter: false,
    isDeadlineFilter: false,
    isTextFilter: false,
    isEarliestStartFilter: false,
    isIAPFilter: false,
    scrumViewOrder: false,
  },
  assetView: {
    showCompact: false,
    showWeekend: true,
    showCurrentDate: false,
  },
  teamPlan: {
    showCompact: false,
    showStandardActions: false,
  },
  checkZoomFactor: true,
  showGlobalMessageConfig: false
}

const initialState = {
  ...initialTemplateState
}

export function reducer(state = initialState, action) {
  let temp = { ...state }
  switch (action.type) {
    case "auth/logout":
      // Keep `activeRightMenuTab` across logouts!
      return {
        ...initialState,
        activeRightMenuTab: state.activeRightMenuTab,
        IAPconfig: state.IAPconfig,
      }
    case "auth/logout-other-device":
    case "auth/login":
    case "auth/verifyMfaToken":
      return {
        ...initialState,
        scheduleMondayDate: convertOffset(DateTime.local().startOf("week").toISO(),action.res.member_timezone).toISO(),
        activeDate: convertOffset(DateTime.local().startOf("day").toISO(),action.res.member_timezone).toISO(),
        scheduleWithWeekend: window.localStorage.getItem("schedule-withWeekend") === "true" ? true : false,
        scheduleWithWeek: window.localStorage.getItem("schedule-withWeek") === "true" ? true : false,
        activeRightMenuTab: JSON.parse(window.localStorage.getItem("visual")).activeRightMenuTab || "Analysis",
        IAPconfig: JSON.parse(window.localStorage.getItem("visual")).IAPconfig || {
          IAPmode: "analysis",
          timePeriod: {
            to: null,
            from: null,
          },
        },
      }
    case "visual/setWeekendVisibility":
      temp.scheduleWithWeekend = action.value
      return temp
    case "visual/setExtraWeekVisibility":
      temp.scheduleWithWeek = action.value
      return temp
    case "visual/setActiveRightMenuTab":
      return { ...state, activeRightMenuTab: action.activeRightMenuTab }
    case "visual/setMenuVisibility":
      if (action.menutype === "rightmenu") {
        temp.rightMenuVisible = action.visibility
      } else if (action.menutype === "leftmenu") {
        temp.leftMenuVisible = action.visibility
      }
      return temp
    case "visual/changeMondayDate":
      temp.scheduleMondayDate = DateTime.fromISO(action.date).toISO()
      return temp
    case "visual/setActiveDate":
      temp.activeDate = DateTime.fromISO(action.date).toISO()
      return temp
    case "visual/setIAPconfiguration":
      temp.IAPconfig = action.configuration
      return temp
    case "projectActions/filterUnplanned":
      temp.filterSettings = { ...action.filterSettings }
      return temp
    case "visual/setShowCompactAssetview":
      temp.assetView.showCompact = action.value
      return temp
    case "visual/setWeekendAssetView":
      temp.assetView.showWeekend = action.value
      return temp
    case "visual/setShowCompactTeamPlan":
      temp.teamPlan.showCompact = action.value
      return temp
    case "teamPlan/showStandardActions":
      temp.teamPlan.showStandardActions = action.value
      return temp
    case "teamStatus/fetchLastSampleAllocation":
      temp.IAPconfig.timePeriod.from = action.res.iapFromDate
      temp.IAPconfig.timePeriod.to = action.res.iapToDate
      return temp
    case "visual/changeCheckZoomFactor":
      temp.checkZoomFactor = false
      return temp
    case "visual/showGlobalMessageConfig":
      temp.showGlobalMessageConfig = action.value
      return temp
    case "visual/showCurrentDate":
      temp.assetView.showCurrentDate = action.value
      return temp
    default:
      return state
  }
}

// Used in multiple views. Careful not to break anything!
export function setActiveDate(date) {
  return { type: "visual/setActiveDate", date }
}

export function setWeekendVisibility(value) {
  return (dispatch) => {
    dispatch({ type: "visual/setWeekendVisibility", value })
    dispatch(loading.onScheduleChange())
  }
}

export function setExtraWeekVisibility(value) {
  return (dispatch) => {
    dispatch({ type: "visual/setExtraWeekVisibility", value })
    dispatch(loading.onScheduleChange())
  }
}

export function setMenuVisibility(menutype, visibility) {
  return (dispatch) => {
    dispatch({ type: "visual/setMenuVisibility", menutype, visibility })
  }
}

export function setActiveRightMenuTab(activeRightMenuTab) {
  return { type: "visual/setActiveRightMenuTab", activeRightMenuTab }
}

export function changeMondayDate(date, forLogin) {
  return (dispatch) => {
    dispatch({ type: "visual/changeMondayDate", date })
    if (!forLogin) {
      dispatch(loading.onScheduleChange())
    }
  }
}

export function setIAPconfiguration(configuration) {
  return { type: "visual/setIAPconfiguration", configuration }
}

export function setShowCompactAssetview(value) {
  return (dispatch) => {
    dispatch({ type: "visual/setShowCompactAssetview", value })
    dispatch(loading.onScheduleChange())
  }
}

export function setShowWeekendAssetview(value) {
  return (dispatch) => {
    dispatch({ type: "visual/setWeekendAssetView", value })
    dispatch(loading.onScheduleChange())
  }
}

export function setShowCompactTeamPlan(value) {
  return (dispatch) => {
    dispatch({ type: "visual/setShowCompactTeamPlan", value })
    dispatch(loading.onScheduleChange())
  }
}

export function setShowStandardActions(value) {
  return { type: "teamPlan/showStandardActions", value }
}

export function changeCheckZoomFactor() {
  return { type: "visual/changeCheckZoomFactor" }
}

// Used in customer admin
export function showGlobalMessageConfig(value) {
  return { type: "visual/showGlobalMessageConfig", value }
}

// Used in asset view to show current date when clicking 'Today'
export function showCurrentDate(value) {
  return { type: "visual/showCurrentDate", value }
}
