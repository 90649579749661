import React from "react"
import { useDragLayer } from "react-dnd"
import { FiPackage } from "react-icons/fi"
import "./index.scss"

// The custom icon shown beside the cursor when dragging an item.
export default function CustomDragLayer() {
  const { isDragging, mouseOffset, type } = useDragLayer((monitor) => ({
    mouseOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    type: monitor.getItemType(),
  }))
  if (!isDragging || !mouseOffset) {
    return null
  }
  const transform = `translate(calc(-50% + ${mouseOffset.x}px), ${mouseOffset.y + 30}px)`
  return (
    type === "smartDrag" ?
    <span className="CustomDragLayer" style={{ transform, WebkitTransform: transform, fontSize: "1.5rem", fontWeight: "700" }}>IAP</span>
    :
    <FiPackage className="CustomDragLayer" style={{ transform, WebkitTransform: transform }}/>
  )
}
