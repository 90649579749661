import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import "./index.scss"

import { Popup, Loading, ConfirmDialog } from "components"
import { message, integrationSheets } from "state_management"
import Table from "react-bootstrap/Table"
import { IntegrationSheetConfig } from "components/Config"

export default function EditSheets({ isAutomatic = false }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)

  const [activeSheet, setActiveSheet] = useState(null)
  const [delSheet, setDelSheet] = useState(null)

  const [newSheetType, setNewSheetType] = useState("")
  const [newSheetName, setNewSheetName] = useState("")

  const actualNewSheetType = isAutomatic
    ? newSheetType
    : !showProjects
      ? "SampleObservationUploadConfig"
      : newSheetType
        ? newSheetType
        : "ActionUploadConfig";
  const isLoading = useSelector((state) =>
    isAutomatic
      ? state.integrationSheets.isLoadingLimsSheets ||
        state.integrationSheets.isLoadingSapSheets
      : state.integrationSheets.isLoadingActionSheets ||
        state.integrationSheets.isLoadingSampleSheets ||
        state.integrationSheets.isLoadingProjectTemplateSheets
  )

  const actionSheets = useSelector((state) => state.integrationSheets.actionSheets)
  const projectTemplateSheets = useSelector((state) => state.integrationSheets.projectTemplateSheets)
  const sampleSheets = useSelector((state) => state.integrationSheets.sampleSheets)
  const limsSheets = useSelector((state) => state.integrationSheets.limsSheets)
  const sapSheets = useSelector((state) => state.integrationSheets.sapSheets)

  useEffect(() => {
    if (!isAutomatic && (!actionSheets || !sampleSheets || !projectTemplateSheets) && !isLoading) {
      dispatch(integrationSheets.fetchActionSheets())
      dispatch(integrationSheets.fetchSampleSheets())
      dispatch(integrationSheets.fetchProjectTemplateSheets())
    } else if (isAutomatic && !limsSheets && !sapSheets && !isLoading) {
      dispatch(integrationSheets.fetchLimsSheets())
      dispatch(integrationSheets.fetchSapSheets())
    }
  })

  useEffect(() => {
    if (isAutomatic && !showProjects ) {
      setNewSheetType("LimsUploadConfig")
    }
    }, [showProjects, isAutomatic])

  if (!isAutomatic && (!actionSheets || !sampleSheets || !projectTemplateSheets) && !isLoading) {
    return <Loading title="Loading sheets" />
  }

  if (isAutomatic && !limsSheets && !sapSheets && !isLoading) {
    return <Loading title="Loading sheets" />
  }

  if (isLoading) {
    return <Loading title="Loading sheets" />
  }

  const handleSaveConversionSheet = (e) => {
    if (!newSheetName) {
      dispatch(message.error("Please provide a Sheet Name"));
    } else if (
      (() => {
        for (var i = 0; i < sheetsToDisplay.length; i++) {
          if (sheetsToDisplay[i].sheetName === newSheetName) {
            return true;
          }
        }
        return false;
      })()
    ) {
      dispatch(message.error("Sheet with that name already exists"));
    } else if (!actualNewSheetType) {
      dispatch(message.error("Please provide a Sheet Type"));
    } else {
      setActiveSheet({
        __typename: actualNewSheetType,
        sheetName: newSheetName,
      });
    }
  };

  const sheetsToDisplay = isAutomatic ? limsSheets.concat(sapSheets) : actionSheets.concat(sampleSheets).concat(projectTemplateSheets)

  return (
    <>
      {delSheet && (
        <ConfirmDialog
          onCancel={() => setDelSheet(null)}
          onConfirm={() => {
            if (delSheet.__typename === "ActionUploadConfig") {
              dispatch(integrationSheets.removeActionSheet(delSheet.id)).then(() => setDelSheet(null))
            } else if (delSheet.__typename === "ProjectTemplateUploadConfig") {
              dispatch(integrationSheets.removeProjectTemplateSheet(delSheet.id)).then(() => setDelSheet(null))
            } else if (delSheet.__typename === "SampleObservationUploadConfig") {
              dispatch(integrationSheets.removeSampleSheet(delSheet.id)).then(() => setDelSheet(null))
            } else if (delSheet.__typename === "LimsUploadConfig"){
              dispatch(integrationSheets.removeLimsAnalysisSheet(delSheet.id)).then(() => setDelSheet(null))
            } else {
              dispatch(integrationSheets.removeSapSheet(delSheet.id)).then(() => setDelSheet(null))
            }
          }}
        >
          <h4>Confirm deletion</h4>
        </ConfirmDialog>
      )}

      <Table className="EditSheetsTable" striped bordered>
        <thead>
          <tr>
            <th>Sheet Name</th>
            <th>Sheet Type</th>
            <th>Configuration</th>
            {isAutomatic && <th>Conversion Sheet</th>}
          </tr>
        </thead>
        <tbody>
          {sheetsToDisplay?.map((sheet) => (
            <tr key={sheet.sheetName}>
              <td>{sheet.sheetName}</td>
              <td>
                {sheet.__typename === "ActionUploadConfig"
                  ? "Projects & Actions"
                  : sheet.__typename === "ProjectTemplateUploadConfig"
                  ? "Project Template"
                  : sheet.__typename === "LimsUploadConfig"
                  ? "LIMS To Analysis"
                  : sheet.__typename === "SapUploadConfig"
                  ? "SAP to project"
                  : "Sample Observations"
                  }
              </td>
              <td>
                <button onClick={() => setActiveSheet(sheet)}>Edit</button>
                <button
                  onClick={() => setDelSheet(sheet)} //
                  className="EditSheets-delete-button"
                  title="delete"
                  aria-label="Close"
                >
                  <span aria-hidden="true">Delete</span>
                </button>
              </td>
              {isAutomatic && (
                <td>
                  <button variant="warning" onClick={() => navigate(`/setup/integration/conversion-sheet/${sheet.id}`)}>
                    View
                  </button>
                </td>)
              }
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <input value={newSheetName} onChange={(e) =>
                setNewSheetName(e.target.value)} />
            </td>
            <td>
              {isAutomatic ? (
                !showProjects ? (
                <div>
                    Lims to Analysis
                </div>
                ) : (
                    <select onChange={(e) => setNewSheetType(e.target.value)}>
                      <option value="">Select Type </option>
                      <option value="LimsUploadConfig">Lims to Analysis</option>
                      <option value="SapUploadConfig">SAP to Project</option>
                    </select>
                  )
              ) : showProjects && showAnalyses ? (
                  <select onChange={(e) => setNewSheetType(e.target.value)}>
                    <option value="">Select Type </option>
                    <option value="ActionUploadConfig">Projects & Actions</option>
                    <option value="SampleObservationUploadConfig">Sample Observations</option>
                    <option value="ProjectTemplateUploadConfig">Project Template</option>
                  </select>
                ) : showProjects ? (
                    <select onChange={(e) => setNewSheetType(e.target.value)}>
                      <option value="ActionUploadConfig">Projects & Actions</option>
                      <option value="ProjectTemplateUploadConfig">Project Template</option>
                    </select>
                  ) : (
                      "Sample Observations"
                    )}
            </td>
            <td>
              <button
                onClick={(e) => handleSaveConversionSheet(e)}
              >
                Add
              </button>
            </td>
          </tr>
        </tfoot>
      </Table>

      {activeSheet !== null && (
        <Popup doesClickOutsideClose={false} nonScrollable onCancel={() => setActiveSheet(null)}>
          <IntegrationSheetConfig sheet={activeSheet} onClose={() => setActiveSheet(null)} />
        </Popup>
      )}
    </>
  )
}
