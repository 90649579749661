import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"

import { FiInfo } from "react-icons/fi"

import { analyses, message, projectActions } from "state_management"
import { Container, Text } from "components/Form"
import { ConfirmDialog } from "components"

import "./index.scss"

export default function DeleteTestData() {

  const dispatch = useDispatch()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [enteredKeyword, setEnteredKeyword] = useState("")
  
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)

  const title = `Confirming this will delete all  ${(showProjects) ? "project" : showAnalyses ? "analysis" : ""} data. There is no going back, even with Simulation Mode.`
  const deletionKeyword = "DELETE"
  return (
    <div className="DeleteTestData">
    {deleteConfirm && (
      <ConfirmDialog
        onCancel={() => {
          setDeleteConfirm(false)
          setEnteredKeyword("")
        }}
        onConfirm={() => {
          if (deletionKeyword !== enteredKeyword) {
            dispatch(message.warning(`type ${deletionKeyword} before confirming`))
            setEnteredKeyword("")
            return
          }
          if(showAnalyses) {
            dispatch(analyses.deleteEverything())
          }
          if(showProjects) {
            dispatch(projectActions.deleteEverything())
          }
          setEnteredKeyword("")
          setDeleteConfirm(false)
        }}
      >
        <Container>
          <div className="DeleteAllUnplannedSamples-info-text">
            <FiInfo title= {title}/>
          </div>
          <h4>To confirm deletion type {deletionKeyword} and press confirm. This will permanently delete the test data, even with simulation mode. There is no going back.</h4>
        </Container>
        <Container>
          <Text value={enteredKeyword} onChange={(e) => setEnteredKeyword(e)} />
        </Container>
      </ConfirmDialog>
    )}
      <div className="DeleteAllUnplannedSamples-container">
          <Button
          variant="secondary"
          onClick={() => {
            setDeleteConfirm(true)
          }}
        >
          Delete Data
        </Button>
      </div>
      </div>
  )
}