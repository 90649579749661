import { DateTime, Duration } from "luxon"
import ROOT from "./root"
import {
  ConnectionError,
  UnauthorizedError,
  NotAllowedError,
  InvalidParametersError,
  ServerError,
  IAPinUseError,
  AnalysisUpdatedError,
  RequestError,
  DoNotShowError,
} from "./error"

DateTime.prototype.toJSON = function () {
  throw new Error("Automatic conversion of datetimes to JSON is disabled")
}

Duration.prototype.toJSON = function () {
  throw new Error("Automatic conversion of durations to JSON is disabled")
}

export default function request(method, url, data = undefined, isJson = true, login = false) {
  const auth = window.store.getState().auth

  let options = {
    method: method,
    credentials: login ? "include" : "same-origin",
    headers: {},
  }

  if (auth.isLoggedIn) {
    options.headers["Authorization"] = `Bearer ${auth.accessToken}`
  }
  if (data !== undefined) {
    if (isJson) {
      options.body = JSON.stringify(data)
      options.headers["Content-Type"] = "application/json"
    } else {
      options.body = data
    }
  }

  return window
    .fetch(ROOT + url, options)
    .catch((err) => {
      // Give the generic `TypeError` that window.fetch returns a more specific name
      // We don't want to display every timethe server is down to the user
      if (url === "/team-status") throw new DoNotShowError("Do Not Show Error")
      throw new ConnectionError("Failed contacting server. Please check your internet connection!")
    })
    .then((res) => {
      // This is expected to have an empty body
      if (res.status === 204) {
        return [res.status, null]
      }
      // Only decode JSON if the content type says so
      if (res.headers.get("Content-Type") !== "application/json") {
        return [res.status, null]
      }
      // We need to use Promise.all, since res.json is a promise
      // This is done now, so that the error handling further down can use the result
      return Promise.all([res.status, res.json()])
    })
    .catch((err) => {
      if (err instanceof RequestError) {
        // Re-throw already handled errors
        throw err
      }
      // Catch JSON errors
      throw new RequestError("Invalid JSON")
    })
    .then(([status, json]) => {
      // In here, we basically use use the json.message provided by the BE, if available
      // Note: The user only sees the `message` parameter, the error type is hidden!
      const message = json && json.message

      if (status === 401) {
        throw new UnauthorizedError(message || "Unauthorized")
      }
      if (status === 403) {
        throw new NotAllowedError(message || "The action you tried to perform is not allowed")
      }
      if (status === 404) {
        // TODO: Extract relevant data
        throw new RequestError(message || "Not found")
      }
      if (status === 422) {
        console.warn("Validation errors:", json && json.errors)
        // TODO: Use the message from the BE, or extract data from `json.errors``
        const errorMessage =  message !== "Failed validating request." ? message : false
        throw new InvalidParametersError(errorMessage || "Some data was invalid, please retry")
      }
      if (status === 409 || status === 419) {
        throw new IAPinUseError(message || "IAP is in use")
      }
      if (status === 410) {
        throw new AnalysisUpdatedError(message || "Analysis has been updated by another user, please refresh")
      }
      if (status >= 500) {
        // TODO: Attempt to extract data (maybe HTTP status title?)
        throw new ServerError(message || "A server error occurred")
      }
      if (status < 200 || status >= 300) {
        throw new RequestError(message || "An unknown error occurred")
      }

      return json
    })
    .catch((err) => {
      console.error(err)
      throw err
    })
}
