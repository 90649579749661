import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConfirmDialog, Popup, ListItem } from "components"
import Loading from "components/Loading"
import "./index.scss"
import { FiSettings, FiPlus, FiTrash2 } from "react-icons/fi"

import { customerAdministration } from "state_management"
import AdminInputConfig from "./AdminInputConfig"

export default function AdminsConfig({ customer }) {
  const dispatch = useDispatch()
  const [activeAdmin, setActiveAdmin] = useState(null)
  const [editAdmin, setEditAdmin] = useState(null)
  const [addAdmin, setAddAdmin] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [isFirst, setIsFirst] = useState(true)

  const admins = useSelector((state) => state.customerAdministration.admins)
  const isLoading = useSelector((state) => state.customerAdministration.isLoadingAdmins)

  if (isFirst) {
    dispatch(customerAdministration.fetchAdmins(customer.id))
    setIsFirst(false)
  }
  if (isFirst || isLoading) {
    return <Loading />
  }

  return (
    <div className="AdminsConfig-container">
      <div className="AdminName-container">
        <p>Administrators</p>
      </div>
      <div className="AdminsConfig-admin-list">
        {Object.values(admins).map((admin) => (
          <ListItem text={admin.name} key={admin.id}>
            <FiSettings
              className="admin-icons-hovereffect"
              style={{ fontSize: "1.5rem" }}
              onClick={() => setEditAdmin(admin)}
            />
            <FiTrash2
              className="admin-icons-hovereffect"
              style={{ fontSize: "1.5rem" }}
              onClick={() => {
                setConfirm(true)
                setActiveAdmin(admin)
              }}
            />
            {/* <Button
              variant="danger"
              style={{ float: "right" }}
              onClick={() => {
                setConfirm(true)
                setActiveAdmin(admin)
              }}
            >
              Remove
            </Button> */}
          </ListItem>
        ))}
      </div>
      {/* <div style={{ marginTop: "20px" }}>
        <Button onClick={() => setAddAdmin(true)}>Add</Button>
      </div> */}
      <div className="AdminsConfig-add-admin">
        <FiPlus title="Add Admin" onClick={() => setAddAdmin(true)} />
      </div>
      {addAdmin && (
        <Popup className="popup-autoWidth" onCancel={() => setAddAdmin(null)}>
          <AdminInputConfig customerId={customer.id} onClose={() => setAddAdmin(null)} />
        </Popup>
      )}
      {editAdmin && (
        <Popup className="popup-autoWidth" nonScrollable onCancel={() => setEditAdmin(null)}>
          <AdminInputConfig admin={editAdmin} customerId={customer.id} onClose={() => setEditAdmin(null)} />
        </Popup>
      )}
      {confirm && (
        <ConfirmDialog
          onCancel={() => setConfirm(false)}
          onConfirm={() => dispatch(customerAdministration.deleteAdmin(activeAdmin.id)).then(() => setConfirm(false))}
        >
          Are you sure you want to delete this admin?
        </ConfirmDialog>
      )}
    </div>
  )
}
