import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"

import { FiInfo } from "react-icons/fi"

import { message, projects } from "state_management"
import { Container, Text } from "components/Form"
import { ConfirmDialog } from "components"
import "./index.scss"

export default function DeleteUnplannedProjects() {
  const dispatch = useDispatch()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [enteredKeyword, setEnteredKeyword] = useState("")

  const deletionKeyword = "DELETE"

  return (
    <>
      {deleteConfirm && (
        <ConfirmDialog
          onCancel={() => {
            setDeleteConfirm(false)
            setEnteredKeyword("")
          }}
          onConfirm={() => {
            if (deletionKeyword !== enteredKeyword) {
              dispatch(message.warning(`type ${deletionKeyword} before confirming`))
              setEnteredKeyword("")
              return
            }
            dispatch(projects.removeAllUnplanned())
            setEnteredKeyword("")
            setDeleteConfirm(false)
          }}
        >
          <Container>
            <div className="DeleteAllUnplannedProjects-info-text">
              <FiInfo title="Confirming this will delete all projects, which have no actions placed on the plan" />
            </div>
            <h4>To confirm deletion type {deletionKeyword} and press confirm</h4>
          </Container>
          <Container>
            <Text value={enteredKeyword} onChange={(e) => setEnteredKeyword(e)} />
          </Container>
        </ConfirmDialog>
      )}
      <div className="DeleteAllUnplannedProjects-container">
        <Button
          variant="danger"
          onClick={() => {
            setDeleteConfirm(true)
          }}
        >
          Delete Projects
        </Button>
      </div>
    </>
  )
}
