import { useDispatch, useSelector } from "react-redux"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import { teamStatus } from "state_management"

export default function AssetOverbookingButton() {
  const dispatch = useDispatch()
  const assetOverbooking = useSelector((state) => state.teamStatus.assetOverbooked)
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  
  return (
    <InputGroup>
      <Form.Check
        disabled={showForecast}
        checked={showForecast ? true : assetOverbooking}
        type={"checkbox"}
        id={`asset-overbooking-checkbox`}
        onChange={(e) => dispatch(teamStatus.overbooking({ assetOverbooking: e.target.checked }))}
      />
      <div className="Include-today">
        <p>Asset Overbooking</p>
      </div>
    </InputGroup>
  )
}
