import React from "react"
import ReactSelect from "react-select"
import { FiPlay, FiCheck, FiPause } from "react-icons/fi"

const taskStateOptions = [
  { label: <>Not Started</>, value: "NOT_STARTED" },
  {
    label: (
      <>
        Started
        <FiPlay />
      </>
    ),
    value: "STARTED",
  },
  {
    label: (
      <>
        Paused
        <FiPause />
      </>
    ),
    value: "PAUSED",
  },
  {
    label: (
      <>
        Finished
        <FiCheck />
      </>
    ),
    value: "STOPPED",
  },
]

export default function TaskStatePicker({ required, disabled, value, onChange }) {
  const taskStateValue = taskStateOptions.find((item) => item.value === value)
  if (required && !taskStateValue) {
    throw new Error("TaskStatePicker received an invalid value")
  }
  return (
    <ReactSelect
      className="TaskStatePicker"
      classNamePrefix="TaskStatePicker"
      placeholder="Select state..."
      isClearable={false}
      isDisabled={disabled}
      options={taskStateOptions}
      onChange={({ value }) => onChange(value)}
      value={taskStateValue}
      menuPosition="absolute"
    />
  )
}
