/**
 * Custom React Hook that manages a timer to run a callback function after a specified delay.
 * The timer can be started and cleared as needed.
 *
 * This hook is useful for implementing delayed actions, such as debouncing inputs,
 * delaying API calls, or any scenario where a delayed callback execution is required.
 *
 * @param {Function} callback - The function to be called after the delay.
 * @param {number} delay - The delay duration (in milliseconds) before the callback is executed.
 * @returns {[Function, Function]} - Returns an array with two functions: startTimer and clearTimer.
 *  - startTimer: Function to start the timer.
 *  - clearTimer: Function to clear the timer.
 */
import { useState, useEffect, useRef } from "react";

export const useTimer = (callback, delay) => {
  const [timerId, setTimerId] = useState(null);
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timer.
  const startTimer = () => {
    if (timerId) clearTimeout(timerId);
    const id = setTimeout(() => {
      savedCallback.current();
    }, delay);
    setTimerId(id);
  };

  const clearTimer = () => {
    if (timerId) clearTimeout(timerId);
  };

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  return [startTimer, clearTimer];
};
