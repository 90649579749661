import React from "react"
import { useLocation } from "react-router-dom"

import "./index.scss"
import { Title, Container, DayPicker, DurationInput, Select } from "components/Form"
import { DateTime, Duration } from "luxon"

export default function PeopleSetup({
  setuptime,
  setSetuptime,
  isDraft,
  perSampleDuration,
  setPerSampleDuration,
  disabled,
  competenceGroups,
  allowedGroupIds,
  setAllowedGroupIds,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  memberTimezone,
  task,
}) {
  const location = useLocation()
  const isAssetView = location.pathname.includes("/asset-view")
  return (
    <div className="container-compartment">
      <p>People Setup</p>
      {isAssetView && (
        <>
          <Container>
            <Title>From date</Title>
            <DayPicker
              required
              disabled={disabled}
              value={startDate}
              onChange={(event) => {
                if (!event) {
                  setStartDate(undefined)
                  return
                }
                if (event.invalid) {
                  return
                }
                setStartDate(event)
              }}
            />
            <Title>From time (HH:MM)</Title>
            <DurationInput
              disabled={disabled}
              value={startTime}
              onChange={setStartTime}
              required
              isTime
            />
          </Container>
          <Container>
            <Title>To date</Title>
            <DayPicker required disabled value={DateTime.fromISO(task.end).setZone(memberTimezone)} />
            <Title>To time (HH:MM)</Title>
            <DurationInput
              disabled
              value={
                Duration.fromObject({
                  hours: DateTime.fromISO(task.end).setZone(memberTimezone).hour,
                  minutes: DateTime.fromISO(task.end).setZone(memberTimezone).minute,
                })
            }
            />
          </Container>
        </>
      )}
      <Container>
        <Title>
          Setuptime (HH:MM)<div className="red-text">*</div>
        </Title>
        <DurationInput value={setuptime} onChange={setSetuptime} required={!isDraft} />
      </Container>
      <Container>
        <Title>Per sample duration (HH:MM:SS)</Title>
        <DurationInput required={!isDraft} withSeconds value={perSampleDuration} onChange={setPerSampleDuration} />
      </Container>
      <Container>
        <Title>
          Allowed Competence Groups<div className="red-text">*</div>
        </Title>
        <Select
          name="Allowed Competence Groups"
          disabled={disabled}
          required={!isDraft}
          collection={Object.values(competenceGroups).sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
            return 0
          })}
          value={allowedGroupIds}
          onChange={setAllowedGroupIds}
          menuPosition="fixed"
        />
      </Container>
    </div>
  )
}
