import React from "react"
import { useSelector, useDispatch } from "react-redux"
import ScheduleItem from "./ScheduleItem"
import { scheduleHover, projectActions } from "state_management"
import dragTypes from "utils/scripts/dragTypes"

export default function ScheduleAction({ actionId, shouldShowState, style, onClick, view, showIcons }) {
  const dispatch = useDispatch()
  const action = useSelector((state) => state.projectActions.plannedProjectActions[actionId])
  const isActive = useSelector((state) => state.scheduleHover.projectId === action.projectId)
  const project = useSelector((state) => state.projects[action.projectId])
  const constraintChecks = useSelector((state) => state.constraintChecks[actionId]) || []
  const assets = useSelector((state) => state.asset.assets)
  return (
    <ScheduleItem
      item={action}
      backgroundColor={project.color}
      shouldShowState={shouldShowState}
      style={style}
      isActive={isActive}
      onMouseEnter={() => dispatch(scheduleHover.setProjectId(action.projectId))}
      onMouseLeave={() => dispatch(scheduleHover.setProjectId(null))}
      onClick={onClick}
      dragType={dragTypes.ACTION}
      name={project.singleActionProject ? action.name : `${project.name}: ${action.name}`}
      description={action.description}
      state={action.stage}
      constraintChecks={constraintChecks}
      movable={action.movable}
      setMovable={(movable) => dispatch(projectActions.setMovable(action.id, movable))}
      view={view}
      assetName={action.assetIds.length ? assets[action.assetIds[0]].name : ""}
      showIcons={showIcons}
    />
  )
}
