import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

import Button from "react-bootstrap/Button"
import { teamStatus, loading, integrationSheets } from "state_management"
import { ConfirmDialog } from "components"

// Planning mode buttons.
// Shown more generally than the schedule buttons.
export default function PlanningModeButtons() {
  const dispatch = useDispatch()

  const [activeConfirmHandler, setActiveConfirmHandler] = useState(null)
  const [activeConfirm, setActiveConfirm] = useState(false)
  const [activeConfirmTitle, setActiveConfirmTitle] = useState(null)
  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const isAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const isProjects = useSelector((state) => state.teamStatus.showProjects)
  const location = useLocation()

  const otherPlannerInitials = useSelector((state) => {
    const currentPlannerId = state.teamStatus.currentPlannerId
    if (currentPlannerId === "self") {
      return null
    } else if (currentPlannerId) {
      return state.members[currentPlannerId].initials
    } else {
      return null
    }
  })

  return (
    <>
      {activeConfirm && (
        <ConfirmDialog
          onCancel={() => setActiveConfirm(false)}
          onConfirm={() => {
            activeConfirmHandler()
            setActiveConfirm(false)
          }}
        >
          <h4>{activeConfirmTitle}</h4>
        </ConfirmDialog>
      )}

      {isCurrentPlanner && isInPlanningMode && (
        <Button
          disabled={!isCurrentPlanner}
          variant="danger"
          onClick={() => {
            setActiveConfirm(true)
            setActiveConfirmHandler(() => () =>
              dispatch(teamStatus.discardPlanningModeChanges()).then(() => {
                dispatch(loading.fetchAll(location.pathname))
                if (isProjects) {
                  dispatch(integrationSheets.fetchProjectTemplateSheets())
                  dispatch(integrationSheets.fetchActionSheets())
                }
                if (isAnalyses) {
                  dispatch(integrationSheets.fetchSampleSheets())
                }
              })
            )
            setActiveConfirmTitle("Are you sure you want to discard the changes made in simulation mode?")
          }}
          title={isCurrentPlanner ? "Exit simulation mode" : `${otherPlannerInitials} is in simulation mode`}
        >
          EXIT SIMULATION
        </Button>
      )}

      {isCurrentPlanner && isInPlanningMode && (
        <Button
          variant="success"
          onClick={() => {
            setActiveConfirm(true)
            setActiveConfirmHandler(() => () => dispatch(teamStatus.acceptPlanningModeChanges()))
            setActiveConfirmTitle("Are you sure you want to publish the changes made in simulation mode?")
          }}
          title={isCurrentPlanner ? "Publish simulation mode changes" : `${otherPlannerInitials} is in simulation mode`}
        >
          PUBLISH
        </Button>
      )}
    </>
  )
}
