import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  hasFetched: false,
  teamName: "",
  timezone: "",
  location: null
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "teamInfo/fetch/BEGIN":
      return { ...state, hasFetched: true }
    case "teamInfo/fetch":
      return {
        ...state,
        teamName: action.res.team.name,
        timezone: action.res.team.locationTimezone,
        location: action.res.team.locationName
      }
    case "teamInfo/setTimeZone":
      return {
        ...state,
        timezone: action.timeZone
      }  
    default:
      return state
  }
}

export const fetch = asyncAction("teamInfo/fetch", (dispatch) => {
  return request("GET", "/team").then((res) => res)
})

export function setLocationTimeZone(timeZone){
  return { type: "teamInfo/setTimeZone", timeZone }
}
