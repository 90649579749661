import React from "react"
import { useSelector } from "react-redux"
import { UnplannedProjectActionItem } from "."

export default function StandardActions({
  setActiveStandardTaskId,
  }) {
  const allStandardActionTemplates = useSelector((state) => state.standardActions.standardActions)
  const allStandardActions = allStandardActionTemplates.reduce((acc, template) => {
    return acc.concat(template.actions);
  }, []).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      {allStandardActions.map((action, index) => (
        <UnplannedProjectActionItem
          key={index}
          action={action}
          onClick={() => setActiveStandardTaskId(action.id)}
        />
      ))}
    </div>
  )
}