import React, { useRef, useEffect, useState } from "react"
import { useDrop} from "react-dnd"
import { useSelector, useDispatch } from "react-redux"

import dragTypes from "utils/scripts/dragTypes"
import { arraysAreEqual } from "utils/scripts/useMemoizeArray"

import { Popup } from "components"
import { WhiteTaskConfig } from "components/Config"
import { DateTime, Duration } from "luxon"

import {
  scheduleHover,
  message,
  whiteTasks,
  analysisTasks,
  projectActions,
  analyses,
  multipleDrag,
  iap,
  waiting,
  teamStatus,
} from "state_management"
import { Button } from "react-bootstrap"

// A single hour in the schedule.
// If this is clicked, a config for adding a white task is opened.
export default function ScheduleElementHour({ memberId, date, interval, height, task, isMobile}) {
  const member = useSelector((state) => state.members[memberId])
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const dispatch = useDispatch()
  const [activeConfig, setActiveConfig] = useState(false)
  const [greenTaskConfig, setGreenTaskConfig] = useState(false)
  const [mobileChoice, setMobileChoice] = useState(false)
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({bookedAssets: [], freeAssets: []})
  const IAPconfig = useSelector((state) => state.visual.IAPconfig);
  const IAPconfigRef = useRef(IAPconfig);

  useEffect(() => {
    IAPconfigRef.current = IAPconfig;
  }, [IAPconfig]);

  const [{ isOver}, drop] = useDrop( () => ({
    accept: Object.values(dragTypes),
    collect: (mon) => ({
      isOver: !!mon.isOver(),
    }),
    drop: ({ type, item }) => {
      const start = date
      const startTime = { hours: DateTime.fromISO(item.start).hour, minutes: DateTime.fromISO(item.start).minute }
      const startDate = DateTime.fromISO(item.start)
      const endDate = DateTime.fromISO(item.start).plus({ seconds: item.duration })
      const endTime = {
        hours: DateTime.fromISO(item.start).plus({ seconds: item.duration }).hour,
        minutes: DateTime.fromISO(item.start).plus({ seconds: item.duration }).minute,
      }
      const recurrentStart = startDate.set({ hour: startTime.hours, minute: startTime.minutes })
      const recurrentEnd = endDate.set({hour: endTime.hours, minute: endTime.minutes})
      const recurrenceRes = {
        name: item.name,
        start: date,
        oldStart: recurrentStart.toISO(),
        duration: recurrentEnd.diff(recurrentStart).as("seconds"),
        description: item.description,
        memberIds: item.memberIds,
        assetIds: item.assetIds,
        greenTask: false,
        amountOfReoccurrences: item.recurrenceAmountOfReoccurrences,
        frequency: item.recurrenceFrequency,
        interval: item.recurrenceInterval,
      }

      if (type === dragTypes.MULTIPLETASK) {
        dispatch(multipleDrag.dropAllTasks([memberId], start))
      } else if (type === dragTypes.MULTIPLEACTION) {
        dispatch(multipleDrag.dropAllActions([memberId], start))
      } else if (type === dragTypes.STANDARD_ACTION) {
        dispatch(projectActions.planStandardAction(item.id, { start, memberIds: [member.id] }))
      } else if (type === dragTypes.ACTION) {
        dispatch(projectActions.move(item.id, { start, memberIds: [member.id] }))
      } else if (type === dragTypes.SMART_DRAG) {
        const input = {memberId: member.id}

        if (item.__typename === "AnalysisTask") {
          input.analysisId = item.analysisId;
        } else {
          input.unplannedAnalysisId = item.id;
        }

        dispatch(teamStatus.setIAPconfiguration(IAPconfigRef.current.timePeriod.from, IAPconfigRef.current.timePeriod.to)).then(() => {
          dispatch(waiting.changeIapLoading("Running IAP"))
          dispatch(iap.runFillInSingleAnalysis(input)).finally(() =>
            dispatch(waiting.changeIapLoading(""))
          )
        })
      } else if (type === dragTypes.ANALYSIS_TASK || type === dragTypes.WHITE_TASK) {
        let memberIds = item.memberIds.slice()
        if (memberIds.indexOf(member.id) === -1) {
          if (memberIds.length > 1) {
            memberIds.push(member.id)
          } else {
            memberIds = [member.id]
          }
        }
        if (item.__typename === "WhiteTask") {
          if (item["recurrenceId"] && !arraysAreEqual(item.memberIds, memberIds)){
            dispatch(message.warning('You cannot drag and drop a recurrent grey task'))
          } else if (item["recurrenceId"] && !item.fromOutlook) {
            recurrenceRes.whiteTaskId = item.recurrenceId
            dispatch(whiteTasks.editRecurrence(item.recurrenceId, recurrenceRes, item.greenTask))
          } else if(!item.fromOutlook){
            dispatch(whiteTasks.move(item.id, { start, memberIds }))
          } else if (item.fromOutlook){
            dispatch(message.warning('You cannot move imported tasks'))
          } 
        } else {
          dispatch(analysisTasks.move(item.id, { start, memberIds }))
        }
      } else {
        dispatch(analyses.moveIntoPlan(item.id, start, member))
      }
    },
  }), [dragTypes, date])

  const dateInZone = DateTime.fromISO(date).setZone(member.timezone)
  const availableOnDate = {
    1: member.workWeek.monday,
    2: member.workWeek.tuesday,
    3: member.workWeek.wednesday,
    4: member.workWeek.thursday,
    5: member.workWeek.friday,
    6: member.workWeek.saturday,
    7: member.workWeek.sunday,
  }

  const overMidnight = member.workHours.min > member.workHours.max

  // If we hav enormal work hours, we just check if we are outside of the workHour interval
  if (!overMidnight && (!availableOnDate[dateInZone.weekday] || (
      dateInZone.startOf("day").plus({ seconds: member.workHours.min }) > dateInZone ||
      dateInZone.startOf("day").plus({ seconds: member.workHours.max }) <= dateInZone
    ))
  ) {
    return <div className="ScheduleElement-div invalid-area" />
  }

  // If it is an overnight shift, instead we check if it is after min workHour today or before max workHour tomorrow.
  // If this is the case, then we don't return invalid area, else we do
  if (overMidnight && !(
    (availableOnDate[dateInZone.weekday] && dateInZone.startOf("day").plus({ seconds: member.workHours.min }) <= dateInZone) ||
    (availableOnDate[(dateInZone.weekday + 6) % 7] && dateInZone.startOf("day").plus({ seconds: member.workHours.max }) > dateInZone)
  )) {
    return <div className="ScheduleElement-div invalid-area" />
  }

  return (
    <>
      {mobileChoice && (
        <Popup nonScrollable onCancel={() => setMobileChoice(false)}>
          <div className="ScheduleElementHour-mobileChoice">
            <h4>Choose what to add</h4>
            <div className="ScheduleElementHour-mobileChoice-content">
              <Button
                variant="primary"
                onClick={() => {
                  setMobileChoice(false)
                  setActiveConfig(true)
                }}
              >
                Add a GreyTask
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setMobileChoice(false)
                  setGreenTaskConfig(true)
                }}
              >
                {task ? "Edit OFFSITE" : "Add OFFSITE"}
              </Button>
            </div>
            <div className="ScheduleElementHour-mobileChoice-cancel">
              <Button variant="secondary" onClick={() => setMobileChoice(false)}>
                Close
              </Button>
            </div>
          </div>
        </Popup>
      )}
      {activeConfig && (
        <Popup nonScrollable onCancel={() => setActiveConfig(false)}>
          <WhiteTaskConfig
            task={{
              start: DateTime.fromISO(date).setZone(memberTimezone).toISO(),
              duration: Duration.fromObject({ hours: interval }).as("seconds"),
              memberIds: [memberId],
            }}

            onClose={() => {
              setActiveConfig(false)}}
            type="team-plan"
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {greenTaskConfig && (
        <Popup
          className={isMobile ? "Mobile-Green-task-config-popup" : "Green-task-config-popup"}
          onCancel={() => setGreenTaskConfig(false)}
        >
          <WhiteTaskConfig
            task={
              task
                ? task
                : {
                    start: DateTime.fromISO(date).setZone(memberTimezone).toISO(),
                    duration: Duration.fromObject({ hours: 1 }).as("seconds"),
                    memberIds: [memberId],
                    greenTask: true,
                    name: date + memberId,
                  }
            }
            onClose={() => setGreenTaskConfig(false)}
            type="team-plan"
          />
        </Popup>
      )}
      <div
        ref={drop}
        className={"ScheduleElement-div" + (task ? " green-task" : "") + (isOver ? " drag-over" : "")}
        onMouseLeave={() => dispatch(scheduleHover.setMemberHour(null, null))}
        onMouseEnter={() => dispatch(scheduleHover.setMemberHour(DateTime.fromISO(date).setZone(memberTimezone).toFormat("HH:mm"), memberId))}
        onDoubleClick={() => {
          isMobile ? setMobileChoice(true) : setActiveConfig(true)
          dispatch(whiteTasks.getBookedFreeAssets(null, { start: DateTime.fromISO(date).setZone(memberTimezone).toISO() })).then((res) => {
            setBookedAndFreeAssets(res)
          })
        }}
        onClick={(e) => {
          if (e.shiftKey) {
            setGreenTaskConfig(true)
            //Green task config contains input elements and user-select: none; can't be used properly
            document.getSelection().removeAllRanges()
          }
        }}
        title={`${DateTime.fromISO(date).setZone(memberTimezone).toFormat("HH:mm")} to ${DateTime.fromISO(date).setZone(memberTimezone)
          .plus({ hours: interval })
          .toFormat("HH:mm")}`}
      />
    </>
  )
}
