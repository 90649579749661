import React, { useState } from "react"
import { useDispatch } from "react-redux"

// import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text } from "components/Form"
import { message, repositories } from "state_management"

// Location config used by admins.
export default function RepositoryConfig({ customerAdminView = false, repositoryData, onClose }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(repositoryData ? repositoryData.name : "")

  return (
    <ConfigContainer
      title={repositoryData ? "Edit repository" : "Add repository"}
      keyWordForAllowedDeletion="DELETE"
      deleteHandler={(allowDeletion) => {
        if (allowDeletion === "DELETE") {
          if (repositoryData) {
            if (customerAdminView) {
              dispatch(repositories.remove(repositoryData.id, customerAdminView)).then(onClose)
            } else {
              dispatch(repositories.remove(repositoryData.id)).then(onClose)
            }
          }
        } else {
          dispatch(message.info("You did not spell DELETE properly"))
        }
      }}
      submitHandler={() => {
        const res = { name }
        if (repositoryData) {
          dispatch(repositories.edit(repositoryData.id, res, customerAdminView)).then(onClose)
        } else {
          if (customerAdminView) {
            dispatch(repositories.createGlobal(res)).then(onClose)
          } else {
            dispatch(repositories.create(res)).then(onClose)
          }
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Repository Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
    </ConfigContainer>
  )
}
