import React from "react"
import ReactSelect from "react-select"

const locationPickerOptions = [
  { label: <>Onsite</>, value: true },
  {
    label: <>Anywhere</>,
    value: false,
  },
]

export default function LocationPicker({ required, disabled, value, onChange, menuPlacement }) {
  const locationStateValue = locationPickerOptions.find((item) => item.value === value)
  if (required && !locationStateValue) {
    throw new Error("LocationPicker received an invalid value")
  }
  return (
    <ReactSelect
      className="TaskStatePicker"
      classNamePrefix="TaskStatePicker"
      placeholder="Select location..."
      isClearable={false}
      isDisabled={disabled}
      isSearchable={false}
      options={locationPickerOptions}
      onChange={({ value }) => onChange(value)}
      value={locationStateValue}
      menuPosition="absolute"
      menuPlacement={menuPlacement}
    />
  )
}
