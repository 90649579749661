import React, { useState } from "react"
import { useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text } from "components/Form"
import { analysisGroups } from "state_management"

// Configuration for analysis groups.
export default function AnalysisGroupConfig({ analysisGroup, onClose, onSubmit }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(analysisGroup ? analysisGroup.name : "")

  return (
    <ConfigContainer
      title={analysisGroup ? "Edit analysis group" : "Add an analysis group"}
      submitHandler={() => {
        setName(name.trim())
        const res = { name: name.trim() }
        if (analysisGroup) {
          dispatch(analysisGroups.edit(analysisGroup.id, res)).then(onSubmit)
        } else {
          dispatch(analysisGroups.create(res)).then(onSubmit)
        }
      }}
      cancelHandler={onClose}
      deleteHandler={analysisGroup ? () => dispatch(analysisGroups.remove(analysisGroup.id)).then(onClose) : null}
    >
      <Container>
        <Title>Analysis group Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
    </ConfigContainer>
  )
}
