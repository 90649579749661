// Contains the different types of draggable items

const dragTypes = {
  WHITE_TASK: "white_task",
  ANALYSIS_TASK: "analysis_task",
  ANALYSIS: "analysis",
  ACTION: "action",
  MULTIPLETASK: "multipleTask",
  MULTIPLEACTION: "multipleAction,",
  MEMBER: "member",
  ASSET: "asset",
  STANDARD_ACTION: "standardAction",
  SMART_DRAG: "smartDrag",
};

export default dragTypes;