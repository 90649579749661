import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import { Text } from "components/Form";

import "./index.scss";
import { FiPlus } from "react-icons/fi";
import { chooseOptimalColor } from "utils/scripts/schedule";
import { projectGroups as projectGroup } from "state_management";

export default function ProjectTemplateTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.projectTemplates);
  const projectGroups = useSelector((state) => state.projectGroups.groups);
  const [filterString, setFilterString] = useState("");

  const projectGroupsSorted = Object.values(projectGroups).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const filteredTemplates = Object.values(templates).filter((template) => {
    return template.name.toUpperCase().includes(filterString.toUpperCase());
  });

  return (
    <div className="ProjectTemplateTab-main-container">
      <div className="project-tab-filter">
        <InputGroup className="AssetView-filter-input-field">
          <Text placeholder="Filter by Name" value={filterString} onChange={setFilterString} />
        </InputGroup>
      </div>
      {projectGroupsSorted.map((group) => (
        <React.Fragment key={group.id}>
          <div className="ProjectGroupName-container">
            <h3>{group.name}</h3>
          </div>
          <div className="ProjectTemplateTab-templates-container">
            {filteredTemplates.map((template) => {
              return template.projectGroupIds.includes(group.id) && (
                <div
                  key={template.id}
                  className={"ProjectTemplateTab-project-container ProjectTemplateTab-hovereffect" + (template.isDraft ? " ProjectTemplateDraft-withBorder" : "")}
                  style={{
                    backgroundColor: template.color || "#607d8b",
                    color: chooseOptimalColor(template.color || "#607d8b"),
                  }}
                  onClick={() => {
                    dispatch(projectGroup.changeActiveProjectGroupId(template.projectGroupIds[0]));
                    navigate(`/setup/project-templates/${template.id}`);
                  }}
                >
                  <p>{template.name}</p>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ))}
      <div className="ProjectGroupName-container">
        <h3>No Group</h3>
      </div>
      <div className="ProjectTemplateTab-templates-container">
        {filteredTemplates.map((template) => {
          return template.projectGroupIds.length === 0 && (
            <div
              key={template.id}
              className={"ProjectTemplateTab-project-container ProjectTemplateTab-hovereffect" + (template.isDraft ? " ProjectTemplateDraft-withBorder" : "")}
              style={{
                backgroundColor: template.color || "#607d8b",
                color: chooseOptimalColor(template.color || "#607d8b"),
              }}
              onClick={() => {
                dispatch(projectGroup.changeActiveProjectGroupId(null));
                navigate(`/setup/project-templates/${template.id}`);
              }}
            >
              <p>{template.name}</p>
            </div>
          );
        })}
        <div className="ProjectTemplateTab-project-container">
          <FiPlus title="Add project template" onClick={() => navigate(`/setup/project-templates/create`)} />
        </div>
      </div>
    </div>
  );
}
