import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { Text } from "components/Form"

const ItemList = ({ value, onChange, itemName = "Item" }) => {
  const [input, setInput] = useState("")

  const handleAdd = () => {
    if (input.trim()) {
      onChange((prevItems) => [...prevItems, input.trim()])
      setInput("")
    }
  }

  const handleRemove = (index) => {
    const newItems = [...value]
    newItems.splice(index, 1)
    onChange(newItems)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ width: "250px" }}>
          <Text
            type="text"
            placeholder={`Add ${itemName}...`}
            value={input}
            onChange={(value) => setInput(value)}
          ></Text>
        </div>
        <Button onClick={handleAdd}>Add</Button>
      </div>
      {value.map((item, index) => (
        <div key={index} style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "250px" }}>
            <Text type="text" disabled={true} value={item} onChange={(value) => setInput(value)}></Text>
          </div>
          <Button variant="danger" style={{ float: "right" }} onClick={() => handleRemove(index)}>
            Remove
          </Button>
        </div>
      ))}
    </div>
  )
}

export default ItemList