import { request } from "utils/request"
import { asyncAction } from "./common"
import { whiteTasks, analyses, projects } from "."

export const run = asyncAction("iap/run", (dispatch, dateFrom, dateTo, type, strategy, sorting) => {
  return request("POST", `/iap/start/${type}`, {
    fromDate: dateFrom.set({ hour: 0, minute: 0, second: 0 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
    toDate: dateTo.set({ hour: 23, minute: 23, second: 23 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
    strategy,
    sorting,
  }).then(() => {
    const state = window.store.getState()
    const isAnalyses = state.teamStatus.showAnalyses
    const isProjects = state.teamStatus.showProjects
    dispatch(whiteTasks.fetch())
    if (isAnalyses) {
      //dispatch(analysisTasks.fetch())
      dispatch(analyses.fetchUnplanned())
      dispatch(analyses.fetchPlanned())
    }
    if (isProjects) {
      dispatch(projects.fetch())
    }
  })
})

export const runFillInSamples = asyncAction("iap/runFillInSamples", (dispatch, dateFrom, dateTo) => {
  return request("POST", `/iap/start/fill-in-samples`, {
    fromDate: dateFrom.set({ hour: 0, minute: 0, second: 0 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
    toDate: dateTo.set({ hour: 23, minute: 23, second: 23 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
  }).then(() => {
    const state = window.store.getState()
    const isAnalyses = state.teamStatus.showAnalyses
    dispatch(whiteTasks.fetch())
    if (isAnalyses) {
      dispatch(analyses.fetchUnplanned())
      dispatch(analyses.fetchPlanned())
    }
  })
})

export const runFillInSingleAnalysis = asyncAction("analyses/moveSmartIntoPlan", (dispatch, input) => {
  return request("POST", `/iap/start/single-analysis`, { input }).then(async (res) => {
    // Waits for both fetches because they are needed in further computations
    await Promise.all([
      dispatch(analyses.fetchUnplanned()),
      dispatch(analyses.fetchPlanned())
    ]);
    return res
  })
})
