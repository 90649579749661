import React, { useState, } from "react"
import { useSelector } from "react-redux"

import EditSheets from "./EditSheets"
import UploadDropzone from "./UploadDropzone"
import IcalIntegration from "./IcalIntegration"
import UploadButton from "./UploadButton"
import DeleteUnplannedProjects from "./DeleteUnplannedProjects"
import DeleteUnplannedSamples from "./DeleteUnplannedSamples"
import DeleteTestData from "./DeleteTestData"
import { Popup, IntegrationResultMessage } from "components"

import "./index.scss"

export default function IntegrationTab() {
  const [resultMessage, setResultMessage] = useState(null)
  const [filesManual, setFilesManual] = useState([])
  const [filesAutomatic, setFilesAutomatic] = useState([])

  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)

  return (
    <div className="integration-tab">
      {resultMessage && (
        <Popup
          onCancel={() => setResultMessage(null)}
          title={resultMessage.aborted ? "Upload aborted!" : "Upload complete!"}
        >
          <IntegrationResultMessage resultMessage={resultMessage} setResultMessage={setResultMessage} />
        </Popup>
      )}

      <p>iCalendar / Outlook Integration</p>
      <IcalIntegration />

      {showProjects && (
        <>
          <hr />
          <p>Delete all Unplanned Projects</p>
          <DeleteUnplannedProjects />
        </>
      )}

      {showAnalyses && (
        <>
          <hr />
          <p>Delete all Unplanned Samples</p>
          <DeleteUnplannedSamples />
        </>
      )}

      {process.env.REACT_APP_ENV !== "production" && (
        <>
          <hr />
          <p>Delete all {showProjects ? "Project" : showAnalyses ? "Analysis" : ""} Test Data</p>
          <DeleteTestData />
        </>
      )}
      {(showProjects || showAnalyses) && (
        <>
          <hr />
          <p><b>Manual Sheet Configuration</b></p>
          <EditSheets />

          <p>File Upload</p>
          <UploadDropzone files={filesManual} onSubmit={setFilesManual} />
          <UploadButton route="/upload/files" files={filesManual} setFiles={setFilesManual} setResultMessage={setResultMessage} />
        </>
      )}
      <>
        <hr style={{ height: 3, opacity: 1, marginTop: '8rem', marginBottom: '8rem'}} />
        <p><b>Automatic Sheet Configuration</b></p>
        <div className="automatic-sheet-container">
          <EditSheets isAutomatic />
        </div>
        <p>File Upload</p>
        <UploadDropzone files={filesAutomatic} onSubmit={setFilesAutomatic} />
        <UploadButton
          route="/upload/raw-data"
          files={filesAutomatic}
          setFiles={setFilesAutomatic}
          setResultMessage={setResultMessage}
        />
      </>
    </div>
  )
}
