import { useDispatch, useSelector } from "react-redux"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import { teamStatus } from "state_management"

export default function IncludeTodayButton() {
  const dispatch = useDispatch()
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  const includeToday = useSelector((state) => state.teamStatus.includeToday)

  return (
    <InputGroup style={{ marginTop: "20px" }}>
      <Form.Check
        disabled={showForecast}
        checked={showForecast ? true : includeToday}
        type={"checkbox"}
        id={`include-today-checkbox`}
        onChange={(e) => dispatch(teamStatus.includeToday({ iapToday: e.target.checked }))}
      />
      <div className="Include-today">
        <p>Include Today</p>
      </div>
    </InputGroup>
  )
}
