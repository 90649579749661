export function isApple() {
    const userAgent = window.navigator.userAgent;

    const appleDevices = [
        'iPhone', 
        'iPad', 
        'iPod', 
        'Macintosh', 
        'MacIntel', 
        'MacPPC', 
        'Mac68K'
    ];

    for (let device of appleDevices) {
        if (userAgent.includes(device)) {
            return true;
        }
    }
    return false;
}

export function isTablet() {
    const userAgent = window.navigator.userAgent;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth;

    // User-agent-based detections
    if (/iPad|Tab|Tablet/i.test(userAgent)) {
        return true;
    }

    // Screen width-based detections (Assuming tablets have a screen width between 600 and 1024 pixels)
    if (screenWidth >= 600 && screenWidth <= 1024) {
        return true;
    }

    return false;
}
