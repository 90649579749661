import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import InputGroup from "react-bootstrap/InputGroup"

import "./index.scss"
import { Container, Title, Select, DayPicker} from "components/Form"
import { ConfigContainer, Popup } from "components"
import { projectActions } from "state_management"
import Button from "react-bootstrap/Button"

// Filter config for actions in To Do list on teamplan
export default function FilterActionsConfig({ cancelHandler }) {
  // Values
  const competenceGroups = useSelector((state) => state.competenceGroups)

  // filtersettings is saved in the visual state. It is therefore saved in local storage
  const filterSettings = useSelector((state) => state.visual.filterSettings)

  // Checkboxes
  const [shouldApplyCompetenceGroupFilter, setShouldApplyCompetenceGroupFilter] = useState(
    filterSettings.isCompetenceGroupFilter
  )
  const [shouldApplyDeadlineFilter, setShouldApplyDeadlineFilter] = useState(filterSettings.isDeadlineFilter)
  const [scrumViewOrder, setScrumViewOrder] = useState(filterSettings.scrumViewOrder)
  // Values
  const [competenceGroupFilter, setCompetenceGroupFilter] = useState(filterSettings.competenceGroupFilter)
  const [deadlineStart, setDeadlineStart] = useState(
    filterSettings.deadlineStart ? DateTime.fromISO(filterSettings.deadlineStart) : null
  )
  const [deadlineEnd, setDeadlineEnd] = useState(
    filterSettings.deadlineEnd ? DateTime.fromISO(filterSettings.deadlineEnd) : null
  )

  // Expands the popup when true
  const [dayPickerOneShows, setDayPickerOneShows] = useState(false)
  const [dayPickerTwoShows, setDayPickerTwoShows] = useState(false)
  const dispatch = useDispatch()

  const handleCompetenceGroupChange = (items) => {
    setCompetenceGroupFilter(items);
    setShouldApplyCompetenceGroupFilter(items.length === 0 ? false : true);
  };

  return (
    <Popup onCancel={cancelHandler}>
      <ConfigContainer
        title="Filter"
        style={{ height: dayPickerOneShows || dayPickerTwoShows ? "500px" : "auto"}}
        submitHandler={() => {
          dispatch(
            projectActions.filterUnplanned(
              shouldApplyCompetenceGroupFilter,
              shouldApplyDeadlineFilter,
              competenceGroupFilter,
              scrumViewOrder,
              deadlineStart ? deadlineStart.toISO() : null,
              deadlineEnd ? deadlineEnd.toISO() : null,
            )
          )
          cancelHandler()
        }}
        cancelHandler={cancelHandler}
        className={`${dayPickerOneShows || dayPickerTwoShows ? "config-container-filter" : ""}`}
      >
        <Container marginBottom="mb-4">
          <InputGroup>
            <Title>By Deadline</Title>
            <Title>From</Title>
            <DayPicker
              required={shouldApplyDeadlineFilter && !deadlineEnd}
              value={deadlineStart}
              onChange={(event) => {
                if (!event) {
                  setDeadlineStart(undefined)
                  setShouldApplyDeadlineFilter(deadlineEnd ? true : false)
                  return
                }
                if (event.invalid) {
                  return
                }
                setDeadlineStart(event)
                setShouldApplyDeadlineFilter(true)
              }}
              onDaypickerClick={setDayPickerOneShows}
            ></DayPicker>
            <Title>To</Title>
            <DayPicker
              required={shouldApplyDeadlineFilter && !deadlineStart}
              value={deadlineEnd}
              onChange={(event) => {
                if (!event) {
                  setDeadlineEnd(undefined)
                  setShouldApplyDeadlineFilter(deadlineStart ? true : false)
                  return
                }
                if (event.invalid) {
                  return
                }
                setDeadlineEnd(event)
                setShouldApplyDeadlineFilter(true)
              }}
              onDaypickerClick={setDayPickerTwoShows}
            ></DayPicker>
            <Button variant="danger" style={{marginLeft: "auto", borderRadius: "0.25rem", boxShadow: "none"}} onClick={() => {
              setDeadlineStart(undefined);
              setDeadlineEnd(undefined);
              setShouldApplyDeadlineFilter(false);
            }}>
              Clear Dates
            </Button>
          </InputGroup>
        </Container>
        <Container marginBottom="mb-4">
          <InputGroup>
            <Title className="Filter-ByGroup">By Competence Group</Title>
            <Select
              required={shouldApplyCompetenceGroupFilter}
              collection={Object.values(competenceGroups).sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                return 0
              })}
              value={competenceGroupFilter}
              onChange={handleCompetenceGroupChange}
              menuPosition="fixed"
            />
          </InputGroup>
        </Container>
        <Container marginBottom="mb-4">
          <Title className="Filter-ByGroup">Scrum View Ordering</Title>
          <input
            type="checkbox"
            defaultChecked={scrumViewOrder}
            onClick={() => setScrumViewOrder(!scrumViewOrder)}
            style={{ marginLeft: "1.5em" }}
          />
        </Container>    
      </ConfigContainer>
    </Popup>
  )
}
