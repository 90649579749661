import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Container, Title, Text, Select } from "components/Form"
import { competenceGroups, message } from "state_management"

// Configuration setup for competence groups.
export default function CompetenceGroupConfig({ group, onClose, nameCheck }) {
  const dispatch = useDispatch()
  const isMember = useSelector((state) => state.auth.isMember)
  const members = useSelector((state) => state.members)

  const [name, setName] = useState(group ? group.name : "")
  const [memberIds, setMemberIds] = useState(group ? group.memberIds : [])

  return (
    <ConfigContainer
      title={group ? "Edit Competence Group" : "Add Competence Group"}
      submitHandler={() => {
        const msg = nameCheck(name, group ? group.name : "")
        if (msg) {
          dispatch(message.warning(msg))
          return
        }
        const res = { name, memberIds }
        if (group) {
          dispatch(competenceGroups.edit(group.id, res)).then(onClose)
        } else {
          dispatch(competenceGroups.create(res)).then(onClose)
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Group Name</Title>
        <Text required disabled={isMember} value={name} onChange={setName} />
      </Container>
      <Container>
        <Title>Group Members</Title>
        <Select
          name="Group Members"
          required
          attr="initials"
          disabled={isMember}
          collection={Object.values(members).filter((member) => !member.archived)}
          value={memberIds}
          onChange={setMemberIds}
          menuPlacement={"bottom"}
          menuPosition={"fixed"}
        />
      </Container>
    </ConfigContainer>
  )
}
