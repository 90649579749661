import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { loading } from "state_management"
import Button from "react-bootstrap/Button"
import { TbRefresh } from "react-icons/tb"

import "./index.scss"

// The update indicator at the top of the screen.
export default function UpdateIndicatorMobile() {
  const dispatch = useDispatch()
  const hasDatabaseChanged = useSelector((state) => state.teamStatus.hasDatabaseChanged)
  const [redButton, setRedButton] = useState(false)
  const MINUTES = 60 * 10 
  const [lastCheck, setLastCheck] = useState(new Date().getTime() / 1000)
  const [waitForDatabase, setWaitForDatabase] = useState(false)
  const [focusTimer, setFocustimer] = useState(new Date().getTime() / 1000)
  const [tries, setTries] = useState(0)
  const location = useLocation()
  // check if the database has been changed every 10 minutes
  useEffect(() => {
    let interVal = setInterval(() => {
      var currentTime = new Date().getTime() / 1000
      if (currentTime - focusTimer > 8) {
        setWaitForDatabase(true)
      }

      if (currentTime - lastCheck >= MINUTES) {
        if(waitForDatabase){
          setTries(tries + 1)
        } 
        if(waitForDatabase && tries > 8){
          setWaitForDatabase(false)
          setTries(0)
          setLastCheck(currentTime)
        }
        if(!waitForDatabase) {
          setLastCheck(currentTime)
        }
        setRedButton(hasDatabaseChanged)
      }

      setFocustimer(currentTime)
    }, 1000)
    return () => {
      clearInterval(interVal)
    }
  })

  return (
    <Button
      title={redButton ? "Changes has been made, reload for new data" : "No changes"}
      className={"UpdateIndicator-mobile" + (hasDatabaseChanged && redButton ? " has-changed" : "")}
      onClick={() => {
        setRedButton(false)
        dispatch(loading.fetchAll(location.pathname))
      }}
      >
      <TbRefresh/>
    </Button>
  )
}
