import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction, getRange } from "./common"

const initialState = {
  assets: {},
  graphInfo: null,
}

export function reducer(state = initialState, action) {

  switch (action.type) {
    case "assets/fetch":
      return { ...state, assets: createMap(action.res) }
    case "assets/create":
      return { ...state, assets: addToMap(state.assets, action.res) }
    case "assets/edit":
      return { ...state, assets: replaceInMap(state.assets, action.res) }
    case "assets/remove":
      return { ...state, assets: removeFromMap(state.assets, action.res) }
    case "projectActions/removePlanned":
      // updating the state by utilizing the spread operator is essential to let the useSelector know that a variable is mutated.
      // AssetView is interested in knowing if graphInfo is updated. Therefore a spread operator is used in line 27 aswell.
      if (!state.graphInfo) return state
      const idsToRemove = action.res.allowedAssetIds
      const newGraphInfo = { ...state.graphInfo }
      for (var i = 0; i < idsToRemove.length; i++) {
        newGraphInfo[idsToRemove[i]] = {
          ...newGraphInfo[idsToRemove[i]],
          projectActions: newGraphInfo[idsToRemove[i]].projectActions.filter(projectAction => projectAction.id !== action.res.id)
        }
      }
      return { ...state, graphInfo: newGraphInfo }
    case "assets/fetchGraphInfo":
      return { ...state, graphInfo: createMap(action.res) }
    case "assets/editOrder":
      return state
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchGraphInfo = asyncAction("assets/fetchGraphInfo", (dispatch) => {
  const state = window.store.getState()
  const [after, before] = getRange(state.visual.scheduleMondayDate, true, state.visual.scheduleWithWeek)
  return request("GET", `/assets/graph-info?before=${before}&after=${after}`).then((res) => res)
})

export const remove = asyncAction("assets/remove", (dispatch, id) => {
  return request("DELETE", `/asset/${id}`).then(() => ({ id }))
})

export const fetch = asyncAction("assets/fetch", (dispatch) => {
  return request("GET", "/assets").then((res) => res.items)
})

export const create = asyncAction("assets/create", (dispatch, input) => {
  return request("POST", "/assets", { input }).then((res) => res.asset)
})

export const edit = asyncAction("assets/edit", (dispatch, id, patch) => {
  return request("PATCH", `/asset/${id}`, { patch }).then((res) => res.asset)
})

export const editOrder = asyncAction("assets/editOrder", (dispatch, input) => {
  return request("POST", "/assets/order", { input }).then(() => {
    dispatch(fetch())
  })
})
