// Helper to find out if the browser is a mobile device or not.
// NOTE: Use this VERY sparingly, and ONLY for application logic, NEVER for
// selecting between different styles! That's a job for CSS itself!
const userAgent = window.navigator.userAgent;
const isMobileRegex = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|iOS|Mobile|Windows Phone|iemobile|Kindle|Silk/i;
// screenSizeThreshold can be adjusted
const isBelowTabletThreshold = (screenSizeThreshold) => {
  const minDim = Math.min(window.screen.width, window.screen.height);
  return minDim <= screenSizeThreshold;
}
const isOnMobile = isMobileRegex.test(userAgent) && isBelowTabletThreshold(750);

export default isOnMobile;