// Languages for the whisper model

export const languages = [
  { name: "English", id: "en" },
  { name: "Dansk", id: "da" },
  { name: "Français", id: "fr" },
  { name: "日本語", id: "ja" },
  { name: "Deutsch", id: "de" },
  { name: "Svenska", id: "sv" },
  { name: "Norsk", id: "no" },
  { name: "Italiano", id: "it" },
  { name: "Español", id: "es" },
  { name: "Suomi", id: "fi" },
  { name: "العربية", id: "ar" },
  { name: "中文", id: "zh" },
  { name: "Hrvatski", id: "hr" },
  { name: "Nederlands", id: "nl" },
  { name: "Bahasa Indonesia", id: "id" },
  { name: "한국어", id: "ko" },
  { name: "Português", id: "pt" },
  { name: "Српски", id: "sr" },
  { name: "Slovenščina", id: "sl" },
  { name: "русский", id: "ru" },
  { name: "Polski", id: "pl" },
  { name: "Čeština", id: "cs" },
  { name: "Magyar", id: "hu" },
  { name: "Română", id: "ro" },
  { name: "Türkçe", id: "tr" },
];
