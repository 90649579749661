import React, { useState } from "react"

import "./index.scss"

import { FiGrid, FiSquare, FiTrash2, FiPlus, FiMinus } from "react-icons/fi"

import SvgNode from "./SvgNode"
import SvgEdge from "./SvgEdge"
import { IoIosArrowRoundForward } from "react-icons/io"
import { ConfirmDialog } from "components"

// Calculate initial positions for the nodes, based on the edges.
export function cleanUp(nodes, edges) {
  let stacks = new Array(Object.values(nodes).length)
  let visited = {}
  for (let i = 0; i < stacks.length; i++) {
    stacks[i] = []
  }
  // Loop over each starting node
  // Assumes non-cyclic / directed
  Object.keys(nodes)
    .filter((nodeId) => !Object.values(edges).find((edge) => edge.nextId === nodeId))
    .forEach((nodeId) => {
      // traverse
      let stack = [{ nodeId, col: 0 }]
      while (stack.length !== 0) {
        const curr = stack.pop()
        if (visited[curr.nodeId]) {
          continue
        }
        visited[curr.nodeId] = true
        stacks[curr.col].push(curr.nodeId)
        Object.values(edges)
          .filter((edge) => edge.prevId === curr.nodeId)
          .forEach((edge) =>
            stack.push({
              nodeId: edge.nextId,
              col: curr.col + 1,
            })
          )
      }
    })

  // Add remaining nodes to separate stacks (to account for cyclic graphs)
  Object.keys(nodes)
    .filter((nodeId) => !visited[nodeId])
    .forEach((nodeId) => {
      stacks[0].push(nodeId)
    })

  const rtn = {}
  stacks.forEach((nodeIds, i) =>
    nodeIds.forEach((nodeId, j) => {
      rtn[nodeId] = {
        x: i * 1.4 + 1 / 1.5,
        y: j * 0.7 + 0.8,
      }
    })
  )
  return rtn
}

// Immutably delete a node, and any edges it might have had.
// Returns the new nodes and edges.
export function deleteNode(nodes, edges, nodeId) {
  const newNodes = { ...nodes }
  delete newNodes[nodeId]
  const newEdges = { ...edges }
  Object.values(edges).forEach((edge) => {
    if (edge.prevId === nodeId || edge.nextId === nodeId) {
      delete newEdges[edge.id]
    }
  })
  return [newNodes, newEdges]
}

// Get a click position relative to the main SVG element.
// `e` must be from an event handler on the main SVG element
function getPosition(e) {
  const ctm = e.currentTarget.firstElementChild.getScreenCTM()
  return {
    x: (e.clientX - ctm.e) / ctm.a,
    y: (e.clientY - ctm.f) / ctm.d,
  }
}

export function initialNodeCoords(nodes, edges) {
  if (Object.keys(nodes).length && nodes[Object.keys(nodes)[0]].coordinates["x"]) {
    const rtn = {}
    for (var nodeId in nodes) {
      rtn[nodeId] = { x: nodes[nodeId].coordinates.x, y: nodes[nodeId].coordinates.y }
    }
    return rtn
  }
  return cleanUp(nodes, edges)
}

// Initial offset for nodes and edges.
const initialOffset = { x: 50, y: 70 }

// The graph design tool.
// This is quite complex, since it's the parent that owns most of the state.
export default function GraphDesignTool({
  required = false,
  requireUniqueNames = false,
  nodes,
  edges,
  onClear,
  nodeColor,
  onCreateNode,
  onEditNode,
  onDeleteNode,
  onCreateEdge,
  onEditEdge,
  configuredNodes,
  onModeChange = false,
  onChangeInNodeCoords,
  mode,
  onSiteClick,
  isProject,
  isDraft,
}) {
  const [dragging, setDragging] = useState(false)
  const [activeNodeId, setActiveNodeId] = useState(null)
  //Only adapted for projects and not analyses
  //const [mode, setMode] = useState(mode)

  const [activeEdgeCoords, setActiveEdgeCoords] = useState({})
  const [activeConfirm, setActiveConfirm] = useState(false)
  // const [activeConnectionConfirm, setActiveConnectionConfirm] = useState(false)
  const [w, setW] = useState(100)
  const [nextNodeId, setNextNodeId] = useState(1) // used for ids
  const [offset, setOffset] = useState(initialOffset)
  // when one changes the offset it is done relative to this point
  const [offsetStartCoords, setOffsetStartCoords] = useState(null)
  const [originalOffset, setOriginalOffset] = useState(null)

  const [nodeCoords, setNodeCoords] = useState(() => initialNodeCoords(nodes, edges, onChangeInNodeCoords))

  // Ensure nodes and nodeCoords are kept in sync
  if (!Object.keys(nodes).reduce((acc, id) => acc && nodeCoords[id], true)) {
    setOffset(initialOffset)
    setNodeCoords(initialNodeCoords(nodes, edges))
    return null
  }

  // Duplicate the given node, giving it a new ID, and attempting to create a
  // relevant name.
  function duplicateNode(node, newId) {
    // \u200B is unicode for no-width space. This avoid accidentally thinking a task name ending on "- number" is a copy
    //const alreadyDuplicatedNode = node.name.match(/^(.*) - (\d+)\u200B$/)
    if (node.name === null) {
      return {
        ...node,
        name: null,
        id: newId,
        isOld: false,
      }
    }
    // Some values used in the while loop
    var nodeList = Object.values(nodes)
    var latestAddition = node.name
    var filteredNodes = nodeList.filter((x) => x.name === latestAddition)
    var numerOfAdditions = 1
    // filterNodes is declared out here due to es-lint warnings
    const filterNodes = () => {
      return nodeList.filter((x) => x.name === latestAddition)
    }
    // filter through our existing nodes until no match
    while (filteredNodes.length) {
      latestAddition = `${node.name} - ${numerOfAdditions}\u200b`
      numerOfAdditions += 1
      filteredNodes = filterNodes()
    }
    return {
      ...node,
      name: latestAddition, //`${originalName} - ${copyIndexes[originalName]}\u200b`,
      id: newId,
      isOld: false,
    }
  }

  return (
    <div className={"GraphDesignTool" + (dragging ? " GraphDesignTool-dragging" : "")}>
      {activeConfirm && (
        <ConfirmDialog
          onCancel={() => setActiveConfirm(false)}
          onConfirm={() => {
            onClear()
            setOffset(initialOffset)
            setNodeCoords({})
            onChangeInNodeCoords({})
            setActiveConfirm(false)
          }}
        >
          <h4>Are you sure you want to reset?</h4>
        </ConfirmDialog>
      )}
      {/* {activeConnectionConfirm && (
        <ConfirmDialog
          onCancel={() => {
            setActiveConnectionConfirm(false)
          }}
          onConfirm={() => {
            onModeChange()
            setActiveConnectionConfirm(false)
          }}
        >
          <h4>Are you sure you want to go into "with connection" mode?</h4>
          <p>(only for advanced users)</p>
        </ConfirmDialog>
      )} */}
      <div
        className="GraphDesignTool-main-container"
        style={{
          cursor: offsetStartCoords ? "grabbing" : "initial",
        }}
        onMouseMove={(e) => {
          if (mode === "node" && dragging) {
            const pos = getPosition(e)
            const coords = {
              x: (pos.x - offset.x) / w,
              y: (pos.y - offset.y) / w,
            }
            setNodeCoords({ ...nodeCoords, [activeNodeId]: coords })
          } else if (mode === "edge" && dragging) {
            const coords = getPosition(e)
            setActiveEdgeCoords({
              x1: activeEdgeCoords.x1,
              y1: activeEdgeCoords.y1,
              x2: coords.x,
              y2: coords.y,
            })
          } else if (offsetStartCoords) {
            const pos = getPosition(e)
            const vec = {
              x: originalOffset.x + pos.x - offsetStartCoords.x,
              y: originalOffset.y + pos.y - offsetStartCoords.y,
            }
            setOffset({
              x: originalOffset.x + vec.x,
              y: originalOffset.y + vec.y,
            })
          }
        }}
        onMouseUp={(e) => {
          if (dragging) {
            setDragging(false)
            setActiveNodeId(null)
            setActiveEdgeCoords({})
          }
          if (mode === "node" && !dragging && offsetStartCoords) {
            const relativePos = getPosition(e)
            const actualPos = {
              x: offset.x + relativePos.x,
              y: offset.y + relativePos.y,
            }
            const squaredDistance =
              Math.pow(actualPos.x - offsetStartCoords.x, 2) + Math.pow(actualPos.y - offsetStartCoords.y, 2)
            // If the cursor hasn't really moved
            if (squaredDistance < 2) {
              const id = btoa(nextNodeId + "frontend")
              setNextNodeId(nextNodeId + 1)
              if (isProject) {
                onCreateNode({ id, name: "", onSite: false })
              } else {
                onCreateNode({ id, name: "" })
              }

              const pos = getPosition(e)
              const vec = {
                x: pos.x - offset.x,
                y: pos.y - offset.y,
              }
              setNodeCoords({ ...nodeCoords, [id]: { x: vec.x / w, y: vec.y / w } })
              onChangeInNodeCoords({ ...nodeCoords, [id]: { x: vec.x / w, y: vec.y / w } })
            }
          }
          setOffsetStartCoords(null)
        }}
        onMouseDown={(e) => {
          const pos = getPosition(e)
          setOffsetStartCoords({
            x: offset.x + pos.x,
            y: offset.y + pos.y,
          })
          setOriginalOffset({ ...offset })
        }}
        onMouseLeave={() => {
          setOffsetStartCoords(null)
          setOriginalOffset(null)
          setDragging(false)
          setActiveEdgeCoords({})
          setActiveNodeId(null)
        }}
      >
        <svg>
          {Object.values(edges).map((edge) => (
            <SvgEdge
              key={edge.id}
              offset={offset}
              onClick={() => onEditEdge(edge)}
              coords1={nodeCoords[edge.prevId]}
              coords2={nodeCoords[edge.nextId]}
              w={w}
            />
          ))}
          <line
            x1={activeEdgeCoords.x1}
            x2={activeEdgeCoords.x2}
            y1={activeEdgeCoords.y1}
            y2={activeEdgeCoords.y2}
            style={{
              stroke: "black",
              strokeWidth: "2",
            }}
          />
        </svg>

        {Object.values(nodes).map((node) => (
          <SvgNode
            key={node.id}
            offset={offset}
            withBorder={configuredNodes.indexOf(node.id) === -1}
            isActive={dragging && activeNodeId === node.id}
            w={w}
            name={node.name || "unnamed"}
            coords={nodeCoords[node.id]}
            onMouseDown={(e) => {
              e.stopPropagation()
              if (mode === "node" && !dragging) {
                setDragging(true)
                setActiveNodeId(node.id)
              } else if (mode === "edge" && !dragging) {
                const coords = {
                  x: nodeCoords[node.id].x * w + offset.x,
                  y: nodeCoords[node.id].y * w + offset.y,
                }
                setDragging(true)
                setActiveNodeId(node.id)
                setActiveEdgeCoords({
                  x1: coords.x,
                  y1: coords.y,
                  x2: coords.x,
                  y2: coords.y,
                })
              }
            }}
            onMouseUp={(e) => {
              onChangeInNodeCoords(nodeCoords)
              e.stopPropagation()
              if (!dragging) {
                return
              }
              if (mode === "edge") {
                if (
                  !Object.values(edges).find((edge) => edge.prevId === node.id && edge.nextId === activeNodeId) &&
                  !Object.values(edges).find((edge) => edge.nextId === node.id && edge.prevId === activeNodeId) &&
                  node.id !== activeNodeId
                ) {
                  onCreateEdge(activeNodeId, node.id)
                }
              }
              setDragging(false)
              setActiveNodeId(null)
              setActiveEdgeCoords({})
            }}
            onConfigure={() => onEditNode(node)}
            onDuplicate={() => {
              const newNode = duplicateNode(node, btoa(nextNodeId + "frontend"))
              setNextNodeId(nextNodeId + 1)
              if (isProject) {
                onCreateNode({ ...newNode, movable: true })
              } else {
                onCreateNode(newNode)
              }

              const newCoords = { x: nodeCoords[node.id].x + 0.3, y: nodeCoords[node.id].y + 0.15 }
              setNodeCoords({ ...nodeCoords, [newNode.id]: newCoords })
              onChangeInNodeCoords({ ...nodeCoords, [newNode.id]: newCoords })
            }}
            onDelete={() => {
              onDeleteNode(node.id)
              const newNodeCoords = { ...nodeCoords }
              delete nodeCoords[node.id]
              setNodeCoords(newNodeCoords)
              onChangeInNodeCoords(newNodeCoords)
            }}
            fill={nodeColor}
            onlyOnSite={node.onSite}
            isAction={isProject}
            onSiteClick={() => onSiteClick(node)}
            nodeColor={nodeColor}
          />
        ))}
      </div>

      <div className="GraphDesignTool-buttons">
        <div className="btn-light" title="Zoom in" onClick={() => setW(Math.min(w + 10, 400))}>
          <FiPlus />
        </div>

        <div className="btn-light" title="Zoom out" onClick={() => setW(Math.min(w - 10, 400))}>
          <FiMinus />
        </div>
        
        <div className={`btn-light ${mode === 'edge' ? 'active' : ''}`} title="Connections" onClick={() => onModeChange()}>
          <IoIosArrowRoundForward />
        </div>

        <div className={`btn-light ${mode !== 'edge' ? 'active' : ''}`} title={isProject ? "Actions" : "Tasks"} onClick={() => onModeChange()}>
          <FiSquare />
        </div>

        <div
          className="btn-light"
          title="Clean up"
          onClick={() => {
            setOffset(initialOffset)
            const tmpCleanUp = cleanUp(nodes, edges)
            setNodeCoords(tmpCleanUp)
            onChangeInNodeCoords(tmpCleanUp)
          }}
        >
          <FiGrid />
        </div>

        <div className="btn-light" title="Remove everything" onClick={() => setActiveConfirm(true)}>
          <FiTrash2 />
        </div>
      </div>
    </div>
  )
}
