import React, { useMemo } from "react"
import "./index.scss"
import { dynamicSortMultiple } from "utils/scripts/sort"
import { DateTime } from "luxon"
import Table from "react-bootstrap/Table"
import { useSelector } from "react-redux"

// A table displaying samples for printing format.
export default function SampleTableForPrinting({ popupSamples }) {
  // Sorting after three parameters. Depends on 'priorityMode'.
  const priorityMode = useSelector((state) => state.teamStatus.sortingParameter)
  const sortedPopupSamples = useMemo(
    () =>
      [...popupSamples].sort(
        dynamicSortMultiple(
          priorityMode === "DEADLINE" ? "deadline" : "priority",
          priorityMode === "DEADLINE" ? "priority" : "deadline",
          "sampleName"
        )
      ),
    [popupSamples, priorityMode]
  )
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  return (
    <Table bordered hover className="SampleTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Product Name</th>
          <th>Priority</th>
          <th>Arrival</th>
          <th>Deadline</th>
          <th>Observation</th>
        </tr>
      </thead>
      <tbody>
        {sortedPopupSamples.map((sample) => (
          <tr
            key={sample.id}
            style={{
              fontStyle: sample.status === "FAILED" ? "italic" : "normal",
              fontWeight: sample.priority === 1 ? "bold" : "normal",
            }}
          >
            <td>{sample.sampleName}</td>
            <td>{sample.productName ? sample.productName : "None"}</td>
            <td>{sample.priority}</td>
            <td className="sampletable-time-column">
              {DateTime.fromISO(sample.arrival).setZone(memberTimezone).toFormat("ff")}
            </td>
            <td className="sampletable-time-column">
              {DateTime.fromISO(sample.deadline).setZone(memberTimezone).toFormat("ff")}
            </td>
            <td>{sample.observationId}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
