import { request } from "utils/request"
import { addToList, replaceInList, removeFromList, asyncAction } from "./common"

// 'globalRepositories' are controlled by customer-admin.
// 'repositories' are per-location of a customer
// 'assignableRepositories' is used in /locations/:locationsId/teams and is a combination of 'repositories' and 'globalRepositories' that are assignable.
// Some globalRepositories are not assignable because they are permanently assigned to a location.
const initialState = {
  isLoadingGlobalRepositories: false,
  globalRepositories: [],
  hasFetched: false,
  repositories: [],
  isLoadingAssignableRepositories: false,
  assignableRepositories: [],
}

export function reducer(state = initialState, action) {
  const temp = { ...state }
  let isGlobal
  switch (action.type) {
    case "repositories/fetchGlobal/BEGIN":
      return { ...state, isLoadingGlobalRepositories: true }
    case "repositories/fetchGlobal":
      return { ...state, globalRepositories: action.res, isLoadingGlobalRepositories: false }
    case "repositories/fetch/BEGIN":
      return { ...state, hasFetched: true }
    case "repositories/fetch":
      return { ...state, repositories: action.res }
    case "repositories/fetchAssignable/BEGIN":
      return { ...state, isLoadingAssignableRepositories: true }
    case "repositories/fetchAssignable":
      return { ...state, assignableRepositories: action.res, isLoadingAssignableRepositories: false }
    case "repositories/create":
      return {
        ...state,
        repositories: addToList(state.repositories, action.res.repository),
        assignableRepositories: addToList(state.assignableRepositories, action.res.repository),
      }
    case "repositories/createGlobal":
      return { ...state, globalRepositories: addToList(state.globalRepositories, action.res.repository) }
    case "repositories/edit":
      isGlobal = action.args[2]

      if (isGlobal) {
        return {
          ...state,
          globalRepositories: state.globalRepositories.map((item) => (item.id === action.args[0] ? {
            ...item,
            name: action.res.repository.name
          } : item))
        }
      } else {
        return {
          ...state,
          repositories: state.repositories.map((item) => (item.id === action.args[0] ? {
            ...item,
            name: action.res.repository.name
          } : item))
        }
      }
    case "repositories/remove":
      isGlobal = action.args[1]
      temp.assignableRepositories = removeFromList(state.assignableRepositories, action.res)
      if (isGlobal) {
        temp.globalRepositories = removeFromList(state.globalRepositories, action.res)
      } else {
        temp.repositories = removeFromList(state.repositories, action.res)
      }
      return temp
    case "repositories/fetchRepository":
      return {
        ...state,
        repositories: replaceInList(state.repositories, action.res.repository),
        globalRepositories: replaceInList(state.globalRepositories, action.res.repository),
      }

    case "repositories/startProcessing":
      const repositoryId = action.args[0]
      isGlobal = action.args[1]
      const targetedRepository = isGlobal ? "globalRepositories" : "repositories"
      temp[targetedRepository] = state[targetedRepository].map((repository) => {
        if (repository.id === repositoryId) {
          return {
            ...repository,
            isProcessing: true,
          }
        }
        return repository
      })
      return temp
    default:
      return state
  }
}

export const fetchGlobal = asyncAction("repositories/fetchGlobal", (dispatch) => {
  return request("GET", `/librarian/repository/global-repositories`).then((res) => res.items)
})

export const createGlobal = asyncAction("repositories/createGlobal", (dispatch, input) => {
  return request("POST", `/librarian/repository/create-global`, { input }).then((res) => res)
})

export const fetch = asyncAction("repositories/fetch", (dispatch) => {
  return request("GET", `/librarian/repository/admin-repositories`).then((res) => res.items)
})

export const create = asyncAction("repositories/create", (dispatch, input) => {
  return request("POST", `/librarian/repository/create`, { input }).then((res) => res)
})

// edit and remove are used for both regular and global repositories
export const edit = asyncAction("repositories/edit", (dispatch, id, patch, isGlobal = false) => {
  return request("PATCH", `/librarian/repository/update/${id}`, { patch }).then((res) => res)
})

export const remove = asyncAction("repositories/remove", (dispatch, id, isGlobal = false) => {
  return request("DELETE", `/librarian/repository/delete/${id}`).then(() => ({ id }))
})

export const fetchAssignable = asyncAction("repositories/fetchAssignable", (dispatch) => {
  return request("GET", `/librarian/repository/assignable-repositories`).then((res) => res.items)
})

export const startProcessing = asyncAction("repositories/startProcessing", (dispatch, id, isGlobal = false) => {
  return request("POST", `/librarian/extract/all-documents/${id}`).then(() => id)
})

export const fetchRepository = asyncAction("repositories/fetchRepository", (dispatch, id) => {
  return request("GET", `/librarian/repository/get-repository/${id}`).then((res) => res)
})

// export const fetchUploadUrl = asyncAction("repositories/getUploadUrl", (dispatch, id, input) => {
//   return request("POST", `/librarian/bucket/get-upload-url/${id}`, { input }).then((res) => res)
// })
