import React from "react"
import Spinner from "react-bootstrap/Spinner"
import "./index.scss"

// A component indicating that something is loading.
// This is pretty dynamically styled, and will work in most containers.
export default function Loading({ title = "Loading" }) {
  return (
    <div className="Loading">
      <Spinner animation="border" variant="primary" />
      <p>{title}</p>
    </div>
  )
}
