import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction, getRange } from "./common"
import { constraintChecks } from "."
import { DateTime } from "luxon"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "whiteTasks/edit":
    case "whiteTasks/move":
      return replaceInMap(state, action.res)
    case "whiteTasks/create":
      return addToMap(state, action.res)
    case "whiteTasks/fetch":
      return createMap(action.res)
    case "whiteTasks/remove":
      return removeFromMap(state, action.res)
    case "whiteTasks/createRecurrence":
      return state
    case "whiteTasks/editRecurrence":
      return state
    case "whiteTasks/removeRecurrence":
      return state
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const remove = asyncAction("whiteTasks/remove", (dispatch, id, greenTask) => {
  return request("DELETE", `/task/white/${id}`).then(() => {
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return { id }
  })
})

export const create = asyncAction("whiteTasks/create", (dispatch, input, greenTask) => {
  return request("POST", "/tasks/white", { input }).then((res) => {
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return res.task
  })
})

// The values: beforeArgument and afterArgument needs to in sync with the same values
// in project.fetch.
// See project.fetch for a more thorough explanation.
export const fetch = asyncAction("whiteTasks/fetch", (dispatch, pastFuture) => {
  const state = window.store.getState()
  let [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )
  let beforeArgument = DateTime.fromISO(before).plus({ months: 1, weeks: 1 }).toUTC().toISO()
  let afterArgument = DateTime.fromISO(after).minus({ months: 1 }).toUTC().toISO()
  before = pastFuture === "past" ? before : DateTime.fromISO(before).plus({ months: 1, weeks: 1 }).toUTC().toISO()
  after = pastFuture === "future" ? after : DateTime.fromISO(after).minus({ months: 1 }).toUTC().toISO()
  dispatch({ type: "whiteTasks/setFetchDate", after: afterArgument, before: beforeArgument })
  return request("GET", `/tasks/white?before=${before}&after=${after}`).then((res) => {
    let whiteTasks = []
    const end = DateTime.fromISO(beforeArgument)
    const start = DateTime.fromISO(afterArgument)
    // Moved 1 month back
    if (pastFuture === "past") {
      whiteTasks = Object.values(state.whiteTasks).filter((task) => {
        return DateTime.fromISO(task.end) < end && DateTime.fromISO(task.start) > DateTime.fromISO(before)
      })
    }
    // Moved 1 month forward
    if (pastFuture === "future") {
      whiteTasks = Object.values(state.whiteTasks).filter((task) => {
        return DateTime.fromISO(task.start) > start && DateTime.fromISO(task.end) < DateTime.fromISO(after)
      })
    }
    return [...res.items, ...whiteTasks]
  })
})

export const edit = asyncAction("whiteTasks/edit", (dispatch, id, patch, greenTask) => {
  return request("PATCH", `/task/white/${id}`, { patch }).then((res) => {
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return res.task
  })
})

export const move = asyncAction("whiteTasks/move", (dispatch, id, patch) => {
  return request("PATCH", `/task/white/${id}`, { patch }).then((res) => {
    constraintChecks.allConstraintChecks(dispatch)
    return res.task
  })
})

//For recurrence
export const createRecurrence = asyncAction("whiteTasks/createRecurrence", (dispatch, input, greenTask) => {
  return request("POST", "/task/recurrence", { input }).then((res) => {
    dispatch(fetch())
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return res
  })
})

export const editRecurrence = asyncAction("whiteTasks/editRecurrence", (dispatch, id, patch, greenTask) => {
  return request("PATCH", `/task/recurrence/${id}`, { patch }).then((res) => {
    dispatch(fetch())
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return res
  })
})

export const removeRecurrence = asyncAction("whiteTasks/removeRecurrence", (dispatch, id, patch, greenTask) => {
  return request("PATCH", `/task/recurrence/${id}`, { patch }).then((res) => {
    dispatch(fetch())
    if (!greenTask) constraintChecks.allConstraintChecks(dispatch)
    return res
  })
})

export const getBookedFreeAssets = asyncAction("projectActions/getBookedFreeAssets", (dispatch, id, patch) => {
  return request("PATCH", `/task/white/asset-information`, {
    patch: {
      whiteTaskId: id,
      start: patch.start,
      duration: patch.duration || 3600,
    }
  }).then((res) => {
    return res;
  });
})
