import React, { useState } from "react"
import "./index.scss"
import Task from "./Task"
import { Droppable } from "react-beautiful-dnd"
import Button from "react-bootstrap/Button"
import { FiPlus, FiPlusCircle, FiTrash2 } from "react-icons/fi"
import { useDispatch,useSelector } from "react-redux"
import { scrumView } from "state_management"
import { Popup } from "components"
import ProjectConfigurationPage from "components/Config/ProjectConfigurationPage"

const Container = ({ children }) => {
  return <div className="ScrumView-Column-Container">{children}</div>
}

const TaskList = ({ children, provided, snapshot }) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.droppableProps}
      className={`ScrumView-Column-TaskList ${snapshot.isDraggingOver}`}
    >
      {children}
    </div>
  )
}

// Columns in scrum view
export default function Column({ column, tasks, projects, onClick, dropId }) {
  const columnsIds = useSelector((state) => state.scrumView.columnsIds)
  const dispatch = useDispatch()
  const deleteColumn = () => {
    columnsIds.forEach((colId) => {
      if(colId.name === column.title){
        dispatch(scrumView.removeColumn(colId.id, column.dropId, column.id))
      }
    })
  }
  const [editProject, setEditProject] = useState(false);

  tasks = tasks.filter(task => task.isStandardAction === false)

  return (
    <>
    {editProject && (
      <Popup
        className="Edit-Create-Project"
        onCancel={() => {
          setEditProject(false)
        }}
      >
        <ProjectConfigurationPage
          isCreateOrEditProject={true}
          setEditProject={() => {
            setEditProject()
          }}
        />
      </Popup>
    )}
    <Container>
      <div className="ScrumView-Column-Title">
        <div>
          <h4>{column.title}</h4>
        </div>
        {column.id === "TODO" && column.title === "To Do" && (
          <div>
            <Button
              className="ScrumView-Column-Title-Element"
              title="Create new project"
              variant="primary"
              onClick={() => setEditProject(true)}
            >
              <FiPlus color="white" className="addProjectButton" />
            </Button>
            <Button
              className="ScrumView-Column-Title-Element"
              title="Create Single Action"
              variant="primary"
              onClick={() => onClick({ isSingleTask: true })}
            >
              <FiPlusCircle color="white" className="addProjectButton" />
            </Button>
          </div>
        )}{(column.title !== "To Do" && column.title !== "Backlog" && column.title !== 'Finished' && column.title !== 'Planned') && (
          <div>
          <Button
              className="ScrumView-Column-Title-Element"
              title="Delete Column"
              variant="primary"
              onClick={() => deleteColumn()}
            >
              <FiTrash2 color="white" className="addProjectButton" />
            </Button>
          </div>
        )}
      </div>
      <Droppable droppableId={column.dropId}>
        {(provided, snapshot) => (
          <TaskList provided={provided} snapshot={snapshot}>
            {tasks.map((task, idx) => (
              <Task task={task} index={idx} key={idx} project={projects[task.projectId]} onClick={onClick} />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
    </>
  )
}
