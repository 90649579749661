import { request } from "utils/request"
import { asyncAction } from "./common"


const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "logging/fetch":
      return action.res
    default:
      return state
  }
}

export const fetchLog = asyncAction("logging/fetch", (dispatch) => {
  return request("GET", `/logging/task-change-logs`).then((res) => {
    return res.items
  })
})