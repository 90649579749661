import React, { useState } from "react"
import { useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text } from "components/Form"
import { assets } from "state_management"

// Configuration for assets.
export default function AssetConfig({ asset, onClose }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(asset ? asset.name : "")

  return (
    <ConfigContainer
      title={asset ? "Edit Asset" : "Add an Asset"}
      submitHandler={() => {
        setName(name.trim())
        const res = { name: name.trim() }
        if (asset) {
          dispatch(assets.edit(asset.id, res)).then(onClose)
        } else {
          dispatch(assets.create(res)).then(onClose)
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Asset Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
    </ConfigContainer>
  )
}
