import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  isLoading: false,
  isFetching: false,
  conversation: [],
  status: "initial",
  availableRepositories: [],
  selectedRepository: {
    name: "",
    id: ""
  },
  template: ""
}

const newChatState = (state) => {
  return {...initialState, availableRepositories: state.availableRepositories}
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "librarian/fetchRepositories/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchRepositories":
      return { ...state, isLoading: false, availableRepositories: action.res }
    case "librarian/getAnswer/BEGIN":
      return { ...state, isFetching: true, status: "begun" }
    case "librarian/getAnswer/ERROR":
      return { ...state, isFetching: false, status: "failed" }
    case "librarian/getAnswer":
      return {
        ...state,
        conversation: action.res.conversation,
        isFetching: false,
        status: "finished",
      }
    case "librarian/getSpeechToTextQuestion":
      return {
        ...state,
        isFetching: false,
        status: "finished",
      };
    case "librarian/addToConversation":
      return { ...state, conversation: [...state.conversation, action.message] }
    case "librarian/setRepository":
      return { ...state, selectedRepository: action.selectedRepository }
    case "librarian/setTemplate":
      return { ...state, template: action.template }
    case "librarian/refreshChat":
      return newChatState(state)
    case "librarian/updateHelpRepository/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/updateHelpRepository":
      return { ...state, isLoading: false, ...action.res }
    case "librarian/updateHelpRepository/ERROR":
      return { ...state, isLoading: false, error: action.error }
    case "auth/login":
    case "auth/verifyMfaToken":
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchRepositories = asyncAction("librarian/fetchRepositories", (dispatch) => {
  return request("GET", `/librarian/repository/user-repositories`).then((res) => {
    return res.items
  })
})

export function addToConversation(message) {
  return (dispatch) => {
    dispatch({ type: "librarian/addToConversation", message })
  }
}

export const getAnswer = asyncAction("librarian/getAnswer", (dispatch, conversation, repositoryId) => {
  return request("POST", `/librarian/chat/${repositoryId}`, { input: { conversation: conversation } }).then((res) => {
    return { ...res, conversation: [...conversation, res.answer] }
  })
})

export const getSpeechToTextQuestion = asyncAction("librarian/getSpeechToTextQuestion", (dispatch, audioFile) => {
  const formData = new FormData();
  formData.append('audioFile.wav', audioFile);
    return request("POST", `/librarian/speech-to-text`, formData, false).then((res) => {
      return { ...res}
    })
});

export const setRepository = (selectedRepository) => {
  return (dispatch) => {
    dispatch({ type: "librarian/setRepository", selectedRepository })
  }
}

export const setTemplate = (template) => {
  return (dispatch) => {
    dispatch({ type: "librarian/setTemplate", template })
  }
}

export function refreshChat(dispatch) {
  return { type: "librarian/refreshChat" }
}

export const updateHelpRepository = asyncAction(
  "librarian/updateHelpRepository",
  (dispatch, id) => {
    // Perform the POST request to update the help repository
    return request("POST", `/librarian/extract/pt-help/${id}`)
      .then((res) => {
        // Return the response to be dispatched and handled by the reducer
        return res;
      })
      .catch((error) => {
        console.error('Failed to update help repository:', error);
        // Re-throw the error to allow the asyncAction handler to dispatch an ERROR action
        throw error;
      });
  }
)

