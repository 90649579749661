import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import ScheduleScreenContent from "./ScheduleScreenContent"
import { ScheduleButtons } from "components"

export default function SingleMemberScheduleScreen({ view }) {
  const { memberId } = useParams()
  const member = useSelector((state) => state.members[memberId])

  return (
    <div className="ScheduleScreen">
      <ScheduleButtons type="single-member" member={member} view={view} />
      <ScheduleScreenContent singleMember members={[member]} view={view} />
    </div>
  )
}
