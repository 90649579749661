import { useSelector } from "react-redux"
import Button from "react-bootstrap/Button"

export default function RunIAPButton({handleClick}) {
  // const dispatch = useDispatch()
  const iapLoading = useSelector((state) => state.waiting.iapLoading)
  
  return (
    <Button
      onClick={() => {
        handleClick()
      }}
      className="run-iap-btn"
    >
      {iapLoading ? " Processing..." : "Run IAP"}
    </Button>
  )
}
