import React, { useState } from "react"
import { FiInfo } from "react-icons/fi"

import "./index.scss"

import { Container, Title, Number, DurationInput, HiddenInput } from "components/Form"
import { ConfigContainer } from "components"

import { Duration } from "luxon"

// Analysis connection config used in graph design tool.
export default function AnalysisConnectionConfig({
  connection,
  taskFrom,
  taskTo,
  submitHandler,
  deleteHandler,
  cancelHandler,
}) {
  const [minTimeBetween, setMinTimeBetween] = useState(
    Duration.fromObject({ seconds: (connection && connection.timeBetween && connection.timeBetween.min) || 0 })
  )
  const [maxTimeBetween, setMaxTimeBetween] = useState(
    connection && connection.timeBetween && connection.timeBetween.max !== null
      ? Duration.fromObject({ seconds: connection.timeBetween.max })
      : null
  )
  const [samplePercentage, setSamplePercentage] = useState(
    connection.samplePercentage ? connection.samplePercentage : 100
  )
  const [isWorkdayConnection, setIsWorkdayConnection] = useState(connection.isWorkdayConnection)

  return (
    <ConfigContainer
      title={`Edit connection from ${taskFrom.name} to ${taskTo.name}`}
      submitHandler={() =>
        submitHandler({
          timeBetween: {
            min: minTimeBetween.as("seconds"),
            max: maxTimeBetween ? maxTimeBetween.as("seconds") : null,
          },
          samplePercentage,
          isWorkdayConnection
        })
      }
      deleteHandler={deleteHandler}
      cancelHandler={cancelHandler}
    >
      <div className="connection-info">
        <FiInfo title="Connections are performers only - does not affect asset allocation" />
      </div>
      <Container>
        <Title>Minimum time between tasks (DD:HH:MM)</Title>
        <DurationInput required withMinutes value={minTimeBetween} onChange={setMinTimeBetween} />
      </Container>
      <Container>
        <Title>Maximum time between tasks (DD:HH:MM)</Title>
        <DurationInput withMinutes value={maxTimeBetween} onChange={setMaxTimeBetween} />
        <em style={{
          color:"grey",
          margin: "0.40em",
        }}>No time limit unless specified</em>
      </Container>
      <Container>
        <Title>Sample percentage</Title>
        <Number required min={0} max={100} value={samplePercentage} onChange={setSamplePercentage} />
      </Container>
      <Container>
        <Title>Connection calculated in workdays</Title>
        <input
          type="checkbox"
          id="connection-config-checkboxWorkday"
          defaultChecked={isWorkdayConnection}
          onClick={() => {setIsWorkdayConnection(!isWorkdayConnection)}}
        />
      </Container>
      <HiddenInput
        message={
          minTimeBetween && maxTimeBetween && minTimeBetween > maxTimeBetween
            ? "Minimum time between must be smaller than maximum time between"
            : null
        }
      />
    </ConfigContainer>
  )
}
