import React, { useState } from "react"
import ToggleButton from "react-bootstrap/ToggleButton"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import { ConfigContainer, Popup } from "components"
import { Title, Container, Text, ItemList } from "components/Form"
import { useDispatch, useSelector } from "react-redux"
import { customerAdministration, message, token } from "state_management"
import { FiCopy } from "react-icons/fi"

import "./index.scss"
import { Button } from "react-bootstrap"

export default function CustomerConfig({ customer, onClose }) {
  const [name, setName] = useState(customer ? customer.name : "")
  const [statusIsActive, setStatusIsActive] = useState(customer ? customer.active : true)
  const [seatsToBePurchased, setSeatsToBePurchased] = useState(customer ? customer.seatsPurchased : 0)
  const adminId = customer ? customer.adminId : ""
  const id = customer ? customer.id : ""
  const [domains, setDomains] = useState(customer ? customer.domains : [])
  const [showToken, setShowToken] = useState(false)
  const tokenString = useSelector((state) => state.token.token)
  const hasApiToken = customer ? customer.hasApiToken : false;

  const dispatch = useDispatch()

  const handleCopy = () => {
    navigator.clipboard
      .writeText(tokenString)
      .then(() => {
        dispatch(message.success("Copied to clipboard"))
      })
      .catch(() => {
        dispatch(message.error("Could not copy to clipboard"))
      })
  }

  return (
    <ConfigContainer
      title={customer ? "Edit customer" : "Add Customer"}
      deleteTitle="Delete Customer"
      keyWordForAllowedDeletion="DELETE"
      deleteHandler={
        customer &&
        ((allowDeletion) => {
          if (allowDeletion === "DELETE") {
            dispatch(customerAdministration.deleteCustomer(id)).then(() => onClose())
          } else {
            dispatch(message.info("You did not spell DELETE properly"))
          }
        })
      }
      cancelHandler={onClose}
      submitHandler={() => {
        if (!customer) {
          const newCustomer = {
            name: name,
            domains: domains,
            seatsPurchased: seatsToBePurchased,
            active: statusIsActive,
          }
          dispatch(customerAdministration.addCustomer(newCustomer))
        } else {
          const updatedCustomer = {
            name: name,
            adminId: adminId,
            domains: domains,
            seatsPurchased: seatsToBePurchased,
            active: statusIsActive,
          }

          dispatch(customerAdministration.editCustomer(id, updatedCustomer))
        }
        onClose()
      }}
    >
      {showToken && (
        <Popup
          nonScrollable
          className="tokenPopupContainer"
          onCancel={() => {
            dispatch(token.hideToken()) // Delete the token from redux
            setShowToken(false)
          }}
        >
          <div>
            <h4>Permanent API token</h4>
            <p>
              This is a permanent API token, which allows you to access your team via our API. Copy and store it in a
              save place.
            </p>
            <Container>
              <Title>API token</Title>
              <Title>
                {tokenString}{" "}
                <span title="copy to clipboard" className="teamconfig-copy-clipboard">
                  <FiCopy onClick={handleCopy} />
                </span>
              </Title>
            </Container>
          </div>
          <Button
            className="close-popup-button"
            variant="primary"
            onClick={() => {
              dispatch(token.hideToken()) // Delete the token from redux
              setShowToken(false)
            }}
          >
            Ok
          </Button>
        </Popup>
      )}
      <Container>
        <Title>Customer Name</Title>
        <Text value={name} required onChange={(e) => setName(e)} />
      </Container>
      <Container>
        <Title>Customer status</Title>
        <ToggleButtonGroup
          type="radio"
          className="CustomerConfig-toggleButtonGroup"
          name="Active/Passive account"
          defaultValue={statusIsActive ? "ACTIVE" : "PASSIVE"}
        >
          <ToggleButton value="ACTIVE" variant="light" onClick={() => setStatusIsActive(true)} id="customeradmin-status-active" className="CustomerConfig-radioButton">
            Active
          </ToggleButton>
          <ToggleButton value="PASSIVE" variant="light" onClick={() => setStatusIsActive(false)} id="customeradmin-status-passive" className="CustomerConfig-radioButton">
            Passive
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
      <div className="CustomerConfig-allowedDomains">
        <Container>
          <Title>Allowed Domains</Title>
          <ItemList value={domains} onChange={setDomains} itemName="domain" />
        </Container>
      </div>
      <Container>
        <Title>Seats purchased</Title>
        <Text value={seatsToBePurchased} onChange={(e) => setSeatsToBePurchased(e)} />
      </Container>

      {customer && (
        <Container>
          <Title>Customer API key</Title>
          {hasApiToken ? (
            <Button
              variant="danger"
              style={{ marginLeft: "1rem", borderRadius: "0.25rem"}}
              onClick={() => {
                dispatch(customerAdministration.setHasAPIToken(id, false))
                dispatch(token.deleteCompanyToken(id))
              }}
            >
              Delete API key
            </Button>
          ) : (
            <Button
              variant="success"
              style={{ marginLeft: "1rem", borderRadius: "0.25rem"}}
              onClick={() => {
                dispatch(customerAdministration.setHasAPIToken(id, true))
                dispatch(token.createCompanyToken(id))
                setShowToken(true)
              }}
            >
              Create API key
            </Button>
            )
          }
        </Container>
      )}
    </ConfigContainer>
  )
}
