import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ConfigContainer, Loading, SampleTable } from "components"
import { plannableSamples, message, samples, analyses } from "state_management"
import { Button } from "react-bootstrap"
import { ConfirmDialog } from "components"
import { Container } from "components/Form"
import { FiInfo } from "react-icons/fi"

// Used as a popup when adding new samples to a planned analysis
export default function PlannableSamples({ analysisId, handler }) {
  const dispatch = useDispatch()

  const sampleData = useSelector((state) => state.plannableSamples[analysisId] || null)
  const [checkedSamples, setCheckedSamples] = useState([])
  const [plannedSamplesConfirmDialog, setPlannedSamplesConfirmDialog] = useState(false)
  const [showPlannedSamples, setShowPlannedSamples] = useState(false)
  const hasExclusiveSample = showPlannedSamples ?
    sampleData?.samples?.plannedSamples.some(sample => sample.exclusive === true) :
    sampleData?.samples?.unplannedSamples.some(sample => sample.exclusive === true)
  
  if (!sampleData) {
    dispatch(plannableSamples.fetch(analysisId)).catch((e) => {
      dispatch(plannableSamples.needsRefresh(analysisId))
    })
  }

  if (!sampleData || sampleData.isLoading) {
    return <Loading />
  } else if(sampleData.needsRefresh) {
    dispatch(message.warning("Analysis has been updated by another user, please refresh"))
    return handler()
  }

  return (
    <>
      {plannedSamplesConfirmDialog && (
        <ConfirmDialog
          onCancel={() => setPlannedSamplesConfirmDialog(false)}
          onConfirm={() => {
            setShowPlannedSamples(true)
            setPlannedSamplesConfirmDialog(false)
          }}
        >
          <Container>
            <div>
              <FiInfo title="Confirming this will show planned samples" />
            </div>
            <h4>Are you sure you want to show planned samples?</h4>
          </Container>
        </ConfirmDialog>
      )}
      <ConfigContainer
        submitHandler={() => {
          // If no checkboxes are clicked
          if (checkedSamples.length === 0) {
            dispatch(message.warning(`No checkboxes selected`))
          } else {
            dispatch(samples.assignSamples(analysisId,checkedSamples)).then(
              () => dispatch(plannableSamples.fetch(analysisId)).then (() => {
                dispatch(analyses.fetchPlanned())
              })
            )
            handler()
          }
        }}
        cancelHandler={() => {
          handler()
        }}
      >
        <div className="radio-buttons-add-samples">
          <Button
          className="buttonAnalysis"
            variant={showPlannedSamples ? "light" : "primary"}
            onClick={() => {
              setShowPlannedSamples(false)
            }}
          >
            Unplanned
          </Button>
          <Button
          className="buttonProject"
            variant={showPlannedSamples ? "primary" : "light"}
            onClick={() => {
              setPlannedSamplesConfirmDialog(true)
            }}
          >
            Planned
          </Button>
        </div>
        { /* This should show planned samples when "showPlannedSamples" is true */}
        <div className="sample-table-planned-main-container">
          <SampleTable
            popupSamples={showPlannedSamples ? sampleData.samples.plannedSamples : sampleData.samples.unplannedSamples}
            checkedSamples={checkedSamples}
            setCheckedSamples={setCheckedSamples}
            analysisId={analysisId}
          />
        </div>
        {hasExclusiveSample && (
          <div className="explanation-text">
            * Indicates an exclusive sample
          </div>
        )}
      </ConfigContainer>
    </>
  )
}
