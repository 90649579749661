import InputGroup from "react-bootstrap/InputGroup"
import { useDispatch, useSelector } from "react-redux"
import { teamStatus } from "state_management"
import Form from "react-bootstrap/Form"

export default function IncludeOverdueButton() {
  const dispatch = useDispatch()
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  const includeOverdue = useSelector((state) => state.teamStatus.includeOverdue)
  return (
    <InputGroup>
      <Form.Check
        disabled={showForecast}
        checked={showForecast ? true : includeOverdue}
        type={"checkbox"}
        id={`include-overdue-checkbox`}
        onChange={(e) => dispatch(teamStatus.includeOverdue({ includeOverdue: e.target.checked }))}
      />
      <div className="Include-today">
        <p>Include Overdue</p>
      </div>
    </InputGroup>
  )
}
