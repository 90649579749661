import { useDispatch, useSelector } from "react-redux"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import { teamStatus } from "state_management"

export default function IgnoreGreyTasksButton() {
  const dispatch = useDispatch()
  const ignoreGreytasks = useSelector((state) => state.teamStatus.ignoreGreytasks)
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  
  return (
    <InputGroup>
      <Form.Check
        disabled={showForecast}
        checked={showForecast ? true : ignoreGreytasks}
        type={"checkbox"}
        id={`iap-ignore-greytasks-checkbox`}
        onChange={(e) => dispatch(teamStatus.ignoreGreytasks({ iapIgnoreWhiteTasks: e.target.checked }))}
      />
      <div className="Include-today">
        <p>Ignore All Greytasks</p>
      </div>
    </InputGroup>
  )
}
