import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { useSwipeable } from "react-swipeable"
import { DateTime } from "luxon"

import "./index.scss"

import { ScheduleMemberHours, ScheduleElement, ErrorBoundary, ScheduleButtonsMobile } from "components"
import { visual } from "state_management"

import { getTaskInterval } from "utils/scripts/schedule"

export default function ScheduleScreenMobile() {
  const { memberId } = useParams()
  const dispatch = useDispatch()
  const allMembers = useSelector((state) => state.members)
  const allWhiteTasks = useSelector((state) => state.whiteTasks)
  const allAnalysisTasks = useSelector((state) => state.analysisTasks)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const mondayDate = DateTime.fromISO(useSelector((state) => state.visual.scheduleMondayDate))
  const [activeDate, setActiveDate] = useState(DateTime.local().setZone(memberTimezone).startOf("day"))

  const members = memberId
    ? [allMembers[memberId]]
    : Object.values(allMembers)
        .filter((member) => member.showOnPlan)
        .filter((member) => !member.archived)

  const setMondayDate = (date) => {
    const thisMonday = date.startOf("week")
    if (!mondayDate.hasSame(thisMonday, "day")) {
      dispatch(visual.changeMondayDate(thisMonday.toISO()))
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setMondayDate(activeDate.plus({ days: 1 }))
      setActiveDate(activeDate.plus({ days: 1 }))
    },
    ...{ delta: 150 }, // Arbitrary value for the sensitivity of swipe
    onSwipedRight: () => {
      setMondayDate(activeDate.minus({ days: 1 }))
      setActiveDate(activeDate.minus({ days: 1 }))
    },
    ...{ delta: 150 }, // Arbitrary value for the sensitivity of swipe
  })

  return (
    <div className="ScheduleScreenMobile" {...handlers}>
      <ScheduleButtonsMobile
        content={activeDate.toFormat("ccc MMM d")}
        setToday={() => {
          setMondayDate(DateTime.local().setZone(memberTimezone).startOf("week"))
          setActiveDate(DateTime.local().setZone(memberTimezone).startOf("day"))
        }}
        goBackOneDay={() => {
          setMondayDate(activeDate.minus({ days: 1 }))
          setActiveDate(activeDate.minus({ days: 1 }))
        }}
        goForwardOneDay={() => {
          setMondayDate(activeDate.plus({ days: 1 }))
          setActiveDate(activeDate.plus({ days: 1 }))
        }}
      />
      <div className="ScheduleScreenMobileContent">
        {members.map((member) => {
          // Extended based on arbitary limit (below 10 hours wide)
          const extended = true
          const [minMemberHeight, maxMemberHeight] = getTaskInterval(
            Object.values(allWhiteTasks)
              .concat(Object.values(allAnalysisTasks))
              .concat(Object.values(allProjectActions))
              .filter((item) => item.memberIds.indexOf(member.id) !== -1),
            [activeDate.startOf("day")],
            member.workHours,
            member.timezone,
            memberTimezone,
            extended
          )
          const dayLength = maxMemberHeight - minMemberHeight
          return (
            <div
              className="ScheduleScreenMobileMember"
              key={member.id}
              style={{ height: 112 * dayLength + 37.59 + "px" }}
            >
              <h4>{member.initials}</h4>
              <div className="ScheduleScreenMobileMemberContent">
                <ErrorBoundary>
                  <ScheduleMemberHours
                    extended={extended}
                    minMemberHeight={minMemberHeight}
                    maxMemberHeight={maxMemberHeight}
                    member={member}
                  />
                  <ScheduleElement
                    extended={extended}
                    minMemberHeight={minMemberHeight}
                    maxMemberHeight={maxMemberHeight}
                    memberId={member.id}
                    date={activeDate.startOf("day").toISO()}
                    isMobile={true}
                  />
                </ErrorBoundary>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
