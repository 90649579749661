import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useTable, useSortBy } from "react-table"

import { FiArrowLeft } from "react-icons/fi"
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa"
import { RiDeleteBinLine } from "react-icons/ri"
import Button from "react-bootstrap/Button"
import "./index.scss"

import { integrationSheets, message } from "state_management"
import { Loading } from "components"

export default function DataGridLayout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sheetId } = useParams()

  const isLoading = useSelector((state) => state.integrationSheets.isLoadingConversionSheet)
  const conversionSheets = useSelector((state) => state.integrationSheets.conversionSheets)
  const conversionSheetEdited = useSelector((state) => state.integrationSheets.editedConversionSheet)
  const limsSheets = useSelector((state) => state.integrationSheets.limsSheets)
  const sapSheets = useSelector((state) => state.integrationSheets.sapSheets)

  const [sorting, setSorting] = useState([])
  const [invalidCells, setInvalidCells] = useState(new Set());

  const currentSheet = useMemo(() => conversionSheets[sheetId] || {}, [conversionSheets, sheetId])
  const shouldFetch = conversionSheets[sheetId] === undefined
  const currentSheetType = limsSheets?.find((sheet) => sheet.id === sheetId)
    ? "Lims"
    : sapSheets?.find((sheet) => sheet.id === sheetId)
    ? "Sap"
    : ""
  // A Set for holding column headers which should contain numeric values
  const numericHeaders = new Set(["leadTimeToLatestArrival", "leadTimeToDeadline", "leadTimeToEarliestStart"])
  const requiredHeaders = new Set(["limsAnalysisName", "plannertechAnalysisName", "leadTimeToDeadline", "projectTemplateName"]);

  useEffect(() => {
    if (shouldFetch) {
      if (currentSheetType === "Lims") {
        dispatch(integrationSheets.fetchLimsConversionSheet(sheetId)).then((res) => {
          dispatch(integrationSheets.setConversionSheetEdited({ ...res }))
        })
      } else if (currentSheetType === "Sap") {
        dispatch(integrationSheets.fetchSapConversionSheet(sheetId)).then((res) => {
          dispatch(integrationSheets.setConversionSheetEdited({ ...res }))
        })
      } else {
        dispatch(integrationSheets.fetchLimsSheets())
        dispatch(integrationSheets.fetchSapSheets())
      }
    } else {
      dispatch(integrationSheets.setConversionSheetEdited({ ...currentSheet }))
    }
  }, [dispatch, sheetId, currentSheetType, shouldFetch, currentSheet])

  // Define a handler to delete a row
  const handleDeleteRow = useCallback(
    (rowIndex) => {
      const newRows = [...conversionSheetEdited.rows]
      newRows.splice(rowIndex, 1)
      dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: newRows }))

      // Maintain current sorting
      setSorting([...sorting])
    },
    [conversionSheetEdited, dispatch, sorting]
  )

  // Transforming our headers into a format that react-table understands.
  const columns = useMemo(() => {
    let baseColumns =
      conversionSheetEdited?.headers.map((header) => ({
        Header: header.name,
        bold: header.bold,
        accessor: header.key,
      })) || []

    // Add delete column with a class to hide its header
    const deleteColumn = {
      id: "delete",
      Header: () => <div style={{ display: "none" }}></div>, // The header will be invisible
      accessor: "deleteAction", // Unique accessor for the delete action
      Cell: ({ row }) => (
        <div className="delete-cell">
          {/* Prevent tabbing to this button */}
          <Button title="Delete this row" variant="danger" onClick={() => handleDeleteRow(row.index)} tabIndex={-1}>
            <RiDeleteBinLine />
          </Button>
        </div>
      ),
    }

    return [...baseColumns, deleteColumn]
  }, [conversionSheetEdited, handleDeleteRow])

  const data = useMemo(() => conversionSheetEdited?.rows || [], [conversionSheetEdited?.rows])

  const {
    getTableProps, // Returns prop getters for the table element.
    getTableBodyProps, // Returns prop getters for the table body element.
    headerGroups, // An array of normalized header groups.
    rows: tableRows, // Array of row objects from our data.
    prepareRow, // Prepares a row (object) for rendering.
    state: { sortBy }, // Get the current sorting state from React Table
  } = useTable({ columns, data, initialState: { sortBy: sorting } }, useSortBy)

  // Update local sorting state when React Table sorting changes
  useEffect(() => {
    setSorting(sortBy)
  }, [sortBy])

  if (isLoading) {
    return <Loading title="Loading sheet" />
  }

  // This function handles keydown events for table navigation.
  // It uses the DOM to find the current cell and determine the target cell based on the key pressed.
  // This is useful for when the table is sorted because the indices stay the same
  const handleKeyDown = (e) => {
    const key = e.key
    if (!["Enter", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)) {
      return // Exit if the key is not handled
    }

    e.preventDefault()

    // Find the cell that triggered the event
    const currentCell = e.target.closest("td")
    if (!currentCell) {
      return // Current cell not found
    }

    const columnIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell)

    let targetCell
    switch (key) {
      case "Enter":
      case "ArrowDown":
        targetCell = e.shiftKey
          ? currentCell.parentElement.previousElementSibling?.children[columnIndex]
          : currentCell.parentElement.nextElementSibling?.children[columnIndex]
        break
      case "ArrowUp":
        targetCell = currentCell.parentElement.previousElementSibling?.children[columnIndex]
        break
      case "ArrowLeft":
        targetCell = currentCell.previousElementSibling
        break
      case "ArrowRight":
        targetCell = currentCell.nextElementSibling
        break
      default:
        return // Exit if the key is not handled
    }

    if (targetCell) {
      // Focus on the contentEditable div within the target cell
      const editableDiv = targetCell.querySelector('div[contenteditable="true"]')
      if (editableDiv) {
        editableDiv.focus()
      }
    }
  }

  // Handle content change in a cell
  const handleCellChange = (e, rowIndex, columnId) => {
    const newInvalidCells = new Set(invalidCells);
    const updatedValue = e.target.innerText.trim() // Trim to ensure it's empty if only whitespaces

    const updatedRows = [...conversionSheetEdited.rows]
    const cellId = `${rowIndex}-${columnId}`;

    if (
      (requiredHeaders.has(columnId) && updatedValue === "") ||
        (numericHeaders.has(columnId) && Number.isNaN(Number(updatedValue)))
    ) {
      newInvalidCells.add(cellId);
    } else {
      newInvalidCells.delete(cellId);
    }

    setInvalidCells(newInvalidCells);

    if (!updatedRows[rowIndex]) {
      updatedRows[rowIndex] = {}
    } else {
      updatedRows[rowIndex] = { ...updatedRows[rowIndex] }
    }

    if (updatedValue === "" && !requiredHeaders.has(columnId)) {
      delete updatedRows[rowIndex][columnId] // Remove the key if cell is empty
    } else {
      updatedRows[rowIndex][columnId] = updatedValue
    }

    dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: updatedRows }))
  }

  // Handle pasting of data into the grid
  const handlePaste = (e, rowIndex, cellIndex) => {
    e.preventDefault();
    const newInvalidCells = new Set(invalidCells);

    if (sorting.length > 0) {
      dispatch(message.warning("Please unsort the table before pasting"));
      return;
    }

    const pastedData = e.clipboardData.getData("Text").trim().split(/\r?\n/).map(row => row.split("\t"));
    const updatedRows = [...conversionSheetEdited.rows];

    pastedData.forEach((row, rIdx) => {
      const targetRowIndex = rowIndex + rIdx;
      updatedRows[targetRowIndex] = { ...updatedRows[targetRowIndex] || {} };

      row.forEach((cell, cIdx) => {
        const targetCellIndex = cellIndex + cIdx;
        if (targetCellIndex < conversionSheetEdited.headers.length) {
          const headerKey = conversionSheetEdited.headers[targetCellIndex].key;
          const cellId = `${targetRowIndex}-${headerKey}`;
          const isRequired = requiredHeaders.has(headerKey);
          const isNumeric = numericHeaders.has(headerKey);
          const isInvalid = (isRequired && cell === "") || (isNumeric && Number.isNaN(Number(cell)));

          isInvalid ? newInvalidCells.add(cellId) : newInvalidCells.delete(cellId);

          if (cell === "" && !isRequired) {
            delete updatedRows[targetRowIndex][headerKey];
          } else {
            updatedRows[targetRowIndex][headerKey] = isNumeric && cell !== "" ? parseFloat(cell) : cell;
          }
        }
      });
    });

    setInvalidCells(newInvalidCells);
    dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: updatedRows }));
  };


  const getOnPasteHandler = (rowIndex, columnId) => (e) => {
    const cellIndex = conversionSheetEdited.headers.findIndex((header) => header.key === columnId)
    handlePaste(e, rowIndex, cellIndex)
  }

  // Adds an empty row to the table.
  const handleAddRow = () => {
    dispatch(
      integrationSheets.setConversionSheetEdited({
        ...conversionSheetEdited,
        rows: [...conversionSheetEdited.rows, {}],
      })
    )
  }

  const handleClearTable = () => {
    dispatch(
      integrationSheets.setConversionSheetEdited({
        ...conversionSheetEdited,
        rows: [],
      })
    )
  }

  function validateAndMarkCells(rows, requiredHeaders) {
    const newInvalidCells = new Set();

    rows.forEach((row, rowIndex) => {
      Object.keys(row).forEach((columnId) => {
        const cellId = `${rowIndex}-${columnId}`;
        const isEmptyOrRequiredNull = ((row[columnId] == null) || row[columnId].toString().trim() === "") && requiredHeaders.has(columnId);
        const isNumericNaN = numericHeaders.has(columnId) && Number.isNaN(Number(row[columnId]))

        if (isEmptyOrRequiredNull || isNumericNaN) {
          newInvalidCells.add(cellId);
        } else if (invalidCells.has(cellId)) {
          newInvalidCells.delete(cellId);
        }
      });
    });

    setInvalidCells(newInvalidCells);

    if (newInvalidCells.size > 0) {
      return false;
    }

    return true;
}

  const handleSave = () => {
    const invalidCellsPresent = validateAndMarkCells(conversionSheetEdited.rows, requiredHeaders);

    if (!invalidCellsPresent) {
      dispatch(message.warning("Please fill in all required fields"));
      return;
    }

    if (currentSheetType === "Lims") {
      dispatch(integrationSheets.saveLimsConversionSheet(sheetId, conversionSheetEdited.rows)).then(() =>
        navigate("/setup/integration")
      )
    } else if (currentSheetType === "Sap") {
      dispatch(integrationSheets.saveSapConversionSheet(sheetId, conversionSheetEdited.rows)).then(() =>
        navigate("/setup/integration")
      )
    }
  }

  const handleReset = () => {
    const currentRows = [...currentSheet.rows];
    validateAndMarkCells(currentRows, requiredHeaders);
    dispatch(integrationSheets.setConversionSheetEdited({ ...currentSheet }))
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className="DataGridLayout-container">
      <h3>{`Automatic ${currentSheetType} Conversion Sheet`}</h3>
      <div className="data-grid-config-buttons">
        <div className="data-grid-left-button">
          <Button variant="primary" title={"Go back"} onClick={handleGoBack}>
            <FiArrowLeft />
          </Button>
        </div>
        <div className="data-grid-center-buttons">
          <Button variant="secondary" onClick={handleReset}>
            Cancel Changes
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </div>
        <div className="data-grid-right-button" style={{ visibility: data.length === 0 ? "hidden" : "" }}>
          <Button variant="danger" onClick={handleClearTable}>
            Clear Table
          </Button>
        </div>
      </div>
      <div className="excelGrid-container">
        <table {...getTableProps()} className="excelGrid">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Displaying headers with sorting functionality.
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.id === "delete" ? "delete-header" : ""}
                    title={`Click to sort by ${column.Header}`}
                    style={{ fontWeight: column.bold ? "bold" : "normal" }}
                  >
                    <div className="excelGrid-header-content">
                      {column.render("Header")}

                      {/* Displaying a sort indicator based on the sort direction. */}
                      <span>{column.isSorted ? column.isSortedDesc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {tableRows.map((row) => {
              prepareRow(row)
              return (
                <MemoizedRow
                  key={row.id}
                  row={row}
                  handleCellChange={handleCellChange}
                  getOnPasteHandler={getOnPasteHandler}
                  handleKeyDown={handleKeyDown}
                  invalidCells={invalidCells}
                />
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="add-row-btn-container">
        <Button variant="secondary" onClick={handleAddRow}>
          Add Row
        </Button>
      </div>
    </div>
  )
}

const MemoizedCell = React.memo(({ cell, handleCellChange, getOnPasteHandler, handleKeyDown, isInvalid }) => {
  const cellStyle = isInvalid ? { borderColor: 'rgba(255, 0, 0, 1)', borderWidth: 2, borderStyle: 'solid' } : {};

  return (
    <td {...cell.getCellProps()} style={cellStyle}>
      <div
        // key={cell.column.id}
        contentEditable
        onBlur={(e) => handleCellChange(e, cell.row.index, cell.column.id)}
        onPaste={getOnPasteHandler(cell.row.index, cell.column.id)}
        onKeyDown={(e) => handleKeyDown(e)}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: cell.value }}
        data-columnid={cell.column.id}
      />
    </td>
  )
})

const MemoizedDeleteCell = React.memo(({ cell }) => {
  return (
    <td {...cell.getCellProps()} className="delete-cell">
      {cell.render("Cell")}
    </td>
  )
})

const MemoizedRow = React.memo(({ row, handleCellChange, getOnPasteHandler, handleKeyDown, invalidCells }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        const cellId = `${row.index}-${cell.column.id}`;
        const isInvalid = invalidCells.has(cellId);

        if (cell.column.id !== "delete") {
          return (
            <MemoizedCell
              key={cell.column.id}
              cell={cell}
              handleCellChange={handleCellChange}
              getOnPasteHandler={getOnPasteHandler}
              handleKeyDown={handleKeyDown}
              isInvalid={isInvalid}
            />
          )
        } else {
          return <MemoizedDeleteCell key={cell.column.id} cell={cell} />
        }
      })}
    </tr>
  )
})
