export function analyzeAudioBlob(blob, threshold = -15) {
  return new Promise((resolve, reject) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const reader = new FileReader();

    reader.onload = function () {
      audioContext.decodeAudioData(reader.result, (buffer) => {
        let isSpeechDetected = false;
        const channelData = buffer.getChannelData(0);

        // Check if any audio chunk's volume is above the threshold
        for (let i = 0; i < channelData.length; i++) {
          if (20 * Math.log10(Math.abs(channelData[i])) > threshold) {
            isSpeechDetected = true;
            break;
          }
        }

        audioContext.close(); // Clean up the audio context
        resolve(isSpeechDetected);
      });
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(blob);
  });
}