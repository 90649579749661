import React from "react"
import "./index.scss"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"
import Table from "react-bootstrap/Table"

export default function LogTable({ log }) {
  const timeZone = useSelector((state) => state.teamInfo.timezone)
  const sortedLogs = log.sort((a, b) => new Date(b.editTime) - new Date(a.editTime));

  const formatDateTime = (dateString) => {
    if (!dateString) return "Date Unavailable";
    const dt = DateTime.fromISO(dateString, { zone: 'utc' }).setZone(timeZone);
    return dt.isValid ? dt.toFormat("d. MMM HH.mm") : "Invalid Date";
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <Table bordered hover className="log-table">
      <thead>
        <tr>
          <th>Edited</th>
          <th>Moved by</th>
          <th>Name</th>
          <th>Moved From</th>
          <th>Moved to</th>
          <th>Old Start</th>
          <th>New Start</th>
          <th>Task Name</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {sortedLogs.map((entry, index) => (
            <tr key={index}>
              <td className="log-table-time-column">{formatDateTime(entry.editTime)}</td>
              <td>{entry.movedByMember}</td>
              <td className="log-table-name">{entry.name}</td>
              <td>{entry.oldMember ? entry.oldMember : "Unplanned"}</td>
              <td>{entry.newMember ? entry.newMember : "Unplanned"}</td>
              <td className="log-table-time-column">{entry.oldStart ? formatDateTime(entry.oldStart) : "Unplanned"}</td>
              <td className="log-table-time-column">{entry.newStart ? formatDateTime(entry.newStart) : "Unplanned"}</td>
              <td className="log-table-task-name">{entry.taskName}</td>
              <td>{capitalizeFirstLetter(entry.type)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
