import { combineReducers } from "redux"
import * as message from "./message"
import * as visual from "./visual"
import * as analysisTasks from "./analysisTasks"
import * as whiteTasks from "./whiteTasks"
import * as members from "./members"
import * as analyses from "./analyses"
import * as projectActions from "./projectActions"
import * as assets from "./assets"
import * as analysisDefinitions from "./analysisDefinitions"
import * as teamStatus from "./teamStatus"
import * as auth from "./auth"
import * as loading from "./loading"
import * as projects from "./projects"
import * as projectTemplates from "./projectTemplates"
import * as competenceGroups from "./competenceGroups"
import * as constraintChecks from "./constraintChecks"
import * as metrics from "./metrics"
import * as iap from "./iap"
import * as scheduleHover from "./scheduleHover"
import * as customerAdministration from "./customerAdministration"
import * as samples from "./samples"
import * as seatInfo from "./seatInfo"
import * as diagnostics from "./diagnostics"
import * as sampleView from "./sampleView"
import * as integrationSheets from "./integrationSheets"
import * as teamsOnLocation from "./teamsOnLocation"
import * as locations from "./locations"
import * as teamInfo from "./teamInfo"
import * as multipleDrag from "./multipleDrag"
import * as reports from "./reports"
import * as dayPicker from "./dayPicker"
import * as waiting from "./waiting"
import * as scrumView from "./scrumView"
import * as token from "./token"
import * as plannableSamples from "./plannableSamples"
import * as analysisGroups from "./analysisGroups"
import * as projectGroups from "./projectGroups"
import * as chatBot from "./chatBot"
import * as librarian from "./librarian"
import * as repositories from "./repositories"
import * as standardActions from "./standardActions"
import * as logging from "./logging"

import { createMap, addToMap, removeFromMap } from "./common"

export { MemberRoles } from "./members"

export const rootReducer = combineReducers({
  message: message.reducer,
  visual: visual.reducer,
  whiteTasks: whiteTasks.reducer,
  analysisTasks: analysisTasks.reducer,
  members: members.reducer,
  analyses: analyses.reducer,
  projectActions: projectActions.reducer,
  asset: assets.reducer,
  analysisDefinitions: analysisDefinitions.reducer,
  teamStatus: teamStatus.reducer,
  auth: auth.reducer,
  loading: loading.reducer,
  projects: projects.reducer,
  projectTemplates: projectTemplates.reducer,
  competenceGroups: competenceGroups.reducer,
  constraintChecks: constraintChecks.reducer,
  metrics: metrics.reducer,
  scheduleHover: scheduleHover.reducer,
  customerAdministration: customerAdministration.reducer,
  samples: samples.reducer,
  seatInfo: seatInfo.reducer,
  diagnostics: diagnostics.reducer,
  sampleView: sampleView.reducer,
  integrationSheets: integrationSheets.reducer,
  teamsOnLocation: teamsOnLocation.reducer,
  locations: locations.reducer,
  teamInfo: teamInfo.reducer,
  multipleDrag: multipleDrag.reducer,
  reports: reports.reducer,
  dayPicker: dayPicker.reducer,
  waiting: waiting.reducer,
  scrumView: scrumView.reducer,
  token: token.reducer,
  plannableSamples: plannableSamples.reducer,
  analysisGroups: analysisGroups.reducer,
  projectGroups: projectGroups.reducer,
  chatBot: chatBot.reducer,
  librarian: librarian.reducer,
  repositories: repositories.reducer,
  standardActions: standardActions.reducer,
  logging: logging.reducer,
})

export {
  message,
  visual,
  whiteTasks,
  analysisTasks,
  members,
  analyses,
  projectActions,
  assets,
  analysisDefinitions,
  teamStatus,
  auth,
  loading,
  projects,
  projectTemplates,
  competenceGroups,
  constraintChecks,
  metrics,
  iap,
  scheduleHover,
  customerAdministration,
  samples,
  seatInfo,
  diagnostics,
  sampleView,
  integrationSheets,
  teamsOnLocation,
  locations,
  teamInfo,
  multipleDrag,
  reports,
  dayPicker,
  waiting,
  scrumView,
  plannableSamples,
  analysisGroups,
  projectGroups,
  chatBot,
  librarian,
  repositories,
  // Common
  createMap,
  addToMap,
  removeFromMap,
  token,
  standardActions,
  logging,
}
