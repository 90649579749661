import { Card, ListGroup, Alert, Badge } from "react-bootstrap"

export default function UploadDisplay({ files, failedFiles, rejectedFiles }) {
  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) // toFixed(2) is used to limit the result to 2 decimal places
  }

  return (
    <>
      {files.length > 0 && (
        <Card>
          <Card.Header style={{ backgroundColor: "#4CAF50", color: "white" }}>Successfully Uploaded Files</Card.Header>
          <ListGroup variant="flush">
            {files.map((file, index) => (
              <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                <div>
                  <i className="fas fa-file-alt"></i>
                  {file.name}
                </div>
                <Badge pill variant="info">
                  {bytesToMB(file.size)} MB
                </Badge>{" "}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      )}

      {failedFiles.length > 0 && (
        <Alert variant="warning">
          <Alert.Heading>Failed Uploads</Alert.Heading>
          {failedFiles.map((file, index) => (
            <p key={index}>{file.path}</p>
          ))}
        </Alert>
      )}

      {rejectedFiles.length > 0 && (
        <Alert variant="warning">
          <Alert.Heading>Rejected File Types</Alert.Heading>
          {rejectedFiles.map((file, index) => (
            <p key={index}>{file.path}</p>
          ))}
        </Alert>
      )}
    </>
  )
}
