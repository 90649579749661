export function convertTokenDisplay(token) {
  // Special case for 0
  if (token === 0) {
    return "0"
  }

  // Convert to Kilo
  let tokenDisplay = token / 1000

  // If it's not a whole number, format it
  if (tokenDisplay % 1 !== 0) {
    tokenDisplay = parseFloat(tokenDisplay.toFixed(2)) // toFixed returns string, so parse it back to float
  }

  // add 'K' to show it's in thousands
  return tokenDisplay + "K"
}