import React from "react"
import { useSelector } from "react-redux"
import { FiChevronUp, FiChevronDown } from "react-icons/fi"

export default function ScheduleMemberElement({ member, onEdit, extended, setExtended }) {
  const currentMemberId = useSelector((state) => state.auth.memberId)
  const isMember = useSelector((state) => state.auth.isMember)

  const editable = !isMember || currentMemberId === member.id

  return (
    <div
      className={"ScheduleMemberElement" + (editable ? " ScheduleMemberElement-editable" : "")}
      onClick={editable ? () => onEdit(member) : undefined}
      title={editable ? "Edit Member" : undefined}
    >
      <h4>{member.initials}</h4>
      {member.archived && <h5>(archived)</h5>}
      {extended ? (
        <FiChevronUp
          title="Reduce"
          onClick={(e) => {
            setExtended(false)
            e.stopPropagation()
          }}
        />
      ) : (
        <FiChevronDown
          title="Expand"
          onClick={(e) => {
            setExtended(true)
            e.stopPropagation()
          }}
        />
      )}
    </div>
  )
}
