import React, { Fragment, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Duration } from "luxon"
import { Loading } from "components"
import { diagnostics } from "state_management"

import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"

// Format an amount of seconds to be displayed in the diagnostics.
// formatDiagnosticsDuration(3 * 60 + 30) == "3 minutes 30 seconds"
function formatDiagnosticsDuration(seconds, showDays) {
  if (seconds === null) {
    return "?"
  }
  let duration = Duration.fromObject({ seconds })
  if (showDays) {
    duration = duration.shiftTo("days", "hours", "minutes", "seconds")
  } else {
    duration = duration.shiftTo("hours", "minutes", "seconds")
  }

  let rtn = ""
  const helper = (inp, str) => {
    if (inp) {
      rtn += ` ${inp} ${str}${inp === 1 ? "" : "s"}`
    }
  }
  helper(duration.days, "day")
  helper(duration.hours, "hour")
  helper(duration.minutes, "minute")
  helper(duration.seconds, "second")
  if (!rtn) {
    return "0 hours"
  }
  return rtn.slice(1)
}

// Analysis / Project diagnostics.
export default function Diagnostics({ isProject }) {
  const dispatch = useDispatch()
  const diagnosticData = useSelector((state) => (isProject ? state.diagnostics.projects : state.diagnostics.analyses))
  const isLoading = useSelector((state) => state.diagnostics.isLoading)

  const [isFirst, setIsFirst] = useState(true)

  if (isFirst) {
    if (isProject) {
      dispatch(diagnostics.fetchProjects())
    } else {
      dispatch(diagnostics.fetchAnalyses())
    }
    setIsFirst(false)
    return <Loading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="Diagnostics">
      <Tabs defaultActiveKey={!isProject ? "analyses" : "competence-group"} id="diagnostics-tabs" className="mb-3" justify>
        {diagnosticData.analysisDiagnostics && (
          <Tab eventKey="analyses" title="Analyses">
            {diagnosticData.analysisDiagnostics.map((diagnostic, idx) => (
              <Fragment key={idx}>
                <h4>{diagnostic.name}</h4>
                <p>Max. sample count: {diagnostic.maxSampleCount || <>&infin;</>}</p>
                <p>
                  Average number of samples allocated:{" "}
                  {diagnostic.avgAllocatedSampleCount !== null ? diagnostic.avgAllocatedSampleCount.toFixed(2) : "?"}
                </p>
                <p>
                  Average sample batch interval: {formatDiagnosticsDuration(diagnostic.avgSampleBatchInterval, true)}
                </p>
                <p>Average analysis duration: {formatDiagnosticsDuration(diagnostic.avgDuration, true)}</p>
              </Fragment>
            ))}
          </Tab>
        )}
        <Tab eventKey="competence-group" title="Competence Group">
          {/* <p className="Pheading">Competence Group Diagnostics</p> */}
          {diagnosticData.competenceGroupDiagnostics.map((diagnostic, idx) => (
            <Fragment key={idx}>
              <h4>{diagnostic.name}</h4>
              <p>Available time one week: {formatDiagnosticsDuration(diagnostic.availableTimeOneWeek, false)}</p>
              <p>Estimated workload one week: {formatDiagnosticsDuration(diagnostic.allocatedTimeOneWeek, false)}</p>
            </Fragment>
          ))}
        </Tab>
        <Tab eventKey="assets" title="Assets">
          {/* <p className="Pheading">Asset Diagnostics</p> */}
          {diagnosticData.assetDiagnostics.map((diagnostic, idx) => (
            <Fragment key={idx}>
              <h4>{diagnostic.name}</h4>
              <p>Estimated workload one week: {formatDiagnosticsDuration(diagnostic.allocatedTimeOneWeek, false)}</p>
            </Fragment>
          ))}
        </Tab>
      </Tabs>
    </div>
  )
}
