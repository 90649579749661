import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  hasFetched: false,
  seatsUsed: null,
  seatsTotal: null,
  seatsBought: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "seatInfo/fetch/BEGIN":
      return { ...state, hasFetched: true }
    case "seatInfo/fetch":
      return { ...state, seatsUsed: action.res.seatsUsed, seatsTotal: action.res.seatsTotal, seatsBought: action.res.seatsBought }
    case "auth/logout":
      return initialState
    case "members/createAsAdmin":
      return { ...state, seatsUsed: action.res.seatsUsed + 1, seatsBought: action.res.seatsBought }
    default:
      return state
  }
}

export const fetch = asyncAction("seatInfo/fetch", (dispatch) => {
  return request("GET", `/company/seat-info`)
})
