import Button from "react-bootstrap/Button"

export default function IntegrationResultMessage({ resultMessage, setResultMessage }) {
    return (
        <>
            {!!(
                resultMessage.amounts.project?.created ||
                resultMessage.amounts.project?.updated ||
                resultMessage.amounts.project?.skipped ||
                resultMessage.amounts.project?.deleted
            ) && (
                    <>
                        <h4>Projects</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Created: {resultMessage.amounts.project?.created}</li>
                            <li className="list-group-item">Updated information: {resultMessage.amounts.project?.updated}</li>
                            <li className="list-group-item">Already loaded: {resultMessage.amounts.project?.skipped}</li>
                            <li className="list-group-item">Deleted: {resultMessage.amounts.project?.deleted}</li>
                        </ul>
                    </>
                )}
            {
                !!(
                    resultMessage.amounts.action?.created ||
                    resultMessage.amounts.action?.updated ||
                    resultMessage.amounts.action?.skipped ||
                    resultMessage.amounts.action?.deleted
                ) && (
                    <>
                        <h4>Actions</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Created: {resultMessage.amounts.action?.created}</li>
                            <li className="list-group-item">
                                Updated information (and therefore now unplanned): {resultMessage.amounts.action?.updated}
                            </li>
                            <li className="list-group-item">Already loaded: {resultMessage.amounts.action?.skipped}</li>
                            <li className="list-group-item">
                                Deleted or marked as finished: {resultMessage.amounts.action?.deleted}
                            </li>
                        </ul>
                    </>
                )
            }
            {
                !!(
                    resultMessage.amounts.sample?.created ||
                    resultMessage.amounts.sample?.updated ||
                    resultMessage.amounts.sample?.skipped ||
                    resultMessage.amounts.sample?.deleted
                ) && (
                    <>
                        <h4>Samples</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Created: {resultMessage.amounts.sample?.created}</li>
                            <li className="list-group-item">Updated information: {resultMessage.amounts.sample?.updated}</li>
                            <li className="list-group-item">Already loaded: {resultMessage.amounts.sample?.skipped}</li>
                            <li className="list-group-item">Done/Deleted: {resultMessage.amounts.sample?.deleted}</li>
                        </ul>
                    </>
                )
            }
            {
                !Object.values(resultMessage.amounts).reduce(
                    (hasChanges, amounts) =>
                        Object.values(amounts).reduce((hasChanges, value) => hasChanges || !!value, hasChanges),
                    false
                ) && <h4>No data found</h4>
            }
            {resultMessage.errors.length > 0 && <div className="Pheading">Following errors while importing:</div>}
            {
                resultMessage.errors.length > 0 && (
                    <ul className="list-group">
                        {resultMessage.errors.map((err) => (
                            <li className="list-group-item" key={err.file + err.sheet + err.row}>
                                {err.file}/{err.sheet}
                                {err.row !== null ? " row " + err.row : ""}: {err.message}
                            </li>
                        ))}
                    </ul>
                )
            }
            <Button className="close-popup-button" variant="primary" onClick={() => setResultMessage(null)}>
                Ok
            </Button>
        </>
    )

}