import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ConfirmDialog } from "components"
import { teamStatus, message, auth } from "state_management"

export default function SimulationLogoutConfirm() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showLogoutConfirm = useSelector((state) => state.teamStatus.showLogoutConfirm)

  return (
    showLogoutConfirm && <ConfirmDialog
      onCancel={() => dispatch(teamStatus.showLogoutConfirm(false))}
      onConfirm={() => {
        dispatch(teamStatus.showLogoutConfirm(false))
        dispatch(teamStatus.discardPlanningModeChanges())
          .then(() => {
            dispatch(auth.logout())
            navigate("/login")
          })
          // Overwrite default error message
          // TODO: Find a better way to do this!
          .catch((e) => {
            if (e.name === "IAPinUseError") {
              dispatch(message.warning("Just a moment - the IAP for your team is running"))
            } else {
              dispatch(message.error("Failed discarding changes. You were not logged out!"))
            }
          })
      }}
    >
      <h4>You are currently in simulation mode. Logging out will discard changes!</h4>
    </ConfirmDialog>
  )
}
