export class Graph{
 
  // Constructor
  constructor(v)
  {
      // Number of vertices
      this.V = v

      // Adjacency List as ArrayList of ArrayList's
      this.adj = new Array(this.V)
      for (let i = 0 ; i < this.V ; i+=1){
          this.adj[i] = []
      }
  }

  // Function to add an edge into the graph
  addEdge(v, w){
      this.adj[v].push(w)
  }

  // A recursive function used by topologicalSort
  topologicalSortUtil(v, visited, stack)
  {
      // Mark the current node as visited.
      visited[v] = true;
      let i = 0;

      // Recur for all the vertices adjacent
      // to thisvertex
      for(i = 0 ; i < this.adj[v].length ; i++){
          if(!visited[this.adj[v][i]]){
              this.topologicalSortUtil(this.adj[v][i], visited, stack)
          }
      }

      // Push current vertex to stack
      // which stores result
      stack.push(v);
  }

  // The function to do Topological Sort.
  // It uses recursive topologicalSortUtil()
  topologicalSort()
  {
      let stack = []

      // Mark all the vertices as not visited
      let visited = new Array(this.V);
      for (let i = 0 ; i < this.V ; i++){
          visited[i] = false;
      }

      // Call the recursive helper
      // function to store
      // Topological Sort starting
      // from all vertices one by one
      for (let i = 0 ; i < this.V ; i++){
          if (visited[i] === false){
              this.topologicalSortUtil(i, visited, stack);
          }
      }
      return stack
  }

  // Driver code with sorting and retrieving in the correct format
  getSortedArray(g,connections,tasks) {
    for(var i = 0; i < connections.length; i++) {
        let from = 0
        let to = 0
        // Find from and to for each edge in the 'connection' variable
        for(var j = 0; j < tasks.length; j++) {
          if(tasks[j].id === connections[i].prevId) {
            from = j
          }
          if(tasks[j].id === connections[i].nextId) {
            to = j
          }
        }
        g.addEdge(from, to)
      }

      let tasksSortedIndex = g.topologicalSort()
      const tasksSorted = tasksSortedIndex.map(taskIndex => tasks[taskIndex]).reverse()
      
      return tasksSorted
      
      // let currNode = 0
      // // Find last node first because it doesn't point to anything
      // for(var k = 0; k < g.adj.length; k++) {
      //   if(g.adj[k].length === 0) {
      //     currNode = k
      //     tasksSorted = [tasks[currNode]]
      //     break
      //   }
      // }
      // // Now iteratively trace back to the starting node, starting with the last node.
      // for(var v = 0; v < tasks.length-1; v++) {
      //   for(var w = 0; w < g.adj.length; w++) {
      //     if(g.adj[w][0] === currNode) {
      //       currNode = w
      //       tasksSorted.unshift(tasks[currNode])
      //       break
      //     }
      //   }
      // }
      // return tasksSorted
  } 
}

