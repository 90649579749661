import React from "react"
import { useSelector, useDispatch } from "react-redux"
import ScheduleItem from "./ScheduleItem"
import { scheduleHover } from "state_management"
import dragTypes from "utils/scripts/dragTypes"

export default function ScheduleWhiteTask({ taskId, shouldShowState, style, onClick }) {
  const dispatch = useDispatch()
  const task = useSelector((state) => state.whiteTasks[taskId])
  const isActive = useSelector((state) => state.scheduleHover.whiteTaskId === taskId)
  const constraintChecks = useSelector((state) => state.constraintChecks.overBookedWhiteTasks[taskId])

  return (
    <ScheduleItem
      item={task}
      backgroundColor={task.fromOutlook ? "#aaaaaa" : "#eeeeee"}
      shouldShowState={shouldShowState}
      style={style}
      isActive={isActive}
      onMouseEnter={() => dispatch(scheduleHover.setWhiteTaskId(taskId))}
      onMouseLeave={() => dispatch(scheduleHover.setWhiteTaskId(null))}
      onClick={onClick}
      dragType={dragTypes.WHITE_TASK}
      name={task.name}
      description={task.description}
      state={task.state}
      constraintChecks={constraintChecks}
    />
  )
}
