import React from "react"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import Button from "react-bootstrap/Button"
import "./index.scss"
import { ScheduleButtons } from "components"

function NavItem({ url, children }) {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Button variant={location.pathname.startsWith(url) ? "primary" : "light"} onClick={() => navigate(url)}>
      {children}
    </Button>
  )
}

export default function AdminHeader() {
  const location = useLocation()
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const isMember = useSelector((state) => state.auth.isMember)
  const memberId = useSelector((state) => state.auth.memberId)
  const teamName = useSelector((state) => state.teamInfo.teamName)
  const teamLocation = useSelector((state) => state.teamInfo.location)
  const creatingOrEditingProject = location.pathname.includes("/setup/projects/")

  let dropDownOptions = []
  if (showProjects) {
    dropDownOptions.push({ url: "/scrum-view", name: "Scrum View" })
  }
  dropDownOptions.push({ url: "/", name: "Team Plan" })
  if (showProjects) {
    dropDownOptions.push({ url: "/project-view", name: "Project View" })
  }
  dropDownOptions.push({ url: "/asset-view", name: "Asset View" })
  if (showAnalyses) {
    dropDownOptions.push({ url: "/sample-view", name: "Sample View" })
  }
  dropDownOptions.push({ url: `/member/${memberId}`, name: "Member Plan" })

  return (
    <>
      <ScheduleButtons type="settings-view" />
      {!isMember && !creatingOrEditingProject && (
        <div className="AdminHeader">
          <div className="TeamLocation">
            <p>Team: {teamName}</p>
            <p>Location: {teamLocation}</p>
          </div>
          <div className="AdminHeader-nav">
            {<NavItem url="/setup/members">Team</NavItem>}
            {<NavItem url="/setup/assets">Assets</NavItem>}
            {(showProjects || showAnalyses) && <NavItem url="/setup/competence-groups">Competence Groups</NavItem>}
            {showAnalyses && !isMember && <NavItem url="/setup/analyses">Analyses</NavItem>}
            {showProjects && !isMember && <NavItem url="/setup/project-templates">Project Templates</NavItem>}
            {<NavItem url="/setup/integration">Integration</NavItem>}
            {!isMember && <NavItem url="/setup/simulation">Advanced</NavItem>}
          </div>
        </div>
      )}


      {/*
        <div className="DropDown">

        <DropdownButton
          title={
            dropDownOptions.find((el) => el.url === location.pathname)
              ? dropDownOptions.find((el) => el.url === location.pathname).name
              : "Views"
          }
          onSelect={(view) => history.push(view)}
        >
          {dropDownOptions.map((view) => {
            return (
              <Dropdown.Item key={view.url} active={view === location.pathname} eventKey={view.url}>
                {view.name}
              </Dropdown.Item>
            )
          })}
        </DropdownButton>
      </div>
        */}

    </>
  )
}
