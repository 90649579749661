import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { analyses, projectActions, message } from "state_management"

export default function LockUnlockButtons() {
  const dispatch = useDispatch()
  const IAPmode = useSelector((state) => state.visual.IAPconfig).IAPmode
  return (
    <div className="lockUnlock-btns">
      <Button
        title={
          IAPmode === "analysis"
            ? "Unlocks all future planned analysis tasks"
            : IAPmode === "project"
            ? "Unlocks all future planned project actions"
            : "Unlocks"
        }
        className="lockUnlock-btn"
        variant="light"
        onClick={() => {
          if (IAPmode === "analysis") dispatch(analyses.unlockAll())
          else if (IAPmode === "project") dispatch(projectActions.unlockAll())
          else {
            dispatch(message.warning("No IAP mode selected. Please select a mode."))
          }
        }}
      >
        Unlock {IAPmode === "analysis" ? "tasks" : IAPmode === "project" ? "actions" : ""}
      </Button>
      <Button
        title={
          IAPmode === "analysis"
            ? "Locks all future planned analysis tasks"
            : IAPmode === "project"
            ? "Locks all future planned project actions"
            : "Locks"
        }
        className="lockUnlock-btn"
        variant="dark"
        onClick={() => {
          if (IAPmode === "analysis") dispatch(analyses.lockAll())
          else if (IAPmode === "project") dispatch(projectActions.lockAll())
          else {
            dispatch(message.warning("No IAP mode selected. Please select a mode."))
          }
        }}
      >
        Lock {IAPmode === "analysis" ? "tasks" : IAPmode === "project" ? "actions" : ""}
      </Button>
    </div>
  )
}
