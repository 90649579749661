import { useDispatch, useSelector } from "react-redux"
import { Button } from "react-bootstrap"

import { teamStatus, waiting, samples } from "state_management"

export default function PriorityButtons({iapMode, isAnalyses, isMixed}) {
  const dispatch = useDispatch()
  const priorityMode = useSelector((state) => state.teamStatus.sortingParameter)
  return (
    <>
      <div className="RightMenuHeader-nav">
        <Button
          title="Prioritize IAP by Deadline"
          variant={priorityMode === "DEADLINE" ? "primary" : "light"}
          className="ButtonAnalysis"
          onClick={() => {
            const newPriorityMode = "DEADLINE";
            // Existing logic to handle priority change
            isAnalyses || (isMixed && iapMode === "analysis")
              ? dispatch(teamStatus.changeIAPPriority({ sortingParameter: newPriorityMode })).then(() => {
                  dispatch(waiting.changeSampleAllocating(true));
                  dispatch(samples.runSampleAllocation()).finally(() => dispatch(waiting.changeSampleAllocating(false)));
                })
              : dispatch(teamStatus.changeIAPPriority({ sortingParameter: newPriorityMode }));
          }}
        >
          Deadline
        </Button>
        <Button
          title="Prioritize IAP by Priority Number"
          variant={priorityMode === "PRIORITY" ? "primary" : "light"}
          className="ButtonProject"
          onClick={() => {
            const newPriorityMode = "PRIORITY";
            // Existing logic to handle priority change
            isAnalyses || (isMixed && iapMode === "analysis")
              ? dispatch(teamStatus.changeIAPPriority({ sortingParameter: newPriorityMode })).then(() => {
                  dispatch(waiting.changeSampleAllocating(true));
                  dispatch(samples.runSampleAllocation()).finally(() => dispatch(waiting.changeSampleAllocating(false)));
                })
              : dispatch(teamStatus.changeIAPPriority({ sortingParameter: newPriorityMode }));
          }}
        >
          Priority
        </Button>
      </div>
    </>
  )
}
