import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import ScheduleItem from "./ScheduleItem"
import { analyses, scheduleHover, message } from "state_management"
import dragTypes from "utils/scripts/dragTypes"

export default function ScheduleAnalysisTask({ taskId, shouldShowState, style, onClick, shouldShowSampleCount, isAltPressed, showIcons }) {
  const dispatch = useDispatch()
  const assets = useSelector((state) => state.asset.assets)
  const task = useSelector((state) => state.analysisTasks[taskId])
  // Note: When the IAP runs, or you move an analysis into the plan, and the
  // `analyses.fetchPlanned` request completes after the `analysisTasks.fetch`,
  // the analysis might not be available!
  // TODO: Fix this!
  const analysis = useSelector((state) => state.analyses.plannedAnalyses[task.analysisId]) || {
    color: "#ffffff",
    id: null,
    name: "Analysis",
    movable: true,
    isStarted: false,
  }
  const isActive = useSelector((state) => state.scheduleHover.analysisId === task.analysisId)
  const constraintChecks = useSelector((state) => state.constraintChecks[taskId]) || []

  const assetNames = useMemo(() => {
    return task.assetRequirements.map((req) => {
      if (req.assetId && assets[req.assetId]) {
        return assets[req.assetId].name;
      }
      return "";
    });
  }, [task.assetRequirements, assets]);

  return (
    <ScheduleItem
      item={task}
      backgroundColor={analysis.color}
      shouldShowState={shouldShowState}
      style={style}
      isActive={isActive}
      onMouseEnter={() => dispatch(scheduleHover.setAnalysisId(task.analysisId))}
      onMouseLeave={() => dispatch(scheduleHover.setAnalysisId(null))}
      onClick={onClick}
      dragType={isAltPressed ? dragTypes.SMART_DRAG : dragTypes.ANALYSIS_TASK}
      name={`${analysis.name}: ${task.name}`}
      description={task.description}
      state={task.state}
      constraintChecks={constraintChecks}
      movable={analysis.movable && !analysis.isStarted}
      setMovable={(movable) => {
        if (analysis.isStarted && movable) {
          const msg = "You can't unlock this since one or more tasks are started/paused/finished"
          dispatch(message.warning(msg))
          return
        } else {
          dispatch(analyses.setMovable(analysis.id, movable))
        }
      }}
      assetName={assetNames}
      sampleCount={analysis?.sampleCount}
      shouldShowSampleCount={shouldShowSampleCount}
      showIcons={showIcons}
    />
  )
}
