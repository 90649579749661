import React from "react"
import DropdownButton from "react-bootstrap/DropdownButton"
import Dropdown from "react-bootstrap/Dropdown"
import { useNavigate, useLocation } from "react-router-dom"

export default function WeekDropDown({ memberId }) {
  const dropDownOptions = [
    { url: `/member/${memberId}`, name: "Week View" },
    { url: `/member/${memberId}/day`, name: "Day View" },
  ]
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <DropdownButton
      title={dropDownOptions.find((el) => el.url === location.pathname).name || "Screen"}
      onSelect={(view) => navigate(view)}
    >
      {dropDownOptions.map((view) => {
        return (
          <Dropdown.Item key={view.url} active={view === location.pathname} eventKey={view.url}>
            {view.name}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
