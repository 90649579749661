import React from "react"
import FormControl from "react-bootstrap/FormControl"

export default function Text({ disabled, required, type, large = false, placeholder, value, onChange, onValidate, textTransform=null, rows = 8 }) {
  // TODO: Find a way to only call onValidate when the form is submitted
  // TODO: The way we get a ref to the node here, and set it's validity, might also be wrong?

  const handleBlur = (event) => {
    if (textTransform === "uppercase") {
      onChange(event.target.value.toUpperCase())
    } else if (textTransform === "lowercase") {
      onChange(event.target.value.toLowerCase())
    }
  }

  return (
    <FormControl
      ref={onValidate ? (node) => node && node.setCustomValidity(onValidate(node.value) || "") : undefined}
      disabled={disabled}
      required={required}
      as={large ? "textarea" : undefined}
      type={type}
      placeholder={placeholder}
      value={onChange ? value || "" : undefined}
      defaultValue={onChange ? undefined : value || ""}
      onChange={onChange ? (event) => onChange(event.target.value) : undefined}
      rows={rows}
      onBlur={handleBlur} // Fires when the element has lost focus
    />
  )
}
