import React, { useState, useEffect } from "react"
import { useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import { ListGroup } from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { FiFolder, FiFile, FiInfo, FiCheck } from "react-icons/fi"
import { request } from "utils/request"
import UploadDisplay from "./UploadDisplay"
import { Loading, Popup } from "components"
import { locations, repositories as repositoriesState, message } from "state_management"

import "./index.scss"
import { updateHelpRepository } from "state_management/librarian"

export default function RepositoryScreen() {
  const { repositoryId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()

  const [files, setFiles] = useState([])
  const [fileUploadStatus, setFileUploadStatus] = useState({ rejectedFiles: [], failedFiles: [] })
  const [resultMessage, setResultMessage] = useState(null)

  const companyId = useSelector((state) => state.auth.companyId)
  const { isLoadingGlobalRepositories, globalRepositories, hasFetched, repositories } = useSelector(
    (state) => state.repositories
  )
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const repository = [...repositories, ...globalRepositories].find((repository) => repository.id === repositoryId)
  const showProcessing = repository.promptPrefix !== '' && Object.keys(repository.scrapers).length > 0;

  const isCustomerAdmin = useSelector((state) => state.auth.role === "customer_admin")
  const isGlobal = location.pathname.includes("/customers/global-repositories/")
  const [isFirst, setIsFirst] = useState(true)

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
      dispatch(repositoriesState.fetchRepository(repositoryId))
    }
  }, [dispatch, isFirst, repositoryId])

  if (!hasFetched && !repository) {
    dispatch(locations.fetch(companyId))
    dispatch(repositoriesState.fetch())
    if (isCustomerAdmin) {
      dispatch(repositoriesState.fetchGlobal())
    }
    return <Loading />
  }

  if (!repository || isLoadingGlobalRepositories) {
    return <Loading />
  }

  function UploadDropzone({ files, onSubmit }) {
    const uniqueDirectories = [
      ...new Set(
        files.map((file) => {
          const curFile = file.path.split("/")
          if (curFile.length > 1) {
            return curFile[1]
          } else {
            return curFile[0]
          }
        })
      ),
    ]

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: (acceptedFiles, rejectedFiles) => {
        // Check file types of all accepted files
        acceptedFiles = acceptedFiles.filter((file) => {
          const fileType = file.type
          if (
            fileType !== "text/plain" &&
            fileType !== "application/msword" &&
            fileType !== "application/pdf" &&
            fileType !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            rejectedFiles.push(file)
            return false
          }
          return true
        })

        let foundIndexes = []
        var trimmedAcceptedFiles = acceptedFiles
        let trimmedFiles = files.map((a) => {
          let idx = acceptedFiles.findIndex((b) => b.name === a.name)
          if (idx === -1) {
            return a
          } else {
            foundIndexes.push(idx)
            return acceptedFiles[idx]
          }
        })
        foundIndexes = foundIndexes.sort((a, b) => {
          return a < b ? -1 : a > b ? 1 : 0
        })
        for (var i = foundIndexes.length - 1; i >= 0; i--) {
          trimmedAcceptedFiles.splice(foundIndexes[i], 1)
        }
        const updatedFiles = { acceptedFiles: [...trimmedFiles, ...trimmedAcceptedFiles] }
        const rejectedFilesToAdd = rejectedFiles.filter((file1) => {
          return !fileUploadStatus.rejectedFiles.some((file2) => file2.name === file1.name)
        })

        setFileUploadStatus({
          ...fileUploadStatus,
          rejectedFiles: [...fileUploadStatus.rejectedFiles, ...rejectedFilesToAdd],
        })
        onSubmit(updatedFiles.acceptedFiles)
      },
    })

    const differentFileTypes = [".pdf", ".txt", ".docx"]
    return (
      <div {...getRootProps({ className: "repository-upload-box" })}>
        <input {...getInputProps()} />
        <div>
          {files.length ? <p>Selected files/folders ready for submit:</p> : null}
          <ul>
            {files.length
              ? uniqueDirectories.map((uniqueDirectory) => (
                <li key={uniqueDirectory} className="UploadDropzone-repository-files">
                  {!differentFileTypes.some((fileType) => uniqueDirectory.includes(fileType)) ? (
                    <FiFolder />
                  ) : (
                    <FiFile />
                  )}
                  <span>{uniqueDirectory}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      const newFiles = files.filter((file) => {
                        const directory = file.path.split("/")
                        // if directory length is greater than 1, it means it is a folder and we will therefore only filter for 'root' folder name
                        // so as to not to delete unwanted files
                        if (directory.length > 1) {
                          return directory[1] !== uniqueDirectory
                          // If not a folder, we will filter for the file name
                        } else {
                          return !file.path.includes(uniqueDirectory)
                        }
                      })
                      onSubmit(newFiles)
                    }}
                    className="UploadDropzone-remove-file"
                    title="delete"
                    aria-label="Close"
                    type="button"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </li>
              ))
              : null}{" "}
          </ul>
        </div>
        <p>
          {isDragActive
            ? "Drop the folders here..."
            : files.length
            ? "Click to select other files"
            : "Drag 'n' drop folders here, or click to select files"}
        </p>
      </div>
    )
  }

  function UploadButton({ files, setFiles, setResultMessage }) {
    const dispatch = useDispatch()

    const uploadFilesToS3 = async (files, presignedData) => {
      let failedFiles = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const url = presignedData[i]["url"]
        const data = presignedData[i]["fields"]

        // Create a new FormData instance
        const formData = new FormData()

        const newProgress = Math.round(((i + 1) / files.length) * 100)
        // Only re-render when there is a visible change in progress
        if (newProgress > progress) {
          setProgress(newProgress)
        }

        // Append the file to the FormData instance
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key])
        })
        formData.append("file", file)

        // Make a POST request to the presigned URL
        const uploadResponse = await fetch(url, { method: "POST", body: formData })

        if (!uploadResponse.ok) {
          failedFiles.push(`Upload failed for file: ${file.path}`)
        }
      }
      return failedFiles
    }

    async function getPresignedPostData() {
      const input = {
        filePaths: files.map((file) => file.path),
      }

      try {
        const res = await request("POST", `/librarian/bucket/get-upload-url/${repositoryId}`, { input })
        return res.presignedPosts
      } catch (e) {
        dispatch(message.error("An Error Occurred", e))
      }
    }

    const onSubmit = async () => {
      if (isLoading) return
      if (files.length === 0) {
        dispatch(message.warning("Please select at least one file before submitting"))
        return
      }

      setIsLoading(true)
      let failedFiles = []
      try {
        const presignedData = await getPresignedPostData() // Get presigned POST data from backend
        failedFiles = await uploadFilesToS3(files, presignedData) // Upload the files to S3
        dispatch(repositoriesState.fetchRepository(repositoryId))

        setResultMessage("Files uploaded successfully")
      } catch (e) {
        setResultMessage("An error occurred during upload files")
      }
      setFileUploadStatus({ ...fileUploadStatus, failedFiles: failedFiles })
      setIsLoading(false)
      setProgress(0)
    }

    return (
      <>
        <Button onClick={onSubmit} disabled={isLoading} variant="success">
          {isLoading ? <Spinner animation="grow" size="sm" /> : ""}
          {isLoading ? ` ${progress}%` : "Submit"}
        </Button>
      </>
    )
  }

  const isProcessing = repository.isProcessing
  const hasUnprocessedFiles = repository.unprocessedFiles
  const isDoneProcessing = !isProcessing && repository.isProcessed
  if (repository.name === "PlannerTech Help") {
    return (
      <div className="help-repository">
      <p className="repository-company-headline">{repository.name}</p>
        <div className="update-button">
          <Button onClick={() =>
            dispatch(updateHelpRepository(repositoryId))
              .then(dispatch(message.success("Help page updated")))}
            disabled={isLoading}
            variant="success">
            {isLoading ? <Spinner animation="grow" size="sm" /> : ""}
            {isLoading ? ` ${progress}%` : "Update Help Page"}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="upload-container">
      <p className="repository-company-headline">{repository.name}</p>
      <UploadDropzone files={files} onSubmit={setFiles} />
      <div className="repository-buttons">
        <div className="repository-upload-buttons">
          <UploadButton files={files} setFiles={setFiles} setResultMessage={setResultMessage} />
          {(fileUploadStatus.rejectedFiles.length > 0 || files.length > 0) && (
            <Button
              variant="secondary"
              onClick={() => {
                setFiles([])
                setFileUploadStatus({ rejectedFiles: [], failedFiles: [] })
              }}
            >
              Clear
            </Button>
          )}
        </div>

        {showProcessing &&  (
          <div className="repository-processing-button-container">
            {isDoneProcessing && <FiCheck className="repository-check-icon" title="This repository is processed" />}
            <div className="repository-processing-button">
              <Button
                variant="success"
                disabled={isProcessing || !hasUnprocessedFiles}
                onClick={() => dispatch(repositoriesState.startProcessing(repositoryId, isGlobal))}
              >
                {isProcessing ? <Spinner animation="border" size="sm" /> : ""}
                {isProcessing ? " Processing" : repository.isProcessed && !hasUnprocessedFiles ? "Processed" : "Process"}
              </Button>
            </div>
            <div
              className="repository-process-info-text"
              title={`${
                isProcessing
                  ? "The repository is currently processing the files. Please refresh the page to see the progress"
                  : hasUnprocessedFiles
                  ? "There are unprocessed files in this repository"
                  : isDoneProcessing
                  ? "All uploaded files have been processed"
                  : "This will start the processing of the repository. It may take a while depending on the size of the repository"
              }`}
            >
              <FiInfo />
            </div>
          </div>
        )}
      </div>

      {fileUploadStatus.rejectedFiles.length > 0 && (
        <div className="repository-upload-rejected-files">
          <h4>
            The following files were rejected due to incompatible formats
            <span className="repository-config-info">
              <FiInfo title="Only .docx, .pdf , .txt files are accepted." />
            </span>
          </h4>

          <div className="repository-upload-rejected-files-listgroup">
            <ListGroup>
              {fileUploadStatus.rejectedFiles.map((file) => (
                <ListGroup.Item variant="danger" key={file.name}>
                  {file.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      )}
      {resultMessage && (
        <Popup
          onCancel={() => {
            setResultMessage(null)
            setFileUploadStatus({ rejectedFiles: [], failedFiles: [] })
            setFiles([]) // Clear the files
          }}
          title={"Upload Finished!"}
          center
          className="repository-upload-popup"
        >
          <UploadDisplay
            files={files}
            failedFiles={fileUploadStatus.failedFiles}
            rejectedFiles={fileUploadStatus.rejectedFiles}
          />
        </Popup>
      )}
    </div>
  )
}
