import React, { useState } from "react"
import { useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text, TimezonePicker } from "components/Form"
import { locations } from "state_management"

// Location config used by admins.
export default function LocationConfig({ location, onClose }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(location ? location.name : "")
  const [timezone, setTimezone] = useState(location ? location.timezone : "Europe/Copenhagen")

  return (
    <ConfigContainer
      title={location ? "Edit location name" : "Add location"}
      deleteIsImportant
      deleteHandler={location ? () => dispatch(locations.remove(location.id)).then(onClose) : undefined}
      submitHandler={() => {
        const res = { name, timezone }
        if (location) {
          dispatch(locations.edit(location.id, res)).then(onClose)
        } else {
          dispatch(locations.create(res)).then(onClose)
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Location Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
      <Container>
        <Title>Timezone</Title>
        <TimezonePicker required value={timezone} onChange={setTimezone} nonMobileView/>
      </Container>
    </ConfigContainer>
  )
}
