import React, { useState } from "react"
import { useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import { Title, Container, Text } from "components/Form"
import { projectGroups } from "state_management"

// Configuration for project groups.
export default function ProjectGroupConfig({ projectGroup, onClose, onSubmit }) {
  const dispatch = useDispatch()
  const [name, setName] = useState(projectGroup ? projectGroup.name : "")

  return (
    <ConfigContainer
      title={projectGroup ? "Edit project group" : "Add an project group"}
      submitHandler={() => {
        setName(name.trim())
        const res = { name: name.trim() }
        if (projectGroup) {
          dispatch(projectGroups.edit(projectGroup.id, res)).then(onSubmit)
        } else {
          dispatch(projectGroups.create(res)).then(onSubmit)
        }
      }}
      cancelHandler={onClose}
      deleteHandler={projectGroup ? () => dispatch(projectGroups.remove(projectGroup.id)).then(onClose) : null}
    >
      <Container>
        <Title>Project group Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
    </ConfigContainer>
  )
}
