import React, { Fragment, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ScheduleItem, ScheduleButtons, Popup } from "components"
import { ActionConfig } from "components/Config"
import { getDates } from "utils/scripts/schedule"
import { DateTime } from "luxon"
import { projectActions } from "state_management"
import { Text } from "components/Form"
import { FiCheck } from "react-icons/fi"

import InputGroup from "react-bootstrap/InputGroup"
import ProjectConfigurationPage from "components/Config/ProjectConfigurationPage"

import "./index.scss"

export default function ProjectViewScreen() {
  const dispatch = useDispatch()

  const plannedActions = Object.values(useSelector((state) => state.projectActions.plannedProjectActions))
  const unplannedActions = Object.values(useSelector((state) => state.projectActions.unplannedProjectActions))
  const currentMemberInitials = useSelector((state) => state.members[state.auth.memberId].initials)
  const members = useSelector((state) => state.members)

  const scheduleMondayDate = useSelector((state) => state.visual.scheduleMondayDate)
  const mondayDateTime = DateTime.fromISO(scheduleMondayDate)
  // Set current date to match the weekday we're in
  //const currentDate = DateTime.fromISO(scheduleMondayDate).set({ weekday: DateTime.local().weekday }).startOf("day")
  const dates = getDates(mondayDateTime, true, false)

  const [activeActionId, setActiveActionId] = useState(null)
  const [filterString, setFilterString] = useState("")
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({bookedAssets: [], freeAssets: []})
  const [editProject, setEditProject] = useState(null)
  const [projectId, setProjectId] = useState(null)

  const activeAction = useSelector((state) => state.projectActions.plannedProjectActions[activeActionId])
  const relevantProjects = Object.values(useSelector((state) => state.projects))
    .filter(({ singleActionProject }) => {
      return !singleActionProject
    })
    .filter(({ name }) => {
      return filterString ? name.toUpperCase().match(filterString.toUpperCase()) : true
    })
    .filter(({ id }) => {
      const actions = plannedActions.filter(({ projectId }) => projectId === id)
      // Show "current" projects
      for (const action of actions) {
        if (mondayDateTime < DateTime.fromISO(action.start)) {
          return true
        }
      }
      // And projects with unplanned actions
      if (unplannedActions.find(({ projectId }) => projectId === id)) {
        return true
      }
      // And empty projects
      if (actions.length === 0) {
        return true
      }

      return false
    })
    .sort((a, b) => {
      const aFirstActionDate = plannedActions
        .filter(({ projectId }) => projectId === a.id)
        // The actions we look at are on the view
        .filter(
          ({ start, end }) =>
            dates[0].startOf("day") <= DateTime.fromISO(end) &&
            dates[dates.length - 1].endOf("day") >= DateTime.fromISO(start)
        )
        .map(({ start }) => DateTime.fromISO(start))
        .reduce((acc, current) => DateTime.min(acc, current), dates[dates.length - 1].endOf("day"))
      const bFirstActionDate = plannedActions
        .filter(({ projectId }) => projectId === b.id)
        // The actions we look at are on the view
        .filter(
          ({ start, end }) =>
            dates[0].startOf("day") <= DateTime.fromISO(end) &&
            dates[dates.length - 1].endOf("day") >= DateTime.fromISO(start)
        )
        .map(({ start }) => DateTime.fromISO(start))
        .reduce((acc, current) => DateTime.min(acc, current), dates[dates.length - 1].endOf("day"))
      // Sort projects with items today first, then with items tomorrow, and so on
      if (aFirstActionDate < bFirstActionDate) return -1
      if (aFirstActionDate > bFirstActionDate) return 1
      // And then alphabetically
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

  const cornerWidth = "15em"

  const dayWidths = dates.map((date) => {
    let maxActions = 1 // Ensure the header stays at least one action wide
    for (const project of relevantProjects) {
      const actions = plannedActions
        .filter(({ projectId }) => projectId === project.id)
        .filter(
          ({ start, end }) =>
            DateTime.fromISO(start).startOf("day") <= date && DateTime.fromISO(end).endOf("day") >= date
        )
      if (maxActions < actions.length) {
        maxActions = actions.length
      }
    }
    // Account for margin!
    return maxActions * 10.5 - 0.5 + "em"
  })

  return (
    <>
    {editProject && projectId && (
      <Popup
        className="Edit-Create-Project"
        onCancel={() => {
          setEditProject(false)
        }}
      >
        <ProjectConfigurationPage
          id={projectId}
          isCreateOrEditProject={true}
          setEditProject={() => {
            setEditProject()
          }}
        />
      </Popup>
    )}
    <div className="ProjectViewScreen">
      {activeActionId && (
        <Popup nonScrollable onCancel={() => setActiveActionId(null)}>
          <ActionConfig
            title="Edit Action"
            projectId={activeAction.projectId}
            action={activeAction}
            cancelHandler={() => setActiveActionId(null)}
            submitHandler={(res) =>
              dispatch(projectActions.editPlanned(activeActionId, res)).then(() => setActiveActionId(null))
            }
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      <ScheduleButtons type="project-view" />
      <div className="ProjectViewContent">
        <div className="ProjectViewHeader">
          <div className="ProjectViewCorner" style={{ width: cornerWidth }}>
            <h4>{currentMemberInitials}</h4>
            <p>Week {mondayDateTime.weekNumber}</p>
            <InputGroup className="ProjectViewFilterBar">
              <Text placeholder="Filter by Name" value={filterString} onChange={setFilterString} />
            </InputGroup>
          </div>
          {dates.map((date, idx) => (
            <div key={date.toISO()} className="ProjectViewDay" style={{ width: dayWidths[idx] }}>
              <h4>{date.toFormat("ccc")}</h4>
              <p>{date.toFormat("MMM d")}</p>
            </div>
          ))}
        </div>
        <div
          className="ProjectViewGrid"
          style={{
            // Project sidebar + day width
            gridTemplateColumns: `${cornerWidth} ${dayWidths.join(" ")}`,
            // Project height
            gridTemplateRows: `repeat(${relevantProjects.length}, auto)`,
          }}
        >
          {relevantProjects.map((project) => (
            <Fragment key={project.id}>
              <div
                className="ProjectViewProjectName"
                onClick={() => {
                  setEditProject(true)
                  setProjectId(project.id)
                }}
              >
                <h4>{project.name}</h4>
                {project.actions.every((action) => action.stage === "FINISHED") && (
                  <FiCheck size={"1.8em"} title="The project is done" />
                )}
              </div>
              {dates.map((date) => (
                <div className="ProjectViewItem" key={date.toISO()}>
                  {plannedActions
                    .filter(({ projectId }) => projectId === project.id)
                    .filter(({ memberIds }) => members[memberIds[0]] && members[memberIds[0]].showOnPlan)
                    .filter(
                      ({ start, end }) =>
                        DateTime.fromISO(start).hasSame(date, "day") && DateTime.fromISO(end).hasSame(date, "day")
                    )
                    .sort((a, b) => {
                      // Sort by start date/time
                      if (DateTime.fromISO(a.start) < DateTime.fromISO(b.start)) return -1
                      if (DateTime.fromISO(a.start) > DateTime.fromISO(b.start)) return 1
                      return 0
                    })
                    .map((action) => (
                      <ScheduleItem
                        item={action}
                        key={action.id}
                        isDraggable={false}
                        backgroundColor={project.color}
                        onClick={() => {
                          const foundAction = plannedActions.find(plannedAction => plannedAction.id === action.id)
                          dispatch(projectActions.getBookedFreeAssets(action.id, foundAction)).then(async (res) => {
                            setBookedAndFreeAssets(res)
                          })
                          setActiveActionId(action.id)
                        }}
                        name={action.name}
                        description={action.description}
                        state={action.state}
                      />
                    ))}
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  </>
  )
}
