import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { IoMdClose } from "react-icons/io"

import { message as messageState } from "state_management"

import "./index.scss"

// Displays a message from the message state.
export default function Message() {
  const dispatch = useDispatch()

  const messageData = useSelector((state) => state.message)
  const [currentMessageData, setCurrentMessageData] = useState(messageData)
  const [shouldShow, setShouldShow] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)

  // These hardcoded messages are in sync with BE. To make them more
  // versatile decide on a http status code that reflects the same properties
  // TODO: make a better way of doing this
  const doNotShowTheseMessages = [
    "Failed logging in: You need to verify your device with Multi-Factor Authentication",
    "Failed logging in: You are logged in somewhere else",
    "You are logged in somewhere else",
    "DoNotShowError",
    "Could not fetch status: Do Not Show Error",
    "Do Not Show Error",
  ]
  const messageDoesNotIncludeBannedMessages = !doNotShowTheseMessages.includes(currentMessageData.message)

  // Set new message data as soon as possible, and clear the redux state. This
  // is done to allow new messages to arrive even before this is done showing.
  // Additionally, if the component goes out of view and into view again (such
  // as when logging out), the message is discarded - which is desired!
  useEffect(() => {
    if (messageData.message || messageData.time || messageData.variant) {
      setCurrentMessageData(messageData)
      setShouldShow(true)
      setShouldRender(true)
      dispatch(messageState.clear())
    }
  }, [dispatch, messageData])

  // The `time` variable determines how long after to fade the component out.
  useEffect(() => {
    if (shouldShow) {
      const timer = window.setTimeout(() => {
        setShouldShow(false)
      }, currentMessageData.time)
      return () => {
        window.clearTimeout(timer)
      }
    }
    // We specify a dependency on `currentMessageData` and not just `time`, so
    // that `useEffect` is run when the entires message state changes!
  }, [dispatch, shouldShow, currentMessageData])

  // When `fadeOut`` is done, hide the entire component, so it doesn't prevent
  // clicking things under it.
  const onAnimationEnd = () => {
    if (!shouldShow) {
      setShouldRender(false)
    }
  }

  return (
    shouldRender &&
    messageDoesNotIncludeBannedMessages && (
      // Use bootstrap "alert" styles to get `color` and `background-color`
      <div
        className={`Message alert-${currentMessageData.variant}`}
        style={{ animation: `${shouldShow ? "fadeIn" : "fadeOut"} 1s forwards` }}
        onAnimationEnd={onAnimationEnd}
      >
        <IoMdClose className="Message-cancel" onClick={() => setShouldShow(false)} title="close message" />
        <p>{currentMessageData.message}</p>
      </div>
    )
  )
}
