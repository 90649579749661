import React from "react"
import { ScheduleButtons, ErrorBoundary } from "components"
import SampleView from "./SampleView"
import "./index.scss"

export default function SampleViewScreen() {
  return (
    <div className="SampleViewScreen">
      <ScheduleButtons type="sample-view" />
      <ErrorBoundary>
        <SampleView />
      </ErrorBoundary>
    </div>
  )
}
