import React from "react"
import { useDropzone } from "react-dropzone"
import "./index.scss"

export default function UploadDropzone({ files, onSubmit }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      // The below is done to allow a user to reselect the same file
      let foundIndexes = []
      var trimmedAcceptedFiles = acceptedFiles
      let trimmedFiles = files.map((a) => {
        let idx = acceptedFiles.findIndex((b) => b.name === a.name)
        if (idx === -1) {
          return a
        } else {
          foundIndexes.push(idx)
          return acceptedFiles[idx]
        }
      })
      foundIndexes = foundIndexes.sort((a, b) => {
        return a < b ? -1 : a > b ? 1 : 0
      })
      for (var i = foundIndexes.length - 1; i >= 0; i--) {
        trimmedAcceptedFiles.splice(foundIndexes[i], 1)
      }
      const updatedFiles = { files: [...trimmedFiles, ...trimmedAcceptedFiles] }
      onSubmit(updatedFiles.files)
    },
  })

  return (
    <div {...getRootProps({ className: "upload-box" })}>
      <input {...getInputProps()} />
      {files.length ? <p>Selected files ready for submit:</p> : null}
      <ul>
        {files.length
          ? files.map((file) => (
              <li key={file.name} className="UploadDropzone-integration-files">
                <span>{file.name}</span>
                <button onClick={(e) => {
                  const newFiles = files.filter( (ele) => file !== ele )
                  onSubmit(newFiles); 
                  e.stopPropagation()
                }} className="UploadDropzone-remove-file" title="delete" aria-label="Close" type="button">
                  <span aria-hidden="true">&times;</span>
                </button>
              </li>
            ))
          : null}{" "}
      </ul>
      <p>
        {isDragActive
          ? "Drop the files here..."
          : files.length
          ? "Click to select other files"
          : "Drag 'n' drop files here, or click to select files"}
      </p>
    </div>
  )
}
