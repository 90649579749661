import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
    conversationList: [],
    latestAnswer: "",
    isFetching: false,
    status: "initial",
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case "chatBot/getAnswer/BEGIN":
            return { ...state, isFetching: true, status: "begun" }
        case "chatBot/getAnswer/ERROR":
            return { ...state, isFetching: false, status: "failed" }
        case "chatBot/getAnswer":
            return { ...state, conversationList: action.res.conversation, latestAnswer: action.res.answer.message, isFetching: false, status: "finished" }
        case "chatBot/refreshChat":
        case "loading/fetchAll":
        case "auth/login":
        case "auth/verifyMfaToken":
        case "auth/logout":
            return initialState
        default:
            return state
    }
}

export const getAnswer = asyncAction("chatBot/getAnswer", (dispatch, conversation, repositoryId, isAdmin = false) => {
  const conv = conversation.length >= 10 ? conversation.slice(2) : conversation
  if (isAdmin) {
    return request("POST", `/librarian/admin-chat/${repositoryId}`, { input: { conversation: conv } }).then((res) => {
      return { ...res, conversation: [...conv, res.answer] }
    })
  } else {
      return request("POST", `/librarian/chat/${repositoryId}`, { input: { conversation: conv } }).then((res) => {
        return { ...res, conversation: [...conv, res.answer] }
      })
  }
})

export function refreshChat() {
    return { type: "chatBot/refreshChat" }
}