import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction } from "./common"

const initialState = {
  activeProjectGroupId: null,
  groups: {}
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "projectGroups/fetch":
      return {...state, groups: createMap(action.res)}
    case "projectGroups/create":
      const newState = {...state, activeProjectGroupId: action.res.id}
      return  {...newState, groups: addToMap(newState.groups, action.res)}
    case "projectGroups/edit":
      return {...state, groups: replaceInMap(state.groups, action.res)}
    case "projectGroups/remove":
      const tmpState = (state.activeProjectGroupId === action.res.id ? {...state, activeProjectGroupId: null} : state)
      return {...tmpState, groups: removeFromMap(state.groups, action.res)}
    case "projectGroups/changeActiveProjectGroupId":
      return {...state, activeProjectGroupId: action.id}
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("projectGroups/fetch", (dispatch) => {
  return request("GET", "/project-groups").then((res) => res.items)
})

export const remove = asyncAction("projectGroups/remove", (dispatch, id) => {
  return request("DELETE", `/project-group/${id}`).then(() => ({ id }))
})

export const create = asyncAction("projectGroups/create", (dispatch, input) => {
  return request("POST", `/project-groups`, { input }).then((res) => res.group)
})

export const edit = asyncAction("projectGroups/edit", (dispatch, id, patch) => {
  return request("PATCH", `/project-group/${id}`, { patch }).then((res) => res.group)
})

export function changeActiveProjectGroupId(id) {
  return { type: "projectGroups/changeActiveProjectGroupId", id }
}