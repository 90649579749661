import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "competenceGroups/fetch":
      return createMap(action.res)
    case "competenceGroups/create":
      return addToMap(state, action.res)
    case "competenceGroups/edit":
      return replaceInMap(state, action.res)
    case "competenceGroups/remove":
      return removeFromMap(state, action.res)
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("competenceGroups/fetch", (dispatch) => {
  return request("GET", "/groups").then((res) => res.items)
})

export const remove = asyncAction("competenceGroups/remove", (dispatch, id) => {
  return request("DELETE", `/group/${id}`).then(() => ({ id }))
})

export const create = asyncAction("competenceGroups/create", (dispatch, input) => {
  return request("POST", `/groups`, { input }).then((res) => res.group)
})

export const edit = asyncAction("competenceGroups/edit", (dispatch, id, patch) => {
  return request("PATCH", `/group/${id}`, { patch }).then((res) => res.group)
})
