import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  hasFetched: false,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "standardActions/fetch":
      return {
        ...state,
        hasFetched: true,
        standardActions: action.res.items,
      };
    case "standardActions/edit":
      return {
        ...state,
        standardActions: state.standardActions.map((task) => {
          if (task.id === action.res.project.id) {
            return action.res.project
          }
          return task
        }),
      }
    case "standardActions/delete":
      return {
        ...state,
        standardActions: state.standardActions.filter((task) => {
          return task.actions[0].id !== action.res
        }),
      }
    case "standardActions/create":
      return {
        ...state,
        standardActions: state.standardActions.concat(action.res.action),
      }
    default:
      return state
  }
}

export const fetchStandardActions = asyncAction("standardActions/fetch", (dispatch) => {
  return request("GET", `/action/standard-action`).then((res) => {
    return res
  })
})

export const createStandardActions = asyncAction("standardActions/create", (dispatch, input) => {
  return request("POST", `/action/standard-action`, { input }).then(async (res) => {
    return res
  })
})

export const editStandardAction = asyncAction("standardActions/edit", (dispatch, id, patch) => {
  return request("PATCH", `/action/standard-action/${id}`, { patch }).then((res) => {
    return res
  })
})

export const deleteStandardAction = asyncAction("standardActions/delete", (dispatch, id ) => {
  return request("DELETE", `/action/standard-action/${id}`).then(() => {
    return id
  })
})