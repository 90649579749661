import { Dropdown, InputGroup } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { FiLogOut, FiHome, FiSettings } from "react-icons/fi"
import { HiOutlinePencilAlt } from "react-icons/hi";

import { auth, teamStatus, librarian } from "state_management"
import isOnMobile from "utils/scripts/isOnMobile"
import { ConfigContainer, Popup } from "components"
import { Container, Select, Title} from "components/Form"
import { languages } from "utils/scripts/languages"
import { Button } from "react-bootstrap"


export const Toolbar = ({ toggleLibrarianHistory }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const isOnlyLibrarian = useSelector((state) => state.auth.isOnlyLibrarian)
  const conversation = useSelector((state) => state.librarian.conversation)
  const selectedRepository = useSelector((state) => state.librarian.selectedRepository)
  const repositories = useSelector((state) => state.librarian.availableRepositories)
  const language = useSelector((state) => state.auth.language ? [state.auth.language] : [])
  const [initialLanguage, setInitialLanguage] = useState(null);

  const [showPersonalSettings, setShowPersonalSettings] = useState(false)

  useEffect(() => {
    if (language[0] && initialLanguage === null) {
      setInitialLanguage(language[0]);
    }
  }, [language, initialLanguage]);

  const handleLanguageChange = (item) => {
    dispatch(auth.setLanguage(item[0]))
  }

  return (
    <div className="chat-messages-toolbar">
      {showPersonalSettings && (
        <Popup
          onCancel={() => {
            setShowPersonalSettings(false)
            if (initialLanguage !== null) {
              dispatch(auth.setLanguage(initialLanguage))
            }
          }}
        >
          <ConfigContainer
            title="Personal Settings for QC Librarian"
            submitHandler={() => {
              setShowPersonalSettings(false)
              setInitialLanguage(language[0])
            }}
            cancelHandler={() => {
              setShowPersonalSettings(false)
              dispatch(auth.setLanguage(initialLanguage))
            }}
          >
            <Container>
              <InputGroup>
                <Title>Speech-to-text language</Title>
                <Select
                  isMulti={false}
                  required={true}
                  disabled={false}
                  collection={languages}
                  value={language}
                  onChange={handleLanguageChange}
                  menuPosition="fixed"
                />
              </InputGroup>
            </Container>

          </ConfigContainer>
        </Popup>
      )}
      {/* <div className="chat-messages-toolbar-dropdown">
                <Dropdown
                    onSelect={(eventKey) => {
                        dispatch(librarian.setTemplate(eventKey))
                    }}
                >
                    <Dropdown.Toggle variant="primary" disabled={conversationState.isFetching}>{template === "" ? "Select template" : template}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="Declaration of Independence">Declaration of Independence</Dropdown.Item>
                        <Dropdown.Item eventKey="Area 51 documents">Area 51 documents</Dropdown.Item>
                        <Dropdown.Item eventKey="USSR Nuclear Codes">USSR Nuclear Codes</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div> */}
      {isOnMobile && (
        <Button variant="primary" className="burger-menu" onClick={() => {dispatch(librarian.refreshChat())}}>
            <HiOutlinePencilAlt title={"History"} />
        </Button>
      )}
      <div className={`chat-messages-toolbar-dropdown${isOnMobile ? "-mobile" : ""}`}>
        <Dropdown
          onSelect={(eventKey) => {
            if (conversation.length > 0) {
              dispatch(librarian.refreshChat())
            }
            dispatch(librarian.setRepository(repositories.find((repository) => repository.id === eventKey)))
          }}
        >
          <Dropdown.Toggle variant="primary">
            {selectedRepository.name === "" ? "Select repository" : selectedRepository.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {repositories.length > 0 &&
              repositories.map((repo) => (
                <Dropdown.Item key={repo.id} eventKey={repo.id}>
                  {repo.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="Librarian-toolbar-buttons">
        {!isOnlyLibrarian && (
          <Button variant="primary" onClick={() => navigate(isOnMobile ? "/navigate" : "/suite")}>
            <FiHome title={"Home"} />
          </Button>
        )}
        <Button variant="primary" onClick={() => setShowPersonalSettings(true)}>
          <FiSettings title={"Personal settings"}/>
        </Button>
        {!isOnMobile && (<Button
          variant="primary"
          onClick={() => {
            window.open(
              "https://arnvind.com/librarian-help/?pass=e8ac673f3af62a7fa4f2ef64bb1482d38dc5353d8a73e9ab7993a788ce03894c"
            )
          }}
          title="Help"
        >
          ?
        </Button>
        )}
        <Button
          variant="primary"
          onClick={() => {
            if (isInPlanningMode && isCurrentPlanner) {
              dispatch(teamStatus.showLogoutConfirm(true))
            } else {
              dispatch(auth.logout()).then(() => navigate("/login"))
            }
          }}
          title={"Logout"}
        >
          <FiLogOut />
        </Button>
      </div>
    </div>
  )
}
