// import { ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { visual } from "state_management"

export default function ModeToggleButtons() {
  const dispatch = useDispatch()
  const IAPconfig = useSelector((state) => state.visual.IAPconfig)
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const activeRightMenuTab = useSelector((state) => state.visual.activeRightMenuTab)

  const showButtons = showProjects && showAnalyses
  const isProject = !showAnalyses || (activeRightMenuTab === "Project" && showProjects)

  return (
      <div className="RightMenuHeader-nav">
        {showButtons && (
          <Button
            variant={isProject ? "light" : "primary"}
            className="ButtonAnalysis"
            onClick={() => {
              if (IAPconfig.IAPmode === "project") {
                dispatch(visual.setActiveRightMenuTab("Analysis"))
                dispatch(visual.setIAPconfiguration({ ...IAPconfig, IAPmode: "analysis" }))
              }
            }}
          >
            Analysis
          </Button>
        )}
        {showButtons && (
          <Button
            variant={isProject ? "primary" : "light"}
            className="ButtonProject"
            onClick={() => {
              if (IAPconfig.IAPmode === "analysis") {
                dispatch(visual.setActiveRightMenuTab("Project"))
                dispatch(visual.setIAPconfiguration({ ...IAPconfig, IAPmode: "project" }))
              }
            }}
          >
            Project
          </Button>
        )}
      </div>
  )
}
