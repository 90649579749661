import React from "react"
import ReactSelect from "react-select"

const parentColumnPicker = [
  { label: <>Backlog</>, value: 'BACKLOG'},
  { label: <>To Do</>, value: 'TODO'},
  { label: <>Planned</>, value: 'PLANNED'},
  { label: <>Finished</>, value:'FINISHED'},

]

export default function ScrumColumnPicker({ value, onChange }) {
  const columnState = parentColumnPicker.find((item) => item.value === value)
  if (!columnState) {
    throw new Error("LocationPicker received an invalid value")
  }
  return (
    <ReactSelect
      className="TaskStatePicker"
      classNamePrefix="TaskStatePicker"
      placeholder="Select location..."
      isClearable={false}
      isDisabled={false}
      isSearchable={false}
      options={parentColumnPicker}
      onChange={({ value }) => onChange(value)}
      value={columnState}
      menuPosition="fixed"
    />
  )
}
