import { useEffect } from "react"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"

// Helper React-hook to use dragging in our application
export default function useCustomDrag(type, item) {
  const [{ isDragging }, drag, preview] = useDrag( () => ({
    type,
    item: { type, item },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  }), [type, item])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview, type, item, isDragging, drag])

  return { isDragging, dragRef: drag }
}
