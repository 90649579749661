import Button from "react-bootstrap/Button"

export default function PriorityForecastButtons() {
  return (
    <Button
      disabled={true}
      title="Prioritize IAP by Deadline"
      value="DEADLINE"
      variant="primary"
      className="iap-mode-btn"
    >
      DEADLINE
    </Button>
  )
}
