import React from "react"
import ReactSelect from "react-select"

const taskStateOptions = [
  { label: <>Backlog</>, value: "BACKLOG" },
  {
    label: <>To Do</>,
    value: "TODO",
  },
  {
    label: <>Planned</>,
    value: "PLANNED",
  },
  {
    label: <>Finished</>,
    value: "FINISHED",
  },
]

export default function TaskStatePicker({ required, disabled, value, onChange, menuPlacement }) {
  const taskStateValue = taskStateOptions.find((item) => item.value === value)
  if (required && !taskStateValue) {
    throw new Error("TaskStatePicker received an invalid value")
  }
  return (
    <ReactSelect
      className="TaskStatePicker"
      classNamePrefix="TaskStatePicker"
      placeholder="Select state..."
      isClearable={false}
      isDisabled={disabled}
      options={taskStateOptions}
      onChange={({ value }) => onChange(value)}
      value={taskStateValue}
      menuPosition="absolute"
      menuPlacement={menuPlacement}
    />
  )
}
