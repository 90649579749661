import React, { useState, useRef, useEffect } from "react"
import { DateTime } from "luxon"
import { DayPicker } from "react-day-picker"
import { HiddenInput } from "."
import { format } from "date-fns"
import "react-day-picker/style.css"

function getDaysInMonth(str) {
  if (str.length < 8) {
    return false
  }
  if (str.charAt(6) === "-") {
    return new Date(str.slice(0, 4), str.slice(5, 6), 0).getDate()
  }
  return new Date(str.slice(0, 4), str.slice(5, 7), 0).getDate()
}

function evalDaysInMonth(value) {
  const daysInTheMonth = getDaysInMonth(value)
  const patterns = {
    31: /\d{4}-(1[0-2]|[1-9])-([1-2][0-9]|3[0-1]|[1-9])$/,
    30: /\d{4}-(1[0-2]|[1-9])-([1-2][0-9]|30|[1-9])$/,
    29: /\d{4}-(1[0-2]|[1-9])-([1-2][0-9]|[1-9])$/,
    28: /\d{4}-(1[0-2]|[1-9])-([1][0-9]|[2][0-8]|[1-9])$/,
  }

  return !patterns[daysInTheMonth]?.test(value)
}

const generateDayPickerProps = (showCurrentMonthOnClick) => {
  if (showCurrentMonthOnClick) {
    const now = DateTime.local()
    return { month: new Date(now.year, now.month - 1) }
  }
  return {}
}

const formatDate = (dateValue, similarInputFormat) =>
  similarInputFormat ? dateValue : dateValue?.toFormat("y-M-d")

const LocaleUtils = {
  getFirstDayOfWeek: () => 1,
}

export default function DayPickerComponent({
  disabled,
  required,
  value,
  onChange,
  style,
  onDaypickerClick,
  similarInputFormat,
  showCurrentMonthOnClick,
}) {
  const dayPickerRef = useRef(null)
  const containerRef = useRef(null)
  const [invalidInput, setInvalidInput] = useState(() =>
    similarInputFormat ? value && evalDaysInMonth(value) : false
  )
  const [inputValue, setInputValue] = useState(
    formatDate(value, similarInputFormat)
  )
  const [isOpen, setIsOpen] = useState(false)
  const dayPickerProps = generateDayPickerProps(showCurrentMonthOnClick)
  const [selected, setSelected] = useState(value ? new Date(value) : null)
  useEffect(() => {
    setInputValue(formatDate(value, similarInputFormat))
  }, [value, similarInputFormat])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleDayClick = (day) => {
    const newValue = format(day, "yyyy-MM-dd");
    setInputValue(newValue)
    setSelected(day)
    onChange(
      newValue
        ? similarInputFormat
          ? newValue
          : DateTime.fromFormat(newValue, "y-M-d")
        : undefined
    );
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value
    setInputValue(newValue)
    if (newValue) {
      const isNonsenseInput = evalDaysInMonth(newValue)
      setInvalidInput(isNonsenseInput)
    } else {
      setInvalidInput(false)
    }
    onChange(
      newValue
        ? similarInputFormat
          ? newValue
          : DateTime.fromFormat(newValue, "y-M-d")
        : undefined
    )
  }

  const handleInputFocus = () => {
    setIsOpen(true)
    onDaypickerClick && onDaypickerClick(true)
  }

  const handleInputBlur = () => {
    onDaypickerClick && onDaypickerClick(false)
    setInputValue(formatDate(value, similarInputFormat))
  }

  return (
    <div
      className="Custom-DayPicker HiddenInput-container"
      style={{ position: "relative" }}
      ref={containerRef}
    >
      <input
        ref={dayPickerRef}
        style={{ ...style, color: invalidInput ? "red" : "" }}
        disabled={disabled}
        required={required}
        className="form-control DayPickerRadius"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {isOpen && (
        <div
          className="DayPicker-OverlayWrapper"
          style={{
            position: "absolute",
            zIndex: 1000,
            top: "100%",
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            left: 0,
            background: "white",
          }}
        >
          <DayPicker
            {...dayPickerProps}
            mode="single"
            onDayClick={handleDayClick}
            selected={selected}
            defaultMonth={selected ? new Date(selected) : new Date(DateTime.local())}
            onSelect={setSelected}
            localeUtils={LocaleUtils}
            weekStartsOn={1}
            modifiersClassNames={{
              selected: 'selected-day',
            }}
          />
        </div>
      )}
      <HiddenInput message={invalidInput ? "Please input a valid date" : null} />
    </div>
  )
}

