import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import { Popup } from "components"

import { message, whiteTasks } from "state_management"
import { request } from "utils/request"

export default function IcalIntegration() {
  const dispatch = useDispatch()
  const members = useSelector((state) => state.members)

  const [icalLoading, setIcalLoading] = useState(false)
  const [result, setResult] = useState(null)

  return (
    <div className="IcalIntegration">
      {result && (
        <Popup onCancel={() => setResult(null)} title="Updated iCalendar / Outlook!">
          {result.loadMembers.length ? (
            <h4>Successfully loaded: {result.loadMembers.map((memberId) => members[memberId].initials).join(", ")}</h4>
          ) : (
            <h4>No members were loaded.</h4>
          )}
          {result.failedMembers.length > 0 && (
            <h4>Failed loading: {result.failedMembers.map((memberId) => members[memberId].initials).join(", ")}</h4>
          )}
          <Button variant="primary" onClick={() => setResult(null)}>
            Ok
          </Button>
        </Popup>
      )}
      <Button
        disabled={icalLoading}
        onClick={() => {
          setIcalLoading(true)
          request("POST", "/members/load-outlook/0")
            .then((res) => {
              setResult(res)
              dispatch(whiteTasks.fetch())
            })
            .catch((e) => {
              if (e.name === "IAPinUseError") {
                dispatch(message.warning("Just a moment - the IAP for your team is running"))
              } else {
                dispatch(message.error("Failed loading iCalendar files"))
              }
            })
            .finally(() => setIcalLoading(false))
        }}
      >
        {icalLoading && <Spinner animation="grow" size="sm" />}
        {icalLoading ? " Loading..." : "Update Integration"}
      </Button>
    </div>
  )
}
