import React from "react"
import { FiPlay, FiPause, FiAlertTriangle, FiSquare, FiCheckSquare  } from "react-icons/fi"
import { FaLockOpen, FaLock, FaMapMarkerAlt} from "react-icons/fa"
import { AiOutlineSync } from "react-icons/ai"
import { chooseOptimalColor, optimalColor, isColorDark } from "utils/scripts/schedule"
import useCustomDrag from "utils/scripts/useCustomDrag"
import dragTypes from "utils/scripts/dragTypes"
import { useEffect } from "react"
import { GiFactory } from "react-icons/gi"
import { TbHexagonLetterA } from "react-icons/tb"
import { useSelector, useDispatch } from "react-redux"
import { multipleDrag, projects, projectActions, analysisTasks, message } from "state_management"

import { DateTime } from "luxon"

export default function ScheduleItem({
  item,
  backgroundColor,
  shouldShowState = 1,
  style,
  isActive,
  onMouseEnter,
  onMouseLeave,
  onClick,
  dragType = dragTypes.WHITE_TASK,
  name,
  description,
  assetName = "",
  sampleCount = 0,
  shouldShowSampleCount = false,
  state,
  constraintChecks,
  movable,
  setMovable,
  showIcons,
  isDraggable = true,
}) {
  const markedAsMultipleDrag = useSelector((state) => {
    return state.multipleDrag.items[item.id] ? true : false
  })

  const finalDragType = markedAsMultipleDrag
    ? dragType === dragTypes.ANALYSIS_TASK
      ? dragTypes.MULTIPLETASK
      : dragTypes.MULTIPLEACTION
    : dragType

  const clickHandler = (event) => {
    if (!event.shiftKey) {
      dispatch(multipleDrag.unGhostAll())
    }
  }

  useEffect(() => {
    if (markedAsMultipleDrag) {
      document.addEventListener("click", clickHandler, false)
      return () => {
        document.removeEventListener("click", clickHandler, false)
      }
    }
  })
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const allowTaskTransfer = useSelector((state) => state.teamStatus.allowTaskTransfer)
  const role = useSelector((state) => state.auth.role)
  const isPlanner = role === "planner"
  const memberId = useSelector((state) => state.auth.memberId)
  const collaborators = item.memberIds
  const lockOnAnalysis = (showProjects && showAnalyses)
  isDraggable = isDraggable && (allowTaskTransfer || isPlanner || collaborators.includes(memberId))
  const dragConfig = useCustomDrag(finalDragType, item);
  const isDragging = isDraggable ? dragConfig.isDragging : false;
  const dragRef = isDraggable ? dragConfig.dragRef : null;

  const dispatch = useDispatch()
  return (
    <div
      ref={item ? dragRef : undefined}
      className={`ScheduleItem shouldShowState-${shouldShowState}`}
      style={{
        opacity: isDragging || markedAsMultipleDrag ? "0.4" : undefined,
        backgroundColor: backgroundColor,
        color: chooseOptimalColor(backgroundColor),
        // TODO: Do something other that a box shadow!
        filter: isActive
          ? "brightness(0.8)"
          : item && item.__typename === "WhiteTask"
          ? "brightness(1.0)"
          : "brightness(1.0)",
        ...style,
      }}
      onMouseEnter={() => {
        dispatch(multipleDrag.hover(item))
        if (onMouseEnter) {
          onMouseEnter()
        }
      }}
      onMouseLeave={() => {
        dispatch(multipleDrag.unhover())
        if (onMouseLeave) {
          onMouseLeave()
        }
      }}
      onClick={(e) => {
        if (e.shiftKey) {
          markedAsMultipleDrag ? dispatch(multipleDrag.remove(item)) : dispatch(multipleDrag.add(item))
        } else if (!markedAsMultipleDrag) {
          onClick()
        }
      }}
      title={`${name} ${assetName ? "\nAsset: " + assetName : ""} ${description ? "\n" + description : ""} ${
        item.__typename === "AnalysisTask" ? (sampleCount ? "\nAmount of samples: " + sampleCount : "No samples") : ""
      }`}
      >
      {item.__typename === "ProjectAction" && item.stage === "FINISHED" && (
        <FiCheckSquare
          onClick={(e) =>  {
          e.stopPropagation()
          dispatch(projectActions.editPlanned(item.id, { stage: "PLANNED" }))
          }}
        />
      )}
      {item.__typename === "ProjectAction" && item.stage !== "FINISHED" && showIcons && (
        <FiSquare
          onClick={(e) => {
            e.stopPropagation()
            dispatch(projectActions.editPlanned(item.id, { stage: "FINISHED" }))
          }}
        />
      )}
      {showIcons && (
        <div className="checkBox" style={{display:"flex"}}>
          <AnalysisTaskCheckbox item={item} state={state} dispatch={dispatch} setMovable={setMovable}/>
        </div>
      )}
      {item.__typename === "ProjectAction" && showIcons && (
        <div className="PlannedProjectAction-Onsite">
          {!item.onSite && (
            <FaMapMarkerAlt
              title={"Can be performed anywhere"}
            />
          )}
          {item.onSite && (
            <GiFactory
              title={"Can only be performed on site"}
              color={optimalColor(backgroundColor,"blue","orange")}
            />
          )}
        </div>
      )}
      {lockOnAnalysis && item.__typename === "AnalysisTask" && showIcons && (
        <TbHexagonLetterA title={"Analysis"} />
      )}

      <span className="ScheduleItems-names">
        <span>{name}</span>
        {shouldShowSampleCount ? (
          <span style={{fontSize: "medium"}}>
            {item.__typename === "AnalysisTask"
              ? sampleCount
                ? "\nSamples: " + sampleCount
                : "No samples"
              : ""}
          </span>
        ) : undefined}
      </span>

      {constraintChecks && constraintChecks.length > 0 && (
        <div
          className="warning-container"
          title={
            constraintChecks.length +
            " warning" +
            (constraintChecks.length === 1 ? "" : "s") +
            " has occurred\n  • " +
            constraintChecks.join("\n  • ")
          }
        >
          <FiAlertTriangle fill="red" />
        </div>
      )}

      {showIcons && movable === true ? (
        <FaLockOpen
        className={`${backgroundColor === '#ffffff' ? 'lock-open-black' : 'lock-open' }`}
          onClick={(e) => {
            if (e.shiftKey) {
              if(item.__typename === "ProjectAction") {
                dispatch(projects.lockProject(item.projectId))
                e.stopPropagation()
                return
              }
            }
            setMovable(false)
            e.stopPropagation()
          }}
        />
      ) : showIcons && movable === false ? (
        <FaLock
          className={`lock-closed ${isColorDark(backgroundColor) ? "lighter-color" : ""}`}
          onClick={(e) => {
            if (e.shiftKey) {
              if(item.__typename === "ProjectAction") {
                dispatch(projects.unlockProject(item.projectId))
                e.stopPropagation()
                return
              }
            }
            setMovable(true)
            e.stopPropagation()
          }}
        />
      ) : null}
      {item["recurrenceId"] && <AiOutlineSync className="recurrent" />}
    </div>
  )
}

function AnalysisTaskCheckbox({ item, state, dispatch, setMovable }) {
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  return (
  <>
    {item.__typename === "AnalysisTask" && state === "STARTED" &&
        <FiPlay
          onClick={(e) =>  {
            e.stopPropagation()
            dispatch(analysisTasks.edit(item.id, { state: "STOPPED" })).then(() => setMovable(false))
            return
          }}
        />
      }

      {item.__typename === "AnalysisTask" && state === "PAUSED" &&
        <FiPause
          onClick={(e) =>  {
            e.stopPropagation()
            dispatch(analysisTasks.edit(item.id, { state: "STOPPED" })).then(() => setMovable(false))
            return
          }}
        />
      }

      {item.__typename === "AnalysisTask" && state === "NOT_STARTED" &&
        <FiSquare
          onClick={(e) =>  {
            const curDate = DateTime.local().setZone(memberTimezone).startOf('day').toISODate()
            const endDate = DateTime.fromISO(item.end).setZone(memberTimezone).startOf("day").toISODate()
            if(endDate <= curDate) {
              e.stopPropagation()
              dispatch(analysisTasks.edit(item.id, { state: "STOPPED" })).then(() => setMovable(false))
              return
            } else {
              e.stopPropagation()
              const msg = "A future Analysis task can't be finished"
              dispatch(message.warning(msg))
              return
            }
          }}
        />
      }

      {item.__typename === "AnalysisTask" && state === "STOPPED" &&
        <FiCheckSquare
          onClick={(e) =>  {
            e.stopPropagation()
            dispatch(analysisTasks.edit(item.id, { state: "NOT_STARTED" })).then(() => setMovable(false))
            return
          }}
        />
      }
  </>
  )
}
