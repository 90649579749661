import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "reports/analysis-tasks":
      return state
    default:
      return state
  }
}

export const analysisTasks = asyncAction("reports/analysis-tasks", (dispatch, dateFrom, dateTo, teamId) => {
  return request("POST", "/tasks/analysis/generate-report", {
    input: {
      fromDate: dateFrom.toFormat("yyyy-MM-dd HH:mm:ssZZ"),
      toDate: dateTo.toFormat("yyyy-MM-dd HH:mm:ssZZ"),
      teamId: teamId,
    },
  }).then((res) => res.report)
})
