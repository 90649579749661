import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DateTime, Duration } from "luxon"
import { FiInfo } from "react-icons/fi"
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import "./index.scss"
import { ConfigContainer, Popup } from "components"
import { Title, Text, Container, DurationInput, DayPicker, Select, TaskStagePicker, LocationPicker, ColorPicker } from "components/Form"
import { message } from "state_management"
import isOnMobile from "utils/scripts/isOnMobile"
import Form from 'react-bootstrap/Form'
import ProjectConfigurationPage from "components/Config/ProjectConfigurationPage"

// We want to display a warning to the user
// if they provide bad input
function validateAction(obj) {
  if (obj.earliestStart && obj.deadline) {
    const earliestStart = DateTime.fromISO(obj.earliestStart)
    const deadline = DateTime.fromISO(obj.deadline)
    if (deadline < earliestStart) {
      return "Deadline has to be later than or equal to earliest start"
    }
    if (earliestStart.plus({ second: obj.duration }) > deadline) {
      return "Not enough room for the duration"
    }
  }
  return null
}

// Config used for actions, actions in project templates and unplanned actions.
export default function ActionConfig({
  action,
  title,
  templateAction = false,
  isGraphNode = false,
  isSingleAction = false,
  submitHandler,
  deleteTitle,
  deleteHandler,
  shiftDeleteHandler,
  confirmDeletionText,
  cancelHandler,
  projectId,
  showCurrentMonthOnClick,
  bookedAndFreeAssets,
}) {
  const dispatch = useDispatch()

  // Different action types. These and some of the props decides what should be
  // dispalyed to the user. The Logic is quite "hacky", and could be made better
  const unplannedAction = !action.start
  const scheduleAction = !templateAction && !isGraphNode && !unplannedAction
  const singleActionEdit = useSelector((state) =>
    state.projects[action.projectId] ? state.projects[action.projectId].singleActionProject : null
  )
  const projectName = useSelector((state) =>
    state.projects[action.projectId] ? state.projects[action.projectId].name : null
  )
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  // Common attributes
  const competenceGroups = useSelector((state) => state.competenceGroups)
  const assets = useSelector((state) => state.asset.assets)
  const [name, setName] = useState(action.name || "")
  const [stage, setStage] = useState(action.stage || "TODO")
  const [location, setLocation] = useState(action.onSite || false)
  const [description, setDescription] = useState(action.description || "")
  const [startDate, setStartDate] = useState(action.start ? DateTime.fromISO(action.start).setZone(memberTimezone) : null)
  const [startTime, setStartTime] = useState(
    startDate ? Duration.fromObject({ hours: startDate.hour, minutes: startDate.minute }) : null
  )
  const [allowedAssetIds, setAllowedAssetIds] = useState(action.allowedAssetIds ? action.allowedAssetIds : [])
  const [assetIds, setAssetIds] = useState(action.assetIds ? action.assetIds : [])
  const [allowedGroupIds, setAllowedGroupIds] = useState(
    action.allowedGroupIds ? action.allowedGroupIds.filter((groupId) => competenceGroups[groupId]) : []
  )
  const [duration, setDuration] = useState(Duration.fromObject({ seconds: action.duration || 0 }))
  const [earliestStart, setEarliestStart] = useState(
    action.earliestStart ? DateTime.fromISO(action.earliestStart).setZone(memberTimezone).toFormat("y-M-d") : null
  )
  const [earliestStartTime, setEarliestStartTime] = useState(() => {
    return action.earliestStart
      ? Duration.fromObject({
          hours: DateTime.fromISO(action.earliestStart).setZone(memberTimezone).hour,
          minutes: DateTime.fromISO(action.earliestStart).setZone(memberTimezone).minute,
        })
      : Duration.fromMillis(0)
  })
  const [deadline, setDeadline] = useState(action.deadline ? DateTime.fromISO(action.deadline).setZone(memberTimezone).toFormat("y-M-d") : null)
  const [deadlineTime, setDeadlineTime] = useState(
    action.deadline
      ? Duration.fromObject({
          hours: DateTime.fromISO(action.deadline).setZone(memberTimezone).hour,
          minutes: DateTime.fromISO(action.deadline).setZone(memberTimezone).minute,
        })
      : Duration.fromObject({ hours: 23, minutes: 59 })
  )
  const [teamMember, setTeamMember] = useState(action.memberIds ? [action.memberIds[0]] : [])
  const members = useSelector((state) => state.members)
  const isStandardAction = action.isStandardAction || title === "Create Standard Action"
  const [isOperational, setIsOperational] = useState(action.id ? action.isOperational : true)
  const [color, setColor] = useState(action.color)
  const allStandardActions = useSelector((state) => state.standardActions.standardActions)
  const [editProject, setEditProject] = useState(false);

  // Displays the remaining fields in the config
  const [showRest, setShowRest] = useState(false)

  let sortedCollection = [];
  if (bookedAndFreeAssets?.freeAssets && bookedAndFreeAssets?.bookedAssets) {
    // Combine freeAssets and bookedAssets first, and mark them appropriately
    const combinedAssets = [
      ...bookedAndFreeAssets.freeAssets.map(assetId => ({
        ...assets[assetId],
        status: 'Free',
      })),
      ...bookedAndFreeAssets.bookedAssets.map(assetId => ({
        ...assets[assetId],
        status: 'Booked',
      }))
    ];

    // Sort the combined assets alphabetically by their name
    const sortedAssets = combinedAssets.sort((a, b) => a.name.localeCompare(b.name))
      .map(asset => ({
        ...asset,
        name: `${asset.name} (${asset.status})` // Append status (Free/Booked) to the name
      }));

    // Assign the sorted assets to sortedCollection
    sortedCollection.push(...sortedAssets);
  }

  const assetsArray = Object.values(assets);
  const sortedAssets = assetsArray.sort((a, b) => a.name.localeCompare(b.name));

  const disabled = false
  return (
    <>
    {editProject && projectId && (
      <Popup
        className="Edit-Create-Project"
        onCancel={() => {
          setEditProject(false)
        }}
      >
        <ProjectConfigurationPage
          id={projectId}
          isCreateOrEditProject={true}
          setEditProject={() => {
            setEditProject()
            cancelHandler()
          }}
        />
      </Popup>
    )}
    <ConfigContainer
      title={title}
      submitHandler={() => {
        let obj = {
          name,
          description,
          duration: !duration ? Duration.fromMillis(0) : duration.as("seconds"),
          allowedGroupIds,
          allowedAssetIds,
          onSite: location,
          earliestStart: !earliestStart
            ? null
            : earliestStartTime
            ? DateTime.fromFormat(earliestStart, "y-M-d").setZone(memberTimezone)
                .set({ hour: earliestStartTime.hours, minute: earliestStartTime.minutes })
                .toISO()
            : DateTime.fromFormat(earliestStart, "y-M-d").toISO(),
          deadline:
            deadline && deadlineTime
              ? DateTime.fromFormat(deadline, "y-M-d").setZone(memberTimezone)
                  .set({ hour: deadlineTime.hours, minute: deadlineTime.minutes })
                  .toISO()
              : null,
        }
        const mes = validateAction(obj)
        if (mes) {
          dispatch(message.warning(mes))
          return
        }
        if (!templateAction && !unplannedAction) {
          obj.assetIds = assetIds
        }
        if (!templateAction && !isGraphNode) {
          obj.stage = stage
        }

        if (!templateAction && !unplannedAction && !isGraphNode && stage !== "TODO" && stage !== "BACKLOG") {
          obj.start = startDate && startDate.set({ hour: startTime.hours, minute: startTime.minutes }).toISO()
          obj.memberIds = teamMember
        }

        if (isStandardAction) {
          const standardAction = allStandardActions.find((project) => project.id === action.projectId);
          obj.color = standardAction ? color : (color || "#ffffff");
          obj.isOperational = isOperational;
        }

        submitHandler(obj)
      }}
      cancelHandler={cancelHandler}
      deleteTitle={deleteTitle}
      deleteHandler={deleteHandler}
      shiftDeleteHandler={shiftDeleteHandler}
      confirmDeletionText={confirmDeletionText}
      projectId={projectId}
      editProjectHandler={!isGraphNode && !isSingleAction && !isOnMobile && !singleActionEdit && !isStandardAction ? () => setEditProject(true) : null}
      canDeleteWhenDisabled={isStandardAction}
    >
      {(scheduleAction || unplannedAction) && !isGraphNode && (!isSingleAction || scheduleAction) && (
        <div className="setup-container">
          <Container>
            <Title>
              Action Name<div className="red-text">*</div>
            </Title>
            <Text required disabled={disabled} value={name} onChange={setName} />
            {!isSingleAction && !isStandardAction && <Text disabled value={projectName} />}
          </Container>
          {unplannedAction && !isGraphNode && !isSingleAction && (
            <Container>
              <Title>
                Allowed Competence Groups <div className="red-text">*</div>
              </Title>
              <Select
                name="Allowed performers"
                disabled={disabled}
                required={!templateAction}
                collection={Object.values(competenceGroups).sort((a, b) => {
                  if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                  if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                  return 0
                })}
                value={allowedGroupIds}
                onChange={setAllowedGroupIds}
                menuPosition="fixed"
              />
            </Container>
          )}
          <div className={`${!(isSingleAction || unplannedAction || isGraphNode) ? "actionconfig-container-compartment" : null }`}>
            {scheduleAction && (
              <Container>
                <Title>
                  From Date <div className="red-text">*</div>
                </Title>
                <DayPicker
                  required
                  disabled={disabled}
                  value={startDate}
                  onChange={(event) => {
                    if (!event) {
                      setStartDate(undefined)
                      return
                    }
                    if (event.invalid) {
                      return
                    }
                    setStartDate(event)
                  }}
                />
                <Title>
                  From Time <div className="red-text">*</div> (HH:MM)
                </Title>
                <DurationInput disabled={disabled} value={startTime} onChange={setStartTime} required isTime/>
              </Container>
            )}
            <Container>
              <Title>
                Duration <div className="red-text">*</div> (HH:MM)
              </Title>
              <DurationInput required={!templateAction} disabled={disabled} value={duration} onChange={setDuration} />
            </Container>
            {scheduleAction && (
              <Container>
                <Title>To Date</Title>
                <DayPicker
                  required
                  disabled
                  value={startDate && duration ? startDate.plus(duration) : null}
                />
                <Title>To Time (HH:MM)</Title>
                <DurationInput disabled value={startTime && duration ? startTime.plus(duration) : null} isTime />
              </Container>
            )}
          </div>
          {scheduleAction && (
            <Container>
              <Title>
                Team Member <div className="red-text">*</div>
              </Title>
              <Select
                required
                closeMenuOnSelect={true}
                attr={"initials"}
                collection={Object.values(members).filter(
                  (member) => (!member.archived || action.memberIds[0] === member.id) && member.showOnPlan
                )}
                value={teamMember}
                onChange={setTeamMember}
                isMulti={false}
                menuPosition="fixed"
              />
            </Container>
          )}
          {scheduleAction && (
            <Container>
              <Title>Used Assets</Title>
              <Select
                disabled={disabled}
                collection={Object.values(sortedCollection)}
                value={assetIds}
                onChange={setAssetIds}
                menuPosition={"fixed"}
              />
            </Container>
          )}
        </div>
      )}

      {(isGraphNode || (isSingleAction && !scheduleAction)) && (
        <>
          <div className="setup-container">
            <Container>
              <Title>
                Action Name <div className="red-text">*</div>
              </Title>
              <Text required disabled={disabled} value={name} onChange={setName} />
              {!templateAction && !isSingleAction && <Text disabled value={projectName} />}
            </Container>
            {(isSingleAction || isGraphNode) && (
              <Container>
                <Title>
                  Allowed Competence Groups<div className="red-text">*</div>
                </Title>
                <Select
                  name="Allowed performers"
                  disabled={disabled}
                  required={!templateAction}
                  collection={Object.values(competenceGroups).sort((a, b) => {
                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                    return 0
                  })}
                  value={allowedGroupIds}
                  onChange={setAllowedGroupIds}
                  menuPosition="fixed"
                />
              </Container>
            )}
            <div className={`${!(isSingleAction || unplannedAction || isGraphNode) ? "actionconfig-container-compartment" : null }`}>
              <Container>
                <Title>
                  Duration (HH:MM) <div className="red-text">*</div>
                </Title>
                <DurationInput required={!templateAction} disabled={disabled} value={duration} onChange={setDuration} />
              </Container>
            </div>
            {isSingleAction && !scheduleAction && action.id && (
              <Container>
                <Title>Stage</Title>
                <TaskStagePicker required disabled={disabled} value={stage} onChange={setStage} />
              </Container>
            )}
          </div>
        </>
      )}
      <div className="action-links" onClick={() => setShowRest(!showRest)}>
          <div className="action-container">
              <span>Additional Information</span>
              {showRest ? <IoIosArrowUp/> : <IoIosArrowDown/>}
          </div>
      </div>
      {showRest && (
        <div className="setup-container">
          {((scheduleAction || unplannedAction) && !isStandardAction) && (
            <Container>
              <Title>Stage</Title>
              <TaskStagePicker required disabled={disabled} value={stage} onChange={setStage} menuPlacement="auto" />
            </Container>
          )}
          <Container>
            <Title>Description</Title>
            <Text large disabled={disabled} value={description} onChange={setDescription} />
          </Container>
          {isStandardAction && (
            <Container>
              <Title>Operational Task</Title>
              <Form.Check
                style={{margin: "0.7em 0em 0em 0em"}}
                defaultChecked={action.id ? action.isOperational : true}
                type={"checkbox"}
                disabled={disabled}
                onChange={(e) => setIsOperational(e.target.checked)}
              />
            </Container>
          )}
          {!isStandardAction && (<div className="actionconfig-container-compartment">
            <p>Time Constraints</p>
            <Container>
              <Title>
                Earliest Start&nbsp;
              </Title>
              <Title>Date</Title>
              <DayPicker
                disabled={disabled}
                value={earliestStart}
                showCurrentMonthOnClick={showCurrentMonthOnClick}
                onChange={(event) => {
                  setEarliestStart(event)
                }}
                similarInputFormat
                />
              <Title>Time (HH:MM)</Title>
              <DurationInput
                disabled={disabled}
                value={earliestStartTime}
                onChange={setEarliestStartTime}
                isTime
                />
                <span className="project-config-info">
                    {!isSingleAction && <FiInfo title="Only advanced users use both earliest start and connections" />}
                </span>
            </Container>
            <Container>
              <Title>Deadline</Title>
              <Title>Date</Title>
              <DayPicker
                required={false}
                disabled={disabled}
                value={deadline}
                showCurrentMonthOnClick={showCurrentMonthOnClick}
                onChange={(event) => {
                  setDeadline(event)
                }}
                similarInputFormat
                />
              <Title>Time (HH:MM)</Title>
              <DurationInput required={false} disabled={disabled} value={deadlineTime} onChange={setDeadlineTime} isTime />
            </Container>
          </div>)}
          <div className="actionconfig-container-compartment">
            <p>{isGraphNode || isSingleAction || unplannedAction ? "" : "Competence and "}Asset Constraints</p>
            {!isGraphNode && !unplannedAction && (
              <Container>
                <Title>Allowed Competence Groups</Title>
                <Select
                  name="Allowed performers"
                  disabled={disabled}
                  required={!templateAction}
                  collection={Object.values(competenceGroups).sort((a, b) => {
                    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                    return 0
                  })}
                  value={allowedGroupIds}
                  onChange={setAllowedGroupIds}
                  menuPosition="fixed"
                  />
              </Container>
            )}
            <Container>
              <Title>Allowed Assets</Title>
              {/* TODO: Update usedAssetIds when this changes! */}
              <Select
                disabled={disabled}
                collection={sortedAssets}
                value={allowedAssetIds}
                onChange={setAllowedAssetIds}
                menuPosition="fixed"
                />
            </Container>
          </div>
          <div className="actionconfig-container-compartment">
            <p>{isGraphNode || isSingleAction || unplannedAction ? "" : "Location"}</p>
            <Container>
              <Title>Location</Title>
              <LocationPicker
              value={location} onChange={setLocation} menuPlacement="top"
              />
            </Container>
          </div>
          {isStandardAction && action.stage !== "PLANNED" &&(
            <Container>
              <Title>
                Color
              </Title>
              <div style={{maxWidth: "93.8%"}}>
                <ColorPicker required={false} value={color} onChange={setColor}/>
              </div>
            </Container>
          )}
        </div>
      )}
    </ConfigContainer>
    </>
  )
}
