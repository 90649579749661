import { request } from "utils/request"
import { asyncAction } from "./common"
import { DateTime } from "luxon"

const initialState = {
  data: null,
  isCurrent: false,
  isLoading: false,
}
// This state is not used anymore since all logic lives in FE
export function reducer(state = initialState, action) {
  switch (action.type) {
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("metrics/fetch", (dispatch) => {
  const after = DateTime.fromISO(window.store.getState().visual.scheduleMondayDate).toUTC().toISO()
  return request("GET", `/metrics?after=${after}`).then((res) => res.metrics)
})
