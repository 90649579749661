import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FiCalendar, FiArchive, FiSettings, FiPlus } from "react-icons/fi"
import { TbSquareLetterP, TbSquareLetterM } from "react-icons/tb";


import "./index.scss"

import { Popup, ListItemDnD, ConfirmDialog } from "components"
import { MemberConfig } from "components/Config"
import { members as membersState, teamInfo, projects } from "state_management"
import dragTypes from "utils/scripts/dragTypes"

export default function TeamTab() {
  const [addConfig, setAddConfig] = useState(false)
  const [activeEdit, setActiveEdit] = useState(null)
  const [activeArchive, setActiveArchive] = useState(null)

  const members = Object.values(useSelector((state) => state.members))
  const dispatch = useDispatch()
  const hasFetched = useSelector((state) => state.teamInfo.hasFetched)
  // The dispatch alone might be causing the  error 'Warning: Cannot update a component (`TeamTab`) while rendering a different component (`TeamTab`)' 
  // Put it inside useEffect to avoid rendering while in other screen
  useEffect(() => {
    if(!hasFetched) {
      dispatch(teamInfo.fetch())
      }
  })
  
  const team = useSelector((state) => state.teamInfo.teamName)

  return (
    <div className="TeamTab-main-container">
      {activeArchive && (
        <ConfirmDialog
          onCancel={() => setActiveArchive(null)}
          onConfirm={() =>
            dispatch(membersState.archive(activeArchive.id)).then(() => {
              dispatch(projects.fetch())
              setActiveArchive(null)
            })
          }
        >
          <h4>Confirm archival of member: {activeArchive.initials}</h4>
        </ConfirmDialog>
      )}
      {activeEdit && (
        <Popup nonScrollable onCancel={() => setActiveEdit(null)}>
          <MemberConfig member={activeEdit} onClose={() => setActiveEdit(null)} />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <MemberConfig onClose={() => setAddConfig(false)} />
        </Popup>
      )}
      <div className="TeamTab-member-container">
        <div className="TeamName-container">
          <p>{team}</p>
        </div>
        {members
          .filter((member) => !member.archived)
          .map((member) => (
            <ListItemDnD
              text={member.initials}
              key={member.id}
              dragType={dragTypes.MEMBER}
              id={member.id}
              item={member}
              onDrop={(dragId, dropId) => {
                const dropIdx = members.findIndex((x) => x.id === dropId)
                const dragIdx = members.findIndex((x) => x.id === dragId)
                if (dragIdx > dropIdx) {
                  members.splice(dropIdx, 0, members[dragIdx])
                  members.splice(dragIdx + 1, 1)
                } else {
                  members.splice(dropIdx + 1, 0, members[dragIdx])
                  members.splice(dragIdx, 1)
                }
                const ids = members.map((x) => x.id)
                dispatch(membersState.editOrder({ ids: ids }))
              }}
            >
              {member.outlookLink && (
                <FiCalendar
                  className="TeamTab-member-outlook-icon"
                  title="Member Has Enabled iCalendar / Outlook Integration"
                />
              )}
              {member.role === "MEMBER" ?
                <TbSquareLetterM title="Member"/>
                :
                <TbSquareLetterP title="Planner"/>
              }
              <FiArchive onClick={() => setActiveArchive(member)} title="Archive Member" />
              <FiSettings onClick={() => setActiveEdit(member)} title="Edit member" />
            </ListItemDnD>
          ))}
        <div className="TeamTab-add-member">
          <FiPlus title="Add Member" onClick={() => setAddConfig(true)} />
        </div>
      </div>
    </div>
  )
}
