// This state is in redux to create a clearer data manipulation interface
import { request } from "utils/request"
import { addToMap, createMap, replaceInMap } from "./common"
import { asyncAction } from "./common"

const initialState = {
  columnOrder: ["BACKLOG", "TODO", "PLANNED", "FINISHED"],
  columnsIds: [],
  tasks: {},
  columns: {},
  filterString: "",
  didFetch: false,
  projects: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "scrumView/fillOut": {
      const projects = action.projects
      // The actual state
      const backlogActions = createMap(Object.values(action.backlog.filter((x) => x.stage === "BACKLOG")))
      const unplannedActions = createMap(Object.values(action.unplanned.filter((x) => x.stage === "TODO")))
      const plannedActions = createMap(Object.values(action.planned.filter((x) => x.stage === "PLANNED")))
      const finishedActions = createMap(Object.values(action.planned.filter((x) => x.stage === "FINISHED")))
      const tasks = { ...backlogActions, ...unplannedActions, ...plannedActions, ...finishedActions }
      const columns = {
        BACKLOG: {
          id: "BACKLOG",
          title: "Backlog",
          taskIds: [
            ...Object.values({ ...backlogActions })
              .filter((task) => task.scrumColumnId === null)
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((x) => x.id),
          ],
        },
        TODO: {
          id: "TODO",
          title: "To Do",
          taskIds: [
            ...Object.values({ ...unplannedActions })
              .filter((task) => task.scrumColumnId === null)
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((x) => x.id),
          ],
        },
        PLANNED: {
          id: "PLANNED",
          title: "Planned",
          taskIds: Object.values({ ...plannedActions })
            .filter((task) => task.scrumColumnId === null)
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((x) => x.id),
        },
        FINISHED: {
          id: "FINISHED",
          title: "Finished",
          taskIds: Object.values({ ...finishedActions })
            .filter((task) => task.scrumColumnId === null)
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((x) => x.id),
        },
      }

      state.columnsIds.forEach((col) => {
        columns[col.name.toUpperCase()] = {
          id: col.stage,
          title: col.name,
          taskIds: Object.values(tasks)
            .filter((task) => task.scrumColumnId === col.id)
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((x) => x.id),
        }
      })

      return {
        ...state,
        columns,
        tasks,
        projects,
        didFetch: true,
      }
    }
    case "scrumView/updateColumns":
      return {
        ...state,
        columns: { ...state.columns, [action.idx1]: action.column1, [action.idx2]: action.column2 },
      }
    case "scrumView/updateColumn":
      return {
        ...state,
        columns: { ...state.columns, [action.idx]: action.column },
      }
    case "scrumView/removeColumn": {
      const tmpColumn = { ...state.columns }
      var tempColOrder = [...state.columnOrder].filter((order) => order !== action.res.dropId)
      tmpColumn[action.res.parent].taskIds = action.res.res.action_ids
      return {
        ...state,
        columnOrder: tempColOrder,
        columns: tmpColumn,
      }
    }
    case "scrumView/updateTasks":
      return { ...state, tasks: replaceInMap(state.tasks, action.task) }
    case "projectActions/editUnplanned":
    case "projectActions/editPlanned":
      return { ...state, tasks: replaceInMap(state.tasks, action.res) }
    case "scrumView/leavePage":
      return { ...state, didFetch: false }
    case "scrumView/updateColumnOrder": {
      const tmpColumn = ["BACKLOG", "TODO", "PLANNED", "FINISHED"]
      action.res.items.forEach((col) => {
        tmpColumn.splice(tmpColumn.indexOf(col.stage) + 1, 0, col.name.toUpperCase())
      })
      return { ...state, columnOrder: tmpColumn, columnsIds: [...action.res.items] }
    }
    case "scrumView/createColumn":
      const tmpCol = { ...state.columns }
      const tmpColumnOrder = [...state.columnOrder]
      tmpCol[action.res.stage_name.name.toUpperCase()] = {
        id: action.res.stage_name.stage,
        title: action.res.stage_name.name,
        taskIds: [],
      }
      tmpColumnOrder.splice(
        tmpColumnOrder.indexOf(action.res.stage_name.stage) + 1,
        0,
        action.res.stage_name.name.toUpperCase()
      )
      return {
        ...state,
        columns: tmpCol,
        columnOrder: tmpColumnOrder,
        columnsIds: [...state.columnsIds, action.res.stage_name],
      }
    case "scrumView/removeAction":
      let tmpTasks = { ...state.tasks }
      for (var id in tmpTasks) {
        if (tmpTasks[id].projectId === action.id.id) {
          delete tmpTasks[id]
        }
      }
      return { ...state, tasks: tmpTasks }
    case "scrumView/sortByTime":
      return {
        ...state,
        columns: action.columns,
        tasks: action.tasks,
        projects: action.projects,
      }
    case "scrumView/reloadScreen":
      return {
        ...state,
        didFetch: false,
      }
    case "actions/createSingleTask":
      return !state.didFetch
        ? state
        : {
            ...state,
            tasks: addToMap(state.tasks, action.res.project.actions[0]),
            columns: {
              ...state.columns,
              TODO: {
                ...state.columns.TODO,
                taskIds: [action.res.project.actions[0].id, ...state.columns.TODO.taskIds],
              },
            },
          }
    default:
      return state
  }
}

// Gets called on mount
export const fillOut = (up, pl, bl, pj) => {
  const unplanned = Object.values(up)
  const planned = Object.values(pl)
  const backlog = Object.values(bl)
  const projects = Object.values(pj)
  return { type: "scrumView/fillOut", unplanned, planned, backlog, projects }
}

export const updateColumns = (idx1, idx2, column1, column2) => {
  return { type: "scrumView/updateColumns", idx1, idx2, column1, column2 }
}
export const updateColumn = (idx, column) => {
  return { type: "scrumView/updateColumn", idx, column }
}

export const sortByTime = (columns, tasks, projects) => {
  return { type: "scrumView/sortByTime", columns, tasks, projects }
}

export const updateTasks = (task) => {
  return { type: "scrumView/updateTasks", task }
}

export const reloadScrumViewScreen = () => {
  return { type: "scrumView/reloadScreen" }
}

export const leavingPage = () => {
  return { type: "scrumView/leavePage" }
}

export const removeAction = (id) => {
  return { type: "scrumView/removeAction", id }
}

export const updateColumnOrder = (cols) => {
  return { type: "scrumView/updateColumnOrder" }
}

export const getColumns = asyncAction("scrumView/updateColumnOrder", (dispatch) => {
  return request("GET", "/scrum-column").then((res) => res)
})

export const createColumn = asyncAction("scrumView/createColumn", (dispatch, input, feInput) => {
  return request("POST", "/scrum-column", { input }).then((res) => {
    return res
  })
})

export const editColumn = asyncAction("scrumView/editColumn", (dispatch, id, payload) => {
  return request("PATCH", `/scrum-column/${id}`, { patch: payload }).then((res) => {
    return res
  })
})

export const removeColumn = asyncAction("scrumView/removeColumn", (dispatch, id, dropId, parent) => {
  return request("DELETE", `/scrum-column/${id}`).then((res) => {
    return { dropId, res, parent }
  })
})
