import React from "react"
import Table from "react-bootstrap/Table"

export default function Tabular({ children }) {
  return (
    <Table bordered hover className="Tabular">
      {children}
    </Table>
  )
}
