import React from "react"
import { useDispatch, useSelector } from "react-redux"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"
import { visual } from "state_management"
import { setMondayDate } from "components/ScheduleButtons/index"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { DateTime } from "luxon"
import WeekDropDown from "./WeekDropDown"

export default function DateButtons({ type, isDayView, activeDate, mondayDate, memberId, date }) {
  const dispatch = useDispatch()
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  return (
    <div className="date-buttons">
      {type !== "sample-view" && type !== "settings-view" && (
        <>
          <ButtonGroup>
            <Button
              title={isDayView ? "Previous Day" : "Last Week"}
              onClick={() =>
                isDayView
                  ? (dispatch(visual.setActiveDate(activeDate.minus({ days: 1 }).toISO())),
                    setMondayDate(activeDate.minus({ days: 1 }), mondayDate, dispatch))
                  : dispatch(visual.changeMondayDate(mondayDate.minus({ weeks: 1 }).toISO()))
              }
            >
              <FiChevronLeft borderwidth={2} />
            </Button>
            <Button
              title={isDayView ? "Next Day" : "Next Week"}
              onClick={() =>
                isDayView
                  ? (dispatch(visual.setActiveDate(activeDate.plus({ days: 1 }).toISO())),
                    setMondayDate(activeDate.plus({ days: 1 }), mondayDate, dispatch))
                  : dispatch(visual.changeMondayDate(mondayDate.plus({ weeks: 1 }).toISO()))
              }
            >
              <FiChevronRight borderwidth={2} />
            </Button>
          </ButtonGroup>
          <Button
            onClick={() => {
              if (isDayView) {
                dispatch(visual.setActiveDate(DateTime.local().setZone(memberTimezone).toISO()))
                setMondayDate(DateTime.local().setZone(memberTimezone), mondayDate, dispatch)
              } else {
                if(type === "asset-view") {
                  dispatch(visual.showCurrentDate(true))
                }
                const thisMonday = DateTime.local().setZone(memberTimezone).startOf("week")
                if (!mondayDate.hasSame(thisMonday, "day")) {
                  dispatch(visual.changeMondayDate(thisMonday.toISO()))
                }
              }
            }}
          >
            Today
          </Button>
          {type === 'single-member' && (<WeekDropDown memberId={memberId}/>)}
        </>
      )}
    </div>
  )
}
