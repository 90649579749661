// Should be used in the parents with daypickers submithandlers
export function validateDayPickers(containers) {
  const msg = "Some dates are invalid"
  return containers.slice(-1)[0] === containers.slice(-2, -1)[0] ? "" : msg
}

// For every new popup container an element is appended to the list below.
// The value relates negatively to the number of dayPickers (components/Form/DayPicker) with bad input

// Example: Two popups, the parent has 1 bad input Daypicker and the child has two
// This will yield: activeContainers: [0, -1, -3]. The child fixes one DayPicker and we get
// activeContainers: [0, -1, -2]. The child fixes its last dayPicker and we get [0, -1, -1].
// One can now save the child which closes it and we get [0, -1].
const initialState = {
  activeContainers: [0],
}

export function reducer(state = initialState, action) {
  const lastIdx = state.activeContainers.length - 1
  const workingValue = state.activeContainers[lastIdx]
  switch (action.type) {
    case "dayPicker/add":
      state.activeContainers[lastIdx] += 1
      return state
    case "dayPicker/remove":
      state.activeContainers[lastIdx] -= 1
      return state
    case "dayPicker/addContainer":
      state.activeContainers.push(workingValue)
      return state
    case "dayPicker/removeContainer":
      state.activeContainers.pop()
      return state
    case "auth/logout":
      return initialState
    default:
      return state
  }
}
export const addDayPicker = () => {
  return { type: "dayPicker/add" }
}
export const removeDayPicker = () => {
  return { type: "dayPicker/remove" }
}
export const resetDayPicker = () => {
  return { type: "dayPicker/reset" }
}
export const addContainer = () => {
  return { type: "dayPicker/addContainer" }
}
export const removeContainer = () => {
  return { type: "dayPicker/removeContainer" }
}
