import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { DateTime, Duration } from "luxon"

import "./index.scss"
import { getWidth, chooseOptimalColor, convertOffset } from "utils/scripts/schedule"
import { analysisTasks, projectActions, whiteTasks } from "state_management"
export default function AssetViewElement({
  assetId,
  setActiveActionId,
  setActiveAnalysisTaskId,
  setActiveWhiteTaskId,
  setBookedAndFreeAssets,
  isCompactView,
  setActiveAssetId,
}) {
  const dispatch = useDispatch()
  const allAnalysisTasks = useSelector((state) => state.analysisTasks)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const allWhiteTasks = useSelector((state) => state.whiteTasks)
  const asset = useSelector((state) => state.asset.graphInfo[assetId])
  const withWeek = useSelector((state) => state.visual.scheduleWithWeek)
  const showWeekendAssetView = useSelector((state) => state.visual.assetView.showWeekend)
  const projects = useSelector((state) => state.projects)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const startDate = convertOffset(useSelector((state) => state.visual.scheduleMondayDate), memberTimezone)
  const minutes = (showWeekendAssetView ? 7 : 5) * 24 * 60 * (withWeek ? 2 : 1)
  const members = useSelector((state) => state.members)
  const teamId = useSelector((state) => state.auth.teamId)

  if (!asset) return <div />
  let taskDescriptions = getWidth(
    startDate,
    asset.analysisTasks.concat(asset.projectActions).concat(asset.whiteTasks),
    minutes
  )
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {taskDescriptions.map((taskDescription, idx) => {
        const task = taskDescription.obj
        const top = taskDescription.left
        const height = taskDescription.width
        const maxHeight = taskDescription.maxWidth
        const start = DateTime.fromISO(task.start).setZone(memberTimezone)
        const end = DateTime.fromISO(task.end).setZone(memberTimezone)
        let left;
        let width;

        // This check is to determine how tasks should render when saturday and sunday isn't shown on the plan
        if (withWeek && !showWeekendAssetView) {
          let satNight = startDate.plus({days: 5})
          let monNight = startDate.plus({days: 7})
          const b1 = start > satNight 
          const b2 = end > satNight
          const b3 = start < monNight
          const b4 = end < monNight
          // If the task is only on weekend
          if(b1 && b2 && b3 && b4) {
            return null
            // If task starts on workday and ends in weekend
          } else if(!b1 && b2 && b3 && b4) {
            left = (Math.ceil(start.diff(startDate).as("minutes")) / minutes) * 100
            width = 50-left
            // If task starts before saturday and ends after sunday
          } else if(!b1 && b2 && b3 && !b4) {
            left = (Math.ceil(start.diff(startDate).as("minutes")) / minutes) * 100
            width = (Math.floor(end.diff(start).minus(Duration.fromObject({days: 2})).as("minutes")) / minutes) * 100
            // If task starts on weekend and ends in workday
          } else if(b1 && b2 && b3 && !b4) {
            left = 50
            width = (Math.floor(end.diff(monNight).as("minutes")) / minutes) * 100
            // If task starts after weekend. Subtract two days from 'left' to make up for the lost weekend
          } else if(b1 && b2 && !b3 && !b4) {
            left = (Math.ceil(start.diff(startDate).minus(Duration.fromObject({days: 2})).as("minutes")) / minutes) * 100
            width = (Math.floor(end.diff(start).as("minutes")) / minutes) * 100
          } else {
            // If task ends before saturday
            left = (Math.ceil(start.diff(startDate).as("minutes")) / minutes) * 100
            width = (Math.floor(end.diff(start).as("minutes")) / minutes) * 100
          }
          // if weekend button is disabled
        } else {
          left = (Math.ceil(start.diff(startDate).as("minutes")) / minutes) * 100
          width = (Math.floor(end.diff(start).as("minutes")) / minutes) * 100
        }

        if (left > 100) return null
        if (left + width > 100) {
          width = 100 - left
        }
        const title =
          task.teamId === teamId
            ? (task.analysisName ? `${task.analysisName}: ` : "") +
              (task.projectName
                ? projects[task.projectId] && projects[task.projectId].singleActionProject
                  ? ""
                  : `${task.projectName}: `
                : "") +
              task.name +
              (task.memberIds && task.memberIds.length !== 0
                ? `\nCollaborators:${task.memberIds.map((id) => " " + (members[id] ? members[id].initials : ""))}`
                : "") +
              (task.plannedBy && members[task.plannedBy] ? `\nBooked by: ${members[task.plannedBy].initials}` : "") +
              (task.description ? `\nDescription: ${task.description}` : "")
            : "Task belongs to team: " + task.teamName
        return (
          <div
            key={idx}
            className={"AssetViewElement-task" + (task.teamId !== teamId ? " AssetViewElement-task-external-team" : "")} 
            style={{
              // First check if task belongs to current team, then check if task is greytask
              backgroundColor: teamId ===  task.teamId ? (task.color ? task.color : "#eeeeee") : "#222222",
              height: height + "%",
              width: width + "%",
              top: top + "%",
              left: left + "%",
              cursor: task.teamId === teamId ? "pointer" : "auto",
            }}
            onClick={() => {
              if (task.analysisName) {
                setActiveAnalysisTaskId(task.id);
                setActiveAssetId(assetId);
              } else if (task.projectName) {
                setActiveActionId(task.id);
                setActiveAssetId(assetId);
              } else if (!task.analysisName && !task.projectName) {
                setActiveWhiteTaskId(task.id);
                setActiveAssetId(assetId);
              }
              
              if (allAnalysisTasks[task.id]) {
                dispatch(analysisTasks.getBookedFreeAssets(task.id, allAnalysisTasks[task.id])).then((res) => {
                  setBookedAndFreeAssets(res)
                })
              } else if (allProjectActions[task.id]) {
                dispatch(projectActions.getBookedFreeAssets(task.id, allProjectActions[task.id])).then((res) => {
                  setBookedAndFreeAssets(res)
                })
              } else if (allWhiteTasks[task.id].recurrenceInterval === null) {
                dispatch(whiteTasks.getBookedFreeAssets(task.id, allWhiteTasks[task.id])).then((res) => {
                  setBookedAndFreeAssets(res)
                })
              } else {
                setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
              }
            }}
            title={title}
          >
          <div className="AssetViewElement-text-container" style={{ color: task.teamId === teamId ? chooseOptimalColor(task.color) : "#ffffff", fontSize: isCompactView ? 0.65 + "rem": "" }}>
            {height === maxHeight && <p>
                {task.teamId === teamId
                  ? (task.analysisName ? `${task.analysisName}: ` : "") +
                    (task.projectName
                      ? projects[task.projectId] && projects[task.projectId].singleActionProject
                        ? ""
                        : `${task.projectName}: `
                      : "") +
                    task.name
                  : task.teamName}
              </p>}
              <p>
              { task.teamId === teamId ? (task.memberIds && task.memberIds.length !== 0
                // TODO: The .sort() should in the future be replaced by proper reduxing
                ? task.memberIds.sort().map(
                  (id) => members[id] ? members[id].initials + (id !== task.memberIds[task.memberIds.length - 1] ? ", " : "") : ""
                )
                // check that the task has a plannedBy attribute. This filters tasks that aren't greytasks, 
                // and also old greytasks that still exist before the 'plannedBy' attribute was implemented
                : task.plannedBy ? (members[task.plannedBy] ? "Booked by: " + members[task.plannedBy].initials : null) : null) 
              : task.teamName}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
