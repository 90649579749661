const successMessage = {
  // Analysis
  // TODO: Split `setMovable` in two so we can show the correct message
  "analyses/setMovable": "Updated analysis",
  // "analyses/setMovable": "Locked analysis",
  // "analyses/setMovable": "Unlocked analysis",
  "analyses/moveIntoPlan": "Successfully moved analysis into plan",
  "analyses/fetchUnplanned": null,
  "analyses/fetchUnassignedSamples": null,
  "analyses/fetchPlanned": null,
  "analyses/remove": "Unplanned analysis successfully",

  // AnalysisDefinition
  "analysisDefinitions/fetch": null,
  "analysisDefinitions/create": "Added new analysis definition", // TODO: "Added new Analysis Definition: {name}"?
  "analysisDefinitions/edit": "Edited analysis definition", // TODO: "Edited Analysis Definition: {name}"?
  "analysisDefinitions/remove": "Deleted analysis definition",
  "analysisDraft/create": "Added new analysis draft",
  "analysisDraft/edit": "Edited analysis draft",

  // Samples
  "samples/delete-unlocked": "Deleted all unplanned samples",  

  // Asset
  "assets/fetchGraphInfo": null,
  "assets/remove": "Succesfully deleted asset",
  "assets/fetch": null,
  "assets/create": "Added new asset", // TODO: "Added new asset: {name}"?
  "assets/edit": "Edited asset", // TODO: "Edited asset: {name}"?

  // Auth
  "auth/login": null, // Handled in complex callback logic somewhere else
  "auth/logout": null, // TODO: Should there be a message here?

  // CompetenceGroup
  "competenceGroups/fetch": null,
  "competenceGroups/create": "Successfully created new competence group", // TODO: Add name?
  "competenceGroups/edit": "Successfully edited competence group", // TODO: Add name?
  "competenceGroups/remove": "Successfully deleted competence group",

  // ConstraintCheck?

  // Iap
  "iap/run": "Successfully ran IAP",
  "iap/runFillInSamples": "Successfully filled in samples",

  // Integration Sheets
  "integrationSheets/fetchActionSheets": null,
  "integrationSheets/fetchProjectTemplateSheets": null,
  "integrationSheets/fetchSampleSheets": null,
  "integrationSheets/createActionSheet": "Added sheet",
  "integrationSheets/createProjectTemplateSheets": "Added sheet",
  "integrationSheets/createSampleSheet": "Added sheet",
  "integrationSheets/editActionSheet": "Edited sheet",
  "integrationSheets/editProjectTemplateSheet": "Edited sheet",
  "integrationSheets/editSampleSheet": "Edited sheet",
  "integrationSheets/removeActionSheet": "Deleted sheet",
  "integrationSheets/removeProjectTemplateSheet": "Deleted sheet",
  "integrationSheets/removeSampleSheet": "Deleted sheet",
  "integrationSheets/saveSapConversionSheet": "Saved SAP sheet",

  // Loading
  "loading/fetchAll": null,

  // Member
  "members/fetch": null,
  "members/create": "Added member", // TODO: Add initials?
  "members/createAsAdmin": "Added member", // TODO: Add initials?
  "members/edit": "Edited member", // TODO: Add initials?
  "members/archive": "Archived member", // TODO: Add initials?

  // Metrics
  "metrics/fetch": null,

  // ProjectAction
  "projectActions/fetchUnplanned": null,
  "projectActions/fetchPlanned": null,
  "projectActions/editPlanned": "Edited project action",
  "projectActions/move": "Project action put on schedule",
  // TODO: Split `setMovable` in two so we can show the correct message
  "projectActions/setMovable": "Updated project action",
  // "projectActions/setMovable": "Locked project action",
  // "projectActions/setMovable": "Unlocked project action",
  "projectActions/setLocation": "Updated location",
  "projectActions/setLocationUnplanned": "Updated location",
  "projectActions/editUnplanned": "Edited unplanned project action",
  "projectActions/removePlanned": "Unplanned project action",

  // Project
  "projects/fetch": null,
  "projects/create": "Added new project", // TODO: Add name?
  "projects/edit": "Updated project", // TODO: Add name?
  "projects/remove": "Deleted project",
  "projects/delete-unplanned": "Deleted all unplanned projects",
  "projects/lockProject": "Locked actions",
  "projects/unlockProject": "Unlocked actions",

  // ProjectTemplate
  "projectTemplates/fetch": null,
  // TODO: Add action type for creating a template from a project
  "projectTemplates/create": "Created new project template", // TODO: Add name?
  "projectTemplates/createDraft": "Created new project template draft", // TODO: Add name?
  "projectTemplates/edit": "Edited project template", // TODO: Add name?
  "projectTemplates/editDraft": "Edited project template draft", // TODO: Add name?
  "projectTemplates/remove": "Deleted project template",

  // White tasks
  "whiteTasks/remove": "Deleted task successfully",
  "whiteTasks/create": "Added new task", // TODO: "Added new task: {name}"?
  "whiteTasks/fetch": null,
  "whiteTasks/edit": "Edited task successfully",
  "whiteTasks/move": null, // No message when moving
  "whiteTasks/createRecurrence": "Created recurrency",
  "whiteTasks/editRecurrence": null,
  "whiteTasks/removeRecurrence": "Removed the recurrent tasks",

  // Analysis tasks
  "analysisTasks/fetch": null,
  "analysisTasks/edit": "Edited analysis task",
  "analysisTasks/move": null, // No message when moving

  // TeamStatus
  "teamStatus/fetchCurrent": null,
  "teamStatus/start": "Entered Simulation Mode",
  "teamStatus/accept": "Simulation Mode changes applied",
  "teamStatus/discard": "Simulation Mode changes discarded",

  //CustomerAdministration
  "customers/addCustomer": "Added a customer",
  "customers/editCustomer": "Edited the customer",
  "customers/deleteCustomer": "Deleted the customer",

  // Token
  "team/create-api-token": "Created API token",
  "team/delete-api-token": "Deleted API token",

}

const errorMessage = {
  // Analysis
  // TODO: Split `setMovable` in two so we can show the correct message
  // "analyses/setMovable/ERROR": "Failed updating analysis",
  // "analyses/setMovable/ERROR": "Failed locking analysis",
  // "analyses/setMovable/ERROR": "Failed unlocking analysis",
  "analyses/fetchUnplanned/ERROR": "", // TODO: Missing message
  "analyses/fetchUnassignedSamples/ERROR": "", // TODO: Missing message
  "analyses/fetchPlanned/ERROR": "", // TODO: Missing message
  "analyses/remove/ERROR": "Failed unplanning analysis",

  // AnalysisDefinition
  "analysisDefinitions/fetch/ERROR": "", // TODO: Missing message
  "analysisDefinitions/create/ERROR": "Failed adding new analysis definition",
  "analysisDefinitions/remove/ERROR": "Failed deleting analysisdefinition",

  // Asset
  "assets/fetchGraphInfo/ERROR": "", // TODO: Missing message
  "assets/remove/ERROR": "Failed deleting asset",
  "assets/fetch/ERROR": "", // TODO: Missing message
  "assets/create/ERROR": "Failed adding asset",

  // Auth
  "auth/login/ERROR": "Failed logging in",

  // CompetenceGroup
  "competenceGroups/fetch/ERROR": "", // TODO: Missing message
  "competenceGroups/create/ERROR": "Failed creating competence group",
  "competenceGroups/remove/ERROR": "Failed deleting competence group",

  // ConstraintCheck?

  // Iap
  // TODO: Better error reporting!
  "iap/run/ERROR": "Failed running IAP",
  "iap/runFillInSamples/ERROR": "Failed filling in samples",

  // Integration Sheets
  "integrationSheets/fetchActionSheets/ERROR": "", // TODO: Missing message
  "integrationSheets/fetchProjectTemplateSheets/ERROR": "", // TODO: Missing message
  "integrationSheets/fetchSampleSheets/ERROR": "", // TODO: Missing message
  "integrationSheets/createActionSheet/ERROR": "Failed adding sheet",
  "integrationSheets/createProjectTemplateSheets/ERROR": "Failed adding sheet",
  "integrationSheets/createSampleSheet/ERROR": "Failed adding sheet",
  "integrationSheets/editActionSheet/ERROR": "Failed editing sheet",
  "integrationSheets/editProjectTemplateSheet/ERROR": "Failed editing sheet",
  "integrationSheets/editSampleSheet/ERROR": "Failed editing sheet",
  "integrationSheets/removeActionSheet/ERROR": "An error occurred when trying to delete sheet",
  "integrationSheets/removeProjectTemplateSheet/ERROR": "An error occurred when trying to delete sheet",
  "integrationSheets/removeSampleSheet/ERROR": "An error occurred when trying to delete sheet",

  // Loading
  // See auth.js, the user is logged out when fetching all failed
  // Done to prevent infinite loading!
  "loading/fetchAll/ERROR": "Failed loading",

  // Member
  "members/fetch/ERROR": "", // TODO: Missing message
  "members/create/ERROR": "Failed adding member",
  "members/createAsAdmin/ERROR": "Failed adding member",
  "members/archive/ERROR": "Failed archiving member",

  // Metrics
  "metrics/fetch/ERROR": "", // TODO: Missing message

  // ProjectAction
  "projectActions/fetchUnplanned/ERROR": "", // TODO: Missing message
  "projectActions/fetchPlanned/ERROR": "", // TODO: Missing message
  // "projectActions/editPlanned/ERROR": "Failed updating action",
  "projectActions/move/ERROR": "Failed moving action onto schedule",
  // "projectActions/setMovable/ERROR": "Failed updating action",
  // "projectActions/editUnplanned/ERROR": "Failed updating action",
  "projectActions/removePlanned/ERROR": "Failed unplanning action",
  "projectActions/setLocation/ERROR" : "Failed to update location",
  "projectActions/setLocationUnplanned/ERROR" : "Failed to update location",

  // Project
  "projects/fetch/ERROR": "", // TODO: Missing message
  "projects/create/ERROR": "Failed adding new project",
  // "projects/edit/ERROR": "Failed updating project",
  "projects/remove/ERROR": "Failed deleting project",
  "projects/delete-unplanned/ERROR": "Failed deleting unplanned projects",
  "projects/lockProject/ERROR": "Failed Locking actions",
  "projects/unlockProject/ERROR": "Failed unlocking actions",

  // ProjectTemplate
  "projectTemplates/fetch/ERROR": "", // TODO: Missing message
  "projectTemplates/create/ERROR": "Failed creating new project template",
  "projectTemplates/createDraft/ERROR": "Failed creating new project template draft",
  "projectTemplates/remove/ERROR": "Failed deleting project template",

  // White tasks
  "whiteTasks/remove/ERROR": "Failed deleting task",
  "whiteTasks/create/ERROR": "Failed creating",
  "whiteTasks/fetch/ERROR": "", // TODO: Missing message
  "whiteTasks/move/ERROR": "", // TODO: Missing message

  // Analysis tasks
  "analysisTasks/fetch/ERROR": "", // TODO: Missing message
  "analysisTasks/move/ERROR": "", // TODO: Missing message

  // TeamStatus
  "teamStatus/fetchCurrent/ERROR": "",
  "teamStatus/start/ERROR": "Failed entering Simulation Mode",
  "teamStatus/accept/ERROR": "Failed applying Simulation Mode changes",
  // TODO: Find a way to display this error properly when logging out!
  "teamStatus/discard/ERROR": "Failed discarding Simulation Mode changes",

  // CustomerAdministration
  "customers/deleteCustomer/ERROR": "Could not delete the customer",
  "customers/addCustomer/ERROR": "Could not add the customer",
  "customers/editCustomer/ERROR": "Could not edit the customer",
  
  // Token
  "team/create-api-token/ERROR": "Could not create API token",
  "team/delete-api-token/ERROR": "Could not delete API token",
}

function getError({ type, err }) {
  if (errorMessage[type]) {
    // We give the error a custom message based on the action the user tried to perform
    return `${errorMessage[type]}: ${err.message}`
  } else if (err) {
    // If an unhandled / un-customized error occurred
    return err.message
  }
  return null
}

function getTime(message) {
  // Kinda arbitary calculation to find how long to display a message
  return Math.max(message.length * 100, 3000)
}

const initialState = {
  message: null,
  time: null,
  variant: null,
}

export function reducer(state = initialState, action) {
  // We do a bit out of the ordinary in this reducer, so that our switch statement doesn't get extremely large

  // Display success messages based on action type
  if (successMessage[action.type]) {
    return {
      message: successMessage[action.type],
      time: getTime(successMessage[action.type]),
      variant: "success",
    }
  }

  // Display error message based on action type
  const error = getError(action)

  if (error) {
    return {
      message: action.err.name === "DoNotShowError" ? "DoNotShowError" : error,
      time: getTime(error),
      variant: action.err.name === "IAPinUseError" || action.err.name === "AnalysisUpdatedError" || action.type === "analyses/moveSmartIntoPlan/ERROR" ? "warning" : "danger",
    }
  }

  // Aaaand back to normal redux stuff again
  switch (action.type) {
    // TODO: Set `time` based on length of message?
    case "message/info":
      return {
        message: action.res,
        time: getTime(action.res),
        variant: "primary",
      }
    case "message/success":
      return {
        message: action.res,
        time: getTime(action.res),
        variant: "success",
      }
    case "message/warning":
      return {
        message: action.res,
        time: getTime(action.res),
        variant: "warning",
      }
    case "message/error":
      return {
        message: action.res,
        time: getTime(action.res),
        variant: "danger",
      }
    case "message/clear":
      return initialState
    default:
      return state
  }
}

export function info(message) {
  return { type: "message/info", res: message }
}

export function success(message) {
  return { type: "message/success", res: message }
}

export function warning(message) {
  return { type: "message/warning", res: message }
}

export function error(message) {
  return { type: "message/error", res: message }
}

export function clear() {
  return { type: "message/clear" }
}
