import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConfigContainer } from "components"

import { auth, visual } from "state_management"
import "./index.scss"

export default function GlobalMessageConfig() {
  const dispatch = useDispatch()
  const globalMessage = useSelector((state) => state.auth.globalMessage)
  const [inputValue, setInputValue] = useState(globalMessage || "")

  const onClose = () => {
    dispatch(visual.showGlobalMessageConfig(false))
  }

  const handleChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSave = () => {
        // Trim trailing spaces, tabs, and newlines
        const trimmedValue = inputValue.replace(/[\s\uFEFF\xA0]+$/g, '');
    dispatch(auth.setGlobalMessage(trimmedValue))
    onClose()
  }

  const handleDelete = () => {
    dispatch(auth.setGlobalMessage(""))
    onClose()
  }

  function adjustTextareaHeight(event) {
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';
  }

  return (
    <div className="Global-Message-Config">
      <ConfigContainer
        title="Global Message"
        submitHandler={handleSave}
        cancelHandler={onClose}
        deleteIsImportant={true}
        confirmDeletionText={"Are you sure you want to clear the global message? This will make it disappear for all users."}
        deleteHandler={handleDelete}
        deleteTitle={"Clear"}
      >
        <textarea className={"input-field"} type="text" id="input-field-id" value={inputValue} 
        onChange={(event) => {
          handleChange(event)
          adjustTextareaHeight(event)
        }}/>
      </ConfigContainer>
    </div>
  )
}
