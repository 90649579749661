import React from "react";
import "./index.scss";
import { Title, Container, DurationInput, Select } from "components/Form";
import { IoMdClose } from "react-icons/io"

export default function AssetSetup({
  index,
  assetRequirement,
  setAssetRequirement,
  removeAssetRequirement,
  assets,
  isDefinition,
  disabled,
  sortedCollection
}) {
  const { assetSetuptime, assetPerSampleDuration, assetOffset, allowedAssetIds, assetId } = assetRequirement;

  const assetsArray = Object.values(assets);
  const sortedAssets = assetsArray.sort((a, b) => a.name.localeCompare(b.name));
  const assetName = index === 0 ? 'Primary' : index + 1;

  // Find the allowed asset ids in sortedAssets
  const allowedAssetIdsArray = allowedAssetIds.map((id) => sortedCollection.find((asset) => asset.id === id))

  return (
    <div className="container-compartment">
      <p>{'Asset Setup ' + assetName}</p>
      <Container>
        {isDefinition && (<IoMdClose className="remove-asset-btn" title="Remove Asset" onClick={() => removeAssetRequirement(index)} />)}
        <Title>Asset setuptime (HH:MM)</Title>
        <DurationInput 
          value={assetSetuptime} 
          onChange={(e) => setAssetRequirement('assetSetuptime', e)} 
          required 
        />
      </Container>
      <Container>
        <Title>Asset per sample duration (HH:MM:SS)</Title>
        <DurationInput 
          required 
          withSeconds 
          value={assetPerSampleDuration} 
          onChange={(e) => setAssetRequirement('assetPerSampleDuration', e)}
        />
      </Container>
      <Container>
        <Title>Asset offset (HH:MM)</Title>
        <DurationInput 
          value={assetOffset} 
          onChange={(e) => setAssetRequirement('assetOffset', e)} 
          required 
        />
      </Container>
      {index > 0 && !isDefinition && (
        <Container>
          <Title>Used Asset</Title>
          <Select
            name="Used Asset"
            collection={allowedAssetIdsArray}
            value={assetId}
            onChange={(e) => {setAssetRequirement('assetId', e[0])}}
            menuPosition="fixed"
            isMulti={false}
          />
        </Container>
      )}
      {isDefinition && (
        <Container>
          <Title>Allowed assets</Title>
          <Select
            name="Allowed assets"
            collection={sortedAssets}
            value={allowedAssetIds}
            onChange={(e) => setAssetRequirement('allowedAssetIds', e)}
            menuPosition="fixed"
          />
        </Container>
      )}
    </div>
  );
}
