
export function isPasswordValid(password, member=null) {
    // Define all password requirements and their respective regex patterns
    const requirements = [
      { name: "at least 8 characters", pattern: /^.{8,}$/ },
      { name: "at least 1 lowercase letter", pattern: /[a-z]/ },
      { name: "at least 1 uppercase letter", pattern: /[A-Z]/ },
      { name: "at least 1 number", pattern: /\d/ },
    ]

    if (member && !password) {
      return { valid: true, missingRequirements: [] }
    }

    let missingRequirements = []
  
    // Check each requirement
    for (let requirement of requirements) {
      if (!requirement.pattern.test(password)) {
        missingRequirements.push(requirement.name)
      }
    }

    // Return validity and list of missing requirements
    return {
      valid: missingRequirements.length === 0,
      missingRequirements: missingRequirements,
    }
  }