import React from "react"
import "./index.scss"

// A custom error boundary.
// You can wrap this around components, and if an inner component crashes, this
// will show the error.
// Note: Error boundaries have to be classes, hence the reason why this is that.
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <div className="Pheading">Something went wrong</div>
          <p>Try reloading, or contact support if the problem persists!</p>
        </div>
      )
    }
    return this.props.children
  }
}
