import { request } from "utils/request"
import { asyncAction } from "./common"
import { DateTime } from "luxon"

const initialState = {
  isLoading: false,
  needsRefresh: false,
  sampleGroups: null,
  sampleGroupsSortedByProduct: null,
  shouldBeDoneSamples: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "sampleView/fetch/BEGIN":
      return { ...state, isLoading: true }
    case "sampleView/fetch":
      const uniqueNames = {}
      action.res.items.forEach((obj) => {
        if (!uniqueNames[obj.productName]) {
          uniqueNames[obj.productName] = {
            [obj.productName]: [],
          }
        }
        uniqueNames[obj.productName][obj.productName].push(obj)
      })
      
      // sortedByProduct uses a reference of values from action.res
      // this causes both to update when we change a sample priority
      const sortedByProduct = Object.values(uniqueNames)

      sortedByProduct.sort((a, b) => {
        const keyA = Object.keys(a)[0];
        const keyB = Object.keys(b)[0];
        // the 'null' check is to make sure that 'null' is always at the last index of the sorted array
        if (keyA === 'null') {
          return 1;
        }
        if (keyB === 'null') {
          return -1;
        }
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
      return { ...state, isLoading: false, needsRefresh: false, sampleGroups: action.res.items, sampleGroupsSortedByProduct: sortedByProduct, shouldBeDoneSamples: action.res.should_be_done_samples}
    case "auth/logout":
      return initialState
    case "analyses/removeAllPlanned":
    case "analyses/remove":
    case "analyses/moveIntoPlan":
    case "analysisTasks/move":
    case "analysisTasks/edit":
    case "iap/run":
    case "iap/runFillInSamples":
    case "samples/unplan-samples":
    case "samples/assign-samples":
      return {...initialState, needsRefresh: true}
    case "samples/changePriority":
      const changedSample = state.sampleGroups.find(sample => sample.sampleName === action.args[1]);
      changedSample.maxPriority = action.args[0]
      const updatedSampleGroups = state.sampleGroups.map(sample => {
        if (sample.sampleName === changedSample.sampleName) {
          return changedSample;
        } else {
          return sample;
        }
      });
      return { ...state, sampleGroups: updatedSampleGroups }
    case "samples/fail-samples":
      const [analysisId, , isSampleView, sampleName, productName] = action.args
      if(!isSampleView) return {...initialState, needsRefresh: true}
      return {
        ...state,
        sampleGroups: state.sampleGroups.map((sample) => {
          if (sample.sampleName === sampleName) {
            return {
              ...sample,
              isDone: false,
              analyses: sample.analyses.map((sampleAnalysis) => {
                if (sampleAnalysis.analysisId === analysisId) {
                  return {
                    ...sampleAnalysis,
                    analysesPlanned: false,
                    tasksStopped: false,
                  }
                }
                return sampleAnalysis
              }),
            }
          }
          return sample
        }),
        sampleGroupsSortedByProduct: state.sampleGroupsSortedByProduct.map((product) => {
          if (Object.keys(product)[0] === productName) {
            return {
              ...product,
              [productName]: product[productName].map((sample) => {
                if (sample.sampleName === sampleName) {
                  return {
                    ...sample,
                    isDone: false,
                    analyses: sample.analyses.map((sampleAnalysis) => {
                      if (sampleAnalysis.analysisId === analysisId) {
                        return {
                          ...sampleAnalysis,
                          analysesPlanned: false,
                          tasksStopped: false,
                        }
                      }
                      return sampleAnalysis
                    }),
                  }
                }
                return sample
              }),
            }
          }
          return product
        }),
      }
    default:
      return state
  }
}

export const fetch = asyncAction("sampleView/fetch", (dispatch) => {
  // Fetch from today plus two days
  const after = DateTime.local().set({hour: 12}).plus({ days: 2 }).toUTC().toISO()
  return request("GET", `/analyses/sample-view?after=${after}`).then((res) => {
    return res
  })
})