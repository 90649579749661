import React from "react"
import { useDispatch, useSelector } from "react-redux"

import "./index.scss"
import { FiChevronsLeft } from "react-icons/fi"

import MetricsTable from "./MetricsTable"
import IAP from "./IAP"

import {
  ModeToggleButtons,
} from "./IAP/TeamSpecificComponents"
import { visual } from "state_management"

// The left menu on the schedule.
export default function LeftMenu({ open }) {
  const dispatch = useDispatch()

  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const isMixed = showProjects && showAnalyses

  return (
    <div className={"LeftMenu" + (open ? " open" : "")}>
      <div className="LeftMenuHeader-container">
        <FiChevronsLeft
          className="CloseMenu"
          title="Close panel"
          onClick={() => dispatch(visual.setMenuVisibility("leftmenu", false))}
        />
        {isMixed && <ModeToggleButtons /> }
      </div>
      <div className="LeftMenuBody-container">
        <IAP />
        <MetricsTable />
      </div>
    </div>
  )
}
