import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import "./index.scss";

import { Popup, GraphDesignTool, ConfirmDialog, PerformerConstraints, ConfigContainer } from "components";
import { deleteNode, initialNodeCoords } from "components/GraphDesignTool";
import { AnalysisTaskConfig, AnalysisConnectionConfig, AnalysisGroupConfig } from "components/Config";
import { Text, Number, Container, ColorPicker, Title } from "components/Form";

import { message, analysisDefinitions, createMap, addToMap, removeFromMap, analysisGroups } from "state_management";
import { FiPlus, FiCircle, FiSettings } from "react-icons/fi";
import { FaArrowUp } from 'react-icons/fa';
import useNavigationBlocker from "utils/hooks/useBlocker"

// Configuration page for setting up the team's analyses.
export default function AnalysisConfigurationPage({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTask, setActiveTask] = useState(null);
  const [activeConnection, setActiveConnection] = useState(null);
  const [addConfig, setAddConfig] = useState(false);
  const [analysisGroupConfigId, setAnalysisGroupConfigId] = useState(null);
  const [showPerformerConstraints, setShowPerformerConstraints] = useState(false);

  const competenceGroups = useSelector((state) => state.competenceGroups);
  const analysisDefinition = useSelector((state) => state.analysisDefinitions[id]);
  const analysisNames = Object.values(useSelector((state) => state.analysisDefinitions)).map(
    (analysis) => analysis.name
  );
  const activeAnalysisGroupId = useSelector((state) => state.analysisGroups.activeAnalysisGroupId);
  const [isDraft, setIsDraft] = useState(true);

  const [tasks, setTasks] = useState(analysisDefinition ? createMap(analysisDefinition.taskDefinitions) : {});
  const [connections, setConnections] = useState(
    analysisDefinition ? createMap(analysisDefinition.taskDefinitionConnections) : {}
  );
  const [performerConstraints, setPerformerConstraints] = useState(
    analysisDefinition
      ? analysisDefinition.performerConstraints.map((constraint) => ({
          firstDefinitionId: constraint.firstDefinitionId,
          secondDefinitionId: constraint.secondDefinitionId,
          performerConstraint: constraint.performerConstraint,
        }))
      : []
  );

  const [color, setColor] = useState(analysisDefinition ? analysisDefinition.color : null);
  const [name, setName] = useState(analysisDefinition ? analysisDefinition.name : "");
  const [maxSampleCount, setMaxSampleCount] = useState(analysisDefinition ? analysisDefinition.maxSampleCount : null);
  const [minSampleCount, setMinSampleCount] = useState(analysisDefinition ? analysisDefinition.minSampleCount : 0);
  const [shouldCheckChanges, setShouldCheckChanges] = useState(true);
  const [leavePageObject, setLeavePageObject] = useState({ msg: "", location: "", display: false });
  const analysisGroupsDropdown = useSelector((state) => state.analysisGroups ? state.analysisGroups.groups : []);
  const [mode, setMode] = useState("node");
  const [nodeCoords, setNodeCoords] = useState(() => initialNodeCoords(tasks, connections));


  //Decides whether or not the user has changed any fields on the page
  function somethingHasChanged(a) {
    var msg = ""

    if (!a) {
      msg = name ? msg.concat("- Analysis Name ") : msg
      msg = color ? msg.concat("- Color ") : msg
      msg = maxSampleCount ? msg.concat("- Max Sample Count ") : msg
      msg = minSampleCount ? msg.concat("- Min Sample Count ") : msg
      msg = performerConstraints.length ? msg.concat("- Performer Constraints Count ") : msg
      msg = Object.values(tasks).length ? msg.concat("- Task Count ") : msg
      msg = Object.values(connections).length ? msg.concat("- Connection Count ") : msg
      return msg
    }
    //Analysis basic checks
    msg = a.color !== color ? msg.concat("- Color ") : msg
    msg = a.maxSampleCount !== maxSampleCount ? msg.concat("- Max Sample Count ") : msg
    msg = a.minSampleCount !== minSampleCount ? msg.concat("- Min Sample Count ") : msg
    msg = a.name !== name ? msg.concat("- Analysis Name ") : msg

    //Task Connection checks
    var connectionsA = a.taskDefinitionConnections
    var connectionsB = Object.values(connections)
    var Connections = false

    if (connectionsA.length === connectionsB.length) {
      for (let i = 0; i < connectionsA.length; i++) {
        Connections = connectionsA[i].id !== connectionsB[i].id ? true : Connections
        Connections = connectionsA[i].isWorkdayConnection !== connectionsB[i].isWorkdayConnection ? true : Connections
        Connections = connectionsA[i].nextId !== connectionsB[i].nextId ? true : Connections
        Connections = connectionsA[i].prevId !== connectionsB[i].prevId ? true : Connections
        Connections = connectionsA[i].samplePercentage !== connectionsB[i].samplePercentage ? true : Connections
        Connections = connectionsA[i].timeBetween.max !== connectionsB[i].timeBetween.max ? true : Connections
        Connections = connectionsA[i].timeBetween.min !== connectionsB[i].timeBetween.min ? true : Connections
      }
    } else {
      msg = msg.concat("- Connection Count")
    }
    msg = Connections ? msg.concat("- Connections") : msg

    //Performer Constraint checks
    var constra = false
    if (a.performerConstraints.length === performerConstraints.length) {
      for (let i = 0; i < a.performerConstraints.length; i++) {
        constra =
          a.performerConstraints[i].firstDefinitionId !== performerConstraints[i].firstDefinitionId ? true : constra
        constra =
          a.performerConstraints[i].performerConstraint !== performerConstraints[i].performerConstraint ? true : constra
        constra =
          a.performerConstraints[i].secondDefinitionId !== performerConstraints[i].secondDefinitionId ? true : constra
      }
    } else {
      msg = msg.concat("- Performer Constraints Count")
    }
    msg = constra ? msg.concat("- Performer Constraints  ") : msg

    //Task definition checks
    let tasksA = a.taskDefinitions || [];
    let tasksB = Object.values(tasks || {});

    if (tasksA.length === tasksB.length) {
        tasksA.forEach((task, i) => {
            // Check for setup time and per sample duration at task level
            let setupTimeChanged = task.setuptime !== tasksB[i].setuptime;
            let sampleDurationChanged = task.perSampleDuration !== tasksB[i].perSampleDuration;

            // Check asset requirements within each task
            const assetReqA = task.assetRequirements || [];
            const assetReqB = tasksB[i].assetRequirements || [];
            const addedOrRemovedAsset = assetReqA.length !== assetReqB.length;

            const assetRequirementsChanged = assetReqA.length === assetReqB.length && assetReqA.some((assetReq, j) => {
                return [
                    assetReq.allowedAssetIds.length !== assetReqB[j].allowedAssetIds.length ||
                    assetReq.allowedAssetIds.some((id, k) => id !== assetReqB[j].allowedAssetIds[k]),
                    assetReq.assetId !== assetReqB[j].assetId,
                    assetReq.assetOffset !== assetReqB[j].assetOffset,
                    assetReq.assetPerSampleDuration !== assetReqB[j].assetPerSampleDuration,
                    assetReq.assetSetuptime !== assetReqB[j].assetSetuptime
                ].some(Boolean);
            });

            if (assetRequirementsChanged) {
                msg += `- Asset Requirements for Asset ${i + 1} `;
            }
            if (setupTimeChanged) {
                msg += `- Setup Time for People Setup`;
            }
            if (sampleDurationChanged) {
                msg += `- Per Sample Duration for People Setup`;
            }
            if (addedOrRemovedAsset) {
                msg += `- Asset has been added or removed`;
            }
        });
    } else {
        msg += "- Task Count ";
    }

    return msg
  }

  function FEcheckconstraintMessage(res,isDraft, isNew) {
    if (isNew && analysisNames.includes(res.name)) {
      return "Analysis Name must be unique"
    } else if(!res.name) {
      return "Please fill out the Analysis Name"
    } else if(!res.color) {
      return "Please choose a Color for the analysis"
    } else if(res.minSampleCount !== null && res.maxSampleCount !== null && res.minSampleCount > res.maxSampleCount) {
      return "Min sample count must be lower than max sample count"
    } else if(!isDraft && res.taskDefinitions.length === 0) {
      return "The analysis must contain a task definition"
    }
    // when converting a draft to definition, or 'Save As' in definition
    if (!(isDraft || isNew) || (isNew && !isDraft)) {
      const taskFound = res.taskDefinitions.some(task => !task.name || task.allowedGroupIds.length === 0 || typeof(task.setuptime) !== 'number')
      if(taskFound) {
        return "Please fill out required task parameters"
      }
      return ""
   }
  }

  const blocker = useNavigationBlocker(shouldCheckChanges && !leavePageObject.display && somethingHasChanged(analysisDefinition));
  if (blocker.state === "blocked" && !leavePageObject.display) {
    setLeavePageObject({
      msg: somethingHasChanged(analysisDefinition),
      location: blocker.tx?.nextLocation?.pathname || "",
      display: true,
    })
  }

  return (
    <>
    <ConfigContainer
      title={ analysisDefinition ? (analysisDefinition.isDraft ? "Analysis DRAFT" : "Analysis Definition" ) : "Analysis DRAFT"}
      cancelHandler={() => navigate("/setup/analyses")}
      submitHandler={() => {
        setName(name.trim())
        setShouldCheckChanges(false)
        const trimmedName = name.trim()
        const res = {
          name: trimmedName,
          color,
          maxSampleCount,
          minSampleCount,
          performerConstraints,
          analysisGroupIds: activeAnalysisGroupId ? [activeAnalysisGroupId] : [],
          taskDefinitions: Object.values(tasks).map((task) => ({
            coordinates: nodeCoords[task.id],
            name: task.name,
            tempId: String(task.id),
            allowedGroupIds: task.allowedGroupIds ? task.allowedGroupIds.filter((groupId) => competenceGroups[groupId]) : [],
            assetRequirements: task.assetRequirements ? task.assetRequirements  : [],
            setuptime: task.setuptime,
            perSampleDuration: task.perSampleDuration,
            description: task.description,
          })),
          taskDefinitionConnections: Object.values(connections).map((connection) => ({
            tempNextId: String(connection.nextId),
            tempPrevId: String(connection.prevId),
            samplePercentage: connection.samplePercentage,
            timeBetween: connection.timeBetween,
            isWorkdayConnection: connection.isWorkdayConnection,
          })),
        }
        if(FEcheckconstraintMessage(res,false,false)) {
          return dispatch(message.warning(FEcheckconstraintMessage(res,false,false)))
        }
        if (!id) {
          dispatch(analysisDefinitions.create(res)).then(() => navigate("/setup/analyses"))
        } else {
          // Ugly quick fix to prevent duplication
          return dispatch(analysisDefinitions.edit(res, id))
        }
      }}
      saveAsHandler={() => {
        setIsDraft(false)
        setName(name.trim())
        const trimmedName = name.trim()
        setShouldCheckChanges(false)
        const res = {
          name: trimmedName,
          color,
          maxSampleCount,
          minSampleCount,
          performerConstraints,
          analysisGroupIds: activeAnalysisGroupId ? [activeAnalysisGroupId] : [],
          taskDefinitions: Object.values(tasks).map((task) => ({
            name: task.name,
            tempId: String(task.id),
            allowedGroupIds: task.allowedGroupIds ? task.allowedGroupIds.filter((groupId) => competenceGroups[groupId]) : [],
            assetRequirements: task.assetRequirements ? task.assetRequirements  : [],
            setuptime: task.setuptime,
            perSampleDuration: task.perSampleDuration,
            description: task.description,
          })),
          taskDefinitionConnections: Object.values(connections).map((connection) => ({
            tempNextId: String(connection.nextId),
            tempPrevId: String(connection.prevId),
            samplePercentage: connection.samplePercentage,
            timeBetween: connection.timeBetween,
            isWorkdayConnection: connection.isWorkdayConnection,
          })),
        }
        if(FEcheckconstraintMessage(res,isDraft,true)) {
          return dispatch(message.warning(FEcheckconstraintMessage(res,isDraft,true)))
        }
        dispatch(analysisDefinitions.create(res)).then(() => navigate("/setup/analyses"))
      }}
      saveDraftHandler={() => {
        setIsDraft(true)
        setName(name.trim())
        setShouldCheckChanges(false)
        const trimmedName = name.trim()
        const res = {
          name: trimmedName,
          color,
          maxSampleCount,
          minSampleCount,
          performerConstraints,
          analysisGroupIds: activeAnalysisGroupId ? [activeAnalysisGroupId] : [],
          taskDefinitions: Object.values(tasks).map((task) => ({
            coordinates: nodeCoords[task.id],
            name: task.name,
            tempId: String(task.id),
            allowedGroupIds: task.allowedGroupIds ? task.allowedGroupIds.filter((groupId) => competenceGroups[groupId]) : [],
            assetRequirements: task.assetRequirements ? task.assetRequirements  : [],
            setuptime: task.setuptime ? task.setuptime : 0,
            perSampleDuration: task.perSampleDuration,
            description: task.description,
          })),
          taskDefinitionConnections: Object.values(connections).map((connection) => ({
            tempNextId: String(connection.nextId),
            tempPrevId: String(connection.prevId),
            samplePercentage: connection.samplePercentage,
            timeBetween: connection.timeBetween,
            isWorkdayConnection: connection.isWorkdayConnection,
          })),
        }
        if(FEcheckconstraintMessage(res,isDraft,false)) {
          return dispatch(message.warning(FEcheckconstraintMessage(res,isDraft,false)))
        }
        if (!id) {
          dispatch(analysisDefinitions.createDraft(res)).then(() => navigate("/setup/analyses"))
        } else {
          // Ugly quick fix to prevent duplication
          return dispatch(analysisDefinitions.editDraft(res, id))
        }
      }}
      saveAsName="Save as new analysis"
      submitButtonName="Save"
      cancelButtonName="Cancel"
      deleteIsImportant
      isDraft={analysisDefinition ? analysisDefinition.isDraft : true}
      deleteHandler={
        id ? () => {
            setShouldCheckChanges(false)
            dispatch(analysisDefinitions.remove(id)).then(() => navigate("/setup/analyses"))
          }
            : null
        }
      >
        {leavePageObject.display && (
          <ConfirmDialog
            onConfirm={() => {
              if (blocker.state === "blocked") {
                blocker.proceed();
              } else {
                navigate(leavePageObject.location);
              }
            }}
            onCancel={() => {
              if (blocker.state === "blocked") {
                blocker.reset();
                setLeavePageObject({ ...leavePageObject, display: false });
              } else {
                setLeavePageObject({ ...leavePageObject, display: false });
              }
            }}
          >
            <b>Are you sure you want to exit?</b>
            <div className="configurationPage-popup-list-of-changes">
              <p>There are unsaved changes in:</p>
              <ul>
                {leavePageObject.msg.split("-").slice(1).map((message, index) => (
                  <li key={index}>{message.trim()}</li>
                ))}
              </ul>
            </div>
          </ConfirmDialog>
        )}
      <div className="AnalysisConfigurationPage-metadata-container">
        <Container>
          <Title className="AnalysisName">
            Analysis Name<div className="red-text">*</div>
          </Title>
          <Text required value={name} onChange={setName} />
        </Container>
        <InputGroup className="Analysis-color">
          <Title>
            Color<div className="red-text">*</div>
          </Title>
          <ColorPicker required value={color} onChange={setColor} />
        </InputGroup>
        <div className="ConfigPage-group-sample-container">
          <div className="ConfigPage-analysis-group">
          <Title>
            Analysis group
          </Title>
          <Dropdown
            className="AnalysisGroup-selector"
            onSelect={(eventKey) => {
              if (eventKey === "add"){
                setAddConfig(true)
              } else if (eventKey === "noGroup"){
                dispatch(analysisGroups.changeActiveAnalysisGroupId(null))
              } else {
                dispatch(analysisGroups.changeActiveAnalysisGroupId(eventKey))
              }
            }}
          >
            <Dropdown.Toggle variant="primary">{activeAnalysisGroupId ? analysisGroupsDropdown[activeAnalysisGroupId].name : "No group"}</Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(analysisGroupsDropdown).map((analysisGroup) => (
                <Dropdown.Item key={analysisGroup.id} eventKey={analysisGroup.id}>
                  {analysisGroup.name}<FiSettings className="AnalysisGroup-setting" onClick={() => {
                    setAddConfig(true)
                    setAnalysisGroupConfigId(analysisGroup.id)
                  }} />
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item key="add" eventKey="add">
                <FiPlus /> Add analysis group
              </Dropdown.Item>
              <Dropdown.Item key="noGroup" eventKey="noGroup">
                <FiCircle /> No group
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
          <div className="ConfigPage-sample-count">
            <Title>
              Max Sample Count<div className="red-text">*</div>
            </Title>
            <Number
              style={{
                textAlign: "center",
                width: maxSampleCount !== null ? `calc(30px + ${maxSampleCount.toString().length * 9}px)` : "30px",
              }}
              required={!isDraft}
              min={1}
              value={maxSampleCount}
              onChange={setMaxSampleCount}
            />
            <Title>Min Sample Count</Title>
            <Number
              style={{
                textAlign: "center",
                width: minSampleCount !== null ? `calc(30px + ${minSampleCount.toString().length * 9}px)` : "30px",
              }}
              min={0}
              value={minSampleCount}
              onChange={setMinSampleCount}
            />
          </div>
        </div>
      </div>
      <p className="Pheading">Analysis Design Tool</p>
      <div className="AnalysisConfigurationPage-drawing">
        <GraphDesignTool
          required
          nodes={tasks}
          edges={connections}
          nodeColor={color ? color.toUpperCase() : "#FFFFFF"}
          onClear={() => {
            setTasks({})
            setConnections({})
          }}
          onCreateNode={(task) => {
            setTasks({ ...tasks, [task.id]: task })
          }}
          onEditNode={(task) => setActiveTask(task)}
          onDeleteNode={(id) => {
            const [newTasks, newConnections] = deleteNode(tasks, connections, id)
            setTasks(newTasks)
            setConnections(newConnections)
            setPerformerConstraints(
              performerConstraints
                .filter((constraint) => constraint.firstDefinitionId !== id)
                .filter((constraint) => constraint.secondDefinitionId !== id)
            )
          }}
          onCreateEdge={(prevId, nextId) => {
            const conn = {
              prevId,
              nextId,
              id: `${prevId}-${nextId}`,
              timeBetween: {
                min: 0,
                max: null,
              },
              samplePercentage: 100,
            }
            setConnections({ ...connections, [conn.id]: conn })
          }}
          onEditEdge={(connection) => setActiveConnection(connection)}
          configuredNodes={Object.values(tasks)
            // When editing a task you can't press "Save" before the name is filled - so this is valid!
            .filter((task) => task.name && task.allowedGroupIds?.length)
            .map((task) => task.id)}
          onModeChange={() => setMode(mode === "node" ? "edge" : "node")}
          mode={mode}
          onChangeInNodeCoords={setNodeCoords}
          isDraft={isDraft}
        />
      </div>
      <p className="Pheading ConfigurationPage">Task Performer Constraints</p>
      <div className="ConfigurationPage-performerConstraints-button">
        <Button variant="link" size="lg" onClick={() => setShowPerformerConstraints(!showPerformerConstraints)}>
            {showPerformerConstraints ? <FaArrowUp className="fa-arrowUp-icon"/> : <FiPlus className="fi-plus-icon"/>}
        </Button>
      </div>
      {showPerformerConstraints && (
          <>
            <PerformerConstraints
              tasks={tasks}
              performerConstraints={performerConstraints}
              onConstraintsChange={setPerformerConstraints}
            />
          </>
        )}
        <hr />
    </ConfigContainer>
    {activeTask && (
        <Popup nonScrollable onCancel={() => setActiveTask(null)}>
          <AnalysisTaskConfig
            title="Edit task"
            isDefinition
            task={activeTask}
            submitHandler={(res) => {
              setTasks(addToMap(tasks, { ...activeTask, ...res }))
              setActiveTask(null)
            }}
            isDraft={isDraft}
            deleteHandler={null}
            cancelHandler={() => setActiveTask(null)}
          />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <AnalysisGroupConfig
            analysisGroup={analysisGroupsDropdown[analysisGroupConfigId]}
            onClose={() => {
              setAddConfig(false)
              setAnalysisGroupConfigId(null)
            }}
            onSubmit={() => {
              setAddConfig(false)
              setAnalysisGroupConfigId(null)
            }}
          />
        </Popup>
      )}
      {activeConnection && (
        <Popup nonScrollable onCancel={() => setActiveConnection(null)}>
          <AnalysisConnectionConfig
            connection={activeConnection}
            taskFrom={tasks[activeConnection.prevId]}
            taskTo={tasks[activeConnection.nextId]}
            submitHandler={(res) => {
              setConnections(addToMap(connections, { ...activeConnection, ...res }))
              setActiveConnection(null)
            }}
            deleteHandler={() => {
              setConnections(removeFromMap(connections, activeConnection))
              setActiveConnection(null)
            }}
            cancelHandler={() => setActiveConnection(null)}
          />
        </Popup>
      )}
    </>
  )
}

