import { request } from "utils/request"
import { createMap, replaceInMap, removeFromMap, asyncAction, addToMap, getRange, removeFromList } from "./common"
import { loading } from "."
import { dynamicSortMultiple } from "utils/scripts/sort"

const initialState = {
  unplannedAnalyses: {},
  plannedAnalyses: {},
  unassignedSamples: null,
  isLoadingUnassignedSamples: false,
  unplanning: false,
  unplannedAnalysesSorted: [],
}

export function reducer(state = initialState, action) {
  const temp = { ...state }
  switch (action.type) {
    case "analyses/setMovable":
    case "analyses/setSamplesLocked":
      return { ...state, plannedAnalyses: replaceInMap(state.plannedAnalyses, action.res) }
    case "analyses/moveIntoPlan":
      temp.unplannedAnalyses = removeFromMap(temp.unplannedAnalyses, action.res)
      temp.unplannedAnalysesSorted = removeFromList(temp.unplannedAnalysesSorted, action.res)
      temp.plannedAnalyses = addToMap(temp.plannedAnalyses, action.res.analysis)
      return { ...temp }
    case "analysisDefinitions/create":
    case "analysisDefinitions/edit":
    case "analysisDefinitions/remove":
      return { ...state, unassignedSamples: null }
    case "analyses/fetchUnplanned":
      // The three lines below are used to sort the Right-Side based on priority-mode. For this we introduce a 'sortedUnplannedAnalyses' state array
      const sortedUnplannedAnalyses = action.res.items.sort(
        dynamicSortMultiple(
          action.res.sortingParameter === "DEADLINE" ? "minSampleDeadline" : "minPriority",
          action.res.sortingParameter === "DEADLINE" ? "minPriority" : "minSampleDeadline",
          "name"
        )
      )
      return {
        ...state,
        unplannedAnalyses: createMap(action.res.items),
        unplannedAnalysesSorted: [...sortedUnplannedAnalyses],
      }
      case "samples/changePriority": {
        action.res.planned_analyses.forEach((plannedAnalysis) => {
          temp.plannedAnalyses = replaceInMap(temp.plannedAnalyses, plannedAnalysis);
        });
      
        action.res.unplanned_analyses.forEach((unplannedAnalysis) => {
          temp.unplannedAnalyses = replaceInMap(temp.unplannedAnalyses, unplannedAnalysis);
        });
      
        const firstPriority = action.res.sortingParameter === "DEADLINE" ? "minSampleDeadline" : "minPriority";
        const secondPriority = action.res.sortingParameter === "DEADLINE" ? "minPriority" : "minSampleDeadline";
      
        // Convert the updated unplannedAnalyses map to an array for sorting
        const unplannedAnalysesArray = Object.values(temp.unplannedAnalyses);
      
        temp.unplannedAnalysesSorted = unplannedAnalysesArray.sort(
          dynamicSortMultiple(firstPriority, secondPriority, "name")
        );
      
        return temp;
      }
    case "analyses/fetchPlanned":
      return { ...state, plannedAnalyses: createMap(action.res.analyses) }
    case "analyses/remove":
      return {
        ...state,
        plannedAnalyses: removeFromMap(state.plannedAnalyses, action.res),
        unassignedSamples: null,
      }
    case "analyses/removeAllPlanned/BEGIN":
      return { ...state, unplanning: true }
    case "analyses/removeAllPlanned":
      var arrLen = action.res ? action.res.length : false
      if (arrLen) {
        for (let i = 0; i < arrLen; i++) {
          temp.unplannedAnalyses = addToMap(temp.unplannedAnalyses, action.res[i])
        }
      }

      return {
        ...temp,
        unplanning: false,
      }
    case "analyses/fetchUnassignedSamples/BEGIN":
      return { ...state, isLoadingUnassignedSamples: true }
    case "analyses/fetchUnassignedSamples":
      return { ...state, unassignedSamples: action.res, isLoadingUnassignedSamples: false }
    case "analyses/lockAll":
      let lockedIds = Object.values(action.res.ids)
      lockedIds.forEach((id) => {
        temp.plannedAnalyses[id] = { ...temp.plannedAnalyses[id], movable: false }
      })
      return temp
    case "analyses/unlockAll":
      let unlockedIds = Object.values(action.res.ids)
      unlockedIds.forEach((id) => {
        temp.plannedAnalyses[id] = { ...temp.plannedAnalyses[id], movable: true }
      })
      return temp
    case "analyses/deleteEverything":
      return temp
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const setMovable = asyncAction("analyses/setMovable", (dispatch, id, movable) => {
  return request("PATCH", `/analysis/${id}`, { patch: { movable } }).then((res) => res.analysis)
})

export const setSamplesLocked = asyncAction("analyses/setSamplesLocked", (dispatch, id, samplesLocked) => {
  return request("PATCH", `/analysis/${id}`, { patch: { samplesLocked } }).then((res) => res.analysis)
})

// used to fetch new info about an analysis. Currently only used when task states are changing
export const getAnalysis = asyncAction("analyses/setMovable", (dispatch, id) => {
  return request("PATCH", `/analysis/${id}`, { patch: {} }).then((res) => res.analysis)
})

export const moveIntoPlan = asyncAction("analyses/moveIntoPlan", (dispatch, id, start, member) => {
  return request("POST", `/analysis/unplanned/${id}/move-into-plan`, {
    memberId: member.id,
    start,
    timezoneOffset: new Date().getTimezoneOffset(),
  }).then((res) => {
    return { ...res, id }
  })
})

export const fetchUnplanned = asyncAction("analyses/fetchUnplanned", (dispatch) => {
  const sortingParameter = window.store.getState().teamStatus.sortingParameter
  return request("GET", "/analyses/unplanned").then((res) => {
    res.sortingParameter = sortingParameter
    return res
  })
})

export const fetchUnassignedSamples = asyncAction("analyses/fetchUnassignedSamples", (dispatch) => {
  return request("GET", "/analyses/unassigned-samples").then((res) => res.items)
})

export const fetchPlanned = asyncAction("analyses/fetchPlanned", (dispatch) => {
  const state = window.store.getState()
  const [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )
  return request("GET", `/analyses?before=${before}&after=${after}`).then((res) => res)
})

export const remove = asyncAction("analyses/remove", (dispatch, id) => {
  return request("DELETE", `/analysis/${id}`).then((res) => {
    dispatch(fetchUnplanned())
    return { res, id }
  })
})

export const removeAllPlanned = asyncAction("analyses/removeAllPlanned", (dispatch) => {
  return request("GET", "/analyses/bulk-unplan").then((res) => {
    // TODO: Update state with return values, instead of BE calls
    dispatch(fetchUnplanned())
    dispatch(fetchPlanned())
    //dispatch(analysisTasks.fetch())
    return res
  })
})

export const lockAll = asyncAction("analyses/lockAll", (dispatch) => {
  return request("POST", "/analyses/lock-all").then((res) => {
    return res
  })
})

export const unlockAll = asyncAction("analyses/unlockAll", (dispatch) => {
  return request("POST", "/analyses/unlock-all").then((res) => {
    return res
  })
})

// ONLY FOR TEST DATA
export const deleteEverything = asyncAction("analyses/deleteEverything", (dispatch) => {
  return request("DELETE", `/analyses/delete-everything`).then((res) => {
    // fetching the updated data which will now be empty
    dispatch(loading.fetchAll())
    return res
  })
})
