import { request } from "utils/request"
import { createMap, replaceInMap, asyncAction, getRange, addToMap } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  let temp = { ...state }
  switch (action.type) {
    case "analysisTasks/edit":
    case "analysisTasks/move":
      return replaceInMap(state, action.res)
    case "analysisTasks/fetch":
      return createMap(action.res)
    case "analyses/remove":
      for (const id in state) {
        if (state[id].analysisId === action.res.id) {
          delete temp[id]
        }
      }
      return temp
    case "analyses/removeAllPlanned":
      for (const id in state) {
        delete temp[id]
      }
      return temp
    case "auth/logout":
      return initialState
    case "analyses/fetchPlanned":
      if(action.res.tasks) {
        return createMap(action.res.tasks)
      }
      return state
    case "analyses/moveIntoPlan":
      for (let i = 0; i < action.res.tasks.length; i++) {
        temp = addToMap(temp, action.res.tasks[i])
      }
      return temp
    case "analyses/moveSmartIntoPlan":
      for (let i = 0; i < action.res.items.length; i++) {
        temp = addToMap(temp, action.res.items[i])
      }
      return temp
    default:
      return state
  }
}

export const fetch = asyncAction("analysisTasks/fetch", (dispatch, forLoading) => {
  const state = window.store.getState()
  const [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )
  return request("GET", `/tasks/analysis?before=${before}&after=${after}`).then((res) => {
    return res.items
  })
})

export const edit = asyncAction("analysisTasks/edit", (dispatch, id, patch) => {
  return request("PATCH", `/task/analysis/${id}`, { patch }).then(({ task }) => {
    return task
  })
})

export const move = asyncAction("analysisTasks/move", (dispatch, id, patch) => {
  return request("PATCH", `/task/analysis/${id}`, { patch }).then(({ task }) => {
    return task
  })
})

export const getBookedFreeAssets = asyncAction("analysisTasks/getBookedFreeAssets", (dispatch, id, patch) => {
  // Check if patch has assetRequirements and prepare the data structure accordingly
  const assetSettings = patch.assetRequirements.map((req) => ({
    assetId: req.assetId,
    allowedAssetIds: req.allowedAssetIds,
    assetSetuptime: req.assetSetuptime,
    assetPerSampleDuration: req.assetPerSampleDuration,
    assetOffset: req.assetOffset
  }));

  return request("PATCH", `/task/asset-information/${id}`, {
    patch: {
      start: patch.start,
      setuptime: patch.setuptime,
      perSampleDuration: patch.perSampleDuration,
      assetRequirements: assetSettings
    }
  }).then((res) => {
    return res;
  });
});