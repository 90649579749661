import React from "react"
import "./index.scss"
import { Draggable } from "react-beautiful-dnd"
import { chooseOptimalColor } from "utils/scripts/schedule"
import { DateTime } from "luxon"
import { useSelector } from "react-redux"

const Container = ({ children, provided, snapshot, project, onClick, task }) => {
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        backgroundColor: project ? project.color : "",
        color: project ? chooseOptimalColor(project.color) : "",
        boxShadow:
          task.deadline || (project && project.deadline)
            ? (!snapshot.isDragging &&
                task.stage !== "FINISHED" &&
                task.deadline &&
                DateTime.fromISO(task.deadline).setZone(memberTimezone).startOf("day") < DateTime.local().setZone(memberTimezone).startOf("day")) ||
              (!task.deadline &&
                project &&
                project.deadline &&
                DateTime.fromISO(project.deadline).setZone(memberTimezone).startOf("day") < DateTime.local().setZone(memberTimezone).startOf("day"))
              ? "0px 0px 0px 2px red"
              : ""
            : "",
        ...provided.draggableProps.style,
      }}
      ref={provided.innerRef}
      className={`ScrumView-Task-Container ${snapshot.isDragging}`}
      onClick={() => onClick(task)}
    >
      {children}
    </div>
  )
}

// An item in the scrum view.
export default function Task({ task, index, project, onClick, onCancel }) {
  const groups = useSelector((state) => state.competenceGroups)
  const members = useSelector((state) => state.members)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const allowedGroups = task.allowedGroupIds
    .filter((id) => groups[id])
    .map((id) => groups[id].name)
    .sort()
    .join(", ")
  return (
    <Draggable draggableId={task.id} index={index} key={task.id}>
      {(provided, snapshot) => (
        <Container provided={provided} snapshot={snapshot} project={project} onClick={onClick} task={task}>
          <h4>{`${project && !project.singleActionProject ? project.name + ":" : ""}${task.name}`}</h4>
          {(task.stage === "BACKLOG" || task.stage === "TODO") && (
            <p>
              Deadline:{" "}
              {task.deadline
                ? DateTime.fromISO(task.deadline).toFormat("ff")
                : !project
                ? ""
                : project.deadline
                ? DateTime.fromISO(project.deadline).toFormat("ff")
                : ""}
            </p>
          )}
          {(task.stage === "PLANNED" || task.stage === "FINISHED") && (
            <>
              <p>Start: {task.start ? DateTime.fromISO(task.start).setZone(memberTimezone).toFormat("ff") : ""}</p>
              <p>{`Team Member: ${members[task.memberIds[0]] ? members[task.memberIds[0]].initials : "Archived"} `}</p>
            </>
          )}
          {(task.stage === "BACKLOG" || task.stage === "TODO") && <p>{`Competence Groups: ${allowedGroups} `}</p>}
        </Container>
      )}
    </Draggable>
  )
}
