import React, { useState } from "react"
import { useDispatch } from "react-redux"
import "./index.scss"

import DropdownButton from "react-bootstrap/DropdownButton"
import DropdownItem from "react-bootstrap/DropdownItem"
import Button from "react-bootstrap/Button"
import { message } from "state_management"

// Performer constraints component.
// Tasks/actions are under the common name "tasks"
export default function PerformerConstraints({ tasks, performerConstraints, onConstraintsChange, isProject = false }) {
  const [firstConstraintTaskId, setFirstConstraintTaskId] = useState(null)
  const [secondConstraintTaskId, setSecondConstraintTaskId] = useState(null)
  const [performerConstraintValue, setPerformerConstraintValue] = useState("SAME")

  const firstIdName = isProject ? "firstId" : "firstDefinitionId"
  const secondIdName = isProject ? "secondId" : "secondDefinitionId"
  const dispatch = useDispatch()
  return (
    <div className="PerformerConstraints-container">
      <div className="PerformerConstraints-selector">
        <DropdownButton
          bsPrefix="CustomButtonStyle btn"
          className="PerformerContraints-item"
          title={
            tasks[firstConstraintTaskId]
              ? `${tasks[firstConstraintTaskId].name} performer`
              : `${isProject ? "Choose Action" : "Choose Task"}`
          }
          onSelect={(id) => setFirstConstraintTaskId(id)}
        >
          {Object.values(tasks)
            .sort((a, b) => {
              if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
              if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
              return 0
            })
            .map((task) => (
              <DropdownItem type="button" key={task.id} eventKey={task.id} as="button">
                {task.name} performer
              </DropdownItem>
            ))}
        </DropdownButton>
        <DropdownButton
          bsPrefix="CustomButtonStyle btn"
          className="PerformerContraints-item"
          title={performerConstraintValue === "SAME" ? "should be equal to" : "should not be equal to"}
          onSelect={(id) => setPerformerConstraintValue(id)}
        >
          <DropdownItem type="button" eventKey="SAME" as="button">
            should be equal to
          </DropdownItem>
          <DropdownItem type="button" eventKey="DIFFERENT" as="button">
            should not be equal to
          </DropdownItem>
        </DropdownButton>
        <DropdownButton
          bsPrefix="CustomButtonStyle btn"
          className="PerformerContraints-item"
          title={
            tasks[secondConstraintTaskId]
              ? `${tasks[secondConstraintTaskId].name} performer`
              : isProject
              ? "Choose Action"
              : "Choose Task"
          }
          onSelect={(id) => setSecondConstraintTaskId(id)}
        >
          {Object.values(tasks).map((task) => (
            <DropdownItem type="button" key={task.id} eventKey={task.id} as="button">
              {task.name} performer
            </DropdownItem>
          ))}
        </DropdownButton>
        <Button
          title="Add constraint"
          variant="primary"
          size="sm"
          className="add-performer-constraint-btn"
          onClick={(e) => {
            if (!tasks[firstConstraintTaskId]) {
              dispatch(message.warning(`Please choose the first ${isProject ? "action" : "task"}`))
              return
            }
            if (!tasks[secondConstraintTaskId]) {
              dispatch(message.warning(`Please choose the second ${isProject ? "action" : "task"}`))
              return
            }
            if (firstConstraintTaskId === secondConstraintTaskId) {
              dispatch(message.warning(`Please choose two different ${isProject ? "actions" : "tasks"}`))
              return
            }

            if (
              performerConstraints.find(
                (performerConstraint) =>
                  (performerConstraint[`${firstIdName}`] === firstConstraintTaskId &&
                    performerConstraint[`${secondIdName}`] === secondConstraintTaskId) ||
                  (performerConstraint[`${firstIdName}`] === secondConstraintTaskId &&
                    performerConstraint[`${secondIdName}`] === firstConstraintTaskId)
              )
            ) {
              dispatch(message.warning("That constraint conflicts with existing ones"))
              return
            }
            // TODO: Check for duplicates
            var constraint = {}
            if (isProject) {
              constraint = {
                firstId: firstConstraintTaskId,
                secondId: secondConstraintTaskId,
                performerConstraint: performerConstraintValue,
              }
            } else {
              constraint = {
                firstDefinitionId: firstConstraintTaskId,
                secondDefinitionId: secondConstraintTaskId,
                performerConstraint: performerConstraintValue,
              }
            }

            onConstraintsChange(performerConstraints.concat([constraint]))
          }}
        >
          Add
        </Button>
      </div>

      <div className="PerformerConstraints-holder">
        {!!performerConstraints.length &&
          performerConstraints.map((constraint, idx) => (
            <div key={idx}>
              <span>
                {tasks[constraint[`${firstIdName}`]].name} performer should
                {constraint.performerConstraint === "SAME" ? " be equal to " : " not be equal to "}
                {tasks[constraint[`${secondIdName}`]].name} performer
              </span>
              <Button
                className="remove-performer-constraint-btn"
                title="Delete constraint"
                variant="danger"
                size="sm"
                onClick={() =>
                  onConstraintsChange(performerConstraints.slice(0, idx).concat(performerConstraints.slice(idx + 1)))
                }
              >
                Remove
              </Button>
            </div>
          ))}
      </div>
    </div>
  )
}
