import React, { useState, useEffect } from "react"
import { Duration } from "luxon"
import { PatternFormat } from "react-number-format"
import HiddenInput from "./HiddenInput"

function CustomNumberFormat({ value, onChange, required, disabled, children, ...props }) {
  // Special use case for when value is empty
  // So, this is a bit confusing:
  // The `value` in the other XXXDuration components are all in the
  // luxon `Duration` format. When passed down to this component, they
  // are converted to a string, which we then show the user.
  //
  // When the user then edits the text, it may not yet be a valid `Duration`, and
  // hence we need to store the in-progress data somehow. That is what `current` does.
  //
  // Example of a user's interaction with this component:
  // current = "07:30", value = "07:30" (initial)
  // current = "07:3M", value = null    (user deleted text, so it's no longer a valid luxon `Duration`)
  // current = "07:MM", value = null
  // current = "07:0M", value = null
  // current = "07:00", value = "07:00" (finished typing, can now be parsed and hence `value` is set)
  //
  // In short: `current` stores the in-progress text, `value` contains the final text.
  const [current, setCurrent] = useState(value)

  useEffect(() => {
    if (value && value !== current) {
      setCurrent(value);
    }
  }, [value, current]);

  return (
    <div className="HiddenInput-container HiddenInput-flex-container">
      <PatternFormat
        value={current}
        required={required}
        onValueChange={(values) => {
          const { formattedValue } = values;
          setCurrent(formattedValue);
          onChange(formattedValue);
        }}
        {...props}
        disabled={disabled}
        className={disabled ? "Disabled-Duration-Input" : ""}
      />
      {
        required && <HiddenInput message={!value ? "Please give numeric input" : null} />
      }
      {
        <HiddenInput message={current && !value ? "Please give empty or full input" : null} />
      }
      {children}
    </div>
  )
}

function DayDuration({ required, disabled, value, onChange, children }) {
  return (
    <CustomNumberFormat
      format="##:##"
      required={required}
      disabled={disabled}
      mask={["D", "D", "H", "H"]}
      placeholder="DD:HH"
      style={{
        width: "4.5rem",
        fontSize: "1rem",
        paddingLeft: "0.5rem",
        border: "1px solid #ced4da",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      }}
      value={value ? value.toFormat("dd:hh") : null}
      onChange={(value) => {
        if (!value || value.match(/[DH]/)) {
          onChange(null)
          return
        }
        const [days, hours] = value.split(":").map((x) => window.parseInt(x))
        onChange(Duration.fromObject({ days, hours }))
      }}
      children={children}
    />
  )
}

function SecondDuration({ required, disabled, value, onChange, children }) {
  return (
    <CustomNumberFormat
      format="##:##:##"
      required={required}
      disabled={disabled}
      mask={["H", "H", "M", "M", "S", "S"]}
      placeholder="HH:MM:SS"
      style={{
        width: "6rem",
        fontSize: "1rem",
        paddingLeft: "0.5rem",
        border: "1px solid #ced4da",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      }}
      value={value ? value.toFormat("hh:mm:ss") : null}
      onChange={(value) => {
        if (!value || value.match(/[HMS]/)) {
          onChange(null)
          return
        }
        const [hours, minutes, seconds] = value.split(":").map((x) => window.parseInt(x))
        onChange(Duration.fromObject({ hours, minutes, seconds }))
      }}
      children={children}
    />
  )
}

function NormalDuration({ required, disabled, value, onChange, children }) {
  return (
    <CustomNumberFormat
      format="##:##"
      required={required}
      disabled={disabled}
      mask={["H", "H", "M", "M"]}
      placeholder="HH:MM"
      style={{
        width: "4.5rem",
        fontSize: "1rem",
        paddingLeft: "0.5rem",
        border: "1px solid #ced4da",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      }}
      value={value ? value.toFormat("hh:mm") : null}
      onChange={(value) => {
        if (!value || value.match(/[HM]/)) {
          onChange(null)
          return
        }
        const [hours, minutes] = value.split(":").map((x) => window.parseInt(x))
        onChange(Duration.fromObject({ hours, minutes }))

      }}
      children={children}
    />
  )
}

function OnlyHourDuration({ required, disabled, value, onChange, children }) {
  return (
    <CustomNumberFormat
      format="##"
      required={required}
      disabled={disabled}
      mask={["H", "H"]}
      placeholder="HH"
      style={{
        width: "4.5rem",
        fontSize: "1rem",
        paddingLeft: "0.5rem",
        border: "1px solid #ced4da",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      }}
      value={value ? value.toFormat("hh") : null}
      onChange={(value) => {
        if (!value || value.match(/[H]/)) {
          onChange(null)
          return
        }
        const hours = value
        onChange(Duration.fromObject({ hours }))
      }}
      children={children}
    />
  )
}

function MinuteDuration({ required, disabled, value, onChange, children }) {
  return (
    <CustomNumberFormat
      format="##:##:##"
      required={required}
      disabled={disabled}
      mask={["D", "D", "H", "H", "M", "M"]}
      placeholder="DD:HH:MM"
      style={{
        width: "6rem",
        fontSize: "1rem",
        paddingLeft: "0.5rem",
        border: "1px solid #ced4da",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      }}
      value={value ? value.toFormat("dd:hh:mm") : null}
      onChange={(value) => {
        if (!value || value.match(/[DHM]/)) {
          onChange(null)
          return
        }
        const [days, hours, minutes] = value.split(":").map((x) => window.parseInt(x))
        onChange(Duration.fromObject({ days, hours, minutes }))
      }}
      children={children}
    />
  )
}

// Children is the error message for duration input
export default function DurationInput({
  value,
  onChange = () => {}, // To prevent bug when disabled=true
  required,
  disabled,
  isTime,
  withDays = false,
  onlyHours = false,
  withSeconds = false,
  withMinutes = false,
}) {
  const valueSeconds = value?.shiftTo('seconds').seconds
  const timeValue = isTime && valueSeconds && value > Duration.fromObject({hours: 24}) ? Duration.fromObject({seconds: valueSeconds % (60*60*24)}) : value
  const Component = (withDays) ? DayDuration : (withSeconds) ? SecondDuration : (withMinutes) ? MinuteDuration : (onlyHours) ? OnlyHourDuration : NormalDuration
  return (
    <Component required={required} disabled={disabled} value={timeValue ? timeValue : value} onChange={onChange}>
      {
        // If the input field is a day duration, then it has to be inside interval [00:00 , 23:59]
        isTime && value && <HiddenInput message={!(timeValue.as('days') < 1) ? "Please give a numeric input between 00:00 and 23:59" : null} />
      }
    </Component>
  )
}
