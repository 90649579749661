import React from "react"
import { FiSettings, FiCopy, FiX } from "react-icons/fi"
import { FaMapMarkerAlt } from "react-icons/fa"
import { GiFactory } from "react-icons/gi"
import { chooseOptimalColor, optimalColor } from "utils/scripts/schedule"

// A node at a specific position in the graph design tool.
export default function SvgNode({
  w,
  fill,
  coords,
  offset,
  isActive,
  withBorder,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  name,
  onConfigure,
  onDuplicate,
  onDelete,
  onlyOnSite,
  isAction,
  onSiteClick,
  nodeColor,
}) {
  return (
    <div
      className={"SvgNode" + (withBorder ? " SvgNode-withBorder" : "") + (isActive ? " SvgNode-isActive" : "")}
      style={{
        backgroundColor: fill,
        color: chooseOptimalColor(fill),
        fontSize: `${w / 100}rem`,
        left: coords.x * w + offset.x - w / 2,
        top: coords.y * w + offset.y - w / 4,
        width: w,
        height: w / 2,
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span title={name}>{name}</span>
      <FiSettings
        title="Edit"
        style={{ bottom: 0, right: 0 }}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onClick={onConfigure}
      />
      <FiCopy
        title="Duplicate"
        style={{ bottom: 0, left: 0 }}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onClick={onDuplicate}
      />
      <FiX
        title="Delete"
        style={{ top: 0, right: 0 }}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onClick={onDelete}
      />
      {isAction && !onlyOnSite && (
        <FaMapMarkerAlt
          title={"Can be performed anywhere"}
          style={{ top: 0, left: 0 }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={onSiteClick}
          //color={onlyOnSite ? "red" : "green"}
        />
      )}
      {isAction && onlyOnSite && (
        <GiFactory
          title={"Can only be performed on site"}
          style={{ top: 0, left: 0 }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={onSiteClick}
          color={optimalColor(nodeColor,"blue","orange")}
        />
      )}
    </div>
  )
}
