import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {
  token: ""
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "team/create-api-token":
      return {token: action.res.token}
    case "customer-admin/create-api-token":
      return {token: action.res.token}
    case "company/create-api-token":
      return {token: action.res.token}
    case "team/hide-api-token":
      return initialState
    default:
      return state
  }
}

export const createToken = asyncAction("team/create-api-token", (dispatch, teamId, locationId) => {
  return request("POST", `/team/create-api-token/${teamId}`)
})

export function hideToken() {
  return { type: "team/hide-api-token"}
}

export const deleteToken = asyncAction("team/delete-api-token", (dispatch, teamId, locationId) => {
  return request("DELETE", `/team/delete-api-token/${teamId}`)
})

export const createCustomerAdminToken = asyncAction("customer-admin/create-api-token", (dispatch) => {
  return request("POST", `/customer-admin/create-api-token`)
})

export const deleteCustomerAdminToken = asyncAction("customer-admin/delete-api-token", (dispatch) => {
  return request("DELETE", `/customer-admin/delete-api-token`)
})

export const createCompanyToken = asyncAction("company/create-api-token", (dispatch, companyId) => {
  return request("POST", `/company/create-api-token/${companyId}`)
})

export const deleteCompanyToken = asyncAction("company/delete-api-token", (dispatch, companyId) => {
  return request("DELETE", `/company/delete-api-token/${companyId}`)
})