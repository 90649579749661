import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputGroup from "react-bootstrap/InputGroup"
import { ConfigContainer, Popup } from "components"
import { Container, Title, Select, DayPicker, DurationInput } from "components/Form"
import { DateTime, Duration } from "luxon"
import { projectActions } from "state_management"

export default function UnplannedToPlannedConfig({
  cancelHandler,
  task,
  submitHandler = () => {},
  res = {},
  index,
  cancelAll,
  columnId,
}) {
  const dispatch = useDispatch()
  const members = useSelector((state) => state.members)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const [teamMember, setTeamMember] = useState([])
  const [startDate, setStartDate] = useState(task.start ? DateTime.fromISO(task.start).setZone(memberTimezone) : null)
  const [startTime, setStartTime] = useState(
    startDate ? Duration.fromObject({ hours: startDate.hour, minutes: startDate.minute }) : null
  )
  const [duration, setDuration] = useState(Duration.fromObject({ seconds: task.duration || 0 }))

  return (
    <Popup onCancel={cancelHandler}>
      <ConfigContainer
        title="Set Planned Values"
        cancelHandler={cancelHandler}
        submitHandler={() => {
          const obj = {
            ...res,
            stage: "PLANNED",
            start: startDate && startDate.setZone(memberTimezone).set({ hour: startTime.hours, minute: startTime.minutes }).toISO(),
            memberIds: teamMember,
            oldIndex: index && index.oldIndex,
            newIndex: index && index.newIndex,
            scrumColumnId: columnId ? columnId : null,
            duration: duration.as("seconds"),
          }
          delete obj.id
          delete obj.__typename
          delete obj.coordinates
          delete obj.end
          delete obj.iapWarning
          delete obj.movable
          delete obj.onSite
          delete obj.state
          delete obj.assetIds
          delete obj.projectId
          dispatch(projectActions.editUnplanned(task.id, obj)).then(() => submitHandler())
          cancelAll()

        }}
      >
        <Container>
          <Title>
            Team Member <div className="red-text">*</div>
          </Title>
          <Select
            required
            closeMenuOnSelect={true}
            attr={"initials"}
            collection={Object.values(members).filter((member) => !member.archived && member.showOnPlan)}
            value={teamMember}
            onChange={setTeamMember}
            isMulti={false}
            menuPosition="fixed"
          />
        </Container>

        <Container>
          <Title>
            From Date <div className="red-text">*</div>
          </Title>
          <div>
            <DayPicker
              required
              value={startDate}
              onChange={(event) => {
                if (!event) {
                  setStartDate(undefined)
                  return
                }
                if (event.invalid) {
                  return
                }
                setStartDate(event)
              }}
            />
          </div>
          <Title>
            From Time <div className="red-text">*</div> (HH:MM)
          </Title>
          <DurationInput value={startTime} onChange={(input) => {
            setStartTime(input && input.shiftTo('days', 'hours', 'minutes'))}} required />
        </Container>
        <Container>
          <Title>
            Duration <div className="red-text">*</div> (HH:MM)
          </Title>
          <DurationInput required value={duration} onChange={(input) => {
            setDuration(input && input.shiftTo('days', 'hours', 'minutes'))
          }} />
        </Container>

        <InputGroup style={{marginBottom: "7rem"}}>
          <Title>To Date</Title>
          <DayPicker required disabled value={startDate && duration && startTime ? startDate.plus(startTime).plus(duration) : null} />
          <Title>To Time (HH:MM)</Title>
          <DurationInput
            disabled
            value={
              startTime && duration
                ? startTime.plus(duration).normalize().set({days:0})
                : null
            }
          />
        </InputGroup>
      </ConfigContainer>
    </Popup>
  )
}
