import React, { useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useReactToPrint } from 'react-to-print'
import { SampleTable, Loading } from "components"
import { Button } from "react-bootstrap"
import { samples, message, analyses } from "state_management"
import { ConfirmDialog, Popup } from "components"
import { Container } from "components/Form"
import { FiInfo, FiPrinter } from "react-icons/fi"
import { FaLockOpen, FaLock } from "react-icons/fa"
import PlannableSamples from "./PlannableSamples"
import SampleTableForPrinting from "./SampleTableForPrinting"
import isOnMobile from "utils/scripts/isOnMobile"

const PrintComponent = React.forwardRef(({ data }, ref) => {
  // Forward the ref to the printed div
  return (
    <div className="print-container" ref={ref}>
      <SampleTableForPrinting popupSamples={data} />
    </div>
  )
})

export default function SampleTableForPlannedAnalysis({ analysisId, cancelHandler }) {
  const dispatch = useDispatch()
  const sampleData = useSelector((state) => state.samples[analysisId] || null)
  const [failConfirm, setFailConfirm] = useState(false)
  const [unplanConfirm, setUnplanConfirm] = useState(false)
  const [showUnplannedSamples, setShowUnplannedSamples] = useState(false)
  const [checkedSamples, setCheckedSamples] = useState([])
  const analysis = useSelector((state) => state.analyses.plannedAnalyses[analysisId])
  const hasExclusiveSample = sampleData?.items ? sampleData.items.some(sample => sample.exclusive === true) : false

  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })


  if (!sampleData) {
    dispatch(samples.fetchForPlanned(analysisId))
  }

  if (!sampleData || sampleData.isLoading) {
    return <Loading />
  }

  return (
    <>
      {showUnplannedSamples && (
        <Popup className="sampletable-addsamples-popup" onCancel={() => setShowUnplannedSamples(false)}>
          <PlannableSamples analysisId={analysisId} handler={() => setShowUnplannedSamples(false)} />
        </Popup>
      )}

      {failConfirm && (
        <ConfirmDialog
          onCancel={() => {
            setFailConfirm(false)
          }}
          onConfirm={() => {
            dispatch(samples.failSamples(analysisId,checkedSamples))
            setCheckedSamples([])
            setFailConfirm(false)
          }}
        >
          <Container>
            <div className="FailSamples-info-text">
              <FiInfo title="Confirming this will fail selected samples. A duplicate will be placed in the unplanned analysis menu" />
            </div>
            <h4>Are you sure you want to fail the selected samples?</h4>
          </Container>
        </ConfirmDialog>
      )}
      {unplanConfirm && (
        <ConfirmDialog
          onCancel={() => {
            setUnplanConfirm(false)
          }}
          onConfirm={() => {
            dispatch(samples.unplanSamples(analysisId,checkedSamples,sampleData.items.filter(sample => checkedSamples.indexOf(sample.id) !== -1)))
            setCheckedSamples([])
            setUnplanConfirm(false)
          }}
        >
          <Container>
            <div className="FailSamples-info-text">
              <FiInfo title="Confirming this will unplan selected samples. It will be placed in the unplanned analysis menu" />
            </div>
            <h4>Are you sure you want to unplan the selected samples?</h4>
          </Container>
        </ConfirmDialog>
      )}
      <div className="sample-table-planned-main-container">
        <div className="add-samples-asset-view">
        {!isOnMobile && (
          <>
            <p style={{ marginRight: "0.5rem" }}>Lock/Unlock samples: </p>
            {analysis.samplesLocked ? (
              <FaLock
                title="Unlock samples"
                className="analysis-samples-lock"
                onClick={() => {
                  dispatch(analyses.setSamplesLocked(analysisId, false));
                }}
              />
            ) : (
              <FaLockOpen
                title="Lock samples"
                className="analysis-samples-lock"
                onClick={() => {
                  dispatch(analyses.setSamplesLocked(analysisId, true));
                }}
              />
            )}
          </>
        )}


          <Button
            variant="success"
            size="lg"
            onClick={ () => setShowUnplannedSamples(true) }
          >
            Add samples
          </Button>
        </div>
        <div className="sample-table-planned">
          <SampleTable popupSamples={sampleData.items} checkedSamples={checkedSamples} setCheckedSamples={setCheckedSamples} analysisId={analysisId} />
        </div>
      </div>
      {hasExclusiveSample && (
        <div className="explanation-text">
          * Indicates an exclusive sample
        </div>
      )}
      <div className="sampletable-buttons">
        <Button variant="secondary" onClick={handlePrint}> <FiPrinter /></Button>
        <PrintComponent data={sampleData.items} ref={printRef} />
        <div className="sampletable-cancel">    
          <Button
            variant="light"
            onClick={() => { 
              cancelHandler() 
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="confirm-buttons">
          <Button 
            variant="danger"
            onClick={() => {
              // If no checkboxes are clicked
              if(checkedSamples.length === 0) {
                dispatch(message.warning(`No checkboxes selected`))
                cancelHandler()
              } else {
                  setUnplanConfirm(true)
                }
            }}
          >
          {isOnMobile ? "Unplan" : "Unplan Selected"}
          </Button>
          <Button 
            className="sampletable-fail-button"
            onClick={() => {
              // If no checkboxes are clicked
              if(checkedSamples.length === 0) {
                dispatch(message.warning(`No checkboxes selected`))
                cancelHandler()
              } else {
                  setFailConfirm(true)
                }
            }}
          >
          {isOnMobile ? "Fail" : "Fail Selected"}
          </Button>
        </div>
      </div>
    </>
  )
}
