import React from "react"
import ReactBootstrapForm from "react-bootstrap/Form"

export default function Form({ children, className, onSubmit }) {
  return (
    <ReactBootstrapForm
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onSubmit(e)
      }}
    >
      {children}
    </ReactBootstrapForm>
  )
}
