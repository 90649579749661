import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"
import { message } from "state_management"
import { Title, Number, Container, Text, Select } from "components/Form"
import { Popup } from "components"

import "./index.scss"
import { DateTime } from "luxon"

// Is used to create recurrences in green/whitetasks
export default function RecurrenceConfig({ onClose, task, onSubmit, recurrenceSettings }) {
  const [amount, setAmount] = useState(recurrenceSettings.amount)
  const [interval, setInterval] = useState(recurrenceSettings.interval)
  const [recurrence, setRecurrence] = useState([recurrenceSettings.recurrence])
  const formatedStartDate = task.recurrenceStart
    ? DateTime.fromISO(task.recurrenceStart).toFormat("yyyy-MM-dd HH:mm")
    : DateTime.fromISO(task.start).toFormat("yyyy-MM-dd HH:mm")
  const recurrenceList = [
    { name: "Daily", id: "DAILY" },
    { name: "Weekly", id: "WEEKLY" },
  ]
  const recurrencePlaceholder = recurrence[0] === "DAILY" ? "day(s)" : recurrence[0] === "WEEKLY" ? "week(s)" : ""

  const dispatch = useDispatch()
  function validateRecurrenceFields() {
    if (task.recurrenceId) {
      return ""
    } else {
      if (!amount && !interval) {
        return "Please fill out number and interval of recurrences"
      } else if (!amount) {
        return "Please fill out number of recurrences"
      } else if (!interval) {
        return "Please fill out interval of recurrences"
      } else if (amount > 365) {
        return "Number of recurrences cannot be greater than 365"
      }
      return ""
    }
  }
  return (
    <div className="RecurrenceConfig-container">
      <Popup className="RecurrenceConfig-Popup" onCancel={onClose}>
        <h3>Recurrence</h3>
        <Container>
          <Title>Start Recurrence</Title>
          <div className="RecurrenceConfig-startDate">
            <Text disabled placeholder={formatedStartDate}></Text>
          </div>
        </Container>
        <Container>
          <Title>Recur</Title>
          <div className="RecurrenceConfig-select">
            <Select
              collection={recurrenceList}
              value={recurrence}
              onChange={setRecurrence}
              isMulti={false}
            ></Select>
          </div>
        </Container>
        <Container>
          <Title>Recur every</Title>
          <div className="Recurrence-number">
            <Number value={interval} onChange={setInterval} min={0} />
          </div>
          <Title>{recurrencePlaceholder}</Title>
        </Container>
        <Container>
          <Title>Number of recurrences </Title>
          <div className="Recurrence-number">
            <Number value={amount} onChange={setAmount} min={0} />
          </div>
          <Title>{recurrencePlaceholder}</Title>
        </Container>
        <div className="RecurrenceConfig-buttons">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const msg = validateRecurrenceFields()
              if (msg) {
                dispatch(message.warning(msg))
                return
              }

              onSubmit({
                recurrence: recurrence[0],
                amount: interval ? amount : 0,
                interval: interval ? interval : 1,
              })

              onClose()
            }}
          >
            Save
          </Button>
        </div>
      </Popup>
    </div>
  )
}
