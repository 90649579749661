import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Dropdown, Button, InputGroup, ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { FiInfo } from "react-icons/fi"
import { FaArrowUp } from 'react-icons/fa';
import { FiPlus, FiCircle, FiSettings } from "react-icons/fi"
import useNavigationBlocker from "utils/hooks/useBlocker"

import "./index.scss"
import { Popup, GraphDesignTool, ConfirmDialog, PerformerConstraints, ConfigContainer, Loading } from "components"
import { ActionConfig, ActionConnectionConfig, ProjectGroupConfig } from "components/Config"
import { Container, Text, Number, DayPicker, ColorPicker, Title, DurationInput } from "components/Form"
import { deleteNode, initialNodeCoords } from "components/GraphDesignTool"

import { DateTime, Duration } from "luxon"
import { ActionValidator, ProjectValidator, SomethingHasChanged } from "utils/scripts/projectHelpers"
import { message, projects, projectTemplates, createMap, addToMap, projectGroups, removeFromMap } from "state_management"

// wraps a function in a promise
function setStateAsync(fun, val) {
  return new Promise((resolve) => {
    fun(val)
    resolve()
  })
}
// Configuration page for projects and project templates. It is quite big but
// has this order in the html: Confirm Dialogs - Action configurations -
// Choose template dropdown - Projet configuration -  Graph design tool
export default function ProjectConfigurationPage({ id, isTemplate = false, isCreateOrEditProject = false, setEditProject = () => {}}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const templates = useSelector((state) => state.projectTemplates)
  const thisItem = useSelector((state) => (isTemplate ? state.projectTemplates[id] : state.projects[id]))
  const competenceGroups = useSelector((state) => state.competenceGroups)
  const [activeAction, setActiveAction] = useState(null)
  const [activeConnection, setActiveConnection] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  // We hide the "saveAsTemplate" button when projects are hidden. The user can
  // still edit projects on the plan, but can't interract with templates!
  const showProjects = useSelector((state) => state.teamStatus.showProjects)

  // Basic project properties
  const [name, setName] = useState(thisItem ? thisItem.name : "")
  const [priority, setPriority] = useState(thisItem ? thisItem.priority : 2)
  const [description, setDescription] = useState(thisItem ? thisItem.description : "")
  const [color, setColor] = useState(thisItem ? thisItem.color : null)
  const [actions, setActions] = useState(
    thisItem ? createMap(thisItem.actions.map((action) => ({ ...action, isOld: true }))) : {}
  )
  const [showPerformerConstraints, setShowPerformerConstraints] = useState(false)
  const [connections, setConnections] = useState(thisItem ? createMap(thisItem.actionConnections) : {})
  const [earliestStart, setEarliestStart] = useState(
    thisItem && thisItem.earliestStart ? DateTime.fromISO(thisItem.earliestStart).setZone(memberTimezone)  : null
  )
  const [earliestStartTime, setEarliestStartTime] = useState(
    earliestStart
      ? Duration.fromObject({ hours: earliestStart.hour, minutes: earliestStart.minute })
      : Duration.fromMillis(0)
  )
  const [deadline, setDeadline] = useState(thisItem && thisItem.deadline ? DateTime.fromISO(thisItem.deadline).setZone(memberTimezone) : null)
  const [deadlineTime, setDeadlineTime] = useState(
    deadline
      ? Duration.fromObject({ hours: deadline.hour, minutes: deadline.minute })
      : Duration.fromObject({ hours: 23, minutes: 59 })
  )
  const activeProjectGroupId = useSelector((state) => state.projectGroups.activeProjectGroupId)
  const [addConfig, setAddConfig] = useState(false)
  const [projectGroupConfigId, setProjectGroupConfigId] = useState(null)
  const projectGroupsDropdown = useSelector((state) => state.projectGroups ? state.projectGroups.groups : [])

  // Handles a user leaving the page with unsaved changes
  const [shouldCheckChanges, setShouldCheckChanges] = useState(true)
  const [leavePageObject, setLeavePageObject] = useState({ msg: "", location: "", display: false })

  const [earliestStartChecked, setEarliestStartChecked] = useState(thisItem ? thisItem.earliestStartChecked : false)

  // GraphDesignTool states that needs to be known by parent
  const [mode, setMode] = useState("node")
  const [nodeCoords, setNodeCoords] = useState(() => initialNodeCoords(actions, connections))

  // For insertion of templates
  const [insertingTemplate, setInsertingTemplate] = useState(false)
  const [chosenTemplate, setChosenTemplate] = useState(false)

  const [performerConstraints, setPerformerConstraints] = useState(
    thisItem
      ? thisItem.performerConstraints.map((constraint) => ({
          firstId: constraint.firstId,
          secondId: constraint.secondId,
          performerConstraint: constraint.performerConstraint,
        }))
      : []
  )

  // For recurrence, if any
  const [showRecurrence, setShowRecurrence] = useState(false)
  const [recurrenceSettings, setRecurrenceSettings] = useState({
    amount: 0,
    interval: 0,
  })
  const [interval, setInterval] = useState(recurrenceSettings.interval)
  const [amount, setAmount] = useState(recurrenceSettings.amount)

  // This is help to notice whether a new connection's nodes have
  // deadlines/earliest start. It is used in a Confirm Dialog later on
  // TODO: connect this to GraphDesignTool and svgEDGE
  const [hasWarnedAboutConnections, setHasWarnedAboutConnections] = useState(false)
  const [canceledWarnedAboutConnections, setCanceledWarnedAboutConnection] = useState(false)
  const ConnectionsAsArray = Object.keys(connections)
  const prevActionLastConnection = ConnectionsAsArray.length
    ? actions[connections[ConnectionsAsArray[ConnectionsAsArray.length - 1]].prevId]
    : 0
  const nextActionLastConnection = ConnectionsAsArray.length
    ? actions[connections[ConnectionsAsArray[ConnectionsAsArray.length - 1]].nextId]
    : 0
  const prevEarliestStartOrDeadline = ConnectionsAsArray.length
    ? prevActionLastConnection.earliestStart || prevActionLastConnection.deadline
    : 0
  const nextEarliestStartOrDeadline = ConnectionsAsArray.length
    ? nextActionLastConnection.earliestStart || nextActionLastConnection.deadline
    : 0
  const connectionsCountChanged = thisItem
    ? ConnectionsAsArray.length - Object.keys(thisItem.actionConnections).length > 0
    : !insertingTemplate && !!ConnectionsAsArray.length

  useEffect(() => {
    if (canceledWarnedAboutConnections) {
      setHasWarnedAboutConnections(false)
      setCanceledWarnedAboutConnection(false)
    }
  }, [canceledWarnedAboutConnections])

  // This useEffect fires when a template is chosen when inside a new project
  // We need to do this in one go, since attributes are dependent on each other.
  // If one is wrongly updated before the other a crash will occur.
  useEffect(() => {
    if (chosenTemplate) {
      const template = chosenTemplate
      const newActions = createMap(
        template.actions.map((action) => ({
          ...action,
          id: action.id + "frontend",
        }))
      )
      const newConnections = createMap(
        template.actionConnections.map((connection) => ({
          ...connection,
          nextId: connection.nextId + "frontend",
          prevId: connection.prevId + "frontend",
        }))
      )
      const earliestStartTemp = template.earliestStart ? DateTime.fromISO(template.earliestStart).setZone(memberTimezone) : null
      const deadlineTemp = template.deadline ? DateTime.fromISO(template.deadline).setZone(memberTimezone) : null
      function fireAllSetStates() {
        return new Promise((resolve, reject) => {
          setActions(newActions)
          setConnections(newConnections)
          setColor(template.color)
          setEarliestStart(earliestStartTemp)
          setEarliestStartTime(
            earliestStartTemp
              ? Duration.fromObject({ hours: earliestStartTemp.hour, minutes: earliestStartTemp.minute })
              : Duration.fromMillis(0)
          )
          setDeadline(deadlineTemp)
          setDeadlineTime(
            deadlineTemp
              ? Duration.fromObject({ hours: deadlineTemp.hour, minutes: deadlineTemp.minute })
              : Duration.fromMillis(0)
          )
          setName(template.name)
          setPriority(template.priority)
          setDescription(template.description)
          setEarliestStartChecked(template.earliestStartChecked)
          setPerformerConstraints(
            template.performerConstraints
              ? template.performerConstraints.map((constraint) => ({
                  firstId: constraint.firstId + "frontend",
                  secondId: constraint.secondId + "frontend",
                  performerConstraint: constraint.performerConstraint,
                }))
              : []
          )
          setNodeCoords(() => initialNodeCoords(newActions, newConnections))
          resolve()
        })
      }
      async function asyncWrapper() {
        await fireAllSetStates()
        await setStateAsync(setChosenTemplate, false)
      }
      asyncWrapper()
    }
  }, [chosenTemplate, memberTimezone])


  // Takes a parameter that determines whether this should return project data
  // or template data.
  function getData(getTemplateData) {
    const res = {
      name,
      color,
      description,
      priority,
      earliestStartChecked,
      projectGroupIds: activeProjectGroupId ? [activeProjectGroupId] : [],
      singleActionProject: false,
      performerConstraints: performerConstraints
        ? performerConstraints.map((constraint) => ({
            firstId: constraint.firstId,
            secondId: constraint.secondId,
            performerConstraint: constraint.performerConstraint,
          }))
        : [],

      earliestStart: earliestStart ? earliestStart.setZone(memberTimezone).toISO() : null,
      deadline: deadline ? deadline.setZone(memberTimezone).toISO() : null,
      actions: Object.values(actions).map((action) => {
        action = { ...action }
        action.coordinates = nodeCoords[action.id]
        action.tempId = String(action.id)
        action.allowedGroupIds = action.allowedGroupIds
          ? action.allowedGroupIds.filter((groupId) => competenceGroups[groupId])
          : null
        if (!action.isOld) {
          // the action was just created
          delete action.id
        }
        // When creating template from active project, it has unwanted attributes
        if (action.orderIndex !== undefined) {
          delete action.orderIndex
        }
        if (action.scrumColumnId !== undefined) {
          delete action.scrumColumnId
        }

        delete action.__typename
        delete action.projectId
        delete action.isOld
        delete action.coords
        delete action.start
        delete action.memberIds
        delete action.end
        delete action.iapWarning
        return action
      }),

      actionConnections:
        getTemplateData && !connections
          ? null
          : Object.values(connections).map((connection) => ({
              tempNextId: String(connection.nextId),
              tempPrevId: String(connection.prevId),
              timeBetween: connection.timeBetween,
              isWorkdayConnection: connection.isWorkdayConnection,
            })),
    }
    if (getTemplateData) {
      // if template delete isSingle action attribute
      delete res.singleActionProject
      res.actions.forEach((action) => {
        delete action.memberIds
        delete action.start
        delete action.state
        delete action.id
        delete action.movable
        delete action.teamId
        delete action.assetIds
        delete action.stage
      })
    }

    return res
  }

  const descriptionTextRows = description.slice().split(/\r\n|\r|\n/).length || 1

  const blocker = useNavigationBlocker(shouldCheckChanges && !leavePageObject.display && SomethingHasChanged(thisItem, getData(isTemplate)))
  if (blocker.state === "blocked" && !leavePageObject.display) {
    setLeavePageObject({
      msg: SomethingHasChanged(thisItem, getData(isTemplate)),
      location: blocker.tx?.nextLocation?.pathname || "",
      display: true,
    })
  }
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <ConfigContainer
      title={isTemplate ? (id ? (thisItem?.isDraft ? "Edit Project Template DRAFT" : "Edit Project Template") : "New Project Template DRAFT") : id ? "Edit Project" : "New Project"}
      cancelHandler={() => {
        if (isTemplate) {
          navigate("/setup/project-templates")
        } else if(isCreateOrEditProject) {
          setEditProject(false)
        } else {
          navigate(-1)
        }
      }}
      submitHandler={() => {
          setIsLoading(true)
        // When submitting, the input paramaters have their leading and trailing whitespaces trimmed.
        const isDraft = false
        setName(name.trim())  // This line secures that the name is trimmed after first render
        const trimmedName = name.trim()
        let res = getData(isTemplate)
        if(isTemplate) {
          res.name = trimmedName
        }
        const msg = ProjectValidator(res, templates, id, isTemplate, isDraft)
        if (msg) {
          dispatch(message.warning(msg))
          setIsLoading(false)
          return
        }
        setShouldCheckChanges(false)
        if (isTemplate) {
          res.earliestStartChecked = true
          if (!id) {
            dispatch(projectTemplates.create(res))
              .then((res) => navigate(`/setup/project-templates/${res.id}`))
              .finally(() => setIsLoading(false))
          } else {
            if(thisItem?.isDraft) {
              res.isDraft = isDraft
            }
            dispatch(projectTemplates.edit(id, res))
              .finally(() => setIsLoading(false))
          }
          setEarliestStartChecked(true)
        } else {
          res.recurrenceInterval = recurrenceSettings.interval
          res.recurrenceAmount = recurrenceSettings.amount
          if (!id) {
            dispatch(projects.create(res))
                .then(() => setEditProject(false))
                .finally(() => setIsLoading(false))
          } else {
            dispatch(projects
                .edit(id, res)).then(() => setEditProject(false))
                .finally(() => setIsLoading(false))
          }
        }
      }}
      deleteIsImportant
      deleteHandler={
        id
          ? () => {
              setShouldCheckChanges(false)
              if (isTemplate) {
                dispatch(projectTemplates.remove(id)).then(() => navigate("/setup/project-templates"))
              } else {
                dispatch(projects.remove(id)).then(() => setEditProject(false))
              }
            }
          : null
      }
      saveAsHandler={
        showProjects
          ? () => {
              const isDraft = false
              setName(name.trim())  // This line secures that the name is trimmed after first render
              const res = getData(true)
              const trimmedName = name.trim()
              res.name = trimmedName
              const msg = ProjectValidator(res, templates, id, isTemplate, isDraft)
              if (msg) {
                dispatch(message.warning(msg))
                return
              }
              setShouldCheckChanges(false)
              dispatch(projectTemplates.create(res)).then(() => navigate(-1))
            }
          : undefined
      }
      saveDraftHandler={
        showProjects
          ? () => {
            const isDraft = true
            setName(name.trim())  // This line secures that the name is trimmed after first render
            const res = getData(true)
            const trimmedName = name.trim()
            res.name = trimmedName
            const msg = ProjectValidator(res, templates, id, isTemplate, isDraft)
            if (msg) {
              dispatch(message.warning(msg))
              return
            }
            setShouldCheckChanges(false)
            if (!id) {
              dispatch(projectTemplates.createDraft(res)).then(() => navigate(-1))
            } else {
              dispatch(projectTemplates.editDraft(id,res))
            }
          }
        : undefined
      }
      saveAsName="Save as new template"
      submitButtonName="Save"
      cancelButtonName="Cancel"
      isDraft={(isTemplate && !id) || (isTemplate && thisItem?.isDraft)}
      isTemplate={isTemplate}
    >
      {connectionsCountChanged &&
        !hasWarnedAboutConnections &&
        prevEarliestStartOrDeadline &&
        nextEarliestStartOrDeadline && (
          <ConfirmDialog
            onCancel={() => {
              delete connections[ConnectionsAsArray[ConnectionsAsArray.length - 1]]
              setCanceledWarnedAboutConnection(true)
              setHasWarnedAboutConnections(true)
            }}
            onConfirm={() => {
              setHasWarnedAboutConnections(true)
            }}
          >
            <h4>Are you sure you want to have this connection?</h4>
            <h5>(Using connections with deadline or earliest start on actions are for advanced users)</h5>
            <br />
          </ConfirmDialog>
        )}
        {leavePageObject.display && (
          <ConfirmDialog
            onConfirm={() => {
              if (blocker.state === "blocked") {
                blocker.proceed();
              } else {
                navigate(leavePageObject.location);
              }
            }}
            onCancel={() => {
              if (blocker.state === "blocked") {
                blocker.reset();
                setLeavePageObject({ ...leavePageObject, display: false });
              } else {
                setLeavePageObject({ ...leavePageObject, display: false });
              }
            }}
          >
            <b>Are you sure you want to exit?</b>
            <div className="configurationPage-popup-list-of-changes">
              <p>There are unsaved changes in:</p>
              <ul>
                {leavePageObject.msg.split("-").slice(1).map((message, index) => (
                  <li key={index}>{message.trim()}</li>
                ))}
              </ul>
            </div>
          </ConfirmDialog>
        )}
        {!thisItem && showProjects && !isTemplate && (
          <div className="ProjectConfigurationPage-template-selector">
            <Dropdown
              onSelect={(key) => {
                const template = templates[key]
                Promise.resolve(setInsertingTemplate(true)).then(() => {
                  setChosenTemplate(template)
                })
              }}
            >
          <Dropdown.Toggle variant="primary">Load from template</Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.values(templates).map((template) => (
              <Dropdown.Item
                key={template.id}
                eventKey={template.id}
                title={template.isDraft ? "This project template is a Draft" : ""}
                className={template.isDraft ? "ProjectConfigurationPage-template-draft" : ""}
              >
                {template.name}
                {template.isDraft ? <span style={{ color: "#1d86d7" }}>draft</span> : ""}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      )}
      <div className="ProjectConfigurationPage-metadata-container">
        <Container>
          <Title>
            Name <div className="red-text">*</div>
          </Title>
          <Text
            required
            value={name}
            onChange={setName}
          />
        </Container>
        <InputGroup className="Project-color">
          <Title>
            Color <div className="red-text">*</div>
          </Title>
          <ColorPicker required={!isTemplate} value={color} onChange={setColor} />
        </InputGroup>
        <Container>
          <Title>Description</Title>
          <Text large value={description} onChange={setDescription} rows={descriptionTextRows} />
        </Container>
        <InputGroup className="Project-startDate">
          <InputGroup.Text className="project-startDateText">
            Start Date<div className="red-text">*</div>&nbsp;
          </InputGroup.Text>
          <DayPicker
            className="project-startDateDayPicker"
            required={!isTemplate || (isTemplate && !thisItem?.isDraft)}
            value={earliestStart}
            onChange={(newEarliestStart) => {
              // When input field is empty
              if (!newEarliestStart) {
                setEarliestStart(undefined)
                return
              }
              // When input field is being edited
              if (newEarliestStart.invalid) {
                return
              }
              if (earliestStartTime) {
                newEarliestStart = newEarliestStart.set({
                  hours: earliestStartTime.hours,
                  minutes: earliestStartTime.minutes,
                })
              }
              // When actions should be edited
              if (earliestStartChecked && earliestStart) {
                const difference = newEarliestStart.diff(earliestStart)
                difference &&
                  Object.values(actions).forEach((action) => {
                    action.earliestStart = action.earliestStart
                      ? DateTime.fromISO(action.earliestStart).setZone(memberTimezone).plus(difference).toISO()
                      : null
                    action.deadline = action.deadline
                      ? DateTime.fromISO(action.deadline).setZone(memberTimezone).plus(difference).toISO()
                      : null
                  })
                // Change project deadline
                deadline && difference && setDeadline(deadline.plus(difference))
              }
              setEarliestStart(newEarliestStart)
            }}
          />
          <InputGroup.Text className="project-startTimeText">Start Time</InputGroup.Text>
          <DurationInput
            className="start-timeInput"
            value={earliestStartTime}
            onChange={(newStartTime) => {
              if (newStartTime && earliestStart && earliestStartChecked) {
                // Change earliest start and deadlines of actions when project's "start date" changes
                const newEarliestStart = earliestStart.set({
                  hour: newStartTime.hours,
                  minute: newStartTime.minutes,
                })
                const difference = newEarliestStart.diff(earliestStart)

                Object.values(actions).forEach((action) => {
                  action.earliestStart = action.earliestStart
                    ? DateTime.fromISO(action.earliestStart).setZone(memberTimezone).plus(difference).toISO()
                    : null
                  action.deadline = action.deadline ? DateTime.fromISO(action.deadline).setZone(memberTimezone).plus(difference).toISO() : null
                })
                setEarliestStart(newEarliestStart)
                // Change project deadline
                if (deadline) {
                  const newDeadline = deadline.plus(difference)
                  setDeadline(newDeadline)
                  setDeadlineTime(Duration.fromObject({ hours: newDeadline.hour, minutes: newDeadline.minute }))
                }
              }
              if (newStartTime) {
                earliestStart &&
                  setEarliestStart(
                    earliestStart.set({
                      hour: newStartTime.hours,
                      minute: newStartTime.minutes,
                    })
                  )
              }
              setEarliestStartTime(newStartTime)
            }}
            isTime
          />
          <input
            id="Project-startDate-checkbox"
            type="checkbox"
            checked={earliestStartChecked}
            onChange={() => {}}
            onClick={() => {
              setEarliestStartChecked(!earliestStartChecked)
            }}
          />
          <div className="project-config-info">
            <FiInfo title="When checked project deadline, action deadline and action start will move relative to project start" />
          </div>
        </InputGroup>
        <InputGroup className="Project-deadline">
          <InputGroup.Text className="project-deadlineText">
            Deadline date
          </InputGroup.Text>
          <DayPicker
            value={deadline}
            onChange={(newDeadline) => {
              // When input field is empty
              if (!newDeadline) {
                setDeadline(undefined)
                return
              }
              // When input field is being edited
              if (newDeadline.invalid) {
                return
              }
              if (deadlineTime) {
                newDeadline = newDeadline.set({
                  hour: deadlineTime.hours,
                  minute: deadlineTime.minutes,
                })
              }
              setDeadline(newDeadline)
            }}
          />
          <InputGroup.Text className="project-deadlineTimeText">Deadline Time</InputGroup.Text>
          <DurationInput
            className="deadline-timeInput"
            value={deadlineTime}
            onChange={(newDeadlineTime) => {
              if (newDeadlineTime && deadline) {
                const newDeadline = deadline.set({
                  hour: newDeadlineTime.hours,
                  minute: newDeadlineTime.minutes,
                })
                setDeadline(newDeadline)
              }
              setDeadlineTime(newDeadlineTime)
            }}
            isTime
          />
        </InputGroup>
        <div className="prio-group-config">
          <div className="project-group-config">
            <Title>
                Project group
            </Title>
            <Dropdown
              className="ProjectGroup-selector"
              onSelect={(eventKey) => {
                if (eventKey === "add"){
                  setAddConfig(true)
                } else if (eventKey === "noGroup"){
                  dispatch(projectGroups.changeActiveProjectGroupId(null))
                } else {
                  dispatch(projectGroups.changeActiveProjectGroupId(eventKey))
                }
              }}
            >
              <Dropdown.Toggle variant="primary">{activeProjectGroupId ? projectGroupsDropdown[activeProjectGroupId].name : "No group"}</Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.values(projectGroupsDropdown).map((projectGroup) => (
                  <Dropdown.Item key={projectGroup.id} eventKey={projectGroup.id}>
                    {projectGroup.name}<FiSettings className="projectGroup-setting" onClick={() => {
                      setAddConfig(true)
                      setProjectGroupConfigId(projectGroup.id)
                    }} />
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item key="add" eventKey="add">
                  <FiPlus /> Add project group
                </Dropdown.Item>
                <Dropdown.Item key="noGroup" eventKey="noGroup">
                  <FiCircle /> No group
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <InputGroup className="Project-priority">
            <InputGroup.Text>Priority</InputGroup.Text>
            <ToggleButtonGroup
              className="Memberconfig-toggleButtonGroup"
              onChange={setPriority}
              value={priority}
              type="radio"
              name="project-priority-selection">
              <ToggleButton variant="light" id="priority-select-1" className="Memberconfig-radioButton" value={1}>1</ToggleButton>
              <ToggleButton variant="light" id="priority-select-2" className="Memberconfig-radioButton" value={2}>2</ToggleButton>
              <ToggleButton variant="light" id="priority-select-3" className="Memberconfig-radioButton" value={3}>3</ToggleButton>
            </ToggleButtonGroup>
            <div className="project-priority-info">
              <FiInfo title="1 is highest priority" />
            </div>
          </InputGroup>
        </div>
      </div>
      <div className="ProjectConfiguration-designToolHeader">
        <p className="setupHeading">Design Tool</p>
      </div>
      <div className="ProjectConfigurationPage-drawing">
        <GraphDesignTool
          required={!isTemplate}
          requireUniqueNames={!isTemplate}
          nodes={actions}
          edges={connections}
          nodeColor={color ? color.toUpperCase() : "#FFFFFF"}
          onClear={() => {
            setActions({})
            setConnections({})
          }}
          onCreateNode={(action) => {
            setActions({ ...actions, [action.id]: action })
          }}
          onEditNode={(action) => setActiveAction(action)}
          onDeleteNode={(id) => {
            const [newActions, newConnections] = deleteNode(actions, connections, id)
            setActions(newActions)
            setConnections(newConnections)
            setPerformerConstraints(
              performerConstraints
                .filter((constraint) => constraint.firstId !== id)
                .filter((constraint) => constraint.secondId !== id)
            )
          }}
          onCreateEdge={(prevId, nextId) => {
            const conn = {
              prevId,
              nextId,
              id: `${prevId}-${nextId}`,
              timeBetween: {
                min: 0,
                max: null,
              },
            }
            Promise.resolve(setInsertingTemplate(false)).then(() => {
              setConnections({ ...connections, [conn.id]: conn })
            })
          }}
          onEditEdge={(connection) => setActiveConnection(connection)}
          configuredNodes={Object.values(actions)
            // When editing a action you can't press "Save" before the name is filled.
            // But! Because of legacy, we might have a template action without an earliest start / deadline!
            .filter((action) => (action.name && action.allowedGroupIds ? action.allowedGroupIds.length : false))
            .map((action) => action.id)}
          onModeChange={() => {
            setMode(mode === "node" ? "edge" : "node")
          }}
          mode={mode}
          onChangeInNodeCoords={setNodeCoords}
          onSiteClick={(action) => {
            setActions({ ...actions, [action.id]: { ...action, onSite: !action.onSite } })
          }}
          isProject={true}
        />
        {!thisItem && showProjects && !isTemplate && (
            <div className="ProjectConfigurationPage-recurrence-button">
              <Button variant="link" size="lg" onClick={() => setShowRecurrence(true)}>
                Recurrence
              </Button>
            </div>
          )}
      </div>

      <p className="Pheading ConfigurationPage">Actions Performer Constraints</p>
      <div className="ConfigurationPage-performerConstraints-button">
        <Button variant="link" size="lg" onClick={() => setShowPerformerConstraints(!showPerformerConstraints)}>
            {showPerformerConstraints ? <FaArrowUp className="fa-arrowUp-icon"/> : <FiPlus className="fi-plus-icon"/>}
        </Button>
      </div>

      {showPerformerConstraints && (
          <>
            <PerformerConstraints
              tasks={actions}
              performerConstraints={performerConstraints}
              onConstraintsChange={setPerformerConstraints}
              isProject={true}
            />
        </>
        )}
        <hr />
    </ConfigContainer>
      )}
    {activeAction && (
        <Popup nonScrollable onCancel={() => setActiveAction(null)}>
          <ActionConfig
            title="Edit Action"
            templateAction={isTemplate}
            isGraphNode
            action={activeAction}
            submitHandler={(res) => {
              const newActions = addToMap(actions, { ...activeAction, ...res })
              const newActiveAction = Object.values(newActions).filter((action) => action.id === activeAction.id)
              const msg = ActionValidator(
                Object.values(newActions),
                Object.values(connections),
                newActiveAction[0],
                deadline
              )
              if (msg) {
                dispatch(message.warning(msg))
              }
              setActions(newActions)
              setActiveAction(null)
            }}
            cancelHandler={() => setActiveAction(null)}
          />
        </Popup>
      )}
      {activeConnection && (
        <Popup nonScrollable onCancel={() => setActiveConnection(null)}>
          <ActionConnectionConfig
            connection={activeConnection}
            actionFrom={actions[activeConnection.prevId]}
            actionTo={actions[activeConnection.nextId]}
            submitHandler={(res) => {
              const newConnections = addToMap(connections, { ...activeConnection, ...res })
              const msg = ActionValidator(
                Object.values(actions),
                Object.values(newConnections),
                actions[activeConnection.prevId],
                deadline
              )
              if (msg) {
                dispatch(message.warning(msg))
              }

              setConnections(newConnections)
              setActiveConnection(null)
            }}
            deleteHandler={() => {
              setConnections(removeFromMap(connections, activeConnection))
              setActiveConnection(null)
            }}
            cancelHandler={() => setActiveConnection(null)}
          />
        </Popup>
      )}
      {showRecurrence && (
        <div className="RecurrenceConfig-container">
          <Popup className="RecurrenceConfig-Popup" onCancel={() => setShowRecurrence(false)}>
            <h3>Recurrence</h3>
            <Container>
              <Title>Recur every</Title>
              <div className="Recurrence-number">
                <Number value={interval} onChange={setInterval} min={0} />
              </div>
              <Title>day(s)</Title>
            </Container>
            <Container>
              <Title>Number of recurrences </Title>
              <div className="Recurrence-number">
                <Number value={amount} onChange={setAmount} min={0} />
              </div>
            </Container>
            <div className="RecurrenceConfig-buttons">
              <Button variant="secondary" onClick={() => setShowRecurrence(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (amount > 365) {
                    dispatch(message.warning("Number of recurrences cannot be greater than 365"))
                    return
                  }

                  setRecurrenceSettings({
                    amount: interval ? amount : 0,
                    interval: interval ? interval : 0,
                  })

                  setShowRecurrence(false)
                }}
              >
                Save
              </Button>
            </div>
          </Popup>
        </div>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(false)}>
          <ProjectGroupConfig
            projectGroup={projectGroupsDropdown[projectGroupConfigId]}
            onClose={() => {
              setAddConfig(false)
              setProjectGroupConfigId(null)
            }}
            onSubmit={() => {
              setAddConfig(false)
              setProjectGroupConfigId(null)
            }}
          />
        </Popup>
      )}
    </>
  )
}
