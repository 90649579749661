import { request } from "utils/request"
import { addToMap, removeFromMap, asyncAction } from "./common"
import { analyses } from "."
import { DateTime } from "luxon"

//pending is the item that is being hovered
//isHovered is true if an item marked as multipleDrag is hovered
const initialState = {
  items: {},
  isHovered: false,
  pending: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "multipleDrag/add":
      if (Object.keys(state.items).length) {
        if (state.items[Object.keys(state.items)[0]].memberIds[0] !== action.item.memberIds[0]) {
          return state
        }
      }
      return { ...state, items: addToMap(state.items, action.item) }
    case "multipleDrag/remove":
      return { ...state, items: removeFromMap(state.items, action.item) }
    case "multipleDrag/dropAllTasks":
      return { ...state, items: {} }
    case "multipleDrag/dropAllActions":
      return { ...state, items: {} }
    //TODO: Make only one ScheduleItem call unGhostAll instead of all of them
    case "multipleDrag/unGhostAll":
      if (!state.isHovered && !Object.values(state.items).includes(state.pending)) {
        return { ...state, items: {} }
      } else {
        return state
      }
    case "multipleDrag/hover":
      if (Object.values(state.items).includes(action.item)) {
        return { ...state, isHovered: true }
      } else {
        return { ...state, pending: action.item }
      }
    case "multipleDrag/unhover":
      return { ...state, isHovered: false, pending: {} }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export function add(item) {
  return { type: "multipleDrag/add", item }
}
export function remove(item) {
  return { type: "multipleDrag/remove", item }
}

export const dropAllTasks = asyncAction("multipleDrag/dropAllTasks", (dispatch, memberIds, newDate) => {
  const state = window.store.getState()
  return request("POST", "/tasks/analysis/bulk-move", {
    input: {
      ids: Object.keys(state.multipleDrag.items),
      memberIds,
      shiftAmount: DateTime.fromISO(newDate)
        .diff(
          DateTime.fromISO(
            Object.values(state.multipleDrag.items).sort((a, b) => {
              if (a.start < b.start) return -1
              if (a.start > b.start) return 1
              return 0
            })[0].start
          )
        )
        .as("seconds"),
    },
  }).then((res) => {
    //dispatch(analysisTasks.fetch())
    dispatch(analyses.fetchPlanned())
    return res
  })
})
export const dropAllActions = asyncAction("multipleDrag/dropAllActions", (dispatch, memberIds, newDate) => {
  const state = window.store.getState()
  return request("POST", "/actions/bulk-move", {
    input: {
      ids: Object.keys(state.multipleDrag.items),
      memberIds,
      shiftAmount: DateTime.fromISO(newDate)
        .diff(
          DateTime.fromISO(
            Object.values(state.multipleDrag.items).sort((a, b) => {
              if (a.start < b.start) return -1
              if (a.start > b.start) return 1
              return 0
            })[0].start
          )
        )
        .as("seconds"),
    },
  }).then((res) => {
    return res
  })
})

export function unGhostAll() {
  return { type: "multipleDrag/unGhostAll" }
}

export function hover(item) {
  return { type: "multipleDrag/hover", item }
}
export function unhover() {
  return { type: "multipleDrag/unhover" }
}
