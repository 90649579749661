import { request } from "utils/request"
import { asyncAction } from "./common"
import { DateTime } from "luxon"

// We never remove from this state, so if a task is deleted, we might still have an entry in here
// But really, that's not a problem!
const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "constraintChecks/fetchAllAnalysisTask":
    case "constraintChecks/fetchAllProjectAction":
    case "constraintChecks/fetchAnalysisTask":
    case "constraintChecks/fetchAllWhiteTask":
    case "constraintChecks/fetchProjectAction": {
      let temp = { ...state }
      Object.keys(action.res).forEach((id) => {
        temp[id] = action.res[id]
      })
      return temp
    }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchAllWhiteTask = (checkAllConstraints) => {
  return { type: "constraintChecks/fetchAllWhiteTask", res: checkAllConstraints }
}

export const fetchAllAnalysisTask = (checkAllConstraints) => {
  return { type: "constraintChecks/fetchAllAnalysisTask", res: checkAllConstraints }
}

export const fetchAnalysisTask = asyncAction("constraintChecks/fetchAnalysisTask", (dispatch, id) => {
  return request("GET", `/task/analysis/${id}/check-constraints`).then((res) => res.warnings)
})

export const fetchAllProjectAction = (checkAllConstraints) => {
  return { type: "constraintChecks/fetchAllProjectAction", res: checkAllConstraints }
}

export const fetchProjectAction = asyncAction("constraintChecks/fetchProjectAction", (dispatch, id) => {
  return request("GET", `/action/${id}/check-constraints`).then((res) => res.warnings)
})

export const allConstraintChecks = (dispatch) => {
  const state = window.store.getState()
  let isAnalysis = state.teamStatus.showAnalyses
  let isProjects = state.teamStatus.showProjects
  const actions = state.projectActions
  const projects = state.projects
  const competenceGroups = state.competenceGroups
  const whiteTasks = state.whiteTasks
  const plannedAnalyses = state.analyses.plannedAnalyses
  const analysisTasks = state.analysisTasks
  const assets = state.asset.assets
  const members = state.members
  const overbookingTasks = findOvebookedAssets(actions.plannedProjectActions, analysisTasks, whiteTasks, assets, plannedAnalyses)

  const overBookedWhiteTasks = checkAllWhiteTasksConstraints(whiteTasks, overbookingTasks)
  dispatch(fetchAllWhiteTask({overBookedWhiteTasks}))
  if (isAnalysis) {
    const analysisTasksWarnings = checkAllAnalysisTasksConstraints(competenceGroups, whiteTasks, analysisTasks, plannedAnalyses, overbookingTasks, members)
    dispatch(fetchAllAnalysisTask(analysisTasksWarnings))
  }
  if (isProjects) {
    const projectActionsWarnings = checkAllProjectActionsConstraints(actions, projects, competenceGroups, whiteTasks, overbookingTasks, members)
    dispatch(fetchAllProjectAction(projectActionsWarnings))
  }
}

export const checkAllProjectActionsConstraints = (actions, projects, competenceGroups, whiteTasks, overbookingTasks, members) => {
  const warnings = {}
  const plannedActions = actions.plannedProjectActions
  const allActions = { ...plannedActions, ...actions.unplannedProjectActions, ...actions.backlogProjectActions }
  for (let action of Object.values(plannedActions)) {
    warnings[action.id] = []
    const warning = warnings[action.id]

    // Check if an action is performed before it's deadline
    if (action.deadline !== null && action.end > action.deadline) {
      warning.push("Not done before the deadline")
    }

    // Check if an action is performed after it's earliest start
    if (action.earliestStart !== null && action.start < action.earliestStart) {
      warning.push("Begins before the earliest start time")
    }

    // Check if an action is performed before the project's earliest start
    if (projects[action.projectId].earliestStart !== null && action.start < projects[action.projectId].earliestStart) {
      warning.push("Begins before the start date of the project")
    }

    // Check if an action is performed before the project's deadline
    if (projects[action.projectId].deadline !== null && action.end > projects[action.projectId].deadline) {
      warning.push("Not done before the project's deadline")
    }

    // Check if the performer is qualified to perform the action
    let allowedPerformer = false
    for (let i = 0; i < action.allowedGroupIds.length; i++) {
      const groupId = action.allowedGroupIds[i]
      if (competenceGroups[groupId]?.memberIds.includes(action.memberIds[0])) {
        allowedPerformer = true
        break
      }
    }
    if (!allowedPerformer) {
      warning.push("Not performed by a qualified performer")
    }

    // Check if connected actions are within min/max interval on incoming and outgoing connections
    const connWarning = withinConnections(action, allActions, projects[action.projectId], competenceGroups, members)
    if (connWarning !== null) {
      warning.push(connWarning)
    }

    // Check if performer constraints are met
    const performerConstraintWarning = fulfillsPerformerConstraints(action, allActions, projects[action.projectId])
    if (performerConstraintWarning !== null) {
      warning.push(performerConstraintWarning)
    }

    // Check if the action is required to be performed on site, and if it is performed on site
    const onSiteWarning = onSitePerformedOffSite(action, whiteTasks, false)
    if (onSiteWarning !== null) {
      warning.push(onSiteWarning)
    }

    // Check if one of the asssets used are overbooked
    if (overbookingTasks.has(action.id)) {
      warning.push("Asset overbooked")
    }
  }

  return warnings
}


export const checkAllAnalysisTasksConstraints = (competenceGroups, whiteTasks, analysisTasks, analyses, overbookingTasks, members) => {
  const warnings = {}

  for (let task of Object.values(analysisTasks)) {
    warnings[task.id] = []
    const warning = warnings[task.id]
    const analysis = analyses[task.analysisId]
    
    // Check if we are done before the minimum sample deadline
    if (analysis.minSampleDeadline !== null && task.end && task.end > analysis.minSampleDeadline){
      warning.push("Will not be done before the deadline")
    }

    // Check if we start after the latest sample arrival
    if (analysis.maxSampleArrival !== null && task.start && task.start < analysis.maxSampleArrival){
      warning.push("Will be started before all the samples have arrived")
    }

    // Check if the performer is qualified to perform the action
    let allowedPerformer = false
    for (let i = 0; i < task.allowedGroupIds.length; i++) {
      const groupId = task.allowedGroupIds[i]
      if (competenceGroups[groupId]?.memberIds.includes(task.memberIds[0])) {
        allowedPerformer = true
        break
      }
    }
    if (!allowedPerformer) {
      warning.push("Not performed by a qualified performer")
    }
    
    // Check if performer constraints are met
    const performerConstraintWarning = fulfillsPerformerConstraints(task, analysisTasks, analysis)
    if (performerConstraintWarning !== null) {
      warning.push(performerConstraintWarning)
    }

    // Check if connected actions are within min/max interval on incoming and outgoing connections
    const connWarning = withinConnections(task, analysisTasks, analysis, competenceGroups)
    if (connWarning !== null) {
      warning.push(connWarning)
    }

    // Check if the action is required to be performed on site, and if it is performed on site
    const onSiteWarning = onSitePerformedOffSite(task, whiteTasks, true)
    if (onSiteWarning !== null) {
      warning.push("Member not on site")
    }

    // Check if one of the asssets used are overbooked
    if (overbookingTasks.has(task.id)) {
      warning.push("Asset overbooked")
    }

    // Check if the analysis contains too few samples
    if (analysis.minSampleCount > analysis.sampleCount) {
      warning.push("Too few samples on analysis")
    }

    // Check if the analysis contains too many samples
    if (analysis.maxSampleCount < analysis.sampleCount) {
      warning.push("Too many samples on analysis")
    }
  }

  return warnings
}

export const checkAllWhiteTasksConstraints = (whiteTasks, overbookingTasks) => {
  const warnings = {};

  for (let task of Object.values(whiteTasks)) {
    warnings[task.id] = [];
    const warning = warnings[task.id];

    // Check if the task's asset is overbooked
    if (overbookingTasks.has(task.id)) {
      warning.push("Asset overbooked");
    }
  }

  return warnings;
}

export const withinConnections = (action, actions, project, competenceGroups) => {
  for (let i = 0; i < project.actionConnections.length; i++) {
    const actionConnection = project.actionConnections[i]
    if (actionConnection.isWorkdayConnection){
      // First we need to find out which days are workdays for a given action!
      let competentMembers = []
      for (let i = 0; i < action.allowedGroupIds.length; i++) {
        const groupId = action.allowedGroupIds[i]
        competentMembers = [...competentMembers, ...competenceGroups[groupId].memberIds]
      }


      // Workweek is defined as solely the week without the weekend
      let allWorkWeek = [true, true, true, true, true, false, false]

      // The code below is if a work week is defined dynamicaly, based on the members work hours
      /*
        // allWorkWeek is an array stating if whether a day is a workday for a given action or not.
        // It is a workday if any member in the competence groups can work on the given day.
        for (let i = 0; i < competentMembers.length; i++) {
          const memberId = competentMembers[i]
          const workWeek = members[memberId].workWeek
          allWorkWeek[0] = allWorkWeek[0] || workWeek["monday"]
          allWorkWeek[1] = allWorkWeek[1] || workWeek["tuesday"]
          allWorkWeek[2] = allWorkWeek[2] || workWeek["wednesday"]
          allWorkWeek[3] = allWorkWeek[3] || workWeek["thursday"]
          allWorkWeek[4] = allWorkWeek[4] || workWeek["friday"]
          allWorkWeek[5] = allWorkWeek[5] || workWeek["saturday"]
          allWorkWeek[6] = allWorkWeek[6] || workWeek["sunday"]
        }
      */

      // Check if the connection is fulfilled
      if (action.id === actionConnection.nextId) {
        const prevAction = actions[actionConnection.prevId]
        if (prevAction.start === null) {
          return `Predecessor action ${prevAction.name} not planned`
        } else if (
          findConnectionEndpointWorkday(DateTime.fromISO(prevAction.end), actionConnection.timeBetween.min, allWorkWeek) > DateTime.fromISO(action.start)
        ) {
          return `Too short delay from ${prevAction.name}`
        } else if (
          actionConnection.timeBetween.max !== null &&
          findConnectionEndpointWorkday(DateTime.fromISO(prevAction.end), actionConnection.timeBetween.max, allWorkWeek) < DateTime.fromISO(action.start)
        ) {
          return `Too long delay from ${prevAction.name}`
        }
      } else if (action.id === actionConnection.prevId) {
        const nextAction = actions[actionConnection.nextId]
        if (
          findConnectionEndpointWorkday(DateTime.fromISO(action.end), actionConnection.timeBetween.min, allWorkWeek) > DateTime.fromISO(nextAction.start)
        ) {
          return `Too short delay to ${nextAction.name}`
        } else if (
          actionConnection.timeBetween.max !== null &&
          findConnectionEndpointWorkday(DateTime.fromISO(action.end), actionConnection.timeBetween.max, allWorkWeek) < DateTime.fromISO(nextAction.start)

        ) {
          return `Too long delay to ${nextAction.name}`
        }
      }
      
    }
    else {
      if (action.id === actionConnection.nextId) {
        const prevAction = actions[actionConnection.prevId]
        if (prevAction.start === null) {
          return `Predecessor action ${prevAction.name} not planned`
        } else if (
          DateTime.fromISO(prevAction.end).plus({ seconds: actionConnection.timeBetween.min }) >
          DateTime.fromISO(action.start)
        ) {
          return `Too short delay from ${prevAction.name}`
        } else if (
          actionConnection.timeBetween.max !== null &&
          DateTime.fromISO(prevAction.end).plus({ seconds: actionConnection.timeBetween.max }) <
            DateTime.fromISO(action.start)
        ) {
          return `Too long delay from ${prevAction.name}`
        }
      } else if (action.id === actionConnection.prevId) {
        const nextAction = actions[actionConnection.nextId]
        if (
          DateTime.fromISO(action.end).plus({ seconds: actionConnection.timeBetween.min }) >
          DateTime.fromISO(nextAction.start)
        ) {
          return `Too short delay to ${nextAction.name}`
        } else if (
          actionConnection.timeBetween.max !== null &&
          DateTime.fromISO(action.end).plus({ seconds: actionConnection.timeBetween.max }) <
            DateTime.fromISO(nextAction.start)
        ) {
          return `Too long delay to ${nextAction.name}`
        }
      }
    }
  }
  return null
}

const secondsPerDay = 24*60*60
export const findConnectionEndpointWorkday = (start, duration, workWeek) => {
  const timeToMidnight = secondsPerDay - 3600*start.hour - 60*start.minute - start.second
  let currentDay = start.weekday - 1
  if (timeToMidnight > duration){
    return start.plus({ seconds: duration })
  }
  else {
    start = start.plus({ seconds: timeToMidnight })
    duration -= timeToMidnight
  }
  currentDay = (currentDay + 1) % 7
  let count = 0
  while (count < 300){
    count += 1
    if (workWeek[currentDay]){
      if (secondsPerDay > duration){
        return start.plus({ seconds: duration })
      }
      else {
        duration -= secondsPerDay
      }
    }
    start = start.plus({ seconds: secondsPerDay })
    currentDay = (currentDay + 1) % 7
  }
}

export const fulfillsPerformerConstraints = (task, tasks, project) => {
  let otherTask = null
  for (let i = 0; i < project.performerConstraints.length; i++) {
    const constraint = project.performerConstraints[i]
    if (constraint.firstId === task.id) {
      otherTask = tasks[constraint.secondId]
    } else if (constraint.secondId === task.id) {
      otherTask = tasks[constraint.firstId]
    } else {
      continue
    }
    if (!otherTask || otherTask.start === null) {
      continue
    }
    if (constraint.performerConstraint === "SAME" && otherTask.memberIds[0] !== task.memberIds[0]) {
      return `Must have the same performer as ${otherTask.name}`
    }
    if (constraint.performerConstraint === "DIFFERENT" && otherTask.memberIds[0] === task.memberIds[0]) {
      return `Must have a different performer than ${otherTask.name}`
    }
  }
  return null
}

export const onSitePerformedOffSite = (action, whiteTasks, isAnalysis) => {
  if (!action.onSite && !isAnalysis) {
    return null
  }
  for (let whiteTask of Object.values(whiteTasks)) {
    if (!whiteTask.greenTask) {
      continue
    } else if (!whiteTask.memberIds.includes(action.memberIds[0])) {
      continue
    } else if (whiteTask.start <= action.start && action.start < whiteTask.end) {
      return "Member not on site"
    } else if (whiteTask.start < action.end && action.end <= whiteTask.end) {
      return "Member not on site"
    }
  }
  return null
}

export const findOvebookedAssets = (projectActions, analysisTasks, whiteTasks, assets, analyses) => {
  const overbookingTasks = new Set()
  for (let asset of Object.values(assets)) {
    const tasksStartEnd = []
    for (let projectAction of Object.values(projectActions)) {
      if (projectAction.assetIds.includes(asset.id)) {
        tasksStartEnd.push([DateTime.fromISO(projectAction.start), DateTime.fromISO(projectAction.end), projectAction.id])
      }
    }
    
    for (let analysisTask of Object.values(analysisTasks)) {
      // Check if any assetRequirement's assetId matches asset.id and is not null
      if (analysisTask.assetRequirements.some(assetReq => assetReq.assetId === asset.id && assetReq.assetId != null)) {
        const sampleCount = analyses[analysisTask.analysisId].sampleCount;
        
        // Iterate over assetRequirements to find matching asset settings
        analysisTask.assetRequirements.forEach(assetReq => {
          if (assetReq.assetId === asset.id) {
            // Calculate the start time based on the offset and the setup time
            const assetStart = DateTime.fromISO(analysisTask.start).plus({ seconds: assetReq.assetSetuptime });
    
            // Calculate the total duration for this asset
            const assetDuration = assetReq.assetSetuptime + sampleCount * assetReq.assetPerSampleDuration;
    
            // Push the computed start time, end time, and task ID into tasksStartEnd array
            tasksStartEnd.push([assetStart, assetStart.plus({ seconds: assetDuration }), analysisTask.id]);
          }
        });
      }
    }
    
    for (let whiteTask of Object.values(whiteTasks)) {
      if (whiteTask.assetIds.includes(asset.id)) {
        const assetStart = DateTime.fromISO(whiteTask.start)
        const assetEnd = DateTime.fromISO(whiteTask.end)
        tasksStartEnd.push([assetStart, assetEnd, whiteTask.id])
      }
    }
    let prevEnd = null
    let prevId = null
    tasksStartEnd.sort()
    for (let i = 0; i < tasksStartEnd.length; i++) {
      const taskStartEnd = tasksStartEnd[i]
      if (prevEnd !== null && prevEnd > taskStartEnd[0]) {
        overbookingTasks.add(prevId)
        overbookingTasks.add(taskStartEnd[2])
      }
      if (taskStartEnd[1] > prevEnd){
        prevEnd = taskStartEnd[1]
      }
      prevId = taskStartEnd[2]
    }
  }
  return overbookingTasks
}
