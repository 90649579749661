import { useState, useEffect } from 'react'

export default function useLocalStorage(key, defaultValue) {
    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : defaultValue
        } catch (error) {
            console.warn("Failed to getItem",error)
            return defaultValue
        }
    })

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            console.warn("Failed to setItem",error)
        }
    }, [key, value])

    return [value, setValue]
}