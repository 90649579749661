import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { Provider } from "react-redux";
import { rootReducer, loading } from "state_management";
import { ErrorBoundary, Message } from "components";
import { createRoot } from 'react-dom/client';

import App from "./App";
import "./index.scss";

import hash from "object-hash";
import { loggedOutState as currentLoggedOutState } from "state_management/auth";
import { initialTemplateState as currentVisualStateTemplate } from "state_management/visual";

// Disable console logging in production, this was a given requirement!
if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}

console.log("running...");

// The initial Redux state
const initialState = {};

// Helper to set an item in local storage
function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch {
    // Ignore errors, the user might have turned localStorage off
  }
}

// Helper to get an item from local storage
function getLocalStorage(key, value) {
  try {
    return JSON.parse(window.localStorage.getItem(key)) || null;
  } catch {
    // Ignore errors, the user might have turned localStorage off
    return null;
  }
}

// Ensure that the auth and visual state we load from browser storage isn't
// formatted in an invalid way. This is used when we deploy the new application,
// to prevent us from loading something the rest of the application doesn't
// expect!
if (hash(getLocalStorage("loggedOutState")) === hash(currentLoggedOutState)) {
  console.log("Loading auth state from browser storage");
  const auth = getLocalStorage("auth");
  if (auth) {
    initialState.auth = auth;
  }
} else {
  console.warn("Logged out state changed. Discarding stored auth state");
  setLocalStorage("loggedOutState", currentLoggedOutState);
}

if (hash(getLocalStorage("visualStateTemplate")) === hash(currentVisualStateTemplate)) {
  console.log("Loading visual state from browser storage");
  const visual = getLocalStorage("visual");
  if (visual) {
    initialState.visual = visual;
  }
} else {
  console.warn("Visual state template changed. Discarding stored visual state");
  setLocalStorage("visualStateTemplate", currentVisualStateTemplate);
}

// Create the Redux store, and make it publicly accessible on the `window`.
// This global reference is later used when making requests, and a few other places.
// Not that pretty, but oh well
window.store = createStore(rootReducer, initialState, applyMiddleware(thunk));

if (
  initialState.auth &&
  initialState.auth.isLoggedIn &&
  initialState.auth.role !== "admin" &&
  initialState.auth.role !== "customer_admin"
) {
  window.store.dispatch(loading.fetchAll(window.location.pathname));
}

// Add listener to set visual and auth state when the store changes
let previousVisual = null;
let previousAuth = null;
window.store.subscribe(() => {
  const state = window.store.getState();
  if (previousVisual !== state.visual) {
    setLocalStorage("visual", state.visual);
    previousVisual = state.visual;
  }
  if (previousAuth !== state.auth) {
    setLocalStorage("auth", state.auth);
    previousAuth = state.auth;
  }
});

// Define routes using createBrowserRouter
const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
  },
]);

// Start the main rendering.
// Up until this point is just "normal" JS code, but after this we enter the
// React-SPA-"framework" rendering!

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={window.store}>
    <ErrorBoundary>
      <Message />
      <RouterProvider router={router} />
    </ErrorBoundary>
  </Provider>
);

