import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import "./index.scss"

import { ConfigContainer } from "components"
import ToggleButton from "react-bootstrap/ToggleButton"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import { Duration, DateTime } from "luxon"
import { Title, Container, Text, DurationInput, TimezonePicker } from "components/Form"
import { members, message, analyses, seatInfo, customerAdministration, auth, visual } from "state_management"

// Member config.
// Used both by ADMINs, MEMBERs and PLANNERs, so make sure it keeps working
// regardless of which membertype the currently logged in user is!
export default function MemberConfig({ member = null, adminTeamId = null, onClose }) {
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [role, setRole] = useState(member ? member.role : "MEMBER")
  const [startTime, setStartTime] = useState(
    member ? Duration.fromObject({ seconds: member.workHours.min }) : Duration.fromObject({ hours: 8 })
  )
  const [endTime, setEndTime] = useState(
    member ? Duration.fromObject({ seconds: member.workHours.max }) : Duration.fromObject({ hours: 16 })
  )
  const isCustomerAdmin = useSelector((state) => state.auth.role) === "customer_admin"
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)

  const [username, setUsername] = useState(member ? (member.name ? member.name : member.username)  : "")  // temporary check if DB changes name to username
  const [email, setEmail] = useState(member ? member.email : "")
  const [password, setPassword] = useState(null)
  const [initials, setInitials] = useState(member ? member.initials : "")
  const [showOnPlan, setShowOnPlan] = useState(member ? member.showOnPlan : true)
  const [timezone, setTimezone] = useState(member ? member.timezone ? member.timezone : "Europe/Dublin" : "Europe/Dublin")
  const [outlookLink, setOutlookLink] = useState(member ? member.outlookLink : null)

  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

  const renderDays = (disabled) => {
    return days.map((day) => {
      return (
        <ToggleButton
          disabled={disabled}
          className="Memberconfig-checkboxButton"
          value={day}
          title={day}
          variant="light"
          key={day}
          id={"member-config-"+day}
        >
          {day.substring(0, 3)}
        </ToggleButton>
      )
    })
  }

  const [workWeek, setWorkWeek] = useState(
    (() => {
      if (member) {
        let arr = []
        Object.entries(member.workWeek).map((entry) => {
          if (entry[1]) {
            arr.push(entry[0])
          }
          return null
        })
        return arr
      } else {
        return [days[0], days[1], days[2], days[3], days[4]]
      }
    })()
  )

  const checkOutlookLink = (link) => {
    let correctLink = true
    if(link && !link.includes('.ics')){
      dispatch(message.warning('Must be a .ics link'))
      correctLink = false
    } 
    if(link && link.length === 0){
      correctLink = true
    }
    setOutlookLink(link)
    return correctLink
  }

  const isPasswordValid = (password) => {
    // Define all password requirements and their respective regex patterns
    const requirements = [
      { name: "at least 8 characters", pattern: /^.{8,}$/ },
      { name: "at least 1 lowercase letter", pattern: /[a-z]/ },
      { name: "at least 1 uppercase letter", pattern: /[A-Z]/ },
      { name: "at least 1 number", pattern: /\d/ },
    ]

    if (member && !password) {
      return { valid: true, missingRequirements: [] }
    }

    let missingRequirements = []
  
    // Check each requirement
    for (let requirement of requirements) {
      if (!requirement.pattern.test(password)) {
        missingRequirements.push(requirement.name)
      }
    }

    // Return validity and list of missing requirements
    return {
      valid: missingRequirements.length === 0,
      missingRequirements: missingRequirements,
    }
  }

  const disabled = member && ((authState.isMember && member && authState.memberId !== member.id) || member.archived)
  return (
    <ConfigContainer
      disabled={disabled}
      title={member ? "Edit member" : "Add member"}
      submitHandler={() => {
        const passwordResult = isPasswordValid(password)
        if(!passwordResult.valid) {
          const passwordMessage = "Missing password requirements: " + passwordResult.missingRequirements.join(', ')
          return dispatch(message.warning(passwordMessage))
        }
        const res = {
          name: username,
          initials,
          email,
          workHours: {
            min: startTime.as("seconds"),
            max: endTime.as("seconds"),
          },
          workWeek: (() => {
            let obj = {}
            days.map((day) => {
              obj[day] = false
              return null
            })
            workWeek.map((day) => {
              obj[day] = true
              return null
            })
            return obj
          })(),
          role,
          password: password || undefined, // Don't send password if it's not set!
          showOnPlan: showOnPlan,
          timezone,
          outlookLink: outlookLink || null,
        }
        // Only use this if the user is exists
        if(member?.id === authState.memberId && timezone !== authState.memberTimezone) {
          dispatch(auth.changeTimezone(timezone))
          const oldMonday = DateTime.local().setZone(memberTimezone).startOf("week")
          const newMonday = DateTime.local().setZone(timezone).startOf("week")
          if (!newMonday.hasSame(oldMonday, "day")) {
            dispatch(visual.changeMondayDate(newMonday.toISO()))
          }
        }
        if (!checkOutlookLink(outlookLink)){
          dispatch(message.warning('Invalid iCalendar / Outlook link'))
        }
        else if (member) {
          const wasShownOnPlan = member.showOnPlan
          dispatch(members.edit(member.id, res)).then(() => {
            if (wasShownOnPlan && !res.showOnPlan){
              dispatch(analyses.fetchPlanned())
              dispatch(analyses.fetchUnplanned())
            }
          }).then(onClose)
        } else {
          if (adminTeamId) {
            dispatch(members.createAsAdmin({ ...res, teamId: adminTeamId })).then(() => {
              // Fetching customers in different ways, depending on if user is customer-admin or admin for a company
              if(isCustomerAdmin) {
                dispatch(customerAdministration.fetchCustomers())
              } else {
                dispatch(seatInfo.fetch())
              }
            }).then(onClose)
          } else {
            dispatch(members.create(res)).then(onClose)
          }
        }
      }}
      cancelHandler={onClose}
    >
      <Container>
        <Title>Username</Title>
        <Text value={username} disabled={disabled} required onChange={setUsername} />
      </Container>
      <Container>
        <Title>Initials</Title>
        <Text required disabled={disabled} value={initials} textTransform={"uppercase"} onChange={(value) => setInitials(value)} />
      </Container>
      <Container>
        <Title>Email</Title>
        <Text
          required
          type="email"
          disabled={disabled}
          value={email}
          onValidate={(value) => {
            if (value.includes("..")) {
              return "Email cannot contain '..'"
            }
          }}
          textTransform={"lowercase"}
          onChange={(value) => setEmail(value)}
        />
      </Container>
      <Container>
        <Title>Password</Title>
        <Text type="password" disabled={disabled} required={!member} value={password} onChange={setPassword} />
      </Container>
      <Container>
        <Title>Role</Title>
        <ToggleButtonGroup
          type="radio"
          className="Memberconfig-toggleButtonGroup"
          name="role"
          defaultValue={member ? member.role : "MEMBER"}
          onChange={setRole}
        >
          <ToggleButton 
            disabled={disabled || authState.isMember} 
            value="MEMBER" 
            variant="light" 
            id="memberconfig-ismember_id"
            className="Memberconfig-radioButton"
            >
            Member
          </ToggleButton>
          <ToggleButton
            disabled={disabled || authState.isMember}
            value="PLANNER"
            variant="light"
            id="memberconfig-isplanner_id"
            className="Memberconfig-radioButton"
          >
            Planner
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
      <Container>
        <Title>Show on Plan</Title>
        <ToggleButtonGroup
          name="showOnPlan"
          className="Memberconfig-toggleButtonGroup"
          type="radio"
          defaultValue={showOnPlan}
          onChange={setShowOnPlan}
        >
          <ToggleButton disabled={disabled} value={true} variant="light" id="memberconfig-showonplan_id" className="Memberconfig-radioButton">
            Shown
          </ToggleButton>
          <ToggleButton disabled={disabled} value={false} variant="light" id="memberconfig-hideonplan_id" className="Memberconfig-radioButton">
            Hidden
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
      <Container>
        <Title>Timezone</Title>
        <TimezonePicker required disabled={disabled} value={timezone} onChange={setTimezone} />
      </Container>
      <Container>
        <Title>iCalendar / Outlook Link</Title>
        <Text type="url" disabled={disabled} value={outlookLink} onChange={checkOutlookLink} />
      </Container>
      <div className="MemberConfig-availability">
        <p>Availability</p>
        <label>Work Hours</label>
        <Container>
          <Title>From</Title>
          <DurationInput disabled={disabled} value={startTime} onChange={setStartTime} required isTime />
          <Title>to</Title>
          <DurationInput disabled={disabled} value={endTime} onChange={setEndTime} required isTime />
        </Container>
        <label>Work Week</label>
        <div className="MemberConfig-work-week">
          <Container>
            <ToggleButtonGroup
              variant="light"
              type="checkbox"
              value={workWeek}
              onChange={(e) => {
                setWorkWeek(e)
              }}
            >
              {renderDays(disabled)}
            </ToggleButtonGroup>
          </Container>
        </div>
      </div>
    </ConfigContainer>
  )
}
