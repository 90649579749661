import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction, getRange } from "./common"
import { projectActions } from "./"
import { DateTime } from "luxon"
const initialState = {}

export function reducer(state = initialState, action) {
  let temp = { ...state }
  switch (action.type) {
    case "projects/fetch":
      return createMap(action.res.items)
    case "projects/create":
      action.res.forEach(project => {
        temp = addToMap(temp, project)
      })
      return temp
    case "projects/edit":
      return replaceInMap(state, action.res)
    case "projects/remove":
      // Actions are also removed when this event happens
      return removeFromMap(state, action.res)
    case "projects/delete-unplanned":
      return state
    case "projects/update": {
      // Finds the changed actions and replaces them with the new ones
      const newState = { ...state }
      action.res.forEach((x) => {
        const actionList = state[x.projectId].actions
        const idx = actionList.findIndex((y) => y.id === x.id)
        newState[x.projectId].actions[idx] = x
      })
      return newState
    }
    case "actions/createSingleTask":
      return addToMap(state, action.res.project)
    case "projects/updateMovable":
      // TODO move the derivation from ids to actions to BE if it takes to long
      action.res.forEach((id) => {
        const correctProject = Object.values(temp).filter((proj) => proj.actions.some((e) => e.id === id))[0]
        const actionList = correctProject.actions
        const idx = actionList.findIndex((y) => y.id === id)
        temp[correctProject.id].actions[idx] = { ...actionList[idx], movable: action.mov }
      })
      return temp
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("projects/fetch", (dispatch, pastFuture) => {
  const state = window.store.getState()
  let [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )

  // Fetch enough actions for the project view, and for everything bewtween interval end and today.
  // before and after are extended by 1 month each, which means that we only fetch planned
  // actions again when this interval is left.

  // beforeArgument and afterArgument are the data we are about to have in redux
  let beforeArgument = DateTime.fromISO(before).plus({ months: 1, weeks: 1 }).toUTC().toISO()
  let afterArgument = DateTime.fromISO(after).minus({ months: 1 }).toUTC().toISO()
  const isNotFirstTime = state.waiting.projectFetchAfter

  if (isNotFirstTime) {
    // Function normally. Old Data is in redux already
    before = pastFuture === "past" ? before : DateTime.fromISO(before).plus({ months: 1, weeks: 1 }).toUTC().toISO()
    after = pastFuture === "future" ? after : DateTime.fromISO(after).minus({ months: 1 }).toUTC().toISO()
  } else {
    // We need to make sure that we got data from atleast today plus 1 month and 1 week.
    // If we move three months back and refreshes we won't have that data
    before =
      pastFuture === "past"
        ? before
        : DateTime.max(
            DateTime.fromISO(before).plus({ months: 1, weeks: 1 }),
            DateTime.local().plus({ months: 1, weeks: 1 })
          )
            .toUTC()
            .toISO()
    after =
      pastFuture === "future"
        ? after
        : DateTime.min(DateTime.fromISO(after).minus({ months: 1 }), DateTime.local().minus({ months: 1 }))
            .toUTC()
            .toISO()
  }

  // Set dates such that they match with the data we got
  dispatch({ type: "projects/setFetchDate", after: afterArgument, before: beforeArgument })

  return request("GET", `/projects?after=${after}&before=${before}`).then((res) => {
    // Save data that is outside of the afterArgument and beforeArgument interval. Flush the rest.
    let plannedActions = []
    let projects = []

    // We have moved 1 month back. Keep data between before and beforeargument
    if (pastFuture === "past") {
      const end = DateTime.fromISO(beforeArgument)
      plannedActions = Object.values(state.projectActions.plannedProjectActions).filter((action) => {
        return DateTime.fromISO(action.end) < end && DateTime.fromISO(action.start) > DateTime.fromISO(before)
      })
      projects = Object.values(state.projects).filter((project) => {
        return project.actions.some(
          (action) =>
            action.start &&
            DateTime.fromISO(action.end) < end &&
            DateTime.fromISO(action.start) > DateTime.fromISO(before)
        )
      })
    }
    // We have moved 1 month forward. Keep data between after and after argument
    if (pastFuture === "future") {
      const start = DateTime.fromISO(afterArgument)
      plannedActions = Object.values(state.projectActions.plannedProjectActions).filter((action) => {
        return DateTime.fromISO(action.end) > start && DateTime.fromISO(action.end) < DateTime.fromISO(after)
      })
      projects = Object.values(state.projects).filter((project) => {
        return project.actions.some(
          (action) =>
            action.end && DateTime.fromISO(action.end) > start && DateTime.fromISO(action.end) < DateTime.fromISO(after)
        )
      })
    }
    return { ...res, planned: [...res.planned, ...plannedActions], items: [...res.items, ...projects] }
  })
})

export const create = asyncAction("projects/create", (dispatch, input) => {
  return request("POST", "/projects", { input }).then((res) => {
    return res.projects
  })
})

export const edit = asyncAction("projects/edit", (dispatch, id, patch) => {
  return request("PATCH", `/project/${id}`, { patch }).then((res) => {
    return res.project
  })
})

export const remove = asyncAction("projects/remove", (dispatch, id) => {
  return request("DELETE", `/project/${id}`).then(() => ({ id }))
})

export const removeAllUnplanned = asyncAction("projects/delete-unplanned", (dispatch) => {
  return request("DELETE", `/projects/delete-unplanned`).then((res) => {
    dispatch(fetch())
    dispatch(projectActions.fetchUnplanned())
    return res
  })
})

// Affects projectAction state
export const lockProject = asyncAction("projects/lockProject", (dispatch, id) => {
  return request("POST", `/project/lock-project/${id}`).then((res) => {
    return res
  })
})

// Affects projectAction state
export const unlockProject = asyncAction("projects/unlockProject", (dispatch, id) => {
  return request("POST", `/project/unlock-project/${id}`).then((res) => {
    return res
  })
})

export const unplan = asyncAction("projects/unplan", (dispatch, id) => {
  return request("PATCH", `/project/unplan-project/${id}`).then((res) => {
    return res
  })
})

// Updates project state based on changes in projectActions state
export const updateProject = (newActions) => {
  return { type: "projects/update", res: newActions }
}

export const updateProjectMovable = (ids, mov) => {
  return { type: "projects/updateMovable", res: ids, mov }
}
