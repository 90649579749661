import React, { useMemo } from "react"
import "./index.scss"
import { dynamicSortMultiple } from "utils/scripts/sort"
import { DateTime } from "luxon"
import Table from "react-bootstrap/Table"
import InputGroup from "react-bootstrap/InputGroup"
import { useSelector } from "react-redux"
// import { teamStatus } from "state_management"

export { default as SampleTableForPlannedAnalysis } from "./SampleTableForPlannedAnalysis"
export { default as SampleTableForUnplannedAnalysis } from "./SampleTableForUnplannedAnalysis"

// A table displaying samples.
// planned prop is used to conditional render between samples that are planned vs unplanned
// 'analysisId is only used for planned and plannable tables to find illegal rows
export default function SampleTable({ popupSamples, checkedSamples, setCheckedSamples, analysisId }) {
  // Sorting after three parameters. Depends on 'priorityMode'.
  const priorityMode = useSelector((state) => state.teamStatus.sortingParameter)
  const transformDeadlines = (samples, timezone) => {
    return samples.map(sample => ({
      ...sample,
      transformedDeadline: DateTime.fromISO(sample.deadline).setZone(timezone).toISO()
    }));
  };  
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const sortedPopupSamples = useMemo(() => {
    const transformedSamples = transformDeadlines(popupSamples, memberTimezone);
    return transformedSamples.sort(dynamicSortMultiple(
      priorityMode === "DEADLINE" ? "transformedDeadline" : "priority",
      priorityMode === "DEADLINE" ? "priority" : "transformedDeadline", 
      "sampleName"
    ));
  }, [popupSamples, priorityMode, memberTimezone]);
  const iapFromDate = DateTime.fromISO(useSelector((state) => state.teamStatus.iapFromDate))

  // NOT used for unplanned sample-table. Used to check for illegal samples (yellow rows)
  const tasksForAnalysis = Object.values(useSelector((state) => state.analysisTasks)).filter(task => task.analysisId === analysisId)

  return (
    <>
      <Table bordered hover className="SampleTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Product Name</th>
            <th>Priority</th>
            <th>Arrival</th>
            <th>Deadline</th>
            <th>Observation</th>
            <th>Status</th>
            {setCheckedSamples && <th>Select Samples</th>}
          </tr>
        </thead>
        <tbody>
          {sortedPopupSamples.map((sample) => (
            <tr
              key={sample.id}
              className={
                DateTime.fromISO(sample.deadline).setZone(memberTimezone) < iapFromDate
                  ? "sampleTable-row-overDeadline"
                  : analysisId && tasksForAnalysis.find((task) => DateTime.fromISO(sample.deadline).setZone(memberTimezone) < DateTime.fromISO(task.end).setZone(memberTimezone))
                    ? "sampleTable-row-illegal"
                    : ""
              }
              style={{ fontStyle: sample.status === "FAILED" ? "italic" : "normal", fontWeight: sample.priority === 1 ? "bold" : "normal" }}
            >
              <td>{sample.sampleName}</td>
              <td className="sampletable-productName" title={sample.productName}>{sample.productName ? sample.productName : "None"}</td>
              <td>{sample.priority}</td>
              <td className="sampletable-time-column">{DateTime.fromISO(sample.arrival).setZone(memberTimezone).toFormat("d. MMM HH.mm")}</td>
              <td className="sampletable-time-column">{DateTime.fromISO(sample.deadline).setZone(memberTimezone).toFormat("d. MMM HH.mm")}</td>
              <td>
                {
                  `${setCheckedSamples ? (sample.status === "FAILED" ? "" : sample.observationId) : sample.observationId}${sample.exclusive ? "*" : ""}`
                }
              </td>
              <td>{sample.status}</td>
              {setCheckedSamples && (
                <td className="sampletable-checkbox-column">
                  {sample.status !== "FAILED" && (
                    <InputGroup className="sample-checkBox">
                      <input
                        type="checkbox"
                        onClick={() => {
                          if (!checkedSamples.includes(sample.id)) {
                            setCheckedSamples([...checkedSamples, sample.id])
                          } else {
                            setCheckedSamples(checkedSamples.filter((id) => id !== sample.id))
                          }
                        }}
                      />
                    </InputGroup>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
