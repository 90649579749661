import { request } from "utils/request"
import { analyses, analysisDefinitions, samples } from "."
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "analysisDefinitions/fetch":
      return createMap(action.res)
    case "analysisDraft/create":
    case "analysisDefinitions/create":
      return addToMap(state, action.res)
    case "analysisDraft/edit":
    case "analysisDefinitions/edit":
      return replaceInMap(state, action.res)
    case "analysisDefinitions/remove":
      return removeFromMap(state, action.res)
    case "analysisGroups/remove":
      // When deleting analysis group, remove this group from all existing definitions
      const newState = {...state}
      Object.keys(newState).forEach((key) => {newState[key] = (newState[key].analysisGroupIds.includes(action.res.id) ? {...newState[key], analysisGroupIds: []} : newState[key])})
      return newState
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("analysisDefinitions/fetch", (dispatch) => {
  return request("GET", "/analysis-definitions").then((res) => res.items)
})

export const create = asyncAction("analysisDefinitions/create", (dispatch, info) => {
  return request("POST", "/analysis-definitions", { input: info }).then((res) => {
    dispatch(analyses.fetchUnplanned())
    dispatch(samples.clear())
    dispatch(analysisDefinitions.fetch()) // Required for placing the def/draft alphabetically. It's sorted in the backend
    return res.analysis_definition
  })
})

export const createDraft = asyncAction("analysisDraft/create", (dispatch, info) => {
  return request("POST", "/analysis-draft", { input: info }).then((res) => {
    dispatch(analyses.fetchUnplanned())
    dispatch(samples.clear())
    dispatch(analysisDefinitions.fetch()) 
    return res.analysis_draft
  })
})

export const edit = asyncAction("analysisDefinitions/edit", (dispatch, info, id) => {
  return request("PATCH", `/analysis-definition/${id}`, { patch: info }).then((res) => {
    dispatch(analyses.fetchPlanned())
    //dispatch(analysisTasks.fetch())
    dispatch(analyses.fetchUnplanned())
    dispatch(samples.clear())
    return res.analysis_definition
  })
})

export const editDraft = asyncAction("analysisDraft/edit", (dispatch, info, id) => {
  return request("PATCH", `/analysis-draft/${id}`, { patch: info }).then((res) => {
    return res.analysis_draft
  })
})

export const remove = asyncAction("analysisDefinitions/remove", (dispatch, id) => {
  return request("DELETE", `/analysis-definition/${id}`).then(() => {
    dispatch(analyses.fetchUnplanned())
    dispatch(samples.clear())
    return { id }
  })
})
