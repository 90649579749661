import { projectTemplates } from "state_management"
import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "projectTemplates/fetch":
      return createMap(action.res)
    case "projectTemplates/create":
    case "projectTemplates/createDraft":
      return addToMap(state, action.res)
    case "projectTemplates/edit":
    case "projectTemplates/editDraft":
      return replaceInMap(state, action.res)
    case "projectTemplates/remove":
      return removeFromMap(state, action.res)
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("projectTemplates/fetch", (dispatch) => {
  return request("GET", "/project-templates").then((res) => res.items)
})

export const create = asyncAction("projectTemplates/create", (dispatch, input) => {
  return request("POST", "/project-templates", { input }).then((res) => { 
    dispatch(projectTemplates.fetch())
    return res["project-template"]
  })
})

export const createDraft = asyncAction("projectTemplates/createDraft", (dispatch, input) => {
  return request("POST", "/project-templates-draft", { input }).then((res) => { 
    dispatch(projectTemplates.fetch())
    return res["project-template"]
  })
})

export const edit = asyncAction("projectTemplates/edit", (dispatch, id, patch) => {
  return request("PATCH", `/project-template/${id}`, { patch }).then((res) => res["project-template"])
})

export const editDraft = asyncAction("projectTemplates/editDraft", (dispatch, id, patch) => {
  return request("PATCH", `/project-template-draft/${id}`, { patch }).then((res) => res["project-template"])
})

export const remove = asyncAction("projectTemplates/remove", (dispatch, id) => {
  return request("DELETE", `/project-template/${id}`).then(() => ({ id }))
})
