import React, { useRef, useEffect } from "react"
import { CirclePicker } from "react-color"
import HiddenInput from "./HiddenInput"

// TODO: Explicitly define available colors.
export default function ColorPicker({ required, value, onChange }) {
  const ref = useRef()

  const colorPalette = [
    // New Colors - Pinks to Lavenders
    "#CDABEB", "#C7CAFF", "#F6C2F3", "#F09EA7",
    
    // Reds to Pinks
    "#f44336", "#F03745", "#ED2A54", "#e91e63",
    
    // Pinkish to Purples
    "#CF217D", "#B62496", "#9c27b0", "#8231B4",
    
    // Purples to Blues
    "#673ab7", "#5346B6", "#3f51b5", "#3568CA",
    "#2B7FDE", "#2196f3", "#03a9f4",
    
    // Cyan-Blues to Greens
    "#00A3A1", "#009688", "#26A36C", "#4caf50",
    
    // Light Greens to Yellows
    "#6CB94D", "#8bc34a", "#ACD042", "#cddc39",
    "#DEE13A", "#EEE63A", "#ffeb3b",
    
    // Oranges to Browns
    "#FFD621", "#ffc107", "#FFB305", "#FFA602",
    "#ff9800", "#FF820B", "#FF6D17", "#ff5722",
    "#D2562F", "#A6563B", "#795548",
    
    // Gray to Black
    "#607d8b", "#000001" // This can't be black because of CirclePicker's box-shadow styling
];


  // Disable tabbing through colors
  // TODO: Research whether CirclePicker has a native way of doing this!
  useEffect(() => {
    for (const element of ref.current.getElementsByTagName("div")) {
      element.tabIndex = -1
    }
  })

  return (
    <div className="ColorPicker HiddenInput-container" ref={ref}>
      <CirclePicker
        colors={colorPalette}
        color={{ hex: value ? value.toUpperCase() : null }}
        onChangeComplete={(value) => onChange(value ? String(value.hex).toLowerCase() : null)}
      />
      {required && <HiddenInput message={!value ? "Please select a color" : null} />}
    </div>
  )
}
