import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "react-bootstrap/Spinner"

import "./index.scss"
import { request } from "utils/request"
import { projects, analyses, samples, message, teamStatus, sampleView } from "state_management"

export default function UploadButton({ route, files, setFiles, setResultMessage }) {
  const [isLoading, setIsLoading] = useState(false)
  const isAnalysis = useSelector((state) => state.teamStatus.showAnalyses)
  const isProjects = useSelector((state) => state.teamStatus.showProjects)
  const IAPconfig = useSelector((state) => state.visual.IAPconfig)

  const dispatch = useDispatch()
  return (
    <>
      <Button
        className="submit-button"
        onClick={() => {
          if (isLoading) return
          if (files.length === 0) {
            dispatch(message.warning("Please select at least one file before submitting"))
            return
          }
          const formData = new FormData()

          //Checks whether acces to the file has been denied
          //We need to do it like this because of the async nature of filereader API
          function checkAcces() {
            return new Promise((resolve, reject) => {
              for (let i = 0; i < files.length; i++) {
                files[i]
                  .slice(0, 1)
                  .arrayBuffer()
                  .catch(() => {
                    dispatch(message.error(`${files[i].name} Has changed. Please select the file again`))
                    return 0
                  })
                  .then((res) => {
                    if (!res) {
                      resolve("terminate")
                    } else if (files.length - i === 1) {
                      resolve("success")
                    }
                  })
              }
            })
          }

          function updateFormData() {
            return new Promise((resolve, reject) => {
              files.forEach((file) => {
                formData.append(file.name, file)
              })
              resolve()
            })
          }

          function handleCall() {
            setIsLoading(true)
            request("POST", route, formData, false)
              .then((res) =>
                Promise.all([
                  isAnalysis ? dispatch(analyses.fetchPlanned()) : "",
                  isAnalysis ? dispatch(analyses.fetchUnplanned()) : "",
                  isAnalysis ? dispatch(sampleView.fetch()) : "",
                  isAnalysis ? dispatch(analyses.fetchUnassignedSamples()) : "",
                  isProjects ? dispatch(projects.fetch()) : "",
                  dispatch(samples.clear()),
                ]).then(() => setResultMessage(res))
              )
              .catch((e) => {
                if (e.name === "IAPinUseError") {
                  dispatch(message.warning("Just a moment - the IAP for your team is running"))
                } else {
                  dispatch(message.error("An Error Occurred"))
                }
              })
              .finally(() => setIsLoading(false))
          }

          async function asyncWrapper() {
            const readPermission = await checkAcces()
            if (readPermission === "success") {
              await updateFormData()
              handleCall()
            }
          }
          if (IAPconfig.timePeriod.from !== null && IAPconfig.timePeriod.to !== null){
            dispatch(teamStatus.setIAPconfiguration(IAPconfig.timePeriod.from, IAPconfig.timePeriod.to)).then(() => { asyncWrapper() } )
          }else{
            asyncWrapper()
          }
          setFiles([])
        }}
      >
        {isLoading ? <Spinner animation="grow" size="sm" /> : ""}
        {isLoading ? " Loading..." : "Submit"}
      </Button>
    </>
  )
}
