import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import { FiChevronsRight, FiBarChart2 } from "react-icons/fi"
import { Popup } from "components"
import { ReportConfig } from "components/Config"

import "./index.scss"

import AnalysisTasksMenu from "./AnalysisTasksMenu"
import ProjectTasksMenu from "./ProjectTasksMenu"
import Diagnostics from "./Diagnostics"
import { visual } from "state_management"

// The right menu on the schedule.
// Note: This isn't rendered if neither projects nor analyses are enabled!
export default function RightMenu({ open }) {
  const dispatch = useDispatch()
  const activeRightMenuTab = useSelector((state) => state.visual.activeRightMenuTab)
  const isMember = useSelector((state) => state.auth.isMember)
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const showForecast = useSelector((state) => state.teamStatus.showForecast)

  const IAPconfig = useSelector((state) => state.visual.IAPconfig)

  const [diagnosticsOpen, setDiagnosticsOpen] = useState(false)
  const [showReportConfig, setShowReportConfig] = useState(false)

  const teamId = useSelector((state) => state.auth.teamId)

  const showButtons = showProjects && showAnalyses
  const isProject = !showAnalyses || (activeRightMenuTab === "Project" && showProjects)

  // TODO: Fix weird buttons when zooming in!
  return (
    <div className={"RightMenu" + (open ? " open" : "")}>
      {showForecast && diagnosticsOpen && (
        <Popup onCancel={() => setDiagnosticsOpen(false)} title={`${isProject ? "Project" : "Analysis"} Diagnostics`}>
          <Diagnostics isProject={isProject} />
        </Popup>
      )}
      {!showForecast && showReportConfig && (
        <Popup className="Report-Popup" nonScrollable onCancel={() => setShowReportConfig(null)}>
          <ReportConfig onClose={() => setShowReportConfig(null)} id={teamId}></ReportConfig>
        </Popup>
      )}
      <div className="RightMenuHeader-container">
        <FiChevronsRight
          className="CloseMenu"
          title="Close panel"
          onClick={() => dispatch(visual.setMenuVisibility("rightmenu", false))}
        />
        <div className="RightMenuHeader-nav">
        {showButtons && (
            <Button
              variant={isProject ? "light" : "primary"}
              className="ButtonAnalysis"
              onClick={() => {
                if(activeRightMenuTab === "Project") {
                  dispatch(visual.setActiveRightMenuTab("Analysis"))
                  dispatch(visual.setIAPconfiguration({ ...IAPconfig, IAPmode: "analysis" }))
                }
              }}
            >
              Analysis
            </Button>
          )}
          {showButtons && (
            <Button
              variant={isProject ? "primary" : "light"}
              className="ButtonProject"
              style={{ marginRight: "4rem"}}
              onClick={() => {
                if(activeRightMenuTab === "Analysis") {
                  dispatch(visual.setActiveRightMenuTab("Project"))
                  dispatch(visual.setIAPconfiguration({ ...IAPconfig, IAPmode: "project" }))
                }
              }}
            >
              Project
            </Button>
          )}
          {!showButtons && (isProject ? <span>Projects</span> : <span>Analyses</span>)}
          {!((showAnalyses && showProjects) || isMember) && showForecast && (
            <Button
              className="ButtonRight"
              title="Run diagnostics"
              variant="primary"
              onClick={() => setDiagnosticsOpen(true)}
            >
              <FiBarChart2 color="white" />
            </Button>
          )}
          {!(showProjects || isMember) && !showForecast && (
            <Button className="ButtonRight" title="Reports" variant="primary" onClick={() => setShowReportConfig(true)}>
              <FiBarChart2 color="white" />
            </Button>
          )}
        </div>
      </div>
      <div className="RightMenuBody-container">
        {!isProject && <AnalysisTasksMenu showTitle={showButtons} />}
        {isProject && <ProjectTasksMenu />}
      </div>
    </div>
  )
}
