import React from "react"
import { useNavigate } from "react-router-dom"
import { FiHome, FiChevronLeft, FiChevronRight } from "react-icons/fi"

import Button from "react-bootstrap/Button"
import { UpdateIndicatorMobile } from "components"

// Main buttons on the mobile schedule screen.
export default function ScheduleButtonsMobile({ content, setToday, goBackOneDay, goForwardOneDay }) {
  const navigate = useNavigate()

  return (
    <div className="ScheduleButtons-mobile">
      <div className="date-buttons-mobile">
        <Button title="Next day"  onClick={goBackOneDay}>
          <FiChevronLeft size="1.5rem" borderwidth={2}/>
        </Button>
        <Button title="Previous day" onClick={goForwardOneDay}>
          <FiChevronRight size="1.5rem"/>
        </Button>
      </div>
      <div className="center-buttons">
        <h3 style={{fontSize: "1.5rem"}}>{content}</h3>
      </div>
      <div className="right-side-components">
        <UpdateIndicatorMobile/>
        <div className="member-planningmode-week-buttons-mobile">
          <Button title="Home" onClick={() => navigate("/navigate")}>
            <FiHome size="1.3em" color="white" />
          </Button>
        </div>
      </div>
    </div>
  )
}
