import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DateTime } from "luxon"

import { Popup, ScheduleMemberHours, ScheduleElement, ErrorBoundary } from "components"
import { MemberConfig } from "components/Config"
import ScheduleMemberElement from "./ScheduleMemberElement"
import { waiting } from "state_management"

import { getDates, getTaskInterval } from "utils/scripts/schedule"
import useMemoizeArray from "utils/scripts/useMemoizeArray"
import { message, visual } from "state_management"
import { isTablet, isApple } from "utils/scripts/deviceCheck"
import isOnMobile from "utils/scripts/isOnMobile"

function ScheduleScreenContentMember({ member, dates, extended, setExtended, singleMember, onEdit, view }) {
  const allWhiteTasks = useSelector((state) => state.whiteTasks)
  const allAnalysisTasks = useSelector((state) => state.analysisTasks)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const isCompactView = useSelector((state) => state.visual.teamPlan.showCompact)
  const [isAltPressed, setIsAltPressed] = useState(false);

  // Memoize the items on this member, since the call to `getTaskInterval` is
  // very expensive! With this, we only run `getTaskInterval` when something
  // changes on a single member, and not on all members!
  const itemsOnThisMember = useMemoizeArray(
    useMemo(() => {
      return Object.values(allWhiteTasks)
        .concat(Object.values(allAnalysisTasks))
        .concat(Object.values(allProjectActions))
        .filter((item) => item.memberIds && item.memberIds.indexOf(member.id) !== -1)
        .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0)) // Consistent order, see useMemoizeArray
    }, [allWhiteTasks, allAnalysisTasks, allProjectActions, member.id])
  )
  const [minMemberHeight, maxMemberHeight] = useMemo(
    () => getTaskInterval(itemsOnThisMember, dates, member.workHours, member.timezone, memberTimezone, extended),
    [itemsOnThisMember, dates, member.workHours, member.timezone, extended, memberTimezone]
  )

  // TODO: Remove this calculated height, and let it be automatically inferred from the content
  const daySize = (maxMemberHeight - minMemberHeight) * (extended ? 4 : 1)

  useEffect(() => {
    const handleMouseMove = (event) => {
      setIsAltPressed(event.altKey);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Alt") {
        setIsAltPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Alt") {
        setIsAltPressed(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <>
      <div className="ScheduleMemberAndHours" style={{ height: isCompactView && !extended ? daySize * 0.45 + "em" : daySize * 1.5 + "em" }}>
        {!singleMember && (
          <ScheduleMemberElement member={member} onEdit={onEdit} extended={extended} setExtended={setExtended} />
        )}
        <ScheduleMemberHours
          member={member}
          minMemberHeight={minMemberHeight}
          maxMemberHeight={maxMemberHeight}
          extended={extended}
          isCompactView={isCompactView}
        />
      </div>
      {dates.map((date) => (
        <div className="ScheduleElement" key={date.toISO()}>
          <ErrorBoundary>
            <ScheduleElement
              memberId={member.id}
              minMemberHeight={minMemberHeight}
              maxMemberHeight={maxMemberHeight}
              date={date.toISO()}
              extended={extended}
              view={view}
              isAltPressed={isAltPressed}
            />
          </ErrorBoundary>
        </div>
      ))}
    </>
  )
}

export default function ScheduleScreenContent({ singleMember, members, moveLeft, moveRight, view }) {
  const dispatch = useDispatch()
  const checkZoomFactor = useSelector((state) => state.visual.checkZoomFactor)
  const currentMemberInitials = useSelector((state) => state.members[state.auth.memberId].initials)
  const withWeekend = useSelector((state) => state.visual.scheduleWithWeekend)
  const withWeek = useSelector((state) => state.visual.scheduleWithWeek)
  const scheduleMondayDate = useSelector((state) => state.visual.scheduleMondayDate)
  const activeDate = useSelector((state) => state.visual.activeDate)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const [extendedMember, setExtendedMember] = useState(singleMember ? members[0].id : null)

  // These are used to determine whether an archived member should be shown on plan
  const whiteTasks = useSelector((state) => state.whiteTasks)
  const analysisTasks = useSelector((state) => state.analysisTasks)
  const projectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const thisMonday = DateTime.fromISO(scheduleMondayDate)
  const allItems = Object.values(whiteTasks)
    .concat(Object.values(analysisTasks))
    .concat(Object.values(projectActions))
    .filter((item) => item.end && DateTime.fromISO(item.end) > thisMonday)
  const [editingMember, setEditingMember] = useState(null)
  const isDayView = view === "day-view"
  // Memoized so ScheduleScreenContentMember recieves a stable reference
  const dates = useMemo(
    () =>
      isDayView ? [DateTime.fromISO(activeDate).setZone(memberTimezone).startOf("day")] : getDates(scheduleMondayDate, withWeekend, withWeek, memberTimezone),
    [scheduleMondayDate, withWeekend, withWeek, isDayView, activeDate, memberTimezone]
  )

  useEffect(() => {
    if (checkZoomFactor) {
      // Introduce a delay to allow for DOM rendering and other tasks to complete.
      const timer = setTimeout(() => {
        const element = document.querySelector(".ScheduleScreenContent")
        if (element) {
          const isOverflowingHorizontally = element.scrollWidth > element.clientWidth
          if (isOverflowingHorizontally && !isTablet() && !isOnMobile) {
            dispatch(message.warning(`The content is too wide for the screen. For a better viewing experience, zoom out (${isApple() ? "cmd" : "ctrl"} +/-)`))
          } 
          dispatch(visual.changeCheckZoomFactor())
        }
      }, 1000)

      return () => clearTimeout(timer) // Cleanup the timer when the component is unmounted or if the effect runs again.
    }
  }, [dispatch, checkZoomFactor])
  
  const cornerWidth = "10em"
  const headerItemWidth =isDayView ? "50vw" : "15em";

  useEffect(() => {
    dispatch(waiting.changeScheduleMonday(scheduleMondayDate))
  })

  return (
    <div className={"ScheduleScreenContent" + (moveLeft ? " move-left" : "") + (moveRight ? " move-right" : "")}>
      {editingMember && (
        <Popup nonScrollable onCancel={() => setEditingMember(null)}>
          <MemberConfig member={editingMember} onClose={() => setEditingMember(null)} />
        </Popup>
      )}
      <div className="ScheduleHeader">
        <div className="ScheduleCorner" style={{ width: cornerWidth }}>
          {<h4>{currentMemberInitials}</h4>}
          <p>Week {DateTime.fromISO(scheduleMondayDate).setZone(memberTimezone).weekNumber}</p>
        </div>
        {dates.map((date) => (
          <div className="ScheduleDay" key={date.toISO()} style={{ width: headerItemWidth }}>
            <h4>{date.toFormat("ccc")}</h4>
            <p>{date.toFormat("MMM d")}</p>
          </div>
        ))}
      </div>
      <div
        className="ScheduleScreenGrid"
        style={{
          // Sidebar width + day width
          gridTemplateColumns: `${cornerWidth} repeat(${dates.length}, ${headerItemWidth})`,
          // Member height
          gridTemplateRows: `repeat(${members.length}, auto)`,
        }}
      >
        {members
          .filter((member) => {
            const itemsWithThatMember = allItems.filter((item) => item.memberIds[0] === member.id)
            return !member.archived || itemsWithThatMember.length
          })
          .map((member) => (
            <ScheduleScreenContentMember
              key={member.id}
              member={member}
              dates={dates}
              extended={extendedMember === member.id}
              setExtended={(extended) => setExtendedMember(extended ? member.id : null)}
              singleMember={singleMember}
              onEdit={() => setEditingMember(member)}
              view={view}
            />
          ))}
      </div>
    </div>
  )
}
