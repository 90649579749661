import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction } from "./common"

const initialState = {
  activeAnalysisGroupId: null,
  groups: {}
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "analysisGroups/fetch":
      return {...state, groups: createMap(action.res)}
    case "analysisGroups/create":
      const newState = {...state, activeAnalysisGroupId: action.res.id}
      return  {...newState, groups: addToMap(newState.groups, action.res)}
    case "analysisGroups/edit":
      return {...state, groups: replaceInMap(state.groups, action.res)}
    case "analysisGroups/remove":
      const tmpState = (state.activeAnalysisGroupId === action.res.id ? {...state, activeAnalysisGroupId: null} : state)
      return {...tmpState, groups: removeFromMap(state.groups, action.res)}
    case "analysisGroups/changeActiveAnalysisGroupId":
      return {...state, activeAnalysisGroupId: action.id}
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("analysisGroups/fetch", (dispatch) => {
  return request("GET", "/analysis-groups").then((res) => res.items)
})

export const remove = asyncAction("analysisGroups/remove", (dispatch, id) => {
  return request("DELETE", `/analysis-group/${id}`).then(() => ({ id }))
})

export const create = asyncAction("analysisGroups/create", (dispatch, input) => {
  return request("POST", `/analysis-groups`, { input }).then((res) => res.group)
})

export const edit = asyncAction("analysisGroups/edit", (dispatch, id, patch) => {
  return request("PATCH", `/analysis-group/${id}`, { patch }).then((res) => res.group)
})

export function changeActiveAnalysisGroupId(id) {
  return { type: "analysisGroups/changeActiveAnalysisGroupId", id }
}