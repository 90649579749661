import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { useLocation } from "react-router-dom"
import "./index.scss"
import { ConfirmDialog } from "components"
import { Container, Text, Form } from "components/Form"
import { FiInfo } from "react-icons/fi"

// The main configuration container.
// Used to add a title and buttons at the bottom of a configuration.
// A lot of props can be passed for different scenarios. They are
// pretty self explainatory
export default function ConfigContainer({
  title,
  titleInfoText,
  disabled,
  cancelHandler,
  canCancelWhenDisabled,
  submitHandler,
  deleteIsImportant,
  keyWordForAllowedDeletion,
  deleteHandler,
  shiftDeleteHandler,
  deleteTitle,
  confirmDeletionText,
  canDeleteWhenDisabled,
  saveTemplateHandler,
  saveAsHandler,
  saveDraftHandler,
  saveAsName,
  canSaveWhenDisabled,
  closeInsteadOfCancel,
  children,
  isDraft,
  isTemplate,
  className,
  editProjectHandler,
}) {
  const [activeConfirm, setActiveConfirm] = useState(false)
  const [allowDeletionKeyWord, setAllowDeletionKeyWord] = useState("")
  const [isWorking, setIsWorking] = useState(false)

  const shiftClickDeletionTexts = ["Are you sure you want to unplan all future actions in this project?"]
  const location = useLocation()
  const isAnalysisView = location.pathname.includes("/analyses/")
  return (
    <Form
      className="ConfigContainer"
      onSubmit={() => {
        const returnValue = submitHandler()
        if (returnValue && title === "Analysis Definition") {
          setIsWorking(true)
          Promise.resolve(returnValue).finally(() => setIsWorking(false))
        }
      }}
    >
      {title && (
        <div className={titleInfoText ? "setupHeading ConfigContainer-Title" : "setupHeading"}>
          {title}
          {titleInfoText && (
            <div className="connection-info">
              <FiInfo title={titleInfoText} />
            </div>
          )}
        </div>
      )}
      <div className={`Config-main-container HiddenInput-container ${className}`}>
        {children}
        {deleteHandler && keyWordForAllowedDeletion && activeConfirm && (
          <ConfirmDialog
            onCancel={() => setActiveConfirm(false)}
            onConfirm={() => {
              deleteHandler(allowDeletionKeyWord)
              setActiveConfirm(false)
            }}
          >
            <h4>To confirm deletion type {keyWordForAllowedDeletion}</h4>
            <Container>
              <Text value={allowDeletionKeyWord} onChange={(e) => setAllowDeletionKeyWord(e)} />
            </Container>
          </ConfirmDialog>
        )}
        
        {deleteHandler && activeConfirm && !keyWordForAllowedDeletion && (
          <ConfirmDialog
            onCancel={() => setActiveConfirm(false)}
            onConfirm={() => {
              if (shiftDeleteHandler) {
                shiftDeleteHandler()
              } else {
                deleteHandler()
              }
              setActiveConfirm(false)
            }}
          >
            <h4>{confirmDeletionText || "Confirm deletion"}</h4>
          </ConfirmDialog>
          )}
      </div>
      <div className="ConfigContainer-buttons">
        {(canDeleteWhenDisabled || !disabled) && deleteHandler && (
          <Button
            variant="danger"
            onClick={(e) => {
              const shiftKeyEffect = e.shiftKey && shiftClickDeletionTexts.includes(confirmDeletionText)
              if (deleteIsImportant || keyWordForAllowedDeletion || shiftKeyEffect) {
                setActiveConfirm(true)
              } else {
                deleteHandler()
              }
            }}
          >
            {deleteTitle || "Delete"}
          </Button>
        )}
        {(canCancelWhenDisabled || !disabled) && (
          <Button
            variant="light"
            onClick={() => {
              cancelHandler()
            }}
          >
            {closeInsteadOfCancel ? "Close" : "Cancel"}
          </Button>
        )}
        {editProjectHandler && (
          <Button variant="info" style={{color: "white"}} onClick={editProjectHandler}>
            Edit Project
          </Button>
        )}
        {!disabled && saveTemplateHandler && (
          <Button variant="warning" onClick={() => saveTemplateHandler()}>
            Save as new template
          </Button>
        )}
        {!disabled && saveAsHandler && !isDraft &&  (
          <Button variant="warning" onClick={() => saveAsHandler()}>
            {saveAsName || "Save as new"}
          </Button>
        )}
        {!disabled && isDraft && saveDraftHandler && (
          <Button variant="warning" onClick={() => saveDraftHandler()}>
            {`Save as ${isAnalysisView ? "" : "project template"} DRAFT`}
          </Button>
        )}
        {(canSaveWhenDisabled || !disabled) && (
          <Button variant="primary" type="submit" disabled={isWorking}>
            {isDraft ? "Save as " + (isTemplate ? "project template" : "analysis definition") : "Save"}
          </Button>
        )}
      </div>
    </Form>
  )
}
