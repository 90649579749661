import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom"

import "./index.scss"

import AnalysisTab from "./AnalysisTab"
import ProjectTemplateTab from "./ProjectTemplateTab"
import TeamTab from "./TeamTab"
import AssetsTab from "./AssetsTab"
import AdminHeader from "./AdminHeader"
import { AnalysisConfigurationPage, ProjectConfigurationPage } from "components/Config"
import IntegrationTab from "./IntegrationTab"
import CompetenceGroupTab from "./CompetenceGroupTab"
import { SimulationLogoutConfirm } from "components"
import { analysisGroups } from "state_management"
import DataGridLayout from "./IntegrationTab/DataGridLayout"
import AutomaticIntegrationTab from "./IntegrationTab/AutomaticIntegrationTab"
import SimulationTab from "./SimulationTab"


function AnalysisDefintionConfig() {
  const dispatch = useDispatch()

  let { analysisDefinitionId } = useParams()
  // initialize the active analysis group
  const analysisDefinition = useSelector((state) => state.analysisDefinitions[analysisDefinitionId])
  dispatch(analysisGroups.changeActiveAnalysisGroupId(analysisDefinition && analysisDefinition.analysisGroupIds ? analysisDefinition.analysisGroupIds[0] : null))
  return (
    <div className="SetupScreen-item-config">
      <AnalysisConfigurationPage id={analysisDefinitionId} />
    </div>
  )
}

function ProjectTab() {
  let { projectId } = useParams()
  return (
    <div className="SetupScreen-item-config">
      <ProjectConfigurationPage id={projectId} />
    </div>
  )
}

function ProjectTemplateConfig() {
  let { templateId } = useParams()
  return (
    <div className="SetupScreen-item-config">
      <ProjectConfigurationPage id={templateId} isTemplate />
    </div>
  )
}

export default function SetupScreen() {
  const isMember = useSelector((state) => state.auth.isMember)
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const location = useLocation()
  const ref = useRef()

  useEffect(() => {
    ref.current.scrollTo(0, 0)
  }, [location])

  return (
    <div className="SetupScreen" ref={ref}>
      <AdminHeader />
      <SimulationLogoutConfirm />
      <Routes>
        {isMember && showAnalyses && !showProjects && <Route path="*" element={<Navigate to="/" />} />}
        {showAnalyses && !isMember && (
          <Route path="analyses/"
            element={<AnalysisTab />}
          />
        )}
        {showAnalyses && !isMember && (
          <Route path="analyses/create"
            element={<AnalysisDefintionConfig />}
          />
        )}
        {showAnalyses && !isMember && (
          <Route
            path="/analyses/:analysisDefinitionId"

            element={<AnalysisDefintionConfig />}
          />
        )}
        {showProjects && (
          <Route path="projects/create"
            element={<ProjectTab />}
          />
        )}
        {/* Route is kept even when projects are disabled, since there might be
            existing projects on the plan (and those can be edited in here) */}
        <Route path="projects/:projectId"
          element={<ProjectTab />}
        />
        {showProjects && (
          <Route path="project-templates"
            element={<ProjectTemplateTab />}
          />
        )}
        {showProjects && (
          <Route path="project-templates/create"
            element={<ProjectTemplateConfig />}
          />
        )}
        {showProjects && (
          <Route path="project-templates/:templateId"
            element={<ProjectTemplateConfig />}
          />
        )}
        {!isMember && (
          <Route path="members"
            element={<TeamTab />}
          />
        )}
        {(showProjects || showAnalyses) && !isMember && (
          <Route path="competence-groups"
            element={<CompetenceGroupTab />}
          />
        )}
        {!isMember && (
          <Route path="assets"
            element={<AssetsTab />}
          />
        )}
        {!isMember && (
          <Route path="simulation"
            element={<SimulationTab />}
          />
        )}
        {!isMember && (
          <Route path="integration"
            element={<IntegrationTab />}
          />
        )}
        {!isMember && (
          <Route path={`integration/conversion-sheet/:sheetId`}
            element={<DataGridLayout />}
          />
        )}
        {!isMember && (
          <Route path={`integration/automatic`}
            element={<AutomaticIntegrationTab />}
          />
        )}
        {/*        {!isMember && <Route path="*" element={<Navigate to="/setup/members" />}/> } */}
      </Routes>
    </div>
  )
}
