import React from "react";
import { useSelector } from "react-redux";
import { Duration } from "luxon";
import { buildHours } from "utils/scripts/schedule";
import "./index.scss";

export default function ScheduleMemberHours({ member, minMemberHeight, maxMemberHeight, extended, isCompactView }) {
  const hoveredHour = useSelector((state) => state.scheduleHover.hour);
  const isHoveredMember = useSelector((state) => state.scheduleHover.memberId === member.id);

  let hours = buildHours(extended, minMemberHeight, maxMemberHeight);

  if (isCompactView) {
    const compactStepSize = extended ? 1 : 2 // Goes back to normal extended view
    let filteredHours = [];

    for (let i = 0; i < hours.length; i += compactStepSize) {
      filteredHours.push(hours[i]);
    }

    // Ensure the last hour is included, checking for unique last hour
    const lastHour = hours[hours.length - 1];
    if (!filteredHours.includes(lastHour)) {
      filteredHours.push(lastHour);
    }

    hours = filteredHours;
  }

  const formattedHours = hours.map((hour) =>
    Duration.fromObject({ hours: hour }).toFormat("hh:mm")
  );

  return (
    <div className="ScheduleMemberHours">
      {formattedHours.map((hour) => (
        <span key={hour} className={isHoveredMember && hour === hoveredHour ? "active" : undefined}>
          {hour}
        </span>
      ))}
    </div>
  );
}
