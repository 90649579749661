import React from "react"

import "./index.scss"
import { Title, Container, Text } from "components/Form"
import { convertTokenDisplay } from "utils/scripts/adminHelpers"

export default function UsageConfig({ item }) {
  const tokenCounts = item.token_counts
  const hasAssets = "assetCount" in item && typeof item.assetCount === "number"
  const hasTokens = tokenCounts?.current_month && tokenCounts?.previous_month
  const hasSeats =
    ("seatsUsed" in item && typeof item.seatsUsed === "number") ||
    ("seatsTotal" in item && typeof item.seatsTotal === "number")

  return (
    <div>
      {hasAssets && (
        <div className="container-compartment">
          <p>Assets</p>
          <Container>
            <Title>Used</Title>
            <Text disabled value={String(item.assetCount)} />
          </Container>
        </div>
      )}
      {hasSeats && (
        <div className="container-compartment">
          <p>Seats</p>
          <Container>
            <Title>Total</Title>
            <Text disabled value={String(item.seatsTotal)} />
          </Container>
        </div>
      )}
      {hasTokens && (
        <div className="container-compartment">
          <p>Tokens</p>
          <Container>
            <Title width="160px">Input This Month</Title>
            <Text disabled value={convertTokenDisplay(tokenCounts.current_month.input)} />
          </Container>
          <Container>
            <Title width="160px">Output This Month</Title>
            <Text disabled value={convertTokenDisplay(tokenCounts.current_month.output)} />
          </Container>
          <Container>
            <Title width="160px">Input Last Month</Title>
            <Text disabled value={convertTokenDisplay(tokenCounts.previous_month.input)} />
          </Container>
          <Container>
            <Title width="160px">Output Last Month</Title>
            <Text disabled value={convertTokenDisplay(tokenCounts.previous_month.output)} />
          </Container>
        </div>
      )}
    </div>
  )
}
