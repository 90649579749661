let ROOT
switch (process.env.REACT_APP_ENV) {
  case "production":
    ROOT = "https://lb-new.pt.arnvind.com"
    break
  case "test":
    ROOT = "https://lb.testpt.arnvind.com"
    break
  default:
    ROOT = "http://localhost:5000"
    break
}

export default ROOT
