import { useRef, useEffect } from "react"

// Hook for closing the Popup component
// Listens for 'Esc' keydown event and when user presses outside of the component
export const useClosePopup = (handler) => {
  let domNode = useRef()

  useEffect(() => {
    let maybeHandler = (event) => {
      // Checks if the last mouseclick 'domNode' (when DomNode is active) was inside the 'div' or outside 
      if(domNode.current && !domNode.current.contains(event.target)) {
        handler()
      }
    }

    // Same as for 'maybeHandler' but checks if the user pressed the 'Esc' key
    let handleKeyPress = (event) => {
      if(event.key === "Escape") {
        handler()
      }
    }
  
    document.addEventListener("mousedown", maybeHandler)
    document.addEventListener("keydown", handleKeyPress)

    return () => {
      document.removeEventListener("mousedown", maybeHandler)
      document.removeEventListener("keydown", handleKeyPress)
    }
  },[handler])

  return domNode
}