import React from "react"
import { Popup } from "components"
import Button from "react-bootstrap/Button"
import "./index.scss"

// A component that asks the user for confirmation.
export default function ConfirmDialog({ children, onCancel, onConfirm, submitName="Confirm" }) {
  return (
    <Popup onCancel={() => onCancel()} className="ConfirmDialog">
      {children}
      <div className="ConfirmDialog-buttons">
        <Button variant="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onConfirm()}>
          {submitName}
        </Button>
      </div>
    </Popup>
  )
}
