import { request } from "utils/request"
import { addToList, replaceInList, removeFromList, asyncAction } from "./common"

const initialState = {
  isLoadingActionSheets: false,
  isLoadingProjectTemplateSheets: false,
  isLoadingSampleSheets: false,
  isLoadingLimsSheets: false,
  isLoadingSapSheets: false,
  isLoadingConversionSheet: false,
  actionSheets: null,
  projectTemplateSheets: null,
  sampleSheets: null,
  limsSheets: null,
  sapSheets: null,
  conversionSheets: {},
  editedConversionSheet: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "integrationSheets/fetchActionSheets/BEGIN":
      return { ...state, isLoadingActionSheets: true }
    case "integrationSheets/fetchActionSheets":
      return { ...state, isLoadingActionSheets: false, actionSheets: action.res }
    case "integrationSheets/fetchProjectTemplateSheets/BEGIN":
      return { ...state, isLoadingProjectTemplateSheets: true }
    case "integrationSheets/fetchProjectTemplateSheets":
      return { ...state, isLoadingProjectTemplateSheets: false, projectTemplateSheets: action.res }
    case "integrationSheets/fetchSampleSheets/BEGIN":
      return { ...state, isLoadingSampleSheets: true }
    case "integrationSheets/fetchSampleSheets":
      return { ...state, isLoadingSampleSheets: false, sampleSheets: action.res }
    case "integrationSheets/fetchLimsSheets/BEGIN":
      return { ...state, isLoadingLimsSheets: true }
    case "integrationSheets/fetchLimsSheets":
      return { ...state, isLoadingLimsSheets: false, limsSheets: action.res }
    case "integrationSheets/fetchSapSheets/BEGIN":
      return { ...state, isLoadingSapSheets: true }
    case "integrationSheets/fetchSapSheets":
      return { ...state, isLoadingSapSheets: false, sapSheets: action.res }
    case "integrationSheets/fetchLimsConversionSheet/BEGIN":
      return { ...state, isLoadingConversionSheet: true }
    case "integrationSheets/fetchLimsConversionSheet":
      return { ...state, isLoadingConversionSheet: false, conversionSheets: {...state.conversionSheets, [action.args[0]]: action.res} }
    case "integrationSheets/fetchSapConversionSheet/BEGIN":
      return { ...state, isLoadingConversionSheet: true }
    case "integrationSheets/fetchSapConversionSheet":
      return { ...state, isLoadingConversionSheet: false, conversionSheets: {...state.conversionSheets, [action.args[0]]: action.res} }
    case "integrationSheets/createActionSheet":
      return { ...state, actionSheets: addToList(state.actionSheets, action.res) }
    case "integrationSheets/createProjectTemplateSheets":
      return { ...state, projectTemplateSheets: addToList(state.projectTemplateSheets, action.res) }
    case "integrationSheets/createSampleSheet":
      return { ...state, sampleSheets: addToList(state.sampleSheets, action.res) }
    case "integrationSheets/createLimsAnalysisSheet":
      return { ...state, limsSheets: addToList(state.limsSheets, action.res) }
    case "integrationSheets/createSapAnalysisSheet":
      return { ...state, sapSheets: addToList(state.sapSheets, action.res) }
    case "integrationSheets/saveLimsConversionSheet":
    case "integrationSheets/saveSapConversionSheet":
      return {
        ...state,
        conversionSheets: {
          ...state.conversionSheets,
          [action.args[0]]: {
            ...state.conversionSheets[action.args[0]],
            rows: action.res,
          }
        },
      }
    case "integrationSheets/editConversionSheet":
      return {
        ...state,
        editedConversionSheet: action.editedConversionSheet,
      }
    case "integrationSheets/editActionSheet":
      return { ...state, actionSheets: replaceInList(state.actionSheets, action.res) }
    case "integrationSheets/editProjectTemplateSheet":
      return { ...state, projectTemplateSheets: replaceInList(state.projectTemplateSheets, action.res) }
    case "integrationSheets/editSampleSheet":
      return { ...state, sampleSheets: replaceInList(state.sampleSheets, action.res) }
    case "integrationSheets/editLimsAnalysisSheet":
      return { ...state, limsSheets: replaceInList(state.limsSheets, action.res) }
    case "integrationSheets/editSapAnalysisSheet":
      return { ...state, sapSheets: replaceInList(state.sapSheets, action.res) }
    case "integrationSheets/removeActionSheet":
      return { ...state, actionSheets: removeFromList(state.actionSheets, action.res) }
    case "integrationSheets/removeProjectTemplateSheet":
      return { ...state, projectTemplateSheets: removeFromList(state.projectTemplateSheets, action.res) }
    case "integrationSheets/removeSampleSheet":
      return { ...state, sampleSheets: removeFromList(state.sampleSheets, action.res) }
    case "integrationSheets/removeLimsAnalysisSheet":
      return { ...state, limsSheets: removeFromList(state.limsSheets, action.res) }
    case "integrationSheets/removeSapSheet":
      return { ...state, sapSheets: removeFromList(state.sapSheets, action.res) }
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchActionSheets = asyncAction("integrationSheets/fetchActionSheets", (dispatch) => {
  return request("GET", "/upload/config/actions").then((res) => res.items)
})

export const fetchProjectTemplateSheets = asyncAction("integrationSheets/fetchProjectTemplateSheets", (dispatch) => {
  return request("GET", "/upload/config/project-templates").then((res) => res.items)
})

export const fetchSampleSheets = asyncAction("integrationSheets/fetchSampleSheets", (dispatch) => {
  return request("GET", "/upload/config/samples").then((res) => res.items)
})

export const fetchLimsSheets = asyncAction("integrationSheets/fetchLimsSheets", (dispatch) => {
  return request("GET", "/upload/config/lims").then((res) => res.items)
})

export const fetchSapSheets = asyncAction("integrationSheets/fetchSapSheets", (dispatch) => {
  return request("GET", "/upload/config/sap").then((res) => res.items)
})

export const fetchLimsConversionSheet = asyncAction("integrationSheets/fetchLimsConversionSheet", (dispatch, sheetId) => {
  return request("GET", `/lims-integration/conversion-sheet/${sheetId}`).then((res) => res.items)
})

export const fetchSapConversionSheet = asyncAction("integrationSheets/fetchSapConversionSheet", (dispatch, sheetId) => {
  return request("GET", `/sap-integration/conversion-sheet/${sheetId}`).then((res) => res.items)
});

export const createActionSheet = asyncAction("integrationSheets/createActionSheet", (dispatch, input) => {
  return request("POST", "/upload/config/actions", { input }).then((res) => res.config)
})

export const createLimsAnalysisSheet = asyncAction("integrationSheets/createLimsAnalysisSheet", (dispatch, input) => {
  return request("POST", "/upload/config/lims", { input }).then((res) => res.config)
})

export const createSapAnalysisSheet = asyncAction("integrationSheets/createSapAnalysisSheet", (dispatch, input) => {
  return request("POST", "/upload/config/sap", { input }).then((res) => res.config)
})

export const createProjectTemplateSheet = asyncAction(
  "integrationSheets/createProjectTemplateSheets",
  (dispatch, input) => {
    return request("POST", "/upload/config/project-templates", { input }).then((res) => res.config)
  }
)

export const createSampleSheet = asyncAction("integrationSheets/createSampleSheet", (dispatch, input) => {
  return request("POST", "/upload/config/samples", { input }).then((res) => res.config)
})

export const editActionSheet = asyncAction("integrationSheets/editActionSheet", (dispatch, id, patch) => {
  return request("PATCH", `/upload/config/actions/${id}`, { patch }).then((res) => res.config)
})
export const editProjectTemplateSheet = asyncAction(
  "integrationSheets/editProjectTemplateSheet",
  (dispatch, id, patch) => {
    return request("PATCH", `/upload/config/project-template/${id}`, { patch }).then((res) => res.config)
  }
)

export const editSampleSheet = asyncAction("integrationSheets/editSampleSheet", (dispatch, id, patch) => {
  return request("PATCH", `/upload/config/samples/${id}`, { patch }).then((res) => res.config)
})

export const editLimsAnalysisSheet = asyncAction("integrationSheets/editLimsAnalysisSheet", (dispatch, id, patch) => {
  return request("PATCH", `/upload/config/lims/${id}`, { patch }).then(
    (res) => res.config
  )
})

export const editSapAnalysisSheet = asyncAction("integrationSheets/editSapAnalysisSheet", (dispatch, sheetId, patch) => {
  return request("PATCH", `/upload/config/sap/${sheetId}`, { patch }).then(
    (res) => res.config
  )
})

export const removeActionSheet = asyncAction("integrationSheets/removeActionSheet", (dispatch, id) => {
  return request("DELETE", `/upload/config/actions/${id}`).then(() => ({ id }))
})
export const removeProjectTemplateSheet = asyncAction(
  "integrationSheets/removeProjectTemplateSheet",
  (dispatch, id) => {
    return request("DELETE", `/upload/config/project-template/${id}`).then(() => ({ id }))
  }
)

export const removeSampleSheet = asyncAction("integrationSheets/removeSampleSheet", (dispatch, id) => {
  return request("DELETE", `/upload/config/samples/${id}`).then(() => ({ id }))
})

export const removeLimsAnalysisSheet = asyncAction("integrationSheets/removeLimsAnalysisSheet", (dispatch, id) => {
  return request("DELETE", `/upload/config/lims/${id}`).then(() => ({ id }))
})

export const removeSapSheet = asyncAction("integrationSheets/removeSapSheet", (dispatch, id) => {
  return request("DELETE", `/upload/config/sap/${id}`).then(() => ({ id }))
})

export const saveLimsConversionSheet = asyncAction("integrationSheets/saveLimsConversionSheet", (dispatch, sheetId, input) => {
  return request("POST", `/lims-integration/conversion-sheet/${sheetId}`, { input }).then((res) => res.items)
})

export const saveSapConversionSheet = asyncAction("integrationSheets/saveSapConversionSheet", (dispatch, sheetId, input) => {
  return request("POST", `/sap-integration/conversion-sheet/${sheetId}`, { input }).then((res) => res.items)
})

export function setConversionSheetEdited(editedConversionSheet) {
  return { type: "integrationSheets/editConversionSheet", editedConversionSheet }
}
