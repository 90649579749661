import { Widget, isWidgetOpened, addResponseMessage, toggleMsgLoader, toggleInputDisabled, renderCustomComponent, deleteMessages, markAllAsRead } from 'react-chat-widget'
import { useEffect, useRef, useState } from 'react'
import 'react-chat-widget/lib/styles.css'
import "./index.scss"
import logo from "utils/stylesheets/logo/logo.png"
import { FaMicrophone } from "react-icons/fa"
import { FaStop } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux"
import { chatBot, librarian, message } from 'state_management'
import { analyzeAudioBlob } from 'utils/scripts/analyseAudio'

export default function ChatBot() {
  const dispatch = useDispatch()
  const widgetContainerRef = useRef()
  const inputRef = useRef(null);

  const isCustomerAdmin = useSelector((state) => state.auth.role) === "customer_admin"
  const isAdmin = useSelector((state) => state.auth.role) === "admin"
  const userName = useSelector((state) => state.auth.memberId)
  const conversation = useSelector((state) => state.chatBot)

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [insertionLength, setInsertionLength] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [textInserted, setTextInserted] = useState(false);
  const [isWidgetVisible, setisWidgetVisible] = useState(isWidgetOpened());

  const repositoryId = process.env.REACT_APP_ENV === "production" ? '9d1cbd97-630a-4e39-b3cd-6473f900dd90' : '7be8bb46-2627-4197-9d5f-aeb4de6bf236'

  useEffect(() => {
    markAllAsRead()
    deleteMessages()
    addResponseMessage(`Hi, I'm your AI CHATBOT, specialized in our Plannertech & Librarian help documentation.
    You can find the full help documentation in the top menu button marked "?".
    You can also always reach out to our Human Support on pt@arnvind.com.
    Feel free to ask me questions! :-)`)
  }, [userName]);

  const handleRefresh = () => {
    dispatch(chatBot.refreshChat())
    deleteMessages()
  }

  const RefreshButton = ({ onRefresh }) => {
    return <button className="rcw-refresh-button" title="Clear the conversation" onClick={onRefresh}>Clear</button>
  };

  const SpeechToTextButton = ({ onClick }) => {
    return <button className="rcw-stt-button" onClick={onClick}>
        {isRecording ? <FaStop /> : <FaMicrophone />}
      </button>
  };

  useEffect(() => {
    if (conversation.status === 'finished') {
      const reply = conversation.latestAnswer
      addResponseMessage(reply)

      toggleMsgLoader(false)
      toggleInputDisabled(false)
    }
  }, [conversation]);

  // Updates the cursor position when the user clicks or types in the input field
  useEffect(() => {
    const updateCursorPosition = (e) => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const position = range.startOffset;
        setCursorPosition(position);
      }
    };

    if (isWidgetVisible) {
      // Delay the execution to wait for the DOM to update
      const timeoutId = setTimeout(() => {
        const inputDiv = document.querySelector('.rcw-input');
        if (inputDiv) {
          inputDiv.addEventListener('input', updateCursorPosition);
          inputDiv.addEventListener('click', updateCursorPosition);
        }
      }, 100);

      // Return a cleanup function
      return () => {
        clearTimeout(timeoutId);
        const inputDiv = document.querySelector('.rcw-input');
        if (inputDiv) {
          inputDiv.removeEventListener('input', updateCursorPosition);
          inputDiv.removeEventListener('click', updateCursorPosition);
        }
      };
    }
  }, [isWidgetVisible]);

  useEffect(() => {
    const launcher = document.querySelector('.rcw-launcher');
    if (launcher) {
      launcher.addEventListener('click', toggleWidgetVisibility);
    }

    return () => {
      if (launcher) {
        launcher.removeEventListener('click', toggleWidgetVisibility);
      }
    }
  }, [])


  // Highlights the inserted text from speech-to-text
  useEffect(() => {
    if (insertionLength > 0 && textInserted) {
      const inputDiv = document.querySelector('.rcw-input');
      if (inputDiv) {
        inputRef.current = inputDiv;
        inputDiv.focus();

        const range = document.createRange();
        const sel = window.getSelection();

        const textNode = inputDiv.firstChild;
        const startPos = cursorPosition - insertionLength;
        const endPos = cursorPosition;

        range.setStart(textNode, startPos);
        range.setEnd(textNode, endPos);
        sel.removeAllRanges();
        sel.addRange(range);

        setTextInserted(false);
      }
    }
  }, [insertionLength, cursorPosition, textInserted]);

  const toggleWidgetVisibility = () => {
    setisWidgetVisible(prevState => !prevState);
  };


  const setInput = (value) => {
    if (widgetContainerRef.current) {
      const inputDiv = widgetContainerRef.current.querySelector('.rcw-input');
      if (inputDiv) {
        inputDiv.innerText = value;
      }
    }
  };


  const handleNewUserMessage = (message) => {
    toggleMsgLoader(true)
    toggleInputDisabled(true)

    // Handle API call to BE that replies with another message
    dispatch(chatBot.getAnswer([...conversation.conversationList, { isBot:false, message: message }], repositoryId, isCustomerAdmin || isAdmin))
      .catch((err) => {
        addResponseMessage(err.message)
        toggleMsgLoader(false)
        toggleInputDisabled(false)
      }).finally(() => {
        if (conversation.conversationList.length === 0) {
          // Add refresh button to chat when the component mounts
          renderCustomComponent(RefreshButton, { onRefresh: handleRefresh });
        }
      })
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let audioChunks = [];

      recorder.addEventListener('dataavailable', event => {
        audioChunks.push(event.data);
      });

      recorder.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        // Send audioBlob to backend here
        analyzeAudioBlob(audioBlob).then(isSpeechDetected => {
          if (isSpeechDetected) {
            dispatch(librarian.getSpeechToTextQuestion(audioBlob)).then((res) => {
              const newText = res.text.trim();

              if (widgetContainerRef.current) {
                const inputDiv = widgetContainerRef.current.querySelector('.rcw-input');
                if (inputDiv) {
                  const currentText = inputDiv.innerText;
                  const beforeCursor = currentText.substring(0, cursorPosition);
                  const afterCursor = currentText.substring(cursorPosition);

                  const updatedInput = `${beforeCursor}${newText}${afterCursor}`;
                  setInput(updatedInput);

                  const newCursorPosition = beforeCursor.length + newText.length;
                  setCursorPosition(newCursorPosition);
                  setInsertionLength(newText.length);
                  setTextInserted(true);
                }
              }
            });
          } else {
            return dispatch(message.warning(`No sound detected. Please try again.`))
          }
        });
        audioChunks = [];
      });

      recorder.start(500);
      setIsRecording(true);
    } catch (error) {
      return dispatch(message.warning(`No microphone detected. Please try again.`))
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className="chat-widget-container">
      <div ref={widgetContainerRef}>
        <Widget
          title="ArnvindGPT Chatbot"
          subtitle="Version 2.0"
          handleNewUserMessage={handleNewUserMessage}
          launcherOpenImg={logo}
        />
      </div>
      {isWidgetVisible && (<SpeechToTextButton onClick={toggleRecording} />)}
    </div>
  );
}
