import { request } from "utils/request"
import { asyncAction } from "./common"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "plannableSamples/fetch/BEGIN":
      return { ...state, [action.args[0]]: { isLoading: true } }
    case "plannableSamples/fetch":
      return {
        ...state,
        [action.res.analysisId]: {
          isLoading: false,
          samples: {
            plannedSamples: action.res.items.plannedSamples,
            unplannedSamples: action.res.items.unplannedSamples,
          },
        },
      };
    case "plannableSamples/needsRefresh":
      return { ...state, [action.analysisId]: { ...state[action.analysisId], needsRefresh: true, isLoading: false}}
    case "samples/assign-samples":
      // Need this check in case the user unplans a sample without entering the 'assign sample' popup
      if (!state[action.args[0]]) {
        return state;
      }
    
      const analysisId = action.args[0];
      const idsToRemove = action.args[1];
    
      let currentPlannedSamples = [...state[analysisId].samples.plannedSamples];
      let currentUnplannedSamples = [...state[analysisId].samples.unplannedSamples];
    
      const updatedPlannedSamples = currentPlannedSamples.filter(sample => !idsToRemove.includes(sample.id));
      const updatedUnplannedSamples = currentUnplannedSamples.filter(sample => !idsToRemove.includes(sample.id));
    
      return {
        ...state,
        [analysisId]: {
          ...state[analysisId],
          samples: {
            plannedSamples: updatedPlannedSamples,
            unplannedSamples: updatedUnplannedSamples,
          },
        },
      };
    case "samples/unplan-samples":
    case "samples/fail-samples":
      // return { ...state, [action.args[0]]: null }
      return initialState
    
    // Set to initial state to force redux to fetch latest update of samples when pressing 'add sample' button
    // Some scenarios like 'runFillInSamples' and moving the tasks on the plan will affect plannable samples
    case "iap/runFillInSamples":
    case "analyses/moveIntoPlan":
    case "analysisTasks/move":
    case "samples/clear":
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("plannableSamples/fetch", (dispatch, analysisId) => {
  return request("GET", `/samples/get-plannable-samples/${analysisId}`).then((res) => ({
    analysisId,
    items: res,
  }))
})

export function needsRefresh(analysisId) {
  return { type: "plannableSamples/needsRefresh", analysisId }
}
