import { request } from "utils/request"
import { addToList, replaceInList, removeFromList, asyncAction } from "./common"

// Admin-only state!
const initialState = null

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "locations/fetch":
      return action.res
    case "locations/create":
      return addToList(state, action.res)
    case "locations/edit":
      return replaceInList(state, action.res)
    case "locations/remove":
      return removeFromList(state, action.res)
    // Super hacky find a better way to do this!
    case "auth/accessCompany":
      return initialState
    case "auth/exitCompany":
      return initialState
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("locations/fetch", (dispatch, companyId) => {
  return request("GET", `/locations/${companyId}`).then((res) => res.items)
})

export const create = asyncAction("locations/create", (dispatch, input) => {
  return request("POST", "/locations", { input }).then((res) => res.location)
})

export const edit = asyncAction("locations/edit", (dispatch, id, patch) => {
  return request("PATCH", `/location/${id}`, { patch }).then((res) => res.location)
})

export const remove = asyncAction("locations/remove", (dispatch, id) => {
  return request("DELETE", `/location/${id}`).then(() => ({ id }))
})
