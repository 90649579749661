import React from "react"
import "./index.scss"

export default function ListItem({ text, children }) {
  return (
    <div className="list-item">
      <p>{text}</p>
      {children}
    </div>
  )
}
