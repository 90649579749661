import React from "react"
import ReactSelect from "react-select"
import HiddenInput from "./HiddenInput"

// Options is a list of {label, value} objects
// Value is the selected values
export default function Select({
  name,
  required,
  disabled,
  attr = "name",
  collection,
  value,
  onChange,
  menuPosition = "absolute",
  menuPlacement = undefined,
  isMulti = true,
  closeMenuOnSelect,
  allowSelectAll=false
}) {
  value = Array.isArray(value) ? value : [value];
  // Alternative to `menuPosition="fixed"`:
  // menuPosition="relative"
  // styles={{ menu: (provided, state) => ({ ...provided, position: "relative" }) }}
  let collectionAdj = collection.map((item) => ({ label: item[attr], value: item.id }))
  if(allowSelectAll) {
    collectionAdj = [{ label: "Select All", value: "ALL" }, ...collectionAdj]
  }
  return (
    <div className="HiddenInput-container Select">
      <ReactSelect
        isMulti={isMulti}
        isClearable={false}
        closeMenuOnSelect={closeMenuOnSelect}
        name={name}
        isDisabled={disabled}
        menuPosition={menuPosition}
        options={collectionAdj}
        onChange={(items) => onChange(items ? (isMulti ? items.map((item) => item.value) : [items.value]) : [])}
        value={collection
          .filter((item) => value.includes(item.id))
          .map((item) => ({ label: item[attr], value: item.id }))}
        menuPlacement={menuPlacement}
      />
      {required && <HiddenInput message={value.length === 0 ? "Please select at least one item" : null} />}
    </div>
  )
}
