import { useRef } from "react"

export function arraysAreEqual(array1, array2) {
  // Compare references
  if (array1 === array2) {
    return true
  }
  // Compare lengths
  if (array1.length !== array2.length) {
    return false
  }
  // Shallow compare every item. Relies on the consistent order of the items
  let areEqual = true
  array1.forEach((item, idx) => {
    if (item !== array2[idx]) {
      areEqual = false
    }
  })
  return areEqual
}

// Memoizes an array, returning a stable reference if the array items hasn't
// changed. For this to work, the array must be sorted.
export default function useMemoizeArray(array) {
  const ref = useRef(array)
  if (!arraysAreEqual(array, ref.current)) {
    ref.current = array
  }
  return ref.current
}
