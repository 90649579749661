import React, { useState } from "react"
import { ConfigContainer, Popup } from "components"
import { Container, Title, Text, ScrumColumnPicker } from "components/Form"


// Pops up when chaning an item from unplanned to planned
export default function UnplannedToPlannedConfig({
  cancelHandler,
  submitHandler,
}) {
  const [parentColumn, setParentColumn] = useState('TODO')
  const [columnName, setColumnName] = useState('')
  const res = {}
  return (
    <Popup onCancel={cancelHandler}>
      <ConfigContainer
        title="Create a new Column"
        cancelHandler={cancelHandler}
        submitHandler={() => {
            res.stage = parentColumn
            res.colName = columnName

            submitHandler(parentColumn, columnName)
        }}
      >
        <Container>
          <Title>
            Parent Column
          </Title>
          <ScrumColumnPicker 
              value={parentColumn}
              onChange={setParentColumn}
          />
        </Container>
        <Container>
            <Title>
                Column Name
            </Title>
            <Text required disabled={false} value={columnName} onChange={setColumnName} />
        </Container>
      </ConfigContainer>
    </Popup>
  )
}
