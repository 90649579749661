import React, { useState } from "react"
import { FiSettings } from "react-icons/fi"

// An edge between two points in the graph design tool.
export default function SvgEdge({ w, coords1, coords2, offset, onClick }) {
  const [showConfigure, setShowConfigure] = useState(false)

  const c1 = {
    x: coords1.x * w + offset.x,
    y: coords1.y * w + offset.y,
  }
  const c2 = {
    x: coords2.x * w + offset.x,
    y: coords2.y * w + offset.y,
  }
  const y = c2.y - c1.y
  const x = c2.x - c1.x

  let angle = Math.atan(x !== 0 ? y / x : (y > 0 ? -1 : 1) * 99999)
  if (x > 0) {
    if (y < 0) {
      angle += 2 * Math.PI
    }
  } else {
    angle += Math.PI
  }
  // angle += Math.PI; // to reverse the vector
  const len = w / 15
  const c3 = {
    x: (c1.x + c2.x) / 2 + (Math.cos(angle) * len) / 2,
    y: (c1.y + c2.y) / 2 + (Math.sin(angle) * len) / 2,
  }
  const c4 = {
    x: c3.x - len * Math.cos(angle - Math.PI / 4),
    y: c3.y - len * Math.sin(angle - Math.PI / 4),
  }
  const c5 = {
    x: c3.x - len * Math.cos(angle + Math.PI / 4),
    y: c3.y - len * Math.sin(angle + Math.PI / 4),
  }

  // Make the toucharea slightly bigger by adding a constant
  const size = 12

  return (
    <g>
      <line
        x1={c1.x}
        x2={c2.x}
        y1={c1.y}
        y2={c2.y}
        style={{
          stroke: "black",
          strokeWidth: "2",
        }}
      />
      <line
        x1={c4.x}
        x2={c3.x}
        y1={c4.y}
        y2={c3.y}
        style={{
          stroke: "black",
          strokeWidth: "2",
        }}
      />
      <line
        x1={c5.x}
        x2={c3.x}
        y1={c5.y}
        y2={c3.y}
        style={{
          stroke: "black",
          strokeWidth: "2",
        }}
      />
      {showConfigure && (
        <foreignObject width={w / 8} height={w / 4} x={c3.x - w / 6 / 2} y={c3.y - w / 7}>
          <FiSettings
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#f8f8f8",
            }}
            borderwidth={2}
            color="black"
          />
        </foreignObject>
      )}
      <polygon
        onMouseEnter={() => setShowConfigure(true)}
        onMouseLeave={() => setShowConfigure(false)}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onClick={onClick}
        style={{
          cursor: "pointer",
        }}
        fillOpacity="0"
        points={
          c4.x -
          size +
          "," +
          (c4.y + size) +
          " " +
          (c5.x - size) +
          "," +
          (c5.y - size) +
          " " +
          (c3.x + size) +
          "," +
          (c5.y - size) +
          " " +
          (c3.x + size) +
          "," +
          (c4.y + size) +
          " "
        }
      />
    </g>
  )
}
