// import { customerAdministration } from "state_management"
import { request } from "utils/request"
import { createMap, addToMap, replaceInMap, asyncAction, getRange } from "./common"

export const MemberRoles = {
  MEMBER: "member",
  PLANNER: "planner",
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "members/fetch":
      return createMap(action.res)
    case "members/create":
      return addToMap(state, action.res)
    case "members/createAsAdmin":
      return state // Noop
    case "members/archive":
    case "members/edit":
      return replaceInMap(state, action.res)
    case "members/fetchOrder":
      return state
    case "members/editOrder":
      return state
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetch = asyncAction("members/fetch", (dispatch) => {
  const state = window.store.getState()
  const [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )
  return request("GET", `/members?after=${after}&before=${before}`).then((res) => res.items)
})

export const createAsAdmin = asyncAction("members/createAsAdmin", (dispatch, input) => {
  return request("POST", "/members", { input }).then((res) => {
    // dispatch(customerAdministration.fetchCustomers())
    return res.member
  })
})

export const create = asyncAction("members/create", (dispatch, input) => {
  return request("POST", "/members", { input }).then((res) => res.member)
})

export const edit = asyncAction("members/edit", (dispatch, id, patch) => {
  return request("PATCH", `/member/${id}`, { patch }).then((res) => res.member)
})

export const archive = asyncAction("members/archive", (dispatch, id) => {
  return request("POST", `/member/${id}/archive`).then((res) => res.member)
})

export const fetchOrder = asyncAction("members/fetchOrder", (dispatch) => {
  return request("GET", "/members/order").then((res) => res)
})

export const editOrder = asyncAction("members/editOrder", (dispatch, input) => {
  return request("POST", "/members/order", { input }).then((res) => {
    dispatch(fetch())
    return res.items
  })
})
